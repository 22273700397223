import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import dash from '../../images/dash.png';
import cp from '../../images/callpay.png';
import mm from '../../images/mobmoney.png';
import cc from '../../images/credit.png';
import tp from '../../images/st3.png';
import { useNavigate } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { getSubscriptionPlanList, getSubscriptionPayment, getUpdatePlan } from "../../../Service/Subscription/SubscriptionStripe";
import { getMobilePayment, getUserprofiledata, getUserSubscription } from '../../../Service/User/UserService';
import Sidebar from '../layout/Sidebar';
import Dbheader from '../layout/Dbheader';
import * as yup from 'yup';
import { useFormik } from 'formik';
import axios from 'axios';
import Usr from '../../images/usr.png';
import Lock from '../../images/lock.png';
import Env from '../../images/envelop.png';
import Case from '../../images/suitcase.png';
import { Col, Toast } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
const Mysubscription = ({setIsSpinner}) => {
    //const { logo } = props;
    const [show, setShow] = useState(false);
    const [showotherfield, setShowField] = useState(false);
    const [mobilepaymentmodalshow, setMobilePaymentModalShow] = useState(false);
    const [showupgrade, setShowUpgrade] = useState(false);
    const navigate = useNavigate();
    const handleCloseUpgrade = () => setShowUpgrade(false);
    const handleClose = () => setShow(false);
    const handleShow = () =>setShow(true) ;
    const handleMobileClose = () => setMobilePaymentModalShow(false);
    const handleMobileShow = () => setMobilePaymentModalShow(true);
    const [subscriptionPlan, setAllSubscriptionPlanList] = useState([]);
    const [subscriptionPlanId, setAllSubscriptionPlanId] = useState();
    const [planEndDate, setPlanEndDate] = useState();
      const [buttonChange, setButtonChange] = useState(false);
      const [profiledata, setProfiledata] = useState([]);
      const [selectedPrice, setSelectedPrice] = useState();
      const [selectedPayment, setSelectedPayment] = useState("manual");
      const [transactionId, setTransactionId] = useState("");
      const [transactionMonth, setTransactionMonth] = useState(0);
      const [transactionMode, setTransactionMode] = useState('');
      const [transactionphone, setTransactionPhone] = useState('');
      const [planamount, setPlanAmount] = useState();
      console.log(selectedPrice,'selectedprice==')
    useEffect(() => {
        GetMySubscription();
        GetMyProfileData();
    }, []);
     const GetMyProfileData = () => {
            getUserprofiledata().then(function (result) {
                const response = result?.data?.data
                console.log(response,"=======response======")
                setProfiledata(response);
                formik.setFieldValue('firstname', response.fname +' ' +response.lname|| '');
      formik.setFieldValue('companyname', response.company_name || '');
      formik.setFieldValue('email', response.email || '');
      formik.setFieldValue('industry_type', response.industry_type || '');
            });
        }

        const onIndustry= (e) => {
            setProfiledata({ ...profiledata, industry_type: e.target.value });
        };
        const onMonthChange=(e)=>
            {
              setTransactionMonth(e.target.value);
              setShowField(true)
            }
            const onModeChange=(e)=>
                {
                    setTransactionMode(e.target.value);
                }
                const OnIdChange=(e)=>
                    {
                      setTransactionId(e.target.value);
                    }
                    const OnphoneNoChange=(e)=>
                        {
                          setTransactionPhone(e.target.value);
                        }
  const PostMobilePayment = async () => {
    if(transactionMonth==''||transactionMonth==0)
      {
          toast.error('Please Enter Month')
      }
    else if(transactionId=='')
    {
        toast.error('Please Enter Transaction Number')
    }
                           else
                        {    const data={sender_mobile_number:transactionphone,
                                transaction_number:transactionId,
                                transaction_mode:transactionMode,
                                plan_amount:planamount,
                                month_count:transactionMonth,
                                total_amount:transactionMonth * planamount,
                            }
                            setIsSpinner(true)
                      await getMobilePayment(data, (res) => {
                                 console.log(res,"=======response======")
                                 setIsSpinner(false)
                               //  setIsSpinner(false)
                                if (res?.success == true) {
                                       Swal.fire({
                                         title: res.message,
                                         icon: 'success',
                                         confirmButtonText: 'OK',
                                         customClass: {
                                           confirmButton: 'my-confirm-button'
                                         }
                                       });
                                       setMobilePaymentModalShow(false);
                                       setShowField(false)
                                     } else {
                                       toast.error(res.message);
                                     }
                             });}
                         }

    const GetMySubscription = () => {
       // setIsSpinner(true)
        getUserSubscription().then(function (result) {
            const response = result?.data?.data
            console.log(response,"=======response======")
            setAllSubscriptionPlanId(response?.plan_id);
            setPlanEndDate(response?.subscription_end_time)
          //  setIsSpinner(false)
        });
    }
    const onSubscribeNow = (planId) => {
        console.log(planId, "planId")
        // window.scrollTo(0, 0);
        if(localStorage.getItem('1Simpleclick_AuthToken'))
       { getSubscriptionPayment(planId, (res) => {
            console.log(res, "planREsponse")
            if (res.success === true) {
                window.location.href = res?.data?.url;
                // navigate('https:\/\/checkout.stripe.com\/c\/pay\/cs_test_a1etc8MdjiIQOMOE1DvT98sxj4FnT3bGpxFuVL9p5HUevDefa4x9qnKjVk#fidkdWxOYHwnPyd1blpxYHZxWjA0SlV2M1xEckRoYWowd3U3U3RpUH1zQHBtTzx%2FNWlTUDUxUUpRfHQ3d2FGd3VddXVDYElAYkhWcnRxU39uVWJtY0dJb1Q1PTRiZGNzVUxSQTw0ME5pcGY2NTVydkJsbmhNXCcpJ2N3amhWYHdzYHcnP3F3cGApJ2lkfGpwcVF8dWAnPyd2bGtiaWBabHFgaCcpJ2BrZGdpYFVpZGZgbWppYWB3dic%2FcXdwYHgl')
                // toast.success(res.message)
                // setShowDelete(false)
                // getCustomerDataList();

            } else {
                // toast.error(res.message)

            }
        })}
    }
    const handleContinue = () => {
        if (selectedPayment === "manual") {
            setShowUpgrade(true)
        } else if (selectedPayment === "online") {
            onSubscribeNow(selectedPrice, selectedPrice);
            console.log('planId',selectedPrice)
        }
        else if (selectedPayment === "mobile") {
            handleMobileShow();
        }
        
        handleClose(); // Close modal after selecting
      };
    //subscription plan api call
    useEffect(() => {
        getSubscriptionPlanDataList();

    }, []);

    async function getSubscriptionPlanDataList() {
        setIsSpinner(true)
        getSubscriptionPlanList().then(function (result) {
            const response = result?.data;
            // console.log(response, "rrrr")
            setAllSubscriptionPlanList(response?.data?.data);
            setIsSpinner(false)
        });
    }
    console.log(subscriptionPlan, "subscriptionPlan")
    
        const formik = useFormik({
          initialValues: {
            user:'',
            firstname: '',
            companyname:'',
            email: '',
            industry_type: '',
        others: '',
          },
          validationSchema: yup.object({
              user:yup.string().required("Number of users is a required field"),
            firstname: yup.string().min(2, "Name must have atleast 2 chracter").required("First Name is a required field"),
           // lastname: yup.string().min(2, "Name must have atleast 2 chracter").required("Last Name is a required field"),
            companyname: yup.string().min(5, "Name must have atleast 5 chracter").required("Company Name is a required field"),
            email: yup.string().email().required("Email is a required field"),
            industry_type: yup.string().required("Select a industry type"),
           // phone: yup.string().min(11, "Phone Number is a required field").required("Phone Number is a required field"),
            
      
          }),
      
      
      
          onSubmit: async (values, { resetForm }) => {
            // login(values);
            setButtonChange(true)
            setIsSpinner(true);
            await getUpdatePlan(profiledata?.id, values.user,values.firstname, values.companyname,
                values.email, values.industry_type, values.others, (res) => {
               console.log(res,'kkkk')
                  if (res.success === true) {
                    setButtonChange(false)
                    setIsSpinner(false)
                    // setAuthed({isAuth: true, token: res.data.token, user: userdata})
                    toast.success(res.message)
                   
                    navigate("/myprofile");
        
                    formik.resetForm();
                  } else {
                    setButtonChange(false)
                    toast.error(res.message)
                    setIsSpinner(false)
                  }
                })
            
          }
      
        })
      
        console.log(formik.values, "phoneeeeeeeeeeee")
      
      
        const renderFirstNameError = formik.touched.firstname && formik.errors.firstname && (<span className='errorTxt' style={{ color: "red" }}>{formik.errors.firstname}</span>);
      
        const renderuserError = formik.touched.user && formik.errors.user && (<span className='errorTxt' style={{ color: "red" }}>{formik.errors.user}</span>);
      
        const renderCompanyNameError = formik.touched.companyname && formik.errors.companyname && (<span className='errorTxt' style={{ color: "red" }}>{formik.errors.companyname}</span>);
      
        const renderEmailError = formik.touched.email && formik.errors.email && (<span className='errorTxt'>{formik.errors.email}</span>);
      
        const renderIndustryError = formik.touched.industry_type && formik.errors.industry_type && (<span className='errorTxt'>{formik.errors.industry_type}</span>);
      
        const renderPhoneError = formik.touched.phone && formik.errors.phone && formik.values.phone > 5 && (<span className='errorTxt'>{formik.errors.phone}</span>);
    return (

        <div className="producservlisting pdt">
            {/* <div className='proflogo'><Link to="/"><img src={logo} /></Link></div> */}
            <Dbheader />
            <Sidebar>
            <div className='authLogo pt-5'>&nbsp;</div>

            <div className='signForm subsMain'>
                <div className='choosePlanTop'>
                    <h3>Update Your Plan</h3>
                    <img src={dash} />
                    <p>Pay for the entire year and get two months absolutely free</p>
                </div>
                <div className='choosePlanBtm'>
                    <div className='row'>
                        {subscriptionPlan?.map((d) => (

                            <div className='col-md-4'>

                                <div className='planBg' style={{ border: `2px solid ${subscriptionPlanId === d?.default_price &&profiledata?.is_subscribed!='admin'? 'blue':'#ddd'}` }}>
                                    <div className={d?.name === "Yearly" ||subscriptionPlanId==d?.default_price ? 'plantop orangebg' : 'plantop'}>
                                        <h5 className='m-0'>{d?.name} </h5>
                                        {d?.name === "Yearly" ?
                                            <button>Popular</button> : ""}
                                    </div>

                                    <div className='plancontent'>
                                        <h2>${d?.plan_price}<small>/ Monthly</small></h2>

                                        {/* <p>Lorem ipsum dolor sit amet,consectetur adipiscing elit.</p> */}
                                        <ul>
                                            {d?.features?.map((s)=>(
                                                <>

                                            <li>{s?.name}</li>
                                            
                                            </>
                                            ))}<p>
                                            {subscriptionPlanId === d?.default_price &&profiledata?.is_subscribed!='admin'&& (
                                              <>
                                                <span style={{ fontWeight: 'bold',color:'#2666ef' }}>Expiry Soon -</span>
                                                <span>{planEndDate}</span>
                                              </>
                                            )}
                                          </p>
                                          

                                        </ul>
         

                                        {subscriptionPlanId==d?.default_price||profiledata?.is_subscribed=='admin' ? 
                                    <button className='commonButton buttonSubmit disabled' onClick={onSubscribeNow.bind(d?.default_price, `${d?.default_price}`)}>Upgrade</button>
                                    :
                                    <button className='commonButton buttonSubmit'
                                  //  onClick={onSubscribeNow.bind(d?.default_price, `${d?.default_price}`)}
                              onClick={()=>{setSelectedPrice(d?.default_price);setPlanAmount(d?.plan_price);handleShow();}}
                                     >Upgrade</button>    
                                    }
                                        
                                    </div>

                                </div>

                            </div>
                        ))}


                        <div className='col-md-4' >

                            <div className='planBg' style={{ border: `2px solid ${profiledata?.is_subscribed == 'admin'? 'blue':'#ddd'}` }}>
                                <div className="plantop">
                                    <h5 className='m-0'>Professional Package</h5>

                                    <button>Popular</button>
                                </div>

                                <div className='plancontent'>
                                    {/* <h2>&nbsp;</h2> */}

                                    <h6 className='highlight pb-3'>Let the Professionals handle it for you</h6>
                                    <ul>
                                        <li>Full bookkeeping services</li>
                                        <li>Financial planning, budgeting & forecasting</li>
                                        <li>Financing and Cash flow planning</li>
                                        <li>Audit preparations</li>
                                        <li>Internal Control Improvements</li>
                                        <li>Risk Management <small>
                                            {/* <i class="fa-regular fa-bracket-round"></i>  <i class="fa-regular fa-bracket-round-right"></i> */}
                                            </small></li>
                                            <li><small>*For fees, contact us for a customized fee structure that meets your business needs*</small></li>
                                        <li>Financial Reporting and Advisory Services</li>
                                    </ul>
                                   { profiledata?.is_subscribed=='admin'?
                                    <button className='commonButton buttonSubmit disabled' onClick={()=>setShowUpgrade(true)}>Upgrade</button>
                                    :
                                    <button className='commonButton buttonSubmit ' onClick={()=>setShowUpgrade(true)}>Upgrade</button>
                                    }
                                </div>

                            </div>

                        </div>

                        {/* <div className='col-md-6'>
                            <div className='planBg'>
                                <div className='plantop'>
                                    <h5 className='m-0'>Annual plan</h5>
                                    <button>Popular</button>
                                </div>
                                <div className='plancontent'>
                                    <h2>$129<small>,00/month</small></h2>
                                    <p>Lorem ipsum dolor sit amet,consectetur adipiscing elit.</p>
                                    <ul>
                                        <li>Lorem ipsum dolor sit amet</li>
                                        <li>Lorem ipsum dolor sit amet</li>
                                        <li>Lorem ipsum dolor sit amet</li>
                                    </ul>
                                    <button className='commonButton buttonSubmit' onClick={onSubscribeNow}>Subscribe</button>
                                </div>
                            </div>
                        </div> */}
                    </div>
                </div>
            </div>
            <Modal  show={showupgrade} onHide={handleCloseUpgrade} className='delModal'>
                                        <Modal.Header closeButton>
                                            <Modal.Title><h5>Upgrade Your Plan</h5></Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body>
                                        <div className='signMainform register'>
                    <form onSubmit={buttonChange ? formik.handleSubmit : formik.handleSubmit}>                    
                    <div className='fieldBlock mb-2'>
                      <label htmlFor="name"><img src={Usr} />&nbsp;</label>
                      <input type="number" id="user" className='form-control' name="user" onChange={formik.handleChange} value={formik.values.user} placeholder='Number of users to be created' />
                      {renderuserError}
                    </div>
                    <div className='fieldBlock mb-2'>
                      <label htmlFor="name"><img src={Case} />&nbsp;</label>
                      <input type="text" id="companyname" className='form-control disabled' name="companyname" onChange={formik.handleChange} value={formik.values.companyname} placeholder='Company Name' />
                      {renderCompanyNameError}
                    </div>

                    <div className='fieldBlock mb-2'>
                      <label htmlFor="name"><img src={Usr} />&nbsp;</label>
                      <input type="text" id="contact person" className='form-control disabled' name="contact person" onChange={formik.handleChange} value={formik.values.firstname} placeholder='Contact Person' />
                      {renderFirstNameError}
                    </div>

                        <div className='fieldBlock mb-2'>
                        <label><img src={Env} />&nbsp; </label>
                        <input type="email" id="email" name="email" className='form-control disabled' placeholder='Email address'  onChange={formik.handleChange} value={formik.values.email} />
                        {renderEmailError}
                        </div>
                        <Col lg="6" className='form-control disabled'>
                                                {/* <Form.Control type="text" placeholder="Financial" /> */}
                                                <Form.Select style={{border:'none',padding:0}} aria-label="Default select example" id="industry" name="industry" value={formik.values.industry_type} onChange={(e) => formik.handleChange}>
                                                    <option hidden selected>Select Business Industry</option>
                                                    <option value="Technology">Technology</option>
                                                    <option value="Finance">Finance</option>
                                                    <option value="Healthcare">Healthcare</option>
                                                    <option value="Retail">Retail</option>
                                                    <option value="Hospitality and Tourism">Hospitality and Tourism</option>
                                                    <option value="Manufacturing">Manufacturing</option>
                                                    <option value="Real Estate">Real Estate</option>
                                                    <option value="Entertainment and Media">Entertainment and Media</option>
                                                </Form.Select>
                                            </Col>
                                            {renderIndustryError}

<div className="fieldBlock mb-2 fieldTextarea" style={{ marginTop: '20px' }}>
  <textarea
    className="form-control"
    name="others" // Name matches the key in formik.values
    placeholder="Other Details"
    onChange={formik.handleChange} // Formik handles the change event
    value={formik.values.others} // Bind value to Formik state
  ></textarea>
  {formik.errors.others && formik.touched.others && (
    <div className="error-message">{formik.errors.others}</div> // Show validation error if it exists
  )}
</div>





                        <button type="submit" className='commonButton buttonSubmit' >Submit</button>
                    </form>
                    </div>
                                        </Modal.Body>
                                    </Modal>

            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton className='paymentShow'>
                    <Modal.Title>Choose Payment method</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {/* <h3>Choose Payment method</h3> */}
                    <div className='subscriptionRado'>
                        <div className='radio'>
                            <input type="radio" id="test1" name="radio-group" checked={selectedPayment === "manual"} 
            onChange={() => setSelectedPayment("manual")}/>
                            <label for="test1"><img src={cc} /> Manual Payment</label>
                        </div>
                        <div className='radio'>
                            <input type="radio" id="test2" name="radio-group"  checked={selectedPayment === "online"} 
            onChange={() => {setSelectedPayment("online")}}/>
                            <label for="test2"><img src={mm} /> Online Payment </label>
                        </div>
                        <div className='radio'>
                            <input type="radio" id="test3" name="radio-group" checked={selectedPayment === "mobile"} 
            onChange={() => {setSelectedPayment("mobile")}}/>
                            <label for="test3"><img src={cp} /> Mobile Payment </label>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer style={{ justifyContent: "flex-start" }}>
                    <button className='commonButton buttonSubmit w-auto' onClick={handleContinue}>Continue</button>
                </Modal.Footer>
            </Modal>

{/*================Mobile Payment Modal =================*/}
<Modal show={mobilepaymentmodalshow} onHide={handleMobileClose}>
  <Modal.Header closeButton className="paymentShow">
    <Modal.Title>Mobile Payments</Modal.Title>
  </Modal.Header>
  <Modal.Body>
    <div className="subscriptionRado">
      <div style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
        <label>
          We request you to complete the payment by sending it to
          <span className="phone-number"> +380 02545658978</span>
        </label>
        <label>
          Plan Amount - <span style={{ fontWeight: "bold" }}>${planamount}</span>
        </label>
      </div>

      <div className="fieldBlock mb-2">
        <label htmlFor="industry">
          <img src={mm} alt="icon" />&nbsp;
        </label>
        <Col lg="6" className="form-control">
          <Form.Select
            style={{ border: "none" }}
            aria-label="Default select example"
            id="industry"
            name="industry"
            value={transactionMonth}
            onChange={(e) => onMonthChange(e)}
          >
            <option hidden>Select Month</option>
            {Array.from({ length: 24 }, (_, i) => (
              <option key={i + 1} value={i + 1}>
                {i + 1}
              </option>
            ))}
          </Form.Select>
        </Col>
      </div>

      {showotherfield && (
        <>
          <div className="fieldBlock mb-2">
            <p>Total Payable Amount - ${transactionMonth * planamount}</p>
          </div>

          <div className="fieldBlock mb-2">
            <label htmlFor="transactionid">
              <img src={cc} alt="icon" />&nbsp;
            </label>
            <input
              type="text"
              id="transactionid"
              className="form-control"
              name="transactionid"
              value={transactionId}
              placeholder="Transaction Number"
              onChange={(e) => OnIdChange(e)}
            />
          </div>

          <div className="fieldBlock mb-2">
            <label htmlFor="transactionmode">
              <img src={cc} alt="icon" />&nbsp;
            </label>
            <input
              type="text"
              id="transactionmode"
              className="form-control"
              name="transactionmode"
              value={transactionMode}
              placeholder="Transaction Type"
              onChange={(e) => onModeChange(e)}
            />
          </div>

          <div className="fieldBlock mb-2">
            <label htmlFor="phoneno">
              <img src={cp} alt="icon" />&nbsp;
            </label>
            <input
              type="text"
              id="phoneno"
              className="form-control"
              name="phoneno"
              value={transactionphone}
              placeholder="Phone No"
              onChange={(e) => OnphoneNoChange(e)}
            />
          </div>
        </>
      )}
    </div>
  </Modal.Body>
  <Modal.Footer style={{ justifyContent: "flex-start" }}>
    <button className="commonButton buttonSubmit w-auto" onClick={PostMobilePayment}>
      Continue
    </button>
  </Modal.Footer>
</Modal>


            </Sidebar>
        </div>
    )
}

export default Mysubscription