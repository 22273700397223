import { React, useState } from 'react'
import Sidebar from '../../layout/Sidebar'
import Dbheader from '../../layout/Dbheader'
import Form from 'react-bootstrap/Form';
import Headtxt from '../Headtxt';
import Col from 'react-bootstrap/Col';
import { PhoneInput } from 'react-international-phone';
import Modal from 'react-bootstrap/Modal';
import { City, Country, State } from "country-state-city";
import { getAddCustomer, getImportCustomer } from "../../../../Service/Customer/CustomerService";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';
import Papa from 'papaparse';
import sampleCsv from './SampleCustomerCsv';
import { CSVLink } from "react-csv";
import CurrencySelect from 'react-select-currency';
import Swal from 'sweetalert2';


const Addcustomer = ({setIsSpinner}) => {
  const navigate = useNavigate();
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  // For toggle
  const [isToggled, setToggled] = useState('');

  const handleToggleOn = () => {
    setToggled(true);
    
  };
  const handleToggleOff = () => {
    setToggled(false);
    setDeliveryAddress('');
    setDeliveryCountry('');
    setDeliveryState('');
    setDeliveryCity('');
    setDeliveryZipCode('')
  };
console.log(isToggled,'isToggles')
  const defaultCountry = 'KE';
  const defaultState = '30'; // ISO code for Nairobi County
  const defaultCity = 'Nairobi'; // Default city in Nairobi County

  let countryData = Country.getAllCountries();
  let initialStates = State.getStatesOfCountry(defaultCountry);
  let initialCities = City.getCitiesOfState(defaultCountry, defaultState);
  let deliveryCountryData = Country.getAllCountries();


  // For drag-drop
  const [file, setFile] = useState(null);
  const [companyType, setCompanyType] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [webUrl, setWebUrl] = useState('');
  const [vatNumber, setVatNumber] = useState('');
  const [billAddress, setBillAddress] = useState('');
  const [country, setCountry] = useState(defaultCountry);
  const [DeliveryCountry, setDeliveryCountry] = useState("");
  const [state, setState] = useState("");
  const [deliveryState, setDeliveryState] = useState("");
  const [city, setCity] = useState("");
  const [deliveryCity, setDeliveryCity] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [deliveryZipCode, setDeliveryZipCode] = useState();
  const [stateData, setStateData] = useState(initialStates);
    const [cityData, setCityData] = useState(initialCities);
  const [deliveryCityData, setDeliveryCityData] = useState([]);
  const [deliveryStateData, setDeliveryStateData] = useState([]);
  const [jsondata, setJsonData] = useState([]);
  const [selectedCurrency, setSelectedCurrency] = useState('KES');
  const [deliveryAddress, setDeliveryAddress] = useState("");
  const handleDrop = (event) => {
    console.log("onDROPPPPP")
    event.preventDefault();

    const droppedFile = event.dataTransfer.files[0];
    handleFile(droppedFile);


  };

  const handleFile = (selectedFile) => {
    console.log(selectedFile, "selectedFile")
    if (selectedFile) {
      // Check if the selected file is a CSV file
      if (selectedFile.type === 'text/csv' || selectedFile.name.endsWith('.csv')) {
        // File is valid, set it in the state

        setFile(selectedFile);
        Papa.parse(selectedFile, {
          header: true, // Assuming the first row contains headers
          complete: (result) => {
            console.log(result.data, "dadadadada")
            setJsonData(result.data);
          },
          error: (error) => {
            console.error('CSV parsing error:', error.message);
          },
        });
      } else {
        // File is not valid, show an error or do something else
        toast.error('Please select a valid CSV file');
        // Optionally, you can clear the file input

      }
    }



    // You can perform additional actions with the file, such as uploading it to a server.
    // For simplicity, we're just logging the file information here.
    console.log('File selected:', selectedFile);
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const onCompanyType = (e) => {
    setCompanyType(e.target.value)
  }

  const onCompanyName = (e) => {
    setCompanyName(e.target.value)
  }

  const onFirstName = (e) => {
    setFirstName(e.target.value)
  }

  const onLastName = (e) => {
    setLastName(e.target.value)
  }

  const onEmail = (e) => {
    setEmail(e.target.value)
  }
  const onWebUrl = (e) => {
    setWebUrl(e.target.value)
  }

  const onVatNumber = (e) => {
    setVatNumber(e.target.value)
  }

  const onBillingAddress = (e) => {
    setBillAddress(e.target.value)
  }

  const onCountry = (e) => {
    setCountry(e.target.value)
    setStateData(State.getStatesOfCountry(e.target.value));
  }

  const onDeliveryCountry = (e) => {
    console.log(e.target.value, "cdscds");
    setDeliveryCountry(e.target.value)
    setDeliveryStateData(State.getStatesOfCountry(e.target.value));
  }
  console.log(deliveryStateData, "dsddsd")

  const onState = (e) => {
    console.log(e.target.value, "stateiso")
    setState(e.target.value);
    setCityData(City.getCitiesOfState(country, e.target.value));
  }

  const onDeliveryState = (e) => {
    console.log(e.target.value, "stateiso")
    setDeliveryState(e.target.value);
    setDeliveryCityData(City.getCitiesOfState(DeliveryCountry, e.target.value));
  }

  const onCity = (e) => {
    setCity(e.target.value)
  }

  const onDeliveryCity = (e) => {
    setDeliveryCity(e.target.value)
  }

  const onZipCode = (e) => {
    setZipCode(e.target.value)
  }

  const onDeliveryAddress = (e) => {
    setDeliveryAddress(e.target.value)
  }

  const onDeliveryZipCode = (e) => {
    setDeliveryZipCode(e.target.value)
  }
  console.log('state===',state)
  const handleCurrencyChange = (selectedOption) => {
    const currency = selectedOption.target.value;
    setSelectedCurrency(currency);
    //  const url = `${Base_URL}`;
    // console.log('Selected currency:', currency);
    // console.log('Selected currency:', selectedCurrency);
  
      // You can send this URL to the API endpoint
    };
  //Add customer
  const onSave = () => {

    if (companyType === "") {
      toast.error("Please select company type")

    } else if (companyType === "Company" && companyName === "") {
      toast.error("Please type company name")

    }
    else if (companyType === "Individual" && firstName === "") {
      toast.error("Please type first name")

    } else if (companyType === "Individual" && lastName === "") {
      toast.error("Please type last name")

    } else if (email === "") {
      toast.error("Please type valid email")
    } else if (phone === "" || phone.length < 7) {
      toast.error("Please type phone number")
    }
    else if (billAddress === "") {
      toast.error("Please type billing address")
    }
    else if (country === "") {
      toast.error("Please select country")

    } 
    // else if (state === "") {
    //   toast.error("Please select state")
    // } 
    // else if (city === "") {
    //   toast.error("Please select city")
    // }
    else if (selectedCurrency === "" ||selectedCurrency === null ||selectedCurrency === undefined) {
      toast.error("Please select currency");
  } 
    // else if (zipCode === "") {
    //   toast.error("Please type zipcode")
    // }
    else if(isToggled==='')
      {
        toast.error("Please choose address for delivery")
      }
else if(isToggled &&deliveryAddress==='')
{
  toast.error("Please type delivery address")
}

else if(isToggled &&DeliveryCountry==='')
  {
    toast.error("Please type delivery country")
  }
  else if(isToggled &&deliveryState==='')
    {
      toast.error("Please type delivery state")
    }
    else if(isToggled &&deliveryCity==='')
      {
        toast.error("Please type delivery city")
      }
      else if(isToggled &&deliveryZipCode==='')
        {
          toast.error("Please type delivery zipcode")
        }
    else {
      setIsSpinner(true);
      getAddCustomer(companyType, companyName, firstName, lastName, email, phone, webUrl
        , vatNumber, billAddress, countryData.find(c => c.isoCode === country)?.name, stateData.find(c => c.isoCode === state)?.name
        , city,selectedCurrency, zipCode, deliveryAddress, deliveryCountryData.find(c => c.isoCode === DeliveryCountry)?.name, deliveryStateData.find(c => c.isoCode === deliveryState)?.name,
        deliveryCity, deliveryZipCode,country,state,isToggled===''?DeliveryCountry:country,isToggled===''?deliveryState:state,
        (r) => {
          if (r.success === true) {
            setIsSpinner(false)
            Swal.fire({
              title: r.message,
            //  text: 'The product has been added successfully!',
              icon: 'success',
              confirmButtonText: 'OK',
              customClass: {
                  confirmButton: 'my-confirm-button'
              }
          }).then(() => {
              navigate("/customerlisting");
          })
        }
          else {
            console.log("nononononono")
            toast.error(r.message)
            setIsSpinner(false)
          }
        })
    }
  }
  console.log(jsondata, "jsondata")

  const onExcelImport = () => {
    getImportCustomer(jsondata,
      (r) => {
        console.log(r, "response")
        if (r.success === true) {
          toast.success(r.message)
          navigate('/customerlisting')

        } else {
          console.log("nononononono")
          toast.error(r.message)

        }
      })


  }
  return (
    <div className="customerListing pdt">
      <Dbheader />
      <Sidebar>
        <div className='customerMain'>
          <div className='custBtm'>
            <div className='dbTopsec pb-3'>
              <Headtxt link="/customerlisting" title="Add Customers" />
              <div className='custButton'>
                <button className='commonButton expBtn' onClick={handleShow}><i class="fa-light fa-download"></i> Import</button>
              </div>
            </div>

            <div className='commonWhite addCustomerForm p-3 mb-3'>
              <Form>
                <Form.Group className="row" controlId="exampleForm.ControlInput1">
                  <Col md="4" className='mb-3'>
                    <Form.Select aria-label="Default select example" onChange={onCompanyType}>
                      <option selected hidden>Select Customer Type</option>
                      <option value="Company">Company</option>
                      <option value="Individual">Individual</option>

                    </Form.Select>
                  </Col>
                  {companyType === "Individual" || companyType === "" ? <><Col md="4" className='mb-3'>
                    <Form.Control type="text" placeholder="First Name" onChange={onFirstName} />
                  </Col>
                    <Col md="4" className='mb-3'>
                      <Form.Control type="text" placeholder="Last Name" onChange={onLastName} />
                    </Col></>
                    : <Col md="8" className='mb-3'>
                      <Form.Control type="text" placeholder="Company Name" onChange={onCompanyName} />
                    </Col>
                  }

                </Form.Group>

                <Form.Group className="row" controlId="exampleForm.ControlInput1">
                  <Col md="6" className='mb-3'>
                    <Form.Control type="email" placeholder="Email address" onChange={onEmail} />
                  </Col>
                  <Col md="6" className='mb-3'>
                    <PhoneInput
                      defaultCountry="ke"
                      value={phone}
                      className='phInp'
                      onChange={(phone) => setPhone(phone)}
                    />
                  </Col>
                </Form.Group>

                <Form.Group className="row" controlId="exampleForm.ControlInput1">
                  <Col md="6" className='mb-3'>
                    <Form.Control type="text" placeholder="Website URL (Optional)" onChange={onWebUrl} />
                  </Col>
                  <Col md="6" className='mb-3'>
                    <Form.Control type="text" placeholder="VAT Number(Optional)" onChange={onVatNumber} />
                  </Col>
                </Form.Group>

                <Form.Group className="row" controlId="exampleForm.ControlInput1">
                  <Col md="6" className='mb-3'>
                    {/* <Form.Control type="text" placeholder="Billing Address" /> */}
                    <Form.Control as="textarea" placeholder="Billing Address" rows={3} onChange={onBillingAddress} />
                  </Col>
                  <Col md="6" className='mb-3'>
                    <Form.Select aria-label="Default select example" className='mb-3' onChange={onCountry} value={country}>
                      <option selected hidden>Select country</option>

                      {countryData.map((con) => (
                        <option value={con.isoCode}>{con.name}</option>

                      ))}
                    </Form.Select>

                    <Form.Select aria-label="Default select example" className='mb-3' onChange={onState}>
                      <option selected hidden>Select state</option>

                      {stateData.map((sta) => (
                        <option value={sta.isoCode}>{sta.name}</option>

                      ))}
                    </Form.Select>

                    <Form.Select aria-label="Default select example" className='mb-3' onChange={onCity}>
                      <option selected hidden>Select city</option>

                      {cityData.map((cit) => (
                        <option value={cit.name}>{cit.name}</option>

                      ))}
                    </Form.Select>
                    <Col md="6" className='mb-3'>
        <CurrencySelect
      value={selectedCurrency}
      onChange={handleCurrencyChange}
      placeholder="Select a currency"      
      className="slctCurrency"
      style={{width:'200%'}}
    />
    {/* </div> */}
    </Col>
                    <Form.Control type="text" placeholder="Zip Code(Optional)" onChange={onZipCode} />
                  </Col>
                </Form.Group>

                <div className='radioline'><span>Use same address for delivery</span>
                  <div>
                    <input type="radio" id="test1" name="radio-group" onClick={handleToggleOff} />
                    <label for="test1">Yes</label>
                  </div>
                  <div>
                    <input type="radio" id="test2" name="radio-group" onClick={handleToggleOn} />
                    <label for="test2">No</label>
                  </div>
                  {/* <div className='radioRel'><Form.Check type="radio" label="Yes" name="group1" aria-label="radio1" /></div>
                  <div className='radioRel'><Form.Check type="radio" label="No" name="group1" aria-label="radio2" /></div> */}
                </div>

                <div className='pt-3'>
                  {isToggled &&
                    <div>
                      <Form.Group className="row mb-3" controlId="exampleForm.ControlInput1">
                        <Col md="6">
                          {/* <Form.Control type="text" placeholder="Billing Address" /> */}
                          <Form.Control as="textarea" placeholder="Delivery Address" rows={3} onChange={onDeliveryAddress} />
                        </Col>
                        <Col md="6">
                          <Form.Select aria-label="Default select example" className='mb-3' onChange={onDeliveryCountry}>
                            <option selected hidden>Select country</option>
                            {deliveryCountryData.map((con) => (
                              <option value={con.isoCode}>{con.name}</option>

                            ))}
                          </Form.Select>

                          <Form.Select aria-label="Default select example" className='mb-3' onChange={onDeliveryState}>
                            <option selected hidden>Select state</option>

                            {deliveryStateData.map((sta) => (
                              <option value={sta.isoCode}>{sta.name}</option>

                            ))}
                          </Form.Select>

                          <Form.Select aria-label="Default select example" className='mb-3' onChange={onDeliveryCity}>
                            <option selected hidden>Select city</option>

                            {deliveryCityData.map((cit) => (
                              <option value={cit.name}>{cit.name}</option>

                            ))}
                          </Form.Select>

                          <Form.Control type="text" placeholder="Zip Code(Optional)" onChange={onDeliveryZipCode} />
                        </Col>
                      </Form.Group>
                    </div>
                  }
                </div>


              </Form>
            </div>
            <button className='commonButton expBtn' onClick={onSave}>Save</button>


          </div>
        </div>
      </Sidebar>

      <Modal show={show} onHide={handleClose} centered className='customerModal'>
        <Modal.Header closeButton>
          <Modal.Title><h5>Import Customers</h5></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='modalContent'>
            <div
              onDrop={handleDrop}
              onDragOver={handleDragOver}
              className='drgdrop'
            >
              <i class="fa-light fa-cloud-arrow-up"></i>
              <div style={{ display: "flex" }}>
                <p>Drag &amp; drop files or </p>
                <input type="file" onChange={(e) => handleFile(e.target.files[0])} style={{ display: 'none' }} />
                <button onClick={() => document.querySelector('input[type="file"]').click()}>Browse</button>
              </div>

              {file && <p>Selected File: {file.name}</p>}
            </div>
          </div>
          <div>
            <p><button className='commonButton modalsubmit' onClick={onExcelImport}>Submit</button></p>

            <CSVLink filename="sample_customer.csv" data={sampleCsv}>
              <p><button className='commonButton downloadformat'>Download format </button></p>
            </CSVLink>
            <span className='commonButton downloadformat' style={{ fontSize: "13px" }}>(Please follow Demo data format mentioned in csv file)</span>

          </div>
        </Modal.Body>
        {/* <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleClose}>
            Save Changes
          </Button>
        </Modal.Footer> */}
      </Modal>

    </div>
  )
}

export default Addcustomer