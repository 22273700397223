import React, { useState, useEffect } from 'react';
import Sidebar from '../../layout/Sidebar'
import Dbheader from '../../layout/Dbheader'
import { Link } from 'react-router-dom'
import Form from 'react-bootstrap/Form';
import Gridview from './Gridview';
import Listview from './Listview';
import Headtxt from '../Headtxt';
import { Button, Dropdown, Modal } from 'react-bootstrap';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { deleteCustomer, getCustomerList, getSearchCustomer, getExcelCustomer } from "../../../../Service/Customer/CustomerService";
import Card from 'react-bootstrap/Card'
import { useNavigate } from 'react-router-dom';
import { CSVLink } from "react-csv";
import '../../../../assets/css/style.css';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Swal from 'sweetalert2';
import { deleteUser, getUserList } from '../../../../Service/User/UserService';
const Userlisting = ({setIsSpinner}) => {

    const [selectedPage, setSelectedPage] = useState('/');
    const navigate = useNavigate();

    const handlePageChange = (page) => {
        setSelectedPage(page);
        navigate(page);
    };



    const [view, setView] = useState(false)

    const [hasMore, setHasMore] = useState(true);
    const [loading, setLoading] = useState(true);
    const [items, setItems] = useState(['Item 1', 'Item 2', 'Item 3', 'Item 4', 'Item 5', 'Item 6', 'Item 7', 'Item 8', 'Item 9', 'Item 10', 'Item 11', 'Item 12', 'Item 13', 'Item 14', 'Item 15']);
    const [visibleItems, setVisibleItems] = useState(5);
    const fetchMoreItems = (start) => {
        // Simulate fetching more items from an API
        const itemsPerPage = 5;
        const totalItems = 20; // Adjust as needed for your use case
        const newItems = [];

        for (let i = start; i < start + itemsPerPage; i++) {
            if (i < totalItems) {
                newItems.push(`Item ${i + 1}`);
            }
        }

        return newItems;
    };
    useEffect(() => {
        // Initial load of items
        loadMoreItems();
    }, []);

    const loadMoreItems = () => {
        setLoading(true);
        setTimeout(() => {
            // Simulate loading delay
            setVisibleItems((prev) => prev + 5);
            setLoading(false);
        }, 1000); // Adjust the delay as needed
    };
    const toggleHandler = (e) => {
        e.preventDefault();
        setView(true)
    }
    const toggleHandler1 = (e) => {
        e.preventDefault();
        setView(false)
    }
    const [userList, setAllUserList] = useState([]);
    const [deleteCustomerId, setDeleteCustomerId] = useState("");
    const [showDelete, setShowDelete] = useState(false);
    const [customerExport, setCustomerExport] = useState([]);
    const [showBlock, setShowBlock] = useState(false);
    const handleCloseDelete = () => setShowDelete(false);
    const handleCloseBlock = () => setShowBlock(false);
    const [adduser, setAddUser] = useState();
    const [status, setStatus] = useState('');
    console.log(userList, "customerList")
    useEffect(() => {
        getCustomerDataList();
        onExcelExport();
    }, []);

    async function getCustomerDataList() {
        getUserList().then(function (result) {
            const response = result?.data;
            // console.log(response, "rrrr")
            setAllUserList(response?.data);
            setAddUser(response?.subUserAdd);
        });
    }
console.log('adduser----------------',adduser)
    const onDelete = (data) => {
        setDeleteCustomerId(data);
        console.log(data, "datadatadatadatadatadata")
        setShowDelete(true)
    }
    const onBlock = (data,data2) => {
        setDeleteCustomerId(data);
        setStatus(data2)
        console.log(data, "datadatadatadatadatadata")
        setShowBlock(true)
    }
    const [show, setShow] = useState(false);
    const [selectedModules, setSelectedModules] = useState(null);
  
    const handleClose = () => setShow(false);
    const handleShow = (modulesData) => {
        setSelectedModules(JSON.parse(modulesData));
        setShow(true);
      };

    const onYes = () => {
        let data = {
            id:deleteCustomerId,
            status: 'Delete',
          }
          setIsSpinner(true)
        deleteUser(data, (res) => {
            console.log(res, "response")
            // if (res.success === true) {
            //     toast.success(res.message)
            //     setShowDelete(false)
            //     getCustomerDataList();

            // } 
            if (res.success === true) {
                setIsSpinner(false)
                Swal.fire({
                    title: res.message,
                    //  text: 'The product has been added successfully!',
                    icon: 'success',
                    confirmButtonText: 'OK',
                    customClass: {
                        confirmButton: 'my-confirm-button'
                    }
                }).then(() => {
                    setIsSpinner(false)
                    setShowDelete(false)
                    getCustomerDataList();
                })
            }
            else {
                setIsSpinner(false)
                toast.error(res.message)

            }
        })
    }

    const onNo = () => {
        setShowDelete(false)

    }
    const onYesBlock = () => {
        let data = {
            id:deleteCustomerId,
            status:status=='Active'? 'Block':'Active',
          }
          setIsSpinner(true)
        deleteUser(data, (res) => {
            console.log(res, "response")
            // if (res.success === true) {
            //     toast.success(res.message)
            //     setShowDelete(false)
            //     getCustomerDataList();

            // } 
            if (res.success === true) {
                setIsSpinner(false)
                Swal.fire({
                    title: res.message,
                    //  text: 'The product has been added successfully!',
                    icon: 'success',
                    confirmButtonText: 'OK',
                    customClass: {
                        confirmButton: 'my-confirm-button'
                    }
                }).then(() => {
                    setShowBlock(false)
                    getCustomerDataList();
                    setIsSpinner(false)
                })
            }
            else {
                setIsSpinner(false)
                toast.error(res.message)

            }
        })
    }

    const onNoBlock = () => {
        setShowBlock(false)

    }
    const convertToCSV = (data) => {
        // const header = Object.keys(data[0]).join(',');
        // const rows = data.map((row) => Object.values(row).join(','));
        return [data].join('\n');
    };

    const onExcelExport = () => {
        getExcelCustomer().then(function (result) {
            const response = result?.data?.data;
            console.log(response, "response")
            setCustomerExport(response);
        });

    }



    // custom-select-drupdown
    const [isOpen, setIsOpen] = useState(false);
    const [selectedOption, setSelectedOption] = useState('More');


    return (
        <div className="customerListing pdt">
            <Dbheader />
            <Sidebar>
                <div className='customerMain'>

                    <div className='custBtm'>
                        <div className='dbTopsec pb-2 noBackbtn'>
                            <Headtxt link="" title="Sub Users Management" />
                        </div>
                        <div className='customerSrchTop pb-3'>
                            {/* <div className='customSrchfield'><span><i class="fa-solid fa-magnifying-glass"></i></span><input type='search' placeholder='Search for a specific Name' onChange={onSearch} /></div> */}
                           <div></div>
                            <div className='custButton'>
                            {adduser ? (
    <Link to="/adduser">
      <button className='commonButton addBtn'>
        <i className="fa-regular fa-circle-plus"></i> Add Sub User
      </button>
    </Link>
  ) : (
    <button className='commonButton addBtn disabled' disabled>
      <i className="fa-regular fa-circle-plus"></i> Add Sub User
    </button>
  )}

                            </div>
                        </div>

                        <div className='mt-2'>
                            <div className='gridList mb-3 text-end'>
                                <button size="100%" style={{ backgroundColor: "#DCE5EC" }} onClick={toggleHandler1} className={!view ? "listactive" : ""}><i class="fa-solid fa-list"></i></button>
                                <button size="100%" style={{ backgroundColor: "#DCE5EC" }} onClick={toggleHandler} className={view ? "gridactive" : ""}><i class="fa-solid fa-grid"></i></button>
                            </div>
                            {!view ?

                                <div className='listViews'>
                                    <table class="table">
                                        <thead>
                                            <tr>
                                                <th scope="col"></th>
                                                <th scope="col">User ID</th>
                                                <th scope="col">Name</th>
                                                <th scope="col">Email</th>
                                                <th scope="col">Assigned Modules</th>
                                                <th scope="col">Status</th>
                                                <th scope="col">Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>        
                                        {userList
  .map((d) => (
    <tr key={d?.id}>
      <td ><img src={d?.profile_image} alt="Profile" style={{height:"60px"}}/></td>
      <td>{d?.subuser_id}</td>
      <td style={{maxWidth:'150px',lineBreak:'anywhere'}}>{d?.fname} {d?.lname}</td>
      <td>{d?.email}</td>
      <td>
        <button className='primary-btn' onClick={() => handleShow(d?.assigned_modules)}>
          Modules
        </button>
      </td>
      <td>{d?.status=='Block'?'Blocked':d?.status}</td>
     
      <td>
        <div className="icon-row">
          <span className='viewIcon orange'>
            <Link to={`/edituser?id=${d?.id}`}><i className="fa-light fa-pen"></i></Link>
          </span>
          <span className='viewIcon'>
            <i className="fa-solid fa-trash-can" onClick={() => onDelete(d?.id)}></i>
          </span>
          <Dropdown className='dropRow'>
            <Dropdown.Toggle id="dropdown-basic">
              <i className="fa-light fa-ellipsis-vertical"></i>
            </Dropdown.Toggle>
            <Dropdown.Menu>
              {/* <Dropdown.Item>
                <i className="fa-light fa-eye"></i> &nbsp; View Details
              </Dropdown.Item> */}
              <Dropdown.Item onClick={() => onBlock(d?.id,d?.status)}>
  <i className="fa-regular fa-circle-xmark"></i> &nbsp; {d?.status === "Active"? 'Block':'Unblock'}
</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </td>
    </tr>
  ))}

                                     </tbody>
                                    </table>

                                    {loading &&
                                        <div className='loadItems'>
                                            <button className="commonButton addBtn">
                                                Loading...
                                            </button>
                                        </div>
                                    }
                                    {!loading && (
                                        <div className='loadItems'>
                                            <button onClick={loadMoreItems} className="commonButton addBtn">
                                                No More User
                                            </button>
                                        </div>
                                    )}
                                    <Modal size="sm" show={showDelete} onHide={handleCloseDelete} className='delModal'>
                                        <Modal.Header closeButton>
                                            <Modal.Title><h5>Are you sure to delete ?</h5></Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body>

                                            <button variant="primary" className='commonButton expBtn' onClick={onYes} >
                                                Yes
                                            </button>
                                            &nbsp;
                                            &nbsp;
                                            &nbsp;
                                            &nbsp;
                                            <button variant="primary" className='commonButton expBtn' onClick={onNo}>
                                                No
                                            </button>
                                        </Modal.Body>
                                    </Modal>
                                </div>


                                :

                                <div className='gridView'>
                                {userList?.map((d) => ( 

                                    <Card>
                                        <Card.Body>
                                            <div className='cardBlock'>
                                                {/* <h6>Profile Picture</h6> */}
                                                <img src={d?.profile_image} className="profile-image" />
                                            </div>
                                            <div className="row">
                                                <div className="col-md-6"><div className='cardBlock'>
                                                    
                                                    <h6>User ID</h6>
                                                    {d?.subuser_id}
                                                </div></div>
                                                <div className="col-md-6">
                                                    <div className='cardBlock'>
                                                    <h6>User Name</h6>
                                                    {d?.fname} {d?.lname}
                                                        
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="row">
                                            <div className="col-md-6">
                                            <div className='cardBlock'>
                                            <h6>User Email</h6>
                                            {d?.email}
                                            </div>
                                            </div>
                                            <div className="col-md-6">
                                            <div className='cardBlock'>
                                                <h6>Status</h6>
                                                Active
                                            </div>
                                            </div>
                                            </div>
                                            <div className='cardBlock actn'>
                                                {/* <h6>Action</h6> */}
                                                <div>
                                                    <span className='viewIcon orange'><Link to={`/edituser?id=${d?.id}`} ><i class="fa-light fa-pen"></i></Link></span>
                                                    <span className='viewIcon'><i class="fa-solid fa-trash-can" onClick={() => onDelete(d?.id)}></i></span>
                                                    <span className='viewIcon'><Link ><i class="fa-regular fa-circle-xmark" onClick={() => onBlock(d?.id,d?.status)}></i></Link></span>
                                                    <button className='primary-btn' onClick={() => handleShow(d?.assigned_modules)}>
                                                    Modules</button>
                                                </div>
                                            </div>
                                        </Card.Body>
                                    </Card>
))}
                                    {/* ===========================delete modal==================================== */}
                                    <Modal size="sm" show={showDelete} onHide={handleCloseDelete} className='delModal'>
                                        <Modal.Header closeButton>
                                            <Modal.Title><h5>Are you sure to delete ?</h5></Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body>

                                            <button variant="primary" className='commonButton expBtn' onClick={onYes} >
                                                Yes
                                            </button>
                                            &nbsp;
                                            &nbsp;
                                            &nbsp;
                                            &nbsp;
                                            <button variant="primary" className='commonButton expBtn' onClick={onNo}>
                                                No
                                            </button>
                                        </Modal.Body>
                                    </Modal>

                                    
                                </div>


                            }

                        </div>

                    </div>
                </div>
            </Sidebar>
             {/* ===========================block modal==================================== */}
             <Modal size="sm" show={showBlock} onHide={handleCloseBlock} className='delModal'>
                                        <Modal.Header closeButton>
                                            <Modal.Title><h5>Are you sure to {status=='Active'?'Block':'Unblock'}  ?</h5></Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body>

                                            <button variant="primary" className='commonButton expBtn' onClick={onYesBlock} >
                                                Yes
                                            </button>
                                            &nbsp;
                                            &nbsp;
                                            &nbsp;
                                            &nbsp;
                                            <button variant="primary" className='commonButton expBtn' onClick={onNoBlock}>
                                                No
                                            </button>
                                        </Modal.Body>
                                    </Modal>
            {/* ====================Assigned Modules ========================*/}
            <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Assigned Modules</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {selectedModules ? (
            <Form>
              {Object.entries(selectedModules).map(([moduleName, subModules]) => (
                <div key={moduleName} style={{ marginBottom: '10px' }}>
                  <Form.Check
                    type="checkbox"
                    label={`${moduleName} Module`}
                    checked= {Object.values(moduleName?? {}).some((value) => value === true)}
                    disabled
                  />
                  <div style={{ marginLeft: '20px' }}>
                    {Object.entries(subModules).map(([subModuleName, isChecked]) => (
                      <Form.Check
                        key={subModuleName}
                        type="checkbox"
                        label={subModuleName.replace(/_/g, ' ')}
                        checked={isChecked}
                        disabled
                      />
                    ))}
                  </div>
                </div>
              ))}
            </Form>
          ) : (
            <p>No modules assigned.</p>
          )}
        </Modal.Body>
      </Modal>
        </div>
    )
}

export default Userlisting