import { React, useState,useEffect } from 'react'
import Sidebar from '../../../layout/Sidebar'
import Dbheader from '../../../layout/Dbheader'
import Form from 'react-bootstrap/Form';
import Headtxt from '../../Headtxt';
import Col from 'react-bootstrap/Col';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Modal from 'react-bootstrap/Modal';
import Pagination from 'react-bootstrap/Pagination';
import Dropdown from 'react-bootstrap/Dropdown';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { PhoneInput } from 'react-international-phone';
import { City, Country, State } from "country-state-city";
import { ToastContainer, toast } from 'react-toastify';
import { useNavigate,useLocation, } from 'react-router-dom';
import { getProductServiceList, getProductServiceListBYSupplierId } from "../../../../../Service/Customer/CustomerService";
import { getSearchSupplier, getAddSuppliers, getImportCustomer, getSupplierList } from "../../../../../Service/Suppliers/SuppliersService";
import { getAddbill,getBillData,getEditbill,getRefundbill,getSendBill } from '../../../../../Service/Quotation/QuotationService';
import { getAddProductService, getImportProductService, getInventoryAccounts } from "../../../../../Service/ProductService/ProductService";
import {getEditSupplier,getSuppliereListById} from "../../../../../Service/Suppliers/SuppliersService";
import getSymbolFromCurrency from 'currency-symbol-map'
import axios from 'axios';
const ViewBillPaymmentDetails = () => {
    const navigate = useNavigate();
    const [show, setShow] = useState(false);
    const [suppliersList, setAllSuppliersList] = useState([]);
    const [companyFinal, setCompanyFinal] = useState("");
    const [paymentMethod, setPaymentMethod] = useState("");
    const [paymentnotes, setPaymentNotes] = useState("");
  const [status, setStatus] = useState("");
    const [file, setFile] = useState([]);
    const [productServiceList, setAllProductServiceList] = useState([]);
    const [productServiceList2, setAllProductServiceList2] = useState([]);
    const [allProductServiceData, setAllProductServiceData] = useState([]);
    const [allReturnProductData, setAllReturnProductData] = useState([]);
    const [allPaymentDetails, setAllPaymentDetails] = useState([]);
    const [supplier1, setSupplier1] = useState(false);
    const supplierShow1 = () => setSupplier1(true);
    const supplier1Close = () => setSupplier1(false);
    const [supplier2, setSupplier2] = useState(false);
  const supplierShow2 = () => setSupplier2(true);
  const handleClosed = () => setSupplier1(false);
  const [paymentTerms, setPaymentTerms] = useState("");
  const [address, setQuoteAddress] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [productService2, setProductService2] = useState(false);
  const productServiceShow2 = () => setProductService2(true);
  const [finalStartDate, setFinalStartDate] = useState("");
  const [finalEndDate, setFinalEndDate] = useState("");
  const [deleteId, setDeleteId] = useState([]);
  const [discountQuantity, setDiscountQuantity] = useState(0);
  const [note, setNote] = useState("");
  const [showPdf, setShowPdf] = useState(false);
  const [dataPdf, setDataPdf] = useState("");
  const [views, setViews] = useState(false)
  const [addressQuote, setAddressQuote] = useState("")
 const [checks, setChecks] = useState("false");
  const [supplier, setSupplier] = useState("");
  const [userId, setUserId] = useState("");
  const [currency, setCurrency] = useState("");
  const [addres, setAddress] = useState("");
  const [image, setImage] = useState();
 // const [supplierid, setSupplierId] = useState("");
  const [selectedDate, setSelectedDate] = useState(null);
  const [imageUrls, setImageUrls] = useState([]);
  const [selectedDateb, setSelectedDateb] = useState(null);
  const [imageStateSet, setImageStateSet] = useState();
  const [olddoc, setOldDoc] = useState([]);
  const [supplierId,setSupplierId]=useState()
  const [inventoryOptions, setInventoryOptions] = useState([]);
  const [subTotal1,setSubTotal1]=useState(0);
  const [subSubDiscount1,setSubDiscount1]=useState(0);
  const [subSubVat1,setSubVat1]=useState(0);
  const [subSubTotalValue1,setSubTotalValue1]=useState(0);
  const [currencyData, setCurrencyData] = useState(null);
  const [selectedCurrency, setSelectedCurrency] = useState();
  const [selectedCurrencyValue, setSelectedCurrencyValue] = useState(null);
  const [error, setError] = useState();
//  const handleShow = () => setShow(true);
  const handleClosePdf = () => setShowPdf(false);
  const vclose = () => {
    setShowPdf(false);
    navigate('/bill')
  }
  const onAddressQuote = (e) => {
    setAddressQuote(e.target.value)
  }
  const toDate = moment(endDate);
  const formattedToDate = toDate.format('YYYY-MM-DD');
  const productServiceClose = () => setProductService2(false);

  const onFromDate = (e) => {
    setStartDate(e)
    console.log(e, "eeeeeeeeeeeeeee")
    const fromDate = moment(e).format('DD-MM-YYYY');
    console.log(fromDate, "fromDate");
    setFinalStartDate(fromDate)

  }

  const onToDate = (e) => {
    setEndDate(e)
    console.log(e, "eeeeeeeeeeeeeee")
    const toDate = moment(e).format('DD-MM-YYYY');
    console.log(toDate, "toDate")
    setFinalEndDate(toDate)
  }
  const [qtyIndex,setQtyIndex]=useState([]);
  const onCheckDelete = (id, indexValue) => {
    setDeleteId((prevDeleteId) => {
        const newDeleteId = prevDeleteId.includes(id)
            ? prevDeleteId.filter((d) => d !== id)
            : [...prevDeleteId, id];

       // subTotal(); // Recalculate subtotal based on new selection
        return newDeleteId;
    });
};
 
  console.log(deleteId, "deleteId")
  async function getallCustomerList(d) {

    getSuppliereListById(d).then(function (result) {
        const response = result.data;
        console.log(response, "response888888886")
        //setAllData(response?.data?.supplier_details)
        setAllProductServiceList(response?.data?.products);
        // setCustomerId(response?.data);
       // setSupplierId(d)
        // setDepartmentId(response?.data?.businessUnit?.Departments);
    });
}
  const getTotalPrice = (price, index, tax) => {
   let totalprice= 0
    if (discountQuantity && discountQuantity[index]?.quantity) {
        
      const totalPrice = Number(discountQuantity[index]?.quantity) * price;
      const totalDiscount = (totalPrice * Number(discountQuantity[index].discount)) / 100;
      const finalDiscount = isNaN(totalDiscount) ? 0 : totalDiscount;
      const totalFinalprice = (totalPrice - finalDiscount);
      const totalTax = (totalFinalprice * Number(tax)) / 100;
        const totalFinalTaxPrice = (totalFinalprice+totalTax);

      return totalFinalTaxPrice;
    }
    return 0;

  }
  const deleteImage = () => {
    // Add your logic here to delete the image
    // For example, you can reset the image URL to an empty string
    setImageUrls([]);
    setFile([])
    setOldDoc([])
  };
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const id = searchParams.get('id');
  const [allData, setAllData] = useState({});
  useEffect(() => {
    getSupplierDataList();
    getInventoryDataList();
   // getProductServiceDataList();
    getData();
    // onQuantityDiscount();
    // getTotalPrice()
  }, []);

  
  const getData = () => {
    getBillData(id).then(function (result) {
        const response = result?.data?.data;
       console.log (result?.data?.data?.Bill?.total, "rrrr")
       setCompanyFinal(result?.data?.data?.supplier)
       setSupplier(result?.data?.data?.supplier?.company_name)
       setAddress(result?.data?.data?.supplier?.billing_address)
       setStartDate(new Date(result?.data?.data?.Bill?.bill_date))
       setSelectedDate(result?.data?.data?.Bill?.bill_date)
       setSelectedDateb(result?.data?.data?.Bill?.expiration_date)
       setEndDate(new Date(result?.data?.data?.Bill?.expiration_date))
       setPaymentTerms(result?.data?.data?.Bill?.payment_terms)
       setPaymentMethod(result?.data?.data?.Bill?.manual_payment_method)
       setPaymentNotes(result?.data?.data?.Bill?.manual_payment_notes)
       setStatus(result?.data?.data?.Bill?.status)
       setOldDoc(result?.data?.data?.Bill?.old_docs)
       setNote(result?.data?.data?.Bill?.notes)
     setUserId(result?.data?.data?.Bill?.user_id)
     setCurrency(result?.data?.data?.Bill?.currency)
       setSupplierId(result?.data?.data?.supplier?.id)
       setImageUrls(result?.data?.data?.Bill?.bill_docs)
       setSelectedCurrency(result?.data?.data?.supplier?.currency)
       JSON.parse(result?.data?.data?.Bill?.products_details).map((d,i)=>(setDiscountQuantity((prevFormData) => ({
        ...prevFormData,
        [i+1]: {
          ['quantity']: d?.qty,
          ['discount']:d?.disc
        },
      }))))
       setAllProductServiceData(JSON.parse(result?.data?.data?.Bill?.products_details).map(data=>({
        "id":data.id,
        "type": data?.type,
        "name": data.product_name,
        "description": data.description,
        "unit": data?.unit,
        "sales_price": data.price,
        "purchase_price":data?.price,
        'price':data?.price,
        "quantity":data.qty,
        "tax": data.vat,
        "discount":data.disc,
        "disc":data.disc,
        'qty':data.qty,
        "total":data.total,
        "inventory":data.inventory,
        "refund":data.refund,
      })))
      setAllReturnProductData(result?.data?.data?.returnProduct);
      setAllPaymentDetails(result?.data?.data?.paymentDetails);
      getallCustomerList(result?.data?.data?.supplier?.id);
      setAllData(response?.Bill);
    });
}
const fetchCurrencyData = async () => {
  const UserCurrency=  localStorage.getItem("1Simpleclick_Currency");
  setCurrencyData(UserCurrency)
    try {
      const apiId = 'onesimpleclick809194384'; // Replace with your actual API ID
      const apiKey = 'omumk49ld03teh0h681go0jmqp'; // Replace with your actual API Key
      const encodedCredentials = btoa(`${apiId}:${apiKey}`);

      const response = await axios.get('https://xecdapi.xe.com/v1/convert_from.json/', {
        params: {
          from:selectedCurrency?selectedCurrency:"CAD" , // base currency
      to: UserCurrency, // target currency
          amount: 1,
        },
        headers: {
          'Authorization': `Basic ${encodedCredentials}`
        }
      });
      setSelectedCurrencyValue(response.data.to[0].mid);
      console.log('UserCurrency',UserCurrency)
    } catch (error) {
      setError(error.message);
      console.error(error);
    }
  };
  useEffect(() => {
      if (selectedCurrency) {
        fetchCurrencyData();
       
      }
    }, [selectedCurrency]);
const url = imageUrls[0];
if (typeof url === 'string') {
    let parts = url.split('.');
    let file_extension = parts[parts.length - 1];
    // Check if the state is already set to avoid unnecessary re-renders
    if (!imageStateSet) { // Assuming imageStateSet is a state variable indicating whether the image state has been set
        setImage(file_extension);
        setImageStateSet(true); // Update the state indicating that the image state has been set
    }
} else {
    console.log('The provided URL is not a string.');
}

  const onSendPdf = () => {
    getSendBill(dataPdf?.data?.details?.id, dataPdf?.data?.supplierEmail, dataPdf?.data?.details?.quote_id,
      addressQuote,checks,
      (r) => {
        console.log(r, "response")
        if (r.success === true) {
          toast.success(r.message)
          navigate('/bill')


        } else {
          console.log("nononononono")
          toast.error(r.message)

        }
      })
  }
  async function getInventoryDataList() {
    getInventoryAccounts("").then(function (result) {
      const response = result?.data;
      console.log(response, "rrrr")
      setInventoryOptions(response?.data?.inventoryAccounts);
      // setAllProductServiceList2(response?.data);
      // setAllProductServiceList(response?.data);
    });
  }
  const checkMe = (e) => {
    setChecks(e.target.checked);
    // console.log(e.target.checked, "Yesssssssssss");
    console.log(e.target.checked? "Yesssssssssss" : "Noooooo");
  }
  

  const toggleHandlerb = (e) => {
    e.preventDefault();
    setViews(true)
  }
  const toggleHandlerc = (e) => {
    e.preventDefault();
    setViews(false)
  }
    const handleDrop = (event) => {
        event.preventDefault();

        const droppedFile = event.dataTransfer.files[0];
        handleFile(droppedFile);
    };
    const onAddress = (e) => {
        setQuoteAddress(e.target.value);
      }
  
      const handleFile = (e,selectedFile) => {
        e.preventDefault();
        if (selectedFile) {
            const allowedTypes = ["application/pdf", "application/msword", "application/vnd.openxmlformats-officedocument.wordprocessingml.document", "image/jpeg", "image/png"];
            if (allowedTypes.includes(selectedFile.type)) {
                setFile(selectedFile);
    
                // You can perform additional actions with the file, such as uploading it to a server.
                // For simplicity, we're just logging the file information here.
                console.log('File selected:', selectedFile);
            } else {
                alert("Only .doc, .pdf, and image files are allowed.");
            }
        }
    };
    const handleDragOver = (event) => {
        event.preventDefault();
    };

    useEffect(() => {
      subTotal();
        subDiscount();
        subVat();
        subTotalValue();
      }, [deleteId,allProductServiceData, discountQuantity]);
    
      console.log("discountQuantity", discountQuantity)
     
      console.log(discountQuantity,'quan')
     
        const subTotal = () => {
          let totalPrice = 0;
  
          // Calculate subtotal for all products if none are checked, otherwise for selected products
          const productsToCalculate = deleteId.length > 0 ? allProductServiceData.filter(d => deleteId.includes(d.id)) : allProductServiceData;
  
          productsToCalculate.forEach((d, i) => {
              const index = allProductServiceData.indexOf(d) + 1;
              if (discountQuantity[`${index}`] && discountQuantity[`${index}`].quantity) {
                  totalPrice += (Number(discountQuantity[`${index}`].quantity) * (d?.type === "service" ? Number(d?.price) : Number(d?.sales_price)));
              }
          });
  
          setSubTotal1(totalPrice);
          return totalPrice;
      };
    //     setSubTotal1(totalPrice);
    //     return totalPrice;
    // };


    const subDiscount = () => {
      let totalDiscount = 0;

      const productsToCalculate = deleteId.length > 0 ? allProductServiceData.filter(d => deleteId.includes(d.id)) : allProductServiceData;

      productsToCalculate.forEach((d, i) => {
          const index = allProductServiceData.indexOf(d) + 1;
          if (discountQuantity[`${index}`]?.discount) {
              const totalPrice = Number(discountQuantity[`${index}`].quantity) * Number(d?.type === "service" ? d?.price : d?.sales_price);
              totalDiscount += (totalPrice * Number(discountQuantity[`${index}`].discount)) / 100;
          }
      });

      setSubDiscount1(totalDiscount);
      return totalDiscount;
  }
    
      const subVat = () => {
        let totalTax = 0;
        const productsToCalculate = deleteId.length > 0 ? allProductServiceData.filter(d => deleteId.includes(d.id)) : allProductServiceData;

        productsToCalculate.forEach((d, i) => {
            const index = allProductServiceData.indexOf(d) + 1;
            if (discountQuantity[`${index}`] && discountQuantity[`${index}`].quantity) {
                const totalPrice = Number(discountQuantity[`${index}`].quantity) * Number(d?.type === "service" ? d?.price : d?.sales_price);
                totalTax += (totalPrice * Number(d?.tax)) / 100;
            }
        });

        setSubVat1(totalTax);
        return totalTax;
      }
    
      const subTotalValue = () => {
        let totalTax = 0;
        let totalPrice = 0;
        let totalDiscount = 0;
        let totalFinalTaxPrice = subTotal() - subDiscount() + subVat();
        let finalDiscount = 0;
        setSubTotalValue1(String(totalFinalTaxPrice))
        return totalFinalTaxPrice;
      }
   
      async function getProductServiceDataList() {
        getProductServiceList("").then(function (result) {
          const response = result?.data;
 console.log(response, "rrrr")
          setAllProductServiceList2(response?.data);
          setAllProductServiceList(response?.data);
        });
      }
    async function getSupplierDataList() {
        getSearchSupplier("").then(function (result) {
          const response = result?.data;
          // console.log(response, "rrrr")
          setAllSuppliersList(response?.data);
        });
      }
      const onDownload = (id) => {
   window.open(id, '_blank'); // Open PDF in a new tab
     
  }
  const ProductList = ({ data }) => {
    // Parse the JSON string
    const productsArray = JSON.parse(data);
  
    // Extract product names
    const productNames = productsArray.map(product => product.product_name);
  
    // Determine the output based on the number of products
    const productNamesString = productNames.length > 1 ? productNames.join(', ') : productNames[0];
  
    // Log the productNamesString for debugging
    console.log('productNamesString', productNamesString);
  
    return (
      <td><span className='lightBlock'>{productNamesString}</span></td>
    );
  };

      const onButtonClick = () => {
        const pdfUrl = dataPdf?.data?.pdf;
        if (pdfUrl) {
          window.open(pdfUrl, '_blank'); // Open PDF in a new tab
        } else {
          // Handle case when PDF URL is not available
          console.error('PDF URL is not available');
        }
      };

      const _HANDLE_FILE_CHANGE = (e)=>{
        e.preventDefault();
        document.querySelector('input[type="file"]').click()
      }

    return (
        <div className="producservlisting pdt">
            <Dbheader />
            <Sidebar>
                <div className='customerMain'>
                    <div className='productServList'>
                        <div className='dbTopsec pb-4 pt-2'>
                            <Headtxt link="/billpayment" title="Bill Payment Details" />

                            {/* <div className='custButton'>
                                <button className='commonButton addBtn'><Link to="/addproductservice"><i class="fa-regular fa-circle-plus"></i> Add Product</Link></button>&nbsp;&nbsp;
                                <button className='commonButton expBtn'><i class="fa-light fa-download"></i> Export Excel</button>
                            </div> */}
                        </div>
                       
{/* payment modallll======== */}
<label>Payment Details</label>
<div className='salesOrdTable'>
                                    <div className='listViews'>
                                        <table class="table">
                                            <thead>
                                                <tr>
                                                    {/* <th>&nbsp;</th> */}
                                                    <th scope="col">Payment Date</th>
                                                    <th scope="col">Payment Method</th>
                                                    <th scope="col">Supplier Name</th>
                                                    <th scope="col">Status</th>
                                                    <th scope="col">Bill Id</th>
                                                    <th scope="col">Description</th>
                                                    <th scope="col">Amount Credit</th>
                                                    <th scope="col">Account Name</th>
                                                    {/* 
                                                    <th scope="col">Disc.%</th>
                                                    <th scope="col">Total</th>
                                                    <th scope="col">VAT%</th>
                                                    <th scope="col">Action</th> */}
                                                </tr>
                                            </thead>
                                            <tbody>
                                          
{allPaymentDetails.length != 0 && allPaymentDetails?.map((d, i) => (

<tr>
  {/* <td><input type='checkbox' checked={deleteId.includes(d?.id)} onChange={() => onCheckDelete(d?.id,i+1)} disabled={d.refund === "true"}/></td> */}
  
  <td><span >{d?.date}</span></td>
  <td><span className='lightBlock'>{paymentMethod}</span></td>
  <td>{companyFinal?.type === "Company" ? companyFinal?.company_name : companyFinal.fname + " " + companyFinal.lname}</td>
  <td>{status}</td>
  <td><span >{d?.bill_id}</span></td>
  <td><span >{paymentnotes===null?"N/A":paymentnotes}</span></td>
  <td><span >{getSymbolFromCurrency(currencyData)} {d?.credit?d?.credit:0}</span></td>
  <td><span>{d?.account_name}</span></td>
 
</tr>
))}


                                            </tbody>
                                        </table>
                                    </div>
                                    </div>

                                    </div>
                </div>
            </Sidebar>

            
        </div>
    )
}
export default ViewBillPaymmentDetails