import { React, useState,useEffect } from 'react'
import Sidebar from '../../layout/Sidebar'
import Dbheader from '../../layout/Dbheader'
import Form from 'react-bootstrap/Form';
import Headtxt from '../Headtxt';
import Col from 'react-bootstrap/Col';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Modal from 'react-bootstrap/Modal';
import Pagination from 'react-bootstrap/Pagination';
import Dropdown from 'react-bootstrap/Dropdown';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { PhoneInput } from 'react-international-phone';
import { City, Country, State } from "country-state-city";
import { ToastContainer, toast } from 'react-toastify';
import { useNavigate,useLocation, } from 'react-router-dom';
import { getInvoiceAccount, getinvoiceData, getProductServiceList, getProductServiceListBYSupplierId, getRefundInvoice } from "../../../../Service/Customer/CustomerService";
import { getSearchSupplier, getAddSuppliers, getImportCustomer, getSupplierList } from "../../../../Service/Suppliers/SuppliersService";
import { getAccounttId, getAddbill,getBillAccount,getBillData,getEditbill,getRefundbill,getSendBill } from '../../../../Service/Quotation/QuotationService';
import { getAddProductService, getImportProductService, getInventoryAccounts } from "../../../../Service/ProductService/ProductService";
import {getEditSupplier,getSuppliereListById} from "../../../../Service/Suppliers/SuppliersService";
import getSymbolFromCurrency from 'currency-symbol-map'
import axios from 'axios';
import Swal from 'sweetalert2';
const SalesReturnrefund = ({ setIsSpinner}) => {
    const navigate = useNavigate();
    const [show, setShow] = useState(false);
    const [suppliersList, setAllSuppliersList] = useState([]);
    const [companyFinal, setCompanyFinal] = useState("");
    const [file, setFile] = useState([]);
    const [productServiceList, setAllProductServiceList] = useState([]);
    const [productServiceList2, setAllProductServiceList2] = useState([]);
    const [allProductServiceData, setAllProductServiceData] = useState([]);
    const [supplier1, setSupplier1] = useState(false);
    const supplierShow1 = () => setSupplier1(true);
    const supplier1Close = () => setSupplier1(false);
    const [supplier2, setSupplier2] = useState(false);
  const supplierShow2 = () => setSupplier2(true);
  const handleClosed = () => setSupplier1(false);
  const [paymentTerms, setPaymentTerms] = useState("");
  const [address, setQuoteAddress] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [productService2, setProductService2] = useState(false);
  const productServiceShow2 = () => setProductService2(true);
  const [finalStartDate, setFinalStartDate] = useState("");
  const [finalEndDate, setFinalEndDate] = useState("");
  const [deleteId, setDeleteId] = useState([]);
  const [discountQuantity, setDiscountQuantity] = useState(0);
  const [note, setNote] = useState("");
  const [showPdf, setShowPdf] = useState(false);
  const [dataPdf, setDataPdf] = useState("");
  const [views, setViews] = useState(false)
  const [addressQuote, setAddressQuote] = useState("")
 const [checks, setChecks] = useState("false");
  const [supplier, setSupplier] = useState("");
  const [userId, setUserId] = useState("");
  const [currency, setCurrency] = useState("");
  const [addres, setAddress] = useState("");
  const [image, setImage] = useState();
 // const [supplierid, setSupplierId] = useState("");
  const [selectedDate, setSelectedDate] = useState(null);
  const [imageUrls, setImageUrls] = useState([]);
  const [selectedDateb, setSelectedDateb] = useState(null);
  const [imageStateSet, setImageStateSet] = useState();
  const [olddoc, setOldDoc] = useState([]);
  const [supplierId,setSupplierId]=useState()
  const [inventoryOptions, setInventoryOptions] = useState([]);
  const [subTotal1,setSubTotal1]=useState(0);
  const [subSubDiscount1,setSubDiscount1]=useState(0);
  const [subSubVat1,setSubVat1]=useState(0);
  const [subSubTotalValue1,setSubTotalValue1]=useState(0);
  const [Account, setAccount] = useState([]);
  const [selectedaccountId, setSelectedAccountId] = useState('');
  const [currencyData, setCurrencyData] = useState(null);
const [selectedCurrency, setSelectedCurrency] = useState();
const [selectedCurrencyValue, setSelectedCurrencyValue] = useState(null);
const [error, setError] = useState();
//  const handleShow = () => setShow(true);
  const handleClosePdf = () => setShowPdf(false);
  const vclose = () => {
    setShowPdf(false);
    navigate('/invoice')
  }
  const onAddressQuote = (e) => {
    setAddressQuote(e.target.value)
  }
  const handleAccountChange = (e) => {
    const selectedId = e.target.value;
    setSelectedAccountId(selectedId);
    
    console.log(e.target,'billlamont===');

};
  const toDate = moment(endDate);
  const formattedToDate = toDate.format('YYYY-MM-DD');
  const productServiceClose = () => setProductService2(false);

  const onFromDate = (e) => {
    setStartDate(e)
    console.log(e, "eeeeeeeeeeeeeee")
    const fromDate = moment(e).format('DD-MM-YYYY');
    console.log(fromDate, "fromDate");
    setFinalStartDate(fromDate)

  }
  async function getAccount() {
    getInvoiceAccount(id).then(function (result) {
        const response = result?.data;
   console.log(response, "rrrr")
   setAccount(response?.data);
   setSelectedAccountId(response?.data[0]?.account_id)
    });
}
  const onToDate = (e) => {
    setEndDate(e)
    console.log(e, "eeeeeeeeeeeeeee")
    const toDate = moment(e).format('DD-MM-YYYY');
    console.log(toDate, "toDate")
    setFinalEndDate(toDate)
  }
  const [qtyIndex,setQtyIndex]=useState([]);
  const onCheckDelete = (id, indexValue) => {
    setDeleteId((prevDeleteId) => {
        const newDeleteId = prevDeleteId.includes(id)
            ? prevDeleteId.filter((d) => d !== id)
            : [...prevDeleteId, id];

       // subTotal(); // Recalculate subtotal based on new selection
        return newDeleteId;
    });
};
 
  console.log(deleteId, "deleteId")
//   async function getallCustomerList(d) {

//     getSuppliereListById(d).then(function (result) {
//         const response = result.data;
//         console.log(response, "response888888886")
//         //setAllData(response?.data?.supplier_details)
//         setAllProductServiceList(response?.data?.products);
//         // setCustomerId(response?.data);
//        // setSupplierId(d)
//         // setDepartmentId(response?.data?.businessUnit?.Departments);
//     });
// }
  const getTotalPrice = (price, index, tax) => {
   let totalprice= 0
    if (discountQuantity && discountQuantity[index]?.quantity) {
        
      const totalPrice = Number(discountQuantity[index]?.quantity) * price;
      const totalDiscount = (totalPrice * Number(discountQuantity[index].discount)) / 100;
      const finalDiscount = isNaN(totalDiscount) ? 0 : totalDiscount;
      const totalFinalprice = (totalPrice - finalDiscount);
      const totalTax = (totalFinalprice * Number(tax)) / 100;
        const totalFinalTaxPrice = (totalFinalprice+totalTax);

      return totalFinalTaxPrice;
    }
    return 0;

  }
  const deleteImage = () => {
    // Add your logic here to delete the image
    // For example, you can reset the image URL to an empty string
    setImageUrls([]);
    setFile([])
    setOldDoc([])
  };
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const id = searchParams.get('id');

  useEffect(() => {
    getSupplierDataList();
    getInventoryDataList();
   // getProductServiceDataList();
    getData();
    getAccount();
    // onQuantityDiscount();
    // getTotalPrice()
  }, []);

  
  const getData = () => {
    getinvoiceData(id).then(function (result) {
        const response = result?.data?.data;
       console.log (result?.data?.data?.invoice?.total, "rrrr")
       setCompanyFinal(result?.data?.data?.customer)
       setSupplier(result?.data?.data?.customer?.company_name)
       setAddress(result?.data?.data?.customer?.billing_address)
       setStartDate(new Date(result?.data?.data?.invoice?.invoice_date))
       setSelectedDate(result?.data?.data?.invoice?.invoice_date)
       setSelectedDateb(result?.data?.data?.invoice?.expiration_date)
       setEndDate(new Date(result?.data?.data?.invoice?.expiration_date))
       setPaymentTerms(result?.data?.data?.invoice?.payment_terms)
       setOldDoc(result?.data?.data?.invoice?.old_docs)
       setNote(result?.data?.data?.invoice?.notes)
     setUserId(result?.data?.data?.invoice?.user_id)
     setSelectedCurrency(result?.data?.data?.customer?.currency)
       setSupplierId(result?.data?.data?.customer?.id)
       setImageUrls(result?.data?.data?.invoice?.invoice_docs)
       JSON.parse(result?.data?.data?.invoice?.products_details).map((d,i)=>(setDiscountQuantity((prevFormData) => ({
        ...prevFormData,
        [i+1]: {
          ['qty']: d?.qty,
          ['discount']:d?.disc
        },
      }))))
       setAllProductServiceData(JSON.parse(result?.data?.data?.invoice?.products_details).map(data=>({
        "id":data.id,
        "type": data?.type,
        "name": data.product_name,
        "description": data.description,
        "unit": data?.unit,
        "sales_price": data.price,
        "purchase_price":data?.price,
        'price':data?.price,
        "qty":data.qty,
        "tax": data.vat,
        "discount":data.disc,
        "disc":data.disc,
        'qty':data.qty,
        "total":data.total,
        "inventory":data.inventory,
        "refund":data.refund,
        'userCurrency_productTotal': data?.userCurrency_productTotal,
        "income_acc":data.income_acc,
        "costofGoodsSold_acc":data.costofGoodsSold_acc,
        "delivery_status":data?.delivery_status
      })))
    //  getallCustomerList(result?.data?.data?.customer?.id)
    });
}

const url = imageUrls[0];
if (typeof url === 'string') {
    let parts = url.split('.');
    let file_extension = parts[parts.length - 1];
    // Check if the state is already set to avoid unnecessary re-renders
    if (!imageStateSet) { // Assuming imageStateSet is a state variable indicating whether the image state has been set
        setImage(file_extension);
        setImageStateSet(true); // Update the state indicating that the image state has been set
    }
} else {
    console.log('The provided URL is not a string.');
}

  const onSendPdf = () => {
    getSendBill(dataPdf?.data?.details?.id, dataPdf?.data?.customerEmail, dataPdf?.data?.details?.quote_id,
      addressQuote,checks,
      (r) => {
        console.log(r, "response")
        // if (r.success === true) {
        //   toast.success(r.message)
        //   navigate('/invoice')
        // }
        if (r.success === true) {
          Swal.fire({
           title: r.message,
         //  text: 'The product has been added successfully!',
           icon: 'success',
           confirmButtonText: 'OK',
           customClass: {
               confirmButton: 'my-confirm-button'
           }
        }).then(() => {
          navigate('/invoice')
        })
        }
        else {
          console.log("nononononono")
          toast.error(r.message)

        }
      })
  }
  async function getInventoryDataList() {
    getInventoryAccounts("").then(function (result) {
      const response = result?.data;
      console.log(response, "rrrr")
      setInventoryOptions(response?.data?.inventoryAccounts);
      // setAllProductServiceList2(response?.data);
      // setAllProductServiceList(response?.data);
    });
  }
  const checkMe = (e) => {
    setChecks(e.target.checked);
    // console.log(e.target.checked, "Yesssssssssss");
    console.log(e.target.checked? "Yesssssssssss" : "Noooooo");
  }
  

  const toggleHandlerb = (e) => {
    e.preventDefault();
    setViews(true)
  }
  const toggleHandlerc = (e) => {
    e.preventDefault();
    setViews(false)
  }
    const handleDrop = (event) => {
        event.preventDefault();

        const droppedFile = event.dataTransfer.files[0];
        handleFile(droppedFile);
    };
    const onAddress = (e) => {
        setQuoteAddress(e.target.value);
      }
  
      const handleFile = (e,selectedFile) => {
        e.preventDefault();
        if (selectedFile) {
            const allowedTypes = ["application/pdf", "application/msword", "application/vnd.openxmlformats-officedocument.wordprocessingml.document", "image/jpeg", "image/png"];
            if (allowedTypes.includes(selectedFile.type)) {
                setFile(selectedFile);
    
                // You can perform additional actions with the file, such as uploading it to a server.
                // For simplicity, we're just logging the file information here.
                console.log('File selected:', selectedFile);
            } else {
                alert("Only .doc, .pdf, and image files are allowed.");
            }
        }
    };
    const handleDragOver = (event) => {
        event.preventDefault();
    };
    const fetchCurrencyData = async () => {
      const UserCurrency=  localStorage.getItem("1Simpleclick_Currency");
      setCurrencyData(UserCurrency)
        try {
          const apiId = 'onesimpleclick809194384'; // Replace with your actual API ID
          const apiKey = 'omumk49ld03teh0h681go0jmqp'; // Replace with your actual API Key
          const encodedCredentials = btoa(`${apiId}:${apiKey}`);
  
          const response = await axios.get('https://xecdapi.xe.com/v1/convert_from.json/', {
            params: {
              from:selectedCurrency?selectedCurrency:"CAD" , // base currency
          to: UserCurrency, // target currency
              amount: 1,
            },
            headers: {
              'Authorization': `Basic ${encodedCredentials}`
            }
          });
          setSelectedCurrencyValue(response.data.to[0].mid);
          console.log('UserCurrency',UserCurrency)
        } catch (error) {
          setError(error.message);
          console.error(error);
        }
      };
      useEffect(() => {
          if (selectedCurrency) {
            fetchCurrencyData();
            getSymbolFromCurrency('CAD')
          }
        }, [selectedCurrency]);
    useEffect(() => {
      subTotal();
        subDiscount();
        subVat();
        subTotalValue();
      }, [deleteId,allProductServiceData, discountQuantity]);
    
      console.log("discountQuantity", discountQuantity)
     
      console.log(discountQuantity,'quan')
     
        const subTotal = () => {
          let totalPrice = 0;
  
          // Calculate subtotal for all products if none are checked, otherwise for selected products
          const productsToCalculate = deleteId.length > 0 ? allProductServiceData.filter(d => deleteId.includes(d.id)) : allProductServiceData;
  
          productsToCalculate.forEach((d, i) => {
              const index = allProductServiceData.indexOf(d) + 1;
              if (discountQuantity[`${index}`] && discountQuantity[`${index}`].qty) {
                  totalPrice += (Number(discountQuantity[`${index}`].qty) * (d?.type === "service" ? Number(d?.price) : Number(d?.sales_price)));
              }
          });
  
          setSubTotal1(totalPrice);
          return totalPrice;
      };
    //     setSubTotal1(totalPrice);
    //     return totalPrice;
    // };


    const subDiscount = () => {
      let totalDiscount = 0;

      const productsToCalculate = deleteId.length > 0 ? allProductServiceData.filter(d => deleteId.includes(d.id)) : allProductServiceData;

      productsToCalculate.forEach((d, i) => {
          const index = allProductServiceData.indexOf(d) + 1;
          if (discountQuantity[`${index}`]?.discount) {
              const totalPrice = Number(discountQuantity[`${index}`].qty) * Number(d?.type === "service" ? d?.price : d?.sales_price);
              totalDiscount += (totalPrice * Number(discountQuantity[`${index}`].discount)) / 100;
          }
      });
      console.log('totalDiscount=',totalDiscount)
      setSubDiscount1(totalDiscount);
      return totalDiscount;
  }
    
  const subVat = () => {
    let totalTax = 0;

    const productsToCalculate = deleteId.length > 0 ? allProductServiceData.filter(d => deleteId.includes(d.id)) : allProductServiceData;

    productsToCalculate.forEach((d, i) => {
        const index = allProductServiceData.indexOf(d) + 1;
        if (discountQuantity[`${index}`] && discountQuantity[`${index}`].qty) {
            const totalPrice = Number(discountQuantity[`${index}`].qty) * Number(d?.type === "service" ? d?.price : d?.sales_price);
            const discountAmount = (totalPrice * Number(discountQuantity[`${index}`].discount)) / 100;
            const discountedPrice = totalPrice - discountAmount;

            totalTax += (discountedPrice * Number(d?.tax)) / 100;
        }
    });

    console.log('totalTax=', totalTax);
    setSubVat1(totalTax);
    return totalTax;
};
    
      const subTotalValue = () => {
        let totalTax = 0;
        let totalPrice = 0;
        let totalDiscount = 0;
        let totalFinalTaxPrice = subTotal() - subDiscount() + subVat();
        let finalDiscount = 0;
        setSubTotalValue1(String(totalFinalTaxPrice))
        return totalFinalTaxPrice;
      }
   
      async function getProductServiceDataList() {
        getProductServiceList("").then(function (result) {
          const response = result?.data;
 console.log(response, "rrrr")
          setAllProductServiceList2(response?.data);
          setAllProductServiceList(response?.data);
        });
      }
    async function getSupplierDataList() {
        getSearchSupplier("").then(function (result) {
          const response = result?.data;
          // console.log(response, "rrrr")
          setAllSuppliersList(response?.data);
        });
      }
    
      const [disableButton, setDisableButton] = useState(false);

      const onDone = () => {
          // Disable the button on first click
          setDisableButton(true);
  
          const selectedProducts = allProductServiceData.filter(d => deleteId.includes(d.id));
  
          // Validation: Check if any selected product is a service
          const serviceProduct = allProductServiceData.find(d => d.type === 'service');
          // if (serviceProduct) {
          //     toast.error("Services are not refundable.");
          //     setDisableButton(false); // Re-enable the button if validation fails
          //     return;
          // }
  
          // Validation: Check if any products are selected
          if (selectedProducts.length <= 0) {
              toast.error("Please select any products/service.");
              setDisableButton(false); // Re-enable the button if validation fails
              return;
          }
  
          // Validation: Check if any account is selected
          if (selectedaccountId === '' || selectedaccountId === null) {
              toast.error("Please select any account");
              setDisableButton(false); // Re-enable the button if validation fails
              return;
          }
  
          let products_details = [];
          selectedProducts.forEach((d, i) => {
              products_details.push({
                  "product_name": d?.name,
                  "qty": Number(discountQuantity[`${i + 1}`]?.qty),
                  "price": d?.type === "service" ? d?.sales_price : d?.purchase_price,
                  "disc": Number(discountQuantity[`${i + 1}`]?.discount ? discountQuantity[`${i + 1}`]?.discount : 0),
                  "total": d?.total,
                  "vat": d?.tax,
                  "unit": d?.unit,
                  "description": d?.description,
                  "id": d?.id,
                  "type": d?.type,
                  "inventory": d.inventory,
                  "refund": "true",
                  'userCurrency_productTotal': d?.userCurrency_productTotal,
                  "income_acc":d.income_acc,
                  "costofGoodsSold_acc":d.costofGoodsSold_acc,
              });
          });
  
          let formData = new FormData();
          formData.append('return_docs', file);
          formData.append('user_id', userId);
          formData.append('customer_id', supplierId);
          formData.append('invoice_date', finalStartDate === '' ? selectedDate : finalStartDate);
          formData.append('expiration_date', finalEndDate === '' ? selectedDateb : finalEndDate);
          formData.append('address', addres);
          formData.append('payment_terms', paymentTerms);
          formData.append('products_details', JSON.stringify(products_details));
          formData.append('sub_total', (selectedCurrencyValue * subTotal()).toFixed(2));
          formData.append('discount_amount', (selectedCurrencyValue * subDiscount()).toFixed(2));
          formData.append('vat', (selectedCurrencyValue * subVat()).toFixed(2));
          formData.append('total', (selectedCurrencyValue * subTotalValue()).toFixed(2));
          formData.append('customerCurrency_subTotal', (subTotal()).toFixed(2));
          formData.append('customerCurrency_discountAmount', (subDiscount()).toFixed(2));
          formData.append('customerCurrency_vat', (subVat()).toFixed(2));
          formData.append('customerCurrency_totalAmount', (subTotalValue()).toFixed(2));
          formData.append('customer_currency', getSymbolFromCurrency(selectedCurrency));
          formData.append('user_currency', getSymbolFromCurrency(currencyData));
          formData.append('notes', note ? note : '');
       formData.append('cashAccount_id', selectedaccountId);
      //  formData.append('cashAccount_id', 86);
      setIsSpinner(true);
          getRefundInvoice(id, formData, (r) => {
              // if (r.success === true) {
              //     toast.success(r.message);
              //     setDataPdf(r);
              //     setShowPdf(true);
              // }
              if (r.success === true) {
                setIsSpinner(false);
                Swal.fire({
                 title: r.message,
               //  text: 'The product has been added successfully!',
                 icon: 'success',
                 confirmButtonText: 'OK',
                 customClass: {
                     confirmButton: 'my-confirm-button'
                 }
              }).then(() => {
                setDataPdf(r);
                  setShowPdf(true);
              })
              }
              else {
                setIsSpinner(false);
                  toast.error(r.message);
                  setDisableButton(false); // Re-enable the button if the request fails
              }
          }).catch((error) => {
              toast.error("An error occurred. Please try again.");
              setIsSpinner(false);
              setDisableButton(false); // Re-enable the button if there is an error
          });
      };

      const onButtonClick = () => {
        const pdfUrl = dataPdf?.data?.pdf;
        if (pdfUrl) {
          window.open(pdfUrl, '_blank'); // Open PDF in a new tab
        } else {
          // Handle case when PDF URL is not available
          console.error('PDF URL is not available');
        }
      };

      const _HANDLE_FILE_CHANGE = (e)=>{
        e.preventDefault();
        document.querySelector('input[type="file"]').click()
      }

    return (
        <div className="producservlisting pdt">
            <Dbheader />
            <Sidebar>
                <div className='customerMain'>
                    <div className='productServList'>
                        <div className='dbTopsec pb-4 pt-2'>
                            <Headtxt link="/invoice" title="Issue a Refunds" />

                            {/* <div className='custButton'>
                                <button className='commonButton addBtn'><Link to="/addproductservice"><i class="fa-regular fa-circle-plus"></i> Add Product</Link></button>&nbsp;&nbsp;
                                <button className='commonButton expBtn'><i class="fa-light fa-download"></i> Export Excel</button>
                            </div> */}
                        </div>

                        <div className='commonWhite addCustomerForm p-3 mb-3'>
                            <Form>
                                <Form.Group className="row mb-3" controlId="exampleForm.ControlInput1">
                                    {/* <Col md="4" className='mb-2'>
                                    <Dropdown className='dropselect'>
                      <Dropdown.Toggle variant="success" id="dropdown-basic" >
                      {supplier!=''?supplier:companyFinal ? companyFinal?.type === "Company" ? companyFinal?.company_name : companyFinal.fname + " " + companyFinal.lname : "Select Supplier"}
                      </Dropdown.Toggle>
      
                    </Dropdown>
                                    </Col> */}
                                             <Col md="4" className='mb-2'>
                  <Form.Control type="text" disabled value={companyFinal?.type === "Company" ? companyFinal?.company_name : companyFinal.fname + " " + companyFinal.lname } />
                </Col>
                                    <Col md="4" className='datepickr mb-3'>
                                        <DatePicker
                                            selected={startDate}
                                            onChange={onFromDate}
                                            dateFormat="dd/MM/yyyy" // You can customize the date format
                                            // isClearable
                                            placeholderText="Bill date"
                                            disabled
                                        />
                                    </Col>
                                    <Col md="4" className='datepickr mb-3'>
                                        <DatePicker
                                          selectsEnd
                                              selected={endDate}
                                              onChange={onToDate}
                                              endDate={endDate}
                                              startDate={startDate}
                                              minDate={startDate}
                                            dateFormat="dd/MM/yyyy" // You can customize the date format
                                            // isClearable
                                            placeholderText="Due date"
                                            disabled
                                        />
                                    </Col>
                                </Form.Group>
                                <Form.Group className="row mb-3" controlId="exampleForm.ControlInput1">
                                    <Col md="6">
                                    <Form.Control type="text" placeholder="Address" onChange={()=>{ onAddress()}} value={companyFinal?.billing_address 
    ? companyFinal.billing_address 
    : [companyFinal?.country, companyFinal?.state, companyFinal?.city]
    .filter(Boolean) // This removes empty values
    .join(', ')} />
                                    </Col>
                                    <Col md="6" className='mb-3'>
                                    <Form.Select aria-label="Default select example" value={paymentTerms} disabled>
                      <option selected hidden>Select Payment Terms </option>
                      <option value="30 days">30 days</option>
                      <option value="60 days">60 days</option>
                      <option value="90 days">90 days</option>
                       <option value="Due on receipt">Due on receipt</option>
                      <option value="3 installation">3 installation</option>
                      <option value="2 installation">2 installation</option>

                    </Form.Select>
                                    </Col>
                                    <Col md="6" className='mb-3'>
  <Form.Select aria-label="Default select example" 
  onChange={(e) => {Account.length <= 0 ? navigate(`/accountchart`) : handleAccountChange(e) }}>
    <option selected hidden value={Account[0]?.account_id}>{Account[0]?.account_id?Account[0]?.account_name:"Payment Account"}</option>
    {Account.length <= 0 ?
      <option value="add"><i className="fa-solid fa-plus"></i>+ Add an Account</option>
      : Account.map((item, i) =>
        <option key={i} value={item.account_id}>{item.account_name}</option>
      )}
  </Form.Select>
</Col>
</Form.Group>
                                <div className='salesOrdTable'>
                                    <div className='listViews'>
                                        <table class="table">
                                            <thead>
                                                <tr>
                                                    <th>&nbsp;</th>
                                                    <th scope="col">Description</th>
                                                    <th scope="col">GL Codes</th>
                                                    <th scope="col">Qty</th>
                                                    {/* <th scope="col">Remaining Qty</th>
                                                    <th scope="col">Returned Qty</th> */}
                                                    <th scope="col">Unit</th>
                                                    <th scope="col">Price</th>
                                                    <th scope="col">Disc.%</th>
                                                    <th scope="col">Total</th>
                                                    <th scope="col">VAT%</th>
                                                    <th scope="col">Delivery Status</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                          
                                            {allProductServiceData.length != 0 && allProductServiceData?.map((d, i) => {
  // Find the inventory account name for the current product/service
  const inventoryAccount = inventoryOptions.find(option => option.id === Number(d.inventory));

  return (
    <tr key={i}>
      <td>
        <input
          type='checkbox'
          checked={deleteId.includes(d?.id)}
          onChange={() => onCheckDelete(d?.id, i + 1)}
          disabled={d.refund === "true" ||d.type==='service'}
        />
      </td>
      <td><span className='lightBlock'>{d?.name}</span></td>
      <td>
        <span className='lightBlock'>
          {inventoryAccount ? inventoryAccount.account_name : "N/A"}
        </span>
      </td>
      <td>
        {d?.qty ? (
          <input className='lightBlock2'  name='qty' value={d?.qty} readOnly/>
        ) : (
          <input className='lightBlock2' name='qty' value={d?.qty ? d?.qty : ''} readOnly/>
        )}
      </td>
      {/* <td>
        {d?.remaining_qty ? (
          <input className='lightBlock' readOnly name='quantity' value={d?.returned_qty} />
        ) : (
          <input className='lightBlock' name='quantity' value={d?.returned_qty ? d?.returned_qty : d?.qty} />
        )}
      </td>
      <td>
        {d?.returned_qty ? (
          <input className='lightBlock' readOnly name='quantity' value={d?.returned_qty} />
        ) : (
          <input className='lightBlock' name='quantity' value={d?.returned_qty ? d?.returned_qty : 0} />
        )}
      </td> */}
      <td><input className='lightBlock2' value={d?.unit} defaultValue={d.unit} readOnly /></td>
      <td><input className='lightBlock2' value={d?.type === 'service' ? d?.price : d?.purchase_price} readOnly name='price' /></td>
      <td>
        {d?.disc ? (
          <input className='lightBlock2' readOnly name='discount' value={d?.disc} />
        ) : (
          <input className='lightBlock2' name='discount' value={d?.disc ? d?.disc : ''} readOnly/>
        )}
      </td>
      <td>
        <input className='lightBlock2' readOnly value={d?.total} id={`all-${d?.id}`} />
      </td>
      <td><span className='lightBlock2' readOnly>{d?.tax}</span></td>
      <td><span className='lightBlock2' readOnly>{d?.delivery_status}</span></td>
    </tr>
  );
})}


                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                {/* <div className='mb-4'>
                                    <button className='commonButton expBtn' onClick={e=>handleShow(e)}><i class="fa-regular fa-magnifying-glass"></i> Add Product or Service</button>&nbsp;&nbsp;
                                    <button className='commonButton addBtn' onClick={e=>handelDelete(e)}>Delete</button>
                                </div> */}

<Form.Group className="row" controlId="exampleForm.ControlInput1">
                                    <Col md="6" className='mb-3'>
                                    <Form.Control as="textarea" placeholder="Notes" rows={3} disabled />
                                    </Col>
                                    <Col md="6" className='mb-3'>
                                        <div className='subTotal'>
                                            <div className='saflex'>
                                                <span>Sub Total</span>
                                                <span>{getSymbolFromCurrency(selectedCurrency)}{Number(subTotal1).toFixed(2)}</span>
                                            </div>
                                            {/* <div className='saflex'>
                                                <span>Discount Rate</span>
                                                <span>20:00</span>
                                            </div> */}
                                            <div className='saflex'>
                                                <span>Discount Amount</span>
                                                <span>{getSymbolFromCurrency(selectedCurrency)}{Number(subSubDiscount1).toFixed(2)}</span>
                                            </div>
                                            <div className='saflex'>
                                                <span>VAT</span>
                                                <span>{getSymbolFromCurrency(selectedCurrency)}{Number(subSubVat1).toFixed(2)}</span>
                                            </div>
                                            <div className='saflex totlbg'>
                                                <span>Total</span>
                                                <span>{getSymbolFromCurrency(selectedCurrency)}{Number(subSubTotalValue1).toFixed(2)}</span>
                                            </div>
                                        </div>
                                    </Col>
                                </Form.Group>
                                <Form.Group className="row" controlId="exampleForm.ControlInput1">
                                   <p>User Total</p>
                                    <Col md="6" className='mb-3'>
                                        <div className='subTotal'>
                                            <div className='saflex'>
                                                <span>Sub Total</span>
                                                <span>{getSymbolFromCurrency(currencyData)}{(selectedCurrencyValue * Number(subTotal1)).toFixed(2)}</span>
                                            </div>
                                            {/* <div className='saflex'>
                                                <span>Discount Rate</span>
                                                <span>20:00</span>
                                            </div> */}
                                            <div className='saflex'>
                                                <span>Discount Amount</span>
                                                <span>{getSymbolFromCurrency(currencyData)}{(selectedCurrencyValue * Number(subSubDiscount1)).toFixed(2)}</span>
                                            </div>
                                            <div className='saflex'>
                                                <span>VAT</span>
                                                <span>{getSymbolFromCurrency(currencyData)}{(selectedCurrencyValue * Number(subSubVat1)).toFixed(2)}</span>
                                            </div>
                                            <div className='saflex totlbg'>
                                                <span>Total</span>
                                                <span>
        {getSymbolFromCurrency(currencyData)}{(selectedCurrencyValue * subSubTotalValue1).toFixed(2)}
      </span>
                                            </div>
                                        </div>
                                    </Col>
                                </Form.Group>

                                <label>Attachments</label>
                                <div className='adsDrag'  disabled>
                                { 
                                
imageUrls!=''?
                                        
                                        <div style={{ position: 'relative', display: 'inline-block', }}>
                                        {/* Render the cross icon */}
                                        <span style={{ position: 'absolute', cursor: 'pointer',width: '200px' }} onClick={deleteImage}  disabled>
                                          <i className="fas fa-times-circle" style={{ fontSize: '1.5em', color: 'red' }}></i>
                                        </span>
                                        {/* Render the current image */}
                                        <i className="fa fa-file" style={{ fontSize: '4.5em', color: '#f39170',marginRight:'10px' }} disabled></i>
                                      </div>
                                      :null
                                        }
     {imageUrls.length <= 0 ?
                                    <div
                                        onDrop={handleDrop}
                                        onDragOver={handleDragOver}
                                        className='drgdrop'
                                        disabled
                                    >
                                        <i class="fa-light fa-cloud-arrow-up"></i>
                                        <div style={{ display: "flex", alignItems: "center" }}>
                                            <p>Drag &amp; drop files or </p>
                                            <input type="file" onChange={(e) => handleFile(e,e.target.files[0])} style={{ display: 'none' }} />
                                            <button onClick={(e) => _HANDLE_FILE_CHANGE(e)}>Browse</button>
                                        </div>

                                        {file && <p>Selected File: {file.name}</p>}
                                    </div>
                                  :null  
                                  }
</div>
</Form>
                        </div>
                        <button className='commonButton expBtn' onClick={onDone} disabled={disableButton}>
            {disableButton ? 'Processing...' : 'Refunds'}
        </button>
        &nbsp;&nbsp;
                        <button className='commonButton addBtn' onClick={()=>navigate('/invoice')}>Cancel</button>


                    </div>
                </div>
            </Sidebar>

            <Modal size="lg" show={showPdf} backdrop="static" onHide={handleClosePdf} className="salesExport">
        <div className='salesExpCol fullHgt'>
          <Modal.Header>
            {/* <Modal.Title>Modal heading</Modal.Title> */}
          </Modal.Header>
        


          <div className='modIframe'><iframe src={dataPdf?.data?.pdf} /></div>
          <div className='expRgt'>
            {/* <button className='commonButton expBtn btnshadow'><i class="fa-regular fa-envelope"></i> Email</button>
                        <button className='commonButton wtBtn btnshadow'><i class="fa-light fa-print"></i> Print</button>
                        <button className='commonButton wtBtn btnshadow'><i class="fa-light fa-down-to-bracket"></i> Download</button> */}

            {!views ?
              <div>
                {/* <button className='commonButton expBtn btnshadow' onClick={toggleHandlerb}><i class="fa-regular fa-envelope"></i> Email</button> */}
                <button className='commonButton wtBtn btnshadow' onClick={onButtonClick}><i class="fa-light fa-print"></i> Print</button>
                <button className='commonButton wtBtn btnshadow' onClick={onButtonClick}><i class="fa-light fa-down-to-bracket"></i> Download</button><br/><br/><br/><br/>
                <button className='commonButton expBtn btnshadow' onClick={vclose}><i class="fa-solid fa-xmark"></i> Close</button>

              </div> :
              <Form>
                <button className='commonButton backArrow' onClick={toggleHandlerc}><i class="fa-solid fa-arrow-left"></i> Back</button>
                <Form.Group className="mb-3 salesRform" controlId="exampleForm.ControlInput1">
                  <Col md="12" className='mb-2'>
                    <label>To <span className='redColor'>*</span></label>
                    <Form.Control type="text" placeholder="Johndoe@gmail.com"  value={dataPdf?.data?.supplierEmail}/>
                  </Col>
                  <Col md="12" className='mb-2'>
                    <label>Subject :</label>
                    <Form.Control type="text" placeholder="ABC Company" value={dataPdf?.data?.details?.quote_id} />
                  </Col>
                  <Col md="12" className='mb-3'>
                    <label>Message :</label>
                    <Form.Control as="textarea" placeholder="Enter your message" rows={3} className='mb-3' onChange={onAddressQuote} />
                  </Col>
                  <div className='salesCheck mb-3'><Form.Check type="checkbox" onClick={checkMe}/> Send me a copy</div>
                  <button type="button" className='commonButton expBtn btnshadow' onClick={onSendPdf}>Send</button>

                </Form.Group>
              </Form>
            }


          </div>
        </div>
      </Modal>
        </div>
    )
}
export default SalesReturnrefund