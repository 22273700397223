import React, { useState, useEffect } from 'react';
import Dbheader from '../../layout/Dbheader'
import Sidebar from '../../layout/Sidebar'
import { Link } from 'react-router-dom'
import Form from 'react-bootstrap/Form'
import Headtxt from '../Headtxt'
import Nav from 'react-bootstrap/Nav';
import { Col } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// import {getAddProductService} from "../../../../Service/ProductService/ProductService";
import { getEditProduct, getProductListById, getAddProductService, getImportProductService } from "../../../../Service/ProductService/ProductService";
import { useNavigate } from 'react-router-dom';
import Papa from 'papaparse';
import { CSVLink } from "react-csv";
import sampleProductServiceCsv from './SampleProductServiceCsv'
import { useParams, useSearchParams, useLocation } from 'react-router-dom';
import Swal from 'sweetalert2';

const ProductEdit = () => {
    const location = useLocation();
    const productId = new URLSearchParams(location.search);
    console.log(productId.get(`id`), "productId")
    const [show, setShow] = useState(false);
    const handleShow = () => setShow(true);
    const handleClose = () => setShow(false);
    const [file, setFile] = useState(null);
    const navigate = useNavigate();
    const [type, setType] = useState("");
    const [name, setName] = useState("");
    const [description, setDescription] = useState("");
    const [unit, setUnit] = useState("");
    const [salePrice, setSalePrice] = useState("");
    const [purchasePrice, setPurchasePrice] = useState("");
    const [purchaseTax, setPurchaseTax] = useState("");
    const [salesTax, setSalesTax] = useState("");
    const [orderQty, setOrderQty] = useState("");
    const [inventory, setInventory] = useState("");
    const [jsondata, setJsonData] = useState([]);
    const [allData, setAllData] = useState(
        {
            name: '',
            salesPrice: '',
        }
    )

    console.log(allData?.inventory, "ALLDATA")

    useEffect(() => {
        getallProductList()
    }, [])

    async function getallProductList() {
        getProductListById(productId.get(`id`)).then(function (result) {
            const response = result.data;
            console.log(response, "response")
            setAllData(response?.data?.product_details)
            // console.log(response?.data?.billing_address === response?.data?.delivery_address, "cococococo")
            // setToggled(response?.data?.billing_address === response?.data?.delivery_address ? false : true)

            // setCustomerId(response?.data);

            // setDepartmentId(response?.data?.businessUnit?.Departments);
        });
    }

    console.log(allData, "allData")


    const handleDrop = (event) => {
        event.preventDefault();

        const droppedFile = event.dataTransfer.files[0];
        handleFile(droppedFile);
    };

    const handleFile = (selectedFile) => {
        console.log(selectedFile, "selectedFile")
        if (selectedFile) {
            // Check if the selected file is a CSV file
            if (selectedFile.type === 'text/csv' || selectedFile.name.endsWith('.csv')) {
                // File is valid, set it in the state

                setFile(selectedFile);
                Papa.parse(selectedFile, {
                    header: true, // Assuming the first row contains headers
                    complete: (result) => {
                        console.log(result.data, "dadadadada")
                        setJsonData(result.data);
                    },
                    error: (error) => {
                        console.error('CSV parsing error:', error.message);
                    },
                });
            } else {
                // File is not valid, show an error or do something else
                toast.error('Please select a valid CSV file');
                // Optionally, you can clear the file input

            }
        }



        // You can perform additional actions with the file, such as uploading it to a server.
        // For simplicity, we're just logging the file information here.
        console.log('File selected:', selectedFile);
    };

    const handleDragOver = (event) => {
        event.preventDefault();
    };


    // For toggle
    // const [isToggled, setToggled] = useState(false);

    const handleToggleOn = () => {
        // setToggled(true);
        setInventory("No")
    };
    const handleToggleOff = () => {
        // setToggled(false);
        setInventory("Yes")
    };
console.log(inventory,"inventoryinventory")

    const onType = (e) => {
        setType(e.target.value)
    }

    const onName = (e) => {
        setName(e.target.value)
        setAllData({ ...allData, name: e.target.value })
    }

    const onDescription = (e) => {
        setDescription(e.target.value)
        setAllData({ ...allData, description: e.target.value })
    }

    const onUnit = (e) => {
        setUnit(e.target.value)
    }

    const onSalesPrice = (e) => {
        setSalePrice(e.target.value)
        setAllData({ ...allData, sales_price: e.target.value })
    }

    const onPurchasePrice = (e) => {
        setPurchasePrice(e.target.value)
        setAllData({ ...allData, purchase_price: e.target.value })
    }

    const onPurchaseTax = (e) => {
        setPurchaseTax(e.target.value)
        setAllData({ ...allData, tax: e.target.value })
    }
    const onSalesTax = (e) => {
        setSalesTax(e.target.value)
        setAllData({ ...allData, sales_tax: e.target.value })
    }
    const onOrderQty = (e) => {
        setOrderQty(e.target.value)
        setAllData({ ...allData, quantity: e.target.value })
    }




    const onSave = () => {
        console.log('price===',allData?.quantity,allData?.type)
        if (name === "" && allData?.name==='') {
            toast.error("Please type  name")
        } else if (unit === "" &&allData?.unit==='') {
            toast.error("Please select a unit")
        }
        //  else if (salePrice === "" && allData?.sales_price==='') {
        //     toast.error("Please type Sales Price(Optional)")
        // } 
        // else if (allData?.sales_price <=0) {
          
        //     toast.error("Please type valid Sales Price(Optional)")
        // }
        //  else if (allData?.tax<=0){
        //     toast.error("Please type valid purchase tax")
        // }
        //  else if (allData?.type === "product" && purchasePrice === "" &&allData?.purchase_price==="") {
        //     toast.error("Please type purchase price")
        // } else if (allData?.type === "product" && allData?.purchase_price<=0) {
        //     toast.error("Please type valid purchase price")
        // }
        // else if (allData?.type === "product" && orderQty === "" &&allData?.quantity==="") {
        //     toast.error("Please type order quantity")
        // } else if (allData?.type === "product" && allData?.quantity<=0) {
        //     toast.error("Please type valid order quantity")
        // } 
        else if (allData?.type === "product" && inventory === "" &&allData?.track_inventor==="") {
            toast.error("Please select track inventory")
        } else {

        getEditProduct(allData?.name, allData?.description, unit ? unit : allData?.unit, allData?.sales_price,
            allData?.tax, allData?.purchase_price, allData?.quantity,allData?.sales_tax, inventory ? inventory : allData?.track_inventory,
            productId.get(`id`),
            (r) => {
                console.log(r, "response")
                // if (r.success === true) {
                //     toast.success(r.message)
                //     navigate("/productservicelisting")
                // } 
                if (r.success === true) {
                    Swal.fire({
                     title: r.message,
                   //  text: 'The product has been added successfully!',
                     icon: 'success',
                     confirmButtonText: 'OK',
                     customClass: {
                         confirmButton: 'my-confirm-button'
                     }
                  }).then(() => {
                    navigate("/productservicelistings")
                  })
                  }
                else {
                    console.log("nononononono")
                    toast.error(r.message)

                }
            })
        }

    }

    const onExcelImport = () => {
        getImportProductService(jsondata,
            (r) => {
                console.log(r, "response")
                // if (r.success === true) {
                //     toast.success(r.message)
                //     navigate('/productservicelisting')

                // }
                if (r.success === true) {
                    Swal.fire({
                     title: r.message,
                   //  text: 'The product has been added successfully!',
                     icon: 'success',
                     confirmButtonText: 'OK',
                     customClass: {
                         confirmButton: 'my-confirm-button'
                     }
                  }).then(() => {
                    navigate("/productservicelistings")
                  })
                  }
                else {
                    console.log("nononononono")
                    toast.error(r.message)

                }
            })


    }

    return (
        <div className="customerListing pdt">
            <Dbheader />
            <Sidebar>
                <div className='customerMain'>
                    <div className='custBtm addSupply'>
                        <div className='dbTopsec pb-4'>
                            <Headtxt link="/productservicelistings" title="Edit Product" />
                            {/* <button className='commonButton expBtn' onClick={handleShow}><i class="fa-light fa-upload"></i> Import Excel</button> */}
                        </div>
                        <div className='commonWhite addCustomerForm p-3 mb-3'>
                            <Form>
                                <Form.Group className="row" controlId="exampleForm.ControlInput1">
                                    {/* <Col md="6" className='mb-3'>
                                        <Form.Select aria-label="Default select example" onChange={onType}>
                                            <option selected hidden>{allData?.type}</option>
                                            <option value="Product">Product</option>
                                            <option value="Service">Service</option>
                                        </Form.Select>
                                    </Col> */}
                                    <Col md="6" className='mb-3'>
                                        <Form.Control name='name' type="text" placeholder="Name" onChange={onName} value={allData?.name} />
                                    </Col>
                                    <Col md="6" className='mb-3'>
                                        <Form.Control name='name' type="text" placeholder="CDode" disabled value={allData?.code} />
                                    </Col>
                                </Form.Group>
                                <Form.Group className="row" controlId="exampleForm.ControlInput1">
                                    <Col md="12" className='mb-3'>
                                        <Form.Control as="textarea" placeholder="Description" name='description' rows={3} onChange={onDescription} value={allData?.description} />
                                    </Col>
                                </Form.Group>



                                <Form.Group className="row" controlId="exampleForm.ControlInput1">
                                {type === "Service" ||allData?.type==="Service" ?
<Col md="4" className='mb-3'>
<Form.Select aria-label="Default select example" onChange={onUnit} value={allData?.unit}>
    <option selected hidden>Select Unit</option>
    <option value="Hour">Hour</option>
    <option value="Day">Day</option>
    <option value="Contract">Contract</option>
    <option value="Percentage of completion">Percentage of completion</option>
</Form.Select>
</Col>
:

<Col md="4" className='mb-3'>
                                        <Form.Select aria-label="Default select example" onChange={onUnit} value={allData?.unit}>
                                            <option selected hidden>Select Unit</option>
                                            <option value="Piece">Piece</option>
                                            <option value="Centiliter">Centiliter</option>
                                            <option value="Ltr">Ltr</option>
                                            <option value="Kg">Kg</option>
                                            <option value="Gms">Gms</option>
                                            <option value="Cubic meter">Cubic meter</option>
                                            <option value="Contract">Contract</option>
                                            <option value="Percentage of completion">Percentage of completion</option>
                                        </Form.Select>
                                    </Col>
                                    
}
                                    <Col md="4" className='mb-3'>
                                        <Form.Control type="number" name='sales' placeholder="Sales Price(Optional)" onChange={onSalesPrice} value={allData?.sales_price} />
                                    </Col>
                                    {/* <Col md="4" className='mb-3'>
                                        <Form.Control type="text" placeholder="Purchase tax" onChange={onPurchaseTax}/>
                                    </Col> */}
                                    <Col md="4" className='mb-3'>
                                        <Form.Control type="number" name='tax' placeholder={type === "Service" ?"Sales tax(Optional)":"Purchase tax(Optional)"} onChange={type === "Service" ?onSalesTax:onPurchaseTax} value={allData?.tax} />
                                    </Col>

                                </Form.Group>


                                <Form.Group className="row" controlId="exampleForm.ControlInput1">

                                    <Col md="4" className='mb-3'>
                                        <Form.Control type="number" name='pricepurchase' placeholder="Purchase price(Optional)" onChange={onPurchasePrice} value={allData?.purchase_price} />
                                    </Col>

                                    <Col md="4" className='mb-3'>
                                        <Form.Control type="number" name='quantity' placeholder="Quantity(Optional)" onChange={onOrderQty} value={allData?.quantity} />

                                    </Col>
                                    <Col md="4" className='mb-3'>
                                        <Form.Control type="text" placeholder="Sales tax(Optional)" onChange={onSalesTax} value={allData?.sales_tax}/>
                                    </Col>
                                </Form.Group>

                                <div className='radioline mt-3 mb-3'><span>Track Inventory</span>
                                    {allData?.track_inventory === "Yes" ?
                                        <>
                                            <div>
                                                <input type="radio" id="test1" name="radio-group" checked onClick={handleToggleOff} />
                                                <label for="test1">Yes</label>
                                            </div>
                                            <div>
                                                <input type="radio" id="test2" name="radio-group" onClick={handleToggleOn} />
                                                <label for="test2">No</label>
                                            </div>
                                        </> :
                                        <>
                                            <div>
                                                <input type="radio" id="test1" name="radio-group" onClick={handleToggleOff} />
                                                <label for="test1">Yes</label>
                                            </div>
                                            <div>
                                                <input type="radio" id="test2" checked name="radio-group" onClick={handleToggleOn} />
                                                <label for="test2">No</label>
                                            </div>
                                        </>}

                                </div>



                                <button type='button' className='commonButton expBtn' onClick={onSave}>Save</button>&nbsp;&nbsp;
                                {/* <button className='commonButton addBtn'>Cancel</button> */}



                            </Form>

                        </div>
                    </div>


                </div>
            </Sidebar>




            <Modal show={show} onHide={handleClose} centered className='customerModal'>
                <Modal.Header closeButton>
                    <Modal.Title><h5>Import Products/Services</h5></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='modalContent'>
                        <div
                            onDrop={handleDrop}
                            onDragOver={handleDragOver}
                            className='drgdrop'
                        >
                            <i class="fa-light fa-cloud-arrow-up"></i>
                            <div style={{ display: "flex" }}>
                                <p>Drag &amp; drop files or </p>
                                <input type="file" onChange={(e) => handleFile(e.target.files[0])} style={{ display: 'none' }} />
                                <button onClick={() => document.querySelector('input[type="file"]').click()}>Browse</button>
                            </div>

                            {file && <p>Selected File: {file.name}</p>}
                        </div>
                    </div>
                    <div>
                        <p><button className='commonButton modalsubmit' onClick={onExcelImport}>Submit</button></p>

                        <CSVLink filename="sample_product_service.csv" data={sampleProductServiceCsv}>
                            <p><button className='commonButton downloadformat'>Download format </button></p>
                        </CSVLink>
                        <span className='commonButton downloadformat' style={{ fontSize: "13px" }}>(Please follow Demo data format mentioned in csv file)</span>

                    </div>
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default ProductEdit