import { React, useState,useEffect } from 'react'
import Sidebar from '../../../layout/Sidebar'
import Dbheader from '../../../layout/Dbheader'
import { Link, useNavigate } from 'react-router-dom'
import Form from 'react-bootstrap/Form';
import Dropdown from 'react-bootstrap/Dropdown';
// import Headtxt from '../Headtxt';
import moment from "moment"
import Listview from '../../sales/Listview';
import Gridview from '../../sales/Gridview';
import Card from 'react-bootstrap/Card'
import Headtxt from '../../Headtxt';
import Modal from 'react-bootstrap/Modal';
import Col from 'react-bootstrap/Col';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import bclogo from '../../../../images/bclogo.png';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { getBillPaymentDownload, getBillPaymentList, getSearchBillPaymentList } from '../../../../../Service/Quotation/QuotationService';
import cals from '../../../../images/cals.png';
import { ToastContainer, toast } from 'react-toastify';
import getSymbolFromCurrency from 'currency-symbol-map';
import income2 from '../../../../images/income2.png';
const BillPayment = () => {
    const navigate= useNavigate()
    const [selectedDate, setSelectedDate] = useState(null);
    const [fromDate, setFromDate] = useState('');
    const [toDate, setToDate] = useState('');
    const [formatedfromDate, setformatedFromDate] = useState(new Date());
    const [formatedtoDate, setformatedToDate] = useState(new Date());
    const [billExport,setBillExport]=useState([]);
    const [bill, setBill] = useState([]);
    const [loading, setLoading] = useState(false);
    const [visibleItems, setVisibleItems] = useState(5);
    const [userData, setUserData] = useState(null);
    const [usertype, setUserType] = useState(null);
    const [userid, setUserId] = useState(null);
    useEffect(() => {
      // Retrieve the JSON string from localStorage
      const jsonString = localStorage.getItem('1Simpleclick_User'); // Replace 'yourStorageKey' with the actual key.
    console.log("localdataaa======",localStorage.getItem('1Simpleclick_User'))
      // Parse the JSON string into an object
      const user_type = localStorage.getItem('1Simpleclick_UserType');
      const data = JSON.parse(jsonString);
      setUserId(localStorage.getItem("1Simpleclick_UserID"));
      // Update the state with the retrieved data
      setUserData(data);
      setUserType(user_type)
    }, []);
    
    const checkModule = (module, subModule) => {
        if (userData && userData.assigned_modules) {
          const assignedModules = userData.assigned_modules;
          
          // Handle the case where assigned_modules is an empty string
          if (assignedModules === "") {
            return true; // Or false, depending on the desired behavior
          }
      
          try {
            const modules = JSON.parse(assignedModules);
            
            // if (modules && modules[module]) {
                return modules[module]?.[subModule] || false; // Fallback to false if subModule is not found
         //   }
          } catch (error) {
            console.error("Error parsing assigned_modules:", error);
          }
        }
        return true; // Default to true if no assigned_modules or any errors
      };
    

      const handleFromDateChange = (e) => {
        const value  = e;
        setFromDate(value);
        // Perform filtration based on the selected from date
        // You can put your filtration logic here
        console.log('Filter data based on from date:',value);
      };
    
      const handleToDateChange = (e) => {
        const value  = e;
        setToDate(value);
        // Perform filtration based on the selected to date
        // You can put your filtration logic here
     
        console.log('Filter data based on to date:', value);
      //  getSearchList()
        //setToDate('')
      };
   
      async function getBill() {
        getBillPaymentList("").then(function (result) {
            const response = result?.data;
       console.log(response, "rrrr")
            setBill(response?.data);
        });
    }
    const getSearchList = () => {
        // Construct the filter criteria based on fromDate and toDate
        const filterCriteria = {
            fromDate: fromDate ? moment(fromDate).format('DD-MM-YYYY') : '',
            toDate: toDate ? moment(toDate).format('DD-MM-YYYY') : ''
        };
        console.log('filter---', filterCriteria);
        const queryString = new URLSearchParams(filterCriteria).toString();
        console.log(queryString, 'cre');
        getBillPaymentList(queryString).then(function (result) {
            const response = result?.data;
            console.log('resultttt---', response);
            setBill(response?.data);
        });
    };
    const onSearch = (e) => {
        getSearchBillPaymentList(e.target.value).then(function (result) {
            const response = result?.data;
            // console.log(response, "rrrr")
            setBill(response?.data);
        });}
    useEffect(() => {
        getBill();
       
    }, []);
    useEffect(() => {
        getSearchList();
    }, [fromDate, toDate]);

    const [show, setShow] = useState(false);
    const handleShow = () => setShow(true);
    const handleClose = () => {
        setShow(false);
        setViews(false)
    };


    const [showrecord, setShowRecord] = useState(false);
    const handleShowRecord  = () => setShowRecord(true);
    const handleCloseRecord = () => {
        setShowRecord(false);
    };


    const [supply, setSupply] = useState(false)
    const toggleCompany = (e) => {
        e.preventDefault();
        setSupply(false)
    }
    const toggleIndividual = (e) => {
        e.preventDefault();
        setSupply(true)
    }


    const loadMoreItems = () => {
        setLoading(true);
        setTimeout(() => {
            // Simulate loading delay
            setVisibleItems((prev) => prev + 5);
            setLoading(false);
        }, 1000); // Adjust the delay as needed
    };
const [view, setView] = useState(false)
  const toggleHandler = (e) => {
    e.preventDefault();
    setView(true)
  }
  const toggleHandler1 = (e) => {
    e.preventDefault();
    setView(false)
  }

  const [views, setViews] = useState(false)
  const [dataPdf, setDataPdf] = useState("");
  const toggleHandlerb = (e) => {
    e.preventDefault();
    setViews(true)
  }
 
  const onDownload = (id) => {
  
        getBillPaymentDownload(id,
      (r) => {
        console.log(r, "response")
        if (r.success === true) {
        //   toast.success(r.message)
        //   navigate('/bill')
       // setDataPdf(r)
       // const pdfUrl = dataPdf?.data?.pdf;
          window.open(r?.data?.pdf, '_blank'); // Open PDF in a new tab
      
        } else {
          console.log("nononononono")
          toast.error(r.message)

        }
      })
  }
  // custom-select-drupdown
const [isOpen, setIsOpen] = useState(false);
const [selectedOption, setSelectedOption] = useState('More');

const toggleDropdown = () => {
  setIsOpen(!isOpen);
};

const handleOptionClick = (option) => {
  setSelectedOption(option);
  setIsOpen(false);
};

const [shows, setShows] = useState(false);
const handleShowinv = () => {
    setShows(true);
    setShowsvat(false);
}
const handleCloseinv = () => {
    setShows(false);
    setShowsvat(true);
}

const [showsvat, setShowsvat] = useState(false);
const handleClosevat = () => setShowsvat(false);
const handleScroll = () => {
    if (
        window.innerHeight + document.documentElement.scrollTop ===
        document.documentElement.offsetHeight
    ) {
        // Auto-load more items when scrolling to the bottom
        loadMoreItems();
    }
};

useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
        window.removeEventListener('scroll', handleScroll);
    };
}, []);
    return (
        <div className="producservlisting pdt">
            <Dbheader />
            <Sidebar>
                <div className='customerMain'>
                <div className='custTop'>
            <Link to='/supplierlisting' className={usertype == 2 ?(checkModule('Purchase','Supplier') ? '' :'disabled'):''}>Supplier</Link>
            {/* <Link to='/productservicelistings'>Product &amp; Service</Link> */}
            <Link to='/quotelisting' className={usertype == 2 ?(checkModule('Purchase','Quote') ? '' :'disabled'):''}>Quote</Link>
            <Link to='/purchaseorderlisting' className={usertype == 2 ?(checkModule('Purchase','PurchaseOrders') ? '' :'disabled'):''}>Purchase Order</Link>
            <Link to='/bill' className={usertype == 2 ?(checkModule('Purchase','Bill') ? '' :'disabled'):''}>Bill</Link>
            <div className="custom-dropdown-container customDropdown ms-4">
                            <div className="custom-dropdown-header active" onClick={toggleDropdown}>
                                <span>{selectedOption}</span>
                                <span className={`dropdown-arrow ${isOpen ? 'open' : ''}`}> <i class="fa-regular fa-angle-down"></i></span>
                            </div>

                            {isOpen && (
                                <ul className="custom-dropdown-options">
                                    <li
                                        className={`option ${selectedOption === 'Option 1' ? 'selected' : ''}`}
                                       // onClick={() => handleOptionClick('Option 1')}
                                    >
                                     <Link to='/manualexpenses' className={`${usertype == 2 ?(checkModule('Purchase','Bill') ? '' :'disabled'):''}`}><img src={income2} alt='' style={{width:'20px',height:'20px',color:'#7E89A1'}}/> Manual Expenses</Link>
                                    </li>
                                    <li
                                        className={`option ${selectedOption === 'Option 2' ? 'selected' : ''}`}
                                        onClick={() => handleOptionClick('Option 2')} hidden
                                    >
                                        <Link to="/billpayment" className={usertype == 2 ?(checkModule('Purchase','ManualExpenses') ? 'active' :'disabled'):'active'}><i class="fa-light fa-calendar-lines-pen"></i> Bill Payment</Link>
                                    </li>
                                    <li
                                        className={`option ${selectedOption === 'Option 3' ? 'selected' : ''}`}
                                        onClick={() => handleOptionClick('Option 3')} hidden
                                    >
                                        <Link to="">Option 3</Link>
                                    </li>
                                    <li
                                        className={`option ${selectedOption === 'Option 4' ? 'selected' : ''}`}
                                      //  onClick={() => handleOptionClick('Option 4')}
                                    >
                                         <Link to="/purchaserefundlist" className={usertype == 2 ?(checkModule('Purchase','Refund') ? '' :'disabled'):''}><i className="fa-light fa-calendar-lines-pen"></i> Refunds</Link>
                                    </li>
                                </ul>
                            )}

{/* <p>You selected: {selectedOption}</p> */}
                        </div>
          </div>
                    <div className='productServList'>
                        <div className='dbTopsec pb-4 pt-2 noBackbtn'>
                            <Headtxt link="/" title="Bill Payment" />
                            
                            {/* <div className='custButton'>
                                <button className='commonButton addBtn'><Link to="/addproductservice"><i class="fa-regular fa-circle-plus"></i> Add Product</Link></button>&nbsp;&nbsp;
                                <button className='commonButton expBtn'><i class="fa-light fa-download"></i> Export Excel</button>
                            </div> */}
                        </div>

                        <div className='customerSrchTop pb-3 poResponsive'>
              <div className='customSrchfield'>
                <div>
                <span><i class="fa-solid fa-magnifying-glass"></i></span><input type='search' placeholder='Search by keywords'  onChange={onSearch}/>
                </div>&nbsp;&nbsp;
                <div className='invDatepicker' >
            <DatePicker
                    selected={fromDate}
                    onChange={handleFromDateChange}
                    selectsStart
                    startDate={fromDate}
                    endDate={toDate}
                    placeholderText="From Date"
                    isClearable={fromDate?true:false}
                />
                 </div>
                   <div className='invDatepicker' style={{ marginLeft: '10px' }}>
                <DatePicker
                    selected={toDate}
                    onChange={handleToDateChange}
                    selectsEnd
                    startDate={fromDate}
                    endDate={toDate}
                    placeholderText="To Date"
                    isClearable={toDate?true:false}
                    
                />
                </div>
                </div>
              <div>
                </div>
              <div>
                
            <Link to='/billpaymentadd' className='commonButton expBtn'><i class="fa-regular fa-circle-plus"></i> Add Bill payment</Link>&nbsp;&nbsp;
                {/* <button className='commonButton expBtn' onClick={handleShow}><i class="fa-light fa-upload"></i> Export</button> */}
              </div>
            </div>


                        {/* <div className='addsuppBtn'>
                            <button className={`${!supply ? "commonButton active" : "commonButton "} `} onClick={toggleCompany}>Product</button>
                            <button className={`${supply ? "commonButton active" : "commonButton "} `} onClick={toggleIndividual}>Service</button>
                        </div> */}
                        <div className='mt-2'>
                            
                            <div className='gridList mb-3 flexfield gridListResp' style={{justifyContent: "flex-end"}}>
                                {/* <div className='srtBy'>
                                    <label>Sort by: </label>&nbsp;
                                    <Form.Select aria-label="Default select example" className='sortby'>
                                    <option>Pending</option>
                                    <option>Cancelled</option>
                                    <option>Bill Generated</option>
                                    </Form.Select>&nbsp;
                                </div> */}
                                <div>
                                <button size="100%" style={{ backgroundColor: "#DCE5EC" }} onClick={toggleHandler1} className={!view? "listactive" : "" }><i class="fa-solid fa-list"></i></button>
                                <button size="100%" style={{ backgroundColor: "#DCE5EC" }} onClick={toggleHandler} className={view? "listactive" : "" }><i class="fa-solid fa-grid"></i></button>
                                </div>
                            </div>
                            {!view ? 
                            <div className='listViews'>
                            <table class="table">
                                <thead>
                                    <tr>
                                        <th scope="col">Bill Date</th>
                                        <th scope="col">Bill Id</th>
                                        <th scope="col">Supplier</th>
                                        <th scope="col">Amount</th>
                                        {/* <th scope="col">Due Amount</th> */}
                                        <th scope="col">Created/Updated By</th>
                                        <th scope="col">Status</th>
                                        <th scope="col">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                {bill?.length != 0 && bill?.slice(0, visibleItems)?.map((item, index) => (
                                         usertype==1? 
                                       <tr>
                                            <td>{item.payment_date}</td>
                                            <td><Link to={`/viewbilldetails?id=${item.BillID}`}>{item.bill_id}</Link></td>
                                            <td>{item.type==='Company'?item.company_name:`${item?.fname} ${item?.lname}`}</td>
                                            <td>{`${item.user_currency} ${item.amount}`}</td>
                                            {/* <td>${item.due_amount}</td> */}
                                            <td>{item.creatorFname} {item.creatorLname}</td>
                                            <td>
                                            <span className='pending' style={{ backgroundColor: item?.status === 'Cancelled' ?  '#f2b8be': item?.status === 'Not Ordered' ? '#f8d5c9' : item?.status === 'Partially Paid' ? '#cfdcf8': item?.status === 'Paid' ? '#d7f4b2': item?.status === 'Billed' ? '#ce7ac': item?.status === 'Unpaid'||'Not Billed' ? '#f0caee' : '#ce7ac',
    color: item?.status === 'Cancelled' ? '#e51c0e' : item?.status === 'Partially Paid' ? '#1650ce': item?.status === 'Not Ordered' ? '#FF6C3C': item?.status === 'Paid' ? '#ce7ac': item?.status === 'Billed' ? '#ce7ac' : item?.status === 'Unpaid'||'Not Billed' ? '#e61cdf': '#6b992c' }}>
        {item?.status}
    </span></td>
                                            <td>
                                                <div className='tdFlex'>
                                                    {/* <span className='viewIcon'><Link to={`/customerdetails`}><i class="fa-light fa-eye"></i></Link></span> */}
                                                {/* <span className='viewIcon orange'><Link to={`/`}><i class="fa-light fa-pen"></i></Link></span> */}
                                                {/* <span className='viewIcon'><i class="fa-regular fa-trash-can"></i></span>&nbsp;&nbsp; */}
                                                    <Dropdown className='dropRow'>
                                                        <Dropdown.Toggle id="dropdown-basic">
                                                        <i class="fa-light fa-ellipsis-vertical"></i>
                                                        </Dropdown.Toggle>

                                                        <Dropdown.Menu>                                                            
                                                       
                                                        <Dropdown.Item onClick={()=>navigate(`/viewbillpaymentdetails?id=${item.BillID}`)} ><i className="fa-light fa-eye"></i> &nbsp; View</Dropdown.Item> 
                                                             <Dropdown.Item onClick={()=> window.open(item?.bill_payment_pdf, '_blank')} ><i class="fa-light fa-download"></i> &nbsp; Download</Dropdown.Item>                                                            
                                                        </Dropdown.Menu>

                                                    </Dropdown>
                                                </div>
                                            </td>
                                        </tr>
                                        : usertype==2&&item.created_by==userid?
                                        <tr>
                                        <td>{item.payment_date}</td>
                                        <td><Link to={`/viewbilldetails?id=${item.BillID}`}>{item.bill_id}</Link></td>
                                        <td>{item.type==='Company'?item.company_name:`${item?.fname} ${item?.lname}`}</td>
                                        <td>{`${item.user_currency} ${item.amount}`}</td>
                                        {/* <td>${item.due_amount}</td> */}
                                        <td>{item.creatorFname} {item.creatorLname}</td>
                                        <td>
                                        <span className='pending' style={{ backgroundColor: item?.status === 'Cancelled' ?  '#f2b8be': item?.status === 'Not Ordered' ? '#f8d5c9' : item?.status === 'Partially Paid' ? '#cfdcf8': item?.status === 'Paid' ? '#d7f4b2': item?.status === 'Billed' ? '#ce7ac': item?.status === 'Unpaid'||'Not Billed' ? '#f0caee' : '#ce7ac',
color: item?.status === 'Cancelled' ? '#e51c0e' : item?.status === 'Partially Paid' ? '#1650ce': item?.status === 'Not Ordered' ? '#FF6C3C': item?.status === 'Paid' ? '#ce7ac': item?.status === 'Billed' ? '#ce7ac' : item?.status === 'Unpaid'||'Not Billed' ? '#e61cdf': '#6b992c' }}>
    {item?.status}
</span></td>
                                        <td>
                                            <div className='tdFlex'>
                                                {/* <span className='viewIcon'><Link to={`/customerdetails`}><i class="fa-light fa-eye"></i></Link></span> */}
                                            {/* <span className='viewIcon orange'><Link to={`/`}><i class="fa-light fa-pen"></i></Link></span> */}
                                            {/* <span className='viewIcon'><i class="fa-regular fa-trash-can"></i></span>&nbsp;&nbsp; */}
                                                <Dropdown className='dropRow'>
                                                    <Dropdown.Toggle id="dropdown-basic">
                                                    <i class="fa-light fa-ellipsis-vertical"></i>
                                                    </Dropdown.Toggle>

                                                    <Dropdown.Menu>                                                            
                                                   
                                                    <Dropdown.Item onClick={()=>navigate(`/viewbillpaymentdetails?id=${item.BillID}`)} ><i className="fa-light fa-eye"></i> &nbsp; View</Dropdown.Item> 
                                                         <Dropdown.Item onClick={()=> window.open(item?.bill_payment_pdf, '_blank')} ><i class="fa-light fa-download"></i> &nbsp; Download</Dropdown.Item>                                                            
                                                    </Dropdown.Menu>

                                                </Dropdown>
                                            </div>
                                        </td>
                                    </tr>
                                    :null
                               ) )}
                                        
                
                
                                </tbody>
                            </table>
                            {loading &&
                                        <div className='loadItems'>
                                            <button className="commonButton addBtn">
                                                Loading...
                                            </button>
                                        </div>
                                    }
                                    {!loading && (
                                        <div className='loadItems'>
                                            <button onClick={loadMoreItems} className="commonButton addBtn">
                                                No More Bill Payment
                                            </button>
                                        </div>
                                    )}
                        </div>
                         : 
                         <div className='gridView'>
                         {bill?.map((item) => (
usertype==1? 
                             <Card>
                                 <Card.Body>
                                     <div className='commonFlex'>
                                         <h6>{item?.bill_id}</h6>
                                         <span><img src={cals} alt='' className='mb-2' /> {item?.bill_date}</span>
                                     </div>
                                     <div className='commonFlex'>
                                         <span className='customr'>{item.type='Company'?item.company_name:`${item?.fname} ${item?.lname}`}</span>
                                         <span>&nbsp;</span>
                                     </div>
                                     <div className='commonFlex'>
                                     <span className='pending' style={{
    backgroundColor: item?.status === 'Cancelled' ? '#f2b8be' :
                     item?.status === 'Not Ordered' ? '#f8d5c9' :
                     item?.status === 'Paid'||item?.status === 'Billed' ? '#d7f4b2' :
                     item?.status === 'Partially Paid' ? '#cfdcf8' :
                   //  item?.status === 'Billed' ? '#ce7ac' :
                     item?.status === 'Unpaid' || item?.status === 'Not Billed' ? '#f0caee' : '',
    color: item?.status === 'Cancelled' ? '#e51c0e' :
           item?.status === 'Partially Paid' ? '#1650ce' :
           item?.status === 'Not Ordered' ? '#FF6C3C' :
           item?.status === 'Paid' ||item?.status === 'Billed'? '#638D2C' :
          // item?.status === 'Billed' ? '#ce7ac' :
           item?.status === 'Unpaid' || item?.status === 'Not Billed' ? '#e61cdf' : ''
}}>{item?.status}</span>
                                         <h6>{item?.total}</h6>
                                     </div>
                                     <div className='commonFlex gridFooter'>
                                         <div>
                                             {/* <span className='viewIcon orange'><Link to={`/`}><i className="fa-light fa-pen"></i></Link></span> */}
                                             {/* <span className='viewIcon'><i className="fa-regular fa-trash-can"></i></span> */}
                                         </div>
                                         <div className='invgridicn'>
                                             {/* <Link to=""><i className="far fa-envelope"></i></Link>&nbsp; */}
                                             <div className='tdFlex'>
                                                 {/* <span className='viewIcon'><Link to={`/customerdetails`}><i className="fa-light fa-eye"></i></Link></span> */}
                                                 {/* <span className='viewIcon orange'><Link to={`/`}><i className="fa-light fa-pen"></i></Link></span>
                                                 <span className='viewIcon'><i className="fa-regular fa-trash-can"></i></span>&nbsp;&nbsp; */}
                                                 <Dropdown className='dropRow'>
                                                        <Dropdown.Toggle id="dropdown-basic">
                                                        <i class="fa-light fa-ellipsis-vertical"></i>
                                                        </Dropdown.Toggle>

                                                        <Dropdown.Menu>                                                            
                                                            <Dropdown.Item onClick={()=>onDownload(item.id)} ><i class="fa-light fa-download"></i> &nbsp; Download</Dropdown.Item>                                                            
                                                        </Dropdown.Menu>
                                                    </Dropdown>
                                             </div>
                                         </div>
                                     </div>
                                     {/* <div className='cardBlock actn'>
                                     <div>
                                         <span className='viewIcon'><Link to={`/customerdetails`}><i className="fa-light fa-eye"></i></Link></span>
                                         <span className='viewIcon orange'><Link to={`/editcustomer`}><i className="fa-light fa-pen"></i></Link></span>
                                         <span className='viewIcon'><i className="fa-regular fa-trash-can"></i></span>
                                     </div>
                                 </div> */}
                                 </Card.Body>
                             </Card>
                             : usertype==2&&item.created_by==userid?
                             <Card>
                                 <Card.Body>
                                     <div className='commonFlex'>
                                         <h6>{item?.bill_id}</h6>
                                         <span><img src={cals} alt='' className='mb-2' /> {item?.bill_date}</span>
                                     </div>
                                     <div className='commonFlex'>
                                         <span className='customr'>{item.type='Company'?item.company_name:`${item?.fname} ${item?.lname}`}</span>
                                         <span>&nbsp;</span>
                                     </div>
                                     <div className='commonFlex'>
                                     <span className='pending' style={{
    backgroundColor: item?.status === 'Cancelled' ? '#f2b8be' :
                     item?.status === 'Not Ordered' ? '#f8d5c9' :
                     item?.status === 'Paid'||item?.status === 'Billed' ? '#d7f4b2' :
                     item?.status === 'Partially Paid' ? '#cfdcf8' :
                   //  item?.status === 'Billed' ? '#ce7ac' :
                     item?.status === 'Unpaid' || item?.status === 'Not Billed' ? '#f0caee' : '',
    color: item?.status === 'Cancelled' ? '#e51c0e' :
           item?.status === 'Partially Paid' ? '#1650ce' :
           item?.status === 'Not Ordered' ? '#FF6C3C' :
           item?.status === 'Paid' ||item?.status === 'Billed'? '#638D2C' :
          // item?.status === 'Billed' ? '#ce7ac' :
           item?.status === 'Unpaid' || item?.status === 'Not Billed' ? '#e61cdf' : ''
}}>{item?.status}</span>
                                         <h6>{item?.total}</h6>
                                     </div>
                                     <div className='commonFlex gridFooter'>
                                         <div>
                                             {/* <span className='viewIcon orange'><Link to={`/`}><i className="fa-light fa-pen"></i></Link></span> */}
                                             {/* <span className='viewIcon'><i className="fa-regular fa-trash-can"></i></span> */}
                                         </div>
                                         <div className='invgridicn'>
                                             {/* <Link to=""><i className="far fa-envelope"></i></Link>&nbsp; */}
                                             <div className='tdFlex'>
                                                 {/* <span className='viewIcon'><Link to={`/customerdetails`}><i className="fa-light fa-eye"></i></Link></span> */}
                                                 {/* <span className='viewIcon orange'><Link to={`/`}><i className="fa-light fa-pen"></i></Link></span>
                                                 <span className='viewIcon'><i className="fa-regular fa-trash-can"></i></span>&nbsp;&nbsp; */}
                                                 <Dropdown className='dropRow'>
                                                        <Dropdown.Toggle id="dropdown-basic">
                                                        <i class="fa-light fa-ellipsis-vertical"></i>
                                                        </Dropdown.Toggle>

                                                        <Dropdown.Menu>                                                            
                                                            <Dropdown.Item onClick={()=>onDownload(item.id)} ><i class="fa-light fa-download"></i> &nbsp; Download</Dropdown.Item>                                                            
                                                        </Dropdown.Menu>
                                                    </Dropdown>
                                             </div>
                                         </div>
                                     </div>
                                     {/* <div className='cardBlock actn'>
                                     <div>
                                         <span className='viewIcon'><Link to={`/customerdetails`}><i className="fa-light fa-eye"></i></Link></span>
                                         <span className='viewIcon orange'><Link to={`/editcustomer`}><i className="fa-light fa-pen"></i></Link></span>
                                         <span className='viewIcon'><i className="fa-regular fa-trash-can"></i></span>
                                     </div>
                                 </div> */}
                                 </Card.Body>
                             </Card>
                             :null
                         ))}

                     </div>
}

             </div>
         </div>
     </div>
            </Sidebar>




        </div>
    )
}

export default BillPayment