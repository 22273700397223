import webApi, { baseURL } from "../../WebApi/WebApi";

export const getCustomerList = async (data) => {
    return await webApi.get(`customer-list`, {

    });
}

export const getProductServiceList = async (data) => {
    return await webApi.get(`product-service-details-search?${data}`,
  
    );
}

export const getProductServiceListBYSupplierId = async (data) => {
    return await webApi.get(`product-service-list-search?${data}`,
  
    );
}

export const getAddCustomer = async (companyType, companyName, firstName, lastName, email, phone, webUrl,
    vatNumber, billAddress, country, state, city,currency, zipCode, deliveryAddress, DeliveryCountry, deliveryState,
    deliveryCity, deliveryZipCode,countrycode,statecode,deliverycountrycode,deliverystatecode ,onSuccess, onFailure) => {


    try {
        const res = await webApi.post(

            `/customer-details-store`,
            {
                type: companyType,
                company_name: companyName,
                fname: firstName,
                lname: lastName,
                email: email,
                phone: phone,
                website_url: webUrl,
                vat_number: vatNumber,
                billing_address: billAddress,
                country: country,
                state: state,
                city: city,
                zipcode: zipCode,
                currency:currency,
                delivery_address: deliveryAddress,
                delivery_country: DeliveryCountry,
                delivery_state: deliveryState,
                delivery_city: deliveryCity,
                delivery_zipcode: deliveryZipCode,
                country_code:countrycode,
                delivery_country_code:deliverycountrycode,
                state_code:statecode,
                delivery_state_code:deliverystatecode,
            }
        );
        if (res.status === 200) {
            const r = res.data;
            console.log(r, "rerere")
            return onSuccess(r);

        } else {
            onFailure("Something Wrong! Please Try again later" + res.data);
        }
    } catch (error) {
        console.log("fdgdf")
    }
};

export const getCustomerListById = async (data) => {
    return await webApi.get(`/customer-details/${data}`, {

    });
}

//edit customer
export const getEditCustomer = async (companyType, companyName, firstName, lastName, email, phone, webUrl,
    vatNumber, billAddress, country, state, city, zipCode, deliveryAddress, DeliveryCountry, deliveryState,
    deliveryCity, deliveryZipCode,id,countryCode,deliverycountryCode,statecode,deliverystateCode, onSuccess, onFailure) => {


    try {
        const res = await webApi.post(

            `/customer-edit`,
            {
                type: companyType,
                company_name: companyName,
                fname: firstName,
                lname: lastName,
                email: email,
                phone: phone,
                website_url: webUrl,
                vat_number: vatNumber,
                billing_address: billAddress,
                country: country,
                state: state,
                city: city,
                zipcode: zipCode,
                delivery_address: deliveryAddress,
                delivery_country: DeliveryCountry,
                delivery_state: deliveryState,
                delivery_city: deliveryCity,
                delivery_zipcode: deliveryZipCode,
                id:id,
                country_code:countryCode,
                delivery_country_code:deliverycountryCode,
                state_code:statecode,
                delivery_state_code:deliverystateCode,
            }
        );
        if (res.status === 200) {


            const r = res.data;
            console.log(r, "rerere")

            return onSuccess(r);

        } else {
            onFailure("Something Wrong! Please Try again later" + res.data);
        }
    } catch (error) {
        console.log("fdgdf")
    }
};


export const deleteCustomer = async (customerId, onSuccess, onFailure) => {
    console.log(customerId,"customerId")
    try {
        const res = await webApi.get(
            `/customer-delete/${customerId}`,
        );
        if (res.status === 200) {
            const r = res.data;
            return onSuccess(r);
        } else {
            onFailure("Something Wrong! Please Try again later" + res.data);
        }
    } catch (error) {
        console.log("fdgdf")
    }
};


export const getSearchCustomer = async (data) => {
    return await webApi.get( `/customer-search?searchText=${data}`, 
{

    });
}


export const getExcelCustomer = async () => {
    return await webApi.get( `/customer-list-export`, 
{

    });
}



export const getImportCustomer = async ( jsonData,onSuccess, onFailure) => {


    try {
        const res = await webApi.post(

            `/customer-details-import`,
            {customer:jsonData}
           
        );
        if (res.status === 200) {


            const r = res.data;
            console.log(r, "rerere")

            return onSuccess(r);

        } else {
            onFailure("Something Wrong! Please Try again later" + res.data);
        }
    } catch (error) {
        console.log("fdgdf")
    }
};

//============================Customer Quote========================
export const getAddSalesquotation = async (data, onSuccess, onFailure) => {
    console.log(data, "data")
    try {
        const res = await webApi.post(

            `/sales-quote-details-store`,
            data,
        );
        if (res.status === 200) {


            const r = res.data;
            console.log(r, "rerere")

            return onSuccess(r);

        } else {
            onFailure("Something Wrong! Please Try again later" + res.data);
        }
    } catch (error) {
        console.log("fdgdf")
    }
};

export const getEditsalesQuotation = async (id,data, onSuccess, onFailure) => {
    console.log(data, "data")
    try {
        const res = await webApi.post(

            `/sales-quote-details-edit/${id}`,
            data,
        );
        if (res.status === 200) {


            const r = res.data;
            console.log(r, "rerere")

            return onSuccess(r);

        } else {
            onFailure("Something Wrong! Please Try again later" + res.data);
        }
    } catch (error) {
        console.log("fdgdf")
    }
};
export const getdeletesalesQuotation = async (id,onSuccess, onFailure) => {
  
    try {
        const res = await webApi.get(

            `/sales-quote-delete/${id}`
           
        );
        if (res.status === 200) {


            const r = res.data;
            console.log(r, "rerere")

            return onSuccess(r);

        } else {
            onFailure("Something Wrong! Please Try again later" + res.data);
        }
    } catch (error) {
        console.log("fdgdf")
    }
};
export const getSendSalesQuote = async (id, email, quoteNo, address,checks, onSuccess, onFailure) => {

    console.log(id, email, quoteNo, address, "d,email,quoteNo,address")
    try {
        const res = await webApi.post(

            `/sales-quote-details-mail-sending/${id}`,
            {
                email: email,
                body: address,
                subject: quoteNo,
                selfCopy:checks
            }

        );
        if (res.status === 200) {


            const r = res.data;
            console.log(r, "rerere")

            return onSuccess(r);

        } else {
            onFailure("Something Wrong! Please Try again later" + res.data);
        }
    } catch (error) {
        console.log("fdgdf")
    }
};
export const getsalesQuotationCancelResend = async (id, data, onSuccess, onFailure) => {
    console.log(data, "data")
    try {
        const res = await webApi.post(

            `sales-quote-action`,{
                id:id,
                text:data
            }
        );
        if (res.status === 200) {


            const r = res.data;
            console.log(r, "rerere")

            return onSuccess(r);

        } else {
            onFailure("Something Wrong! Please Try again later" + res.data);
        }
    } catch (error) {
        console.log("fdgdf")
    }
};
export const getSalesQuotationList = async (data) => {
    return await webApi.get(`sales-quote-details-search?${data}`,

    );
}
export const getsalesQuoteData = async (data) => {
    
    return await webApi.get(`/sales-quote-details/${data}`,
    );
}
export const getsalesDateSearch = async (queryString) => {
    
    return await webApi.get(`sales-quote-details-search?${queryString}`,
    );
}
export const getSalesExcelQuote = async () => {
    return await webApi.get( `/sales-quote-list-export`, 
{

    });
}
//=================sales order=========================
export const addSalesOrder = async (data, onSuccess, onFailure) => {
    console.log(data, "data")
    try {
        const res = await webApi.post(

            `/sales-order-details-store`,
            data,
        );
        if (res.status === 200) {


            const r = res.data;
            console.log(r, "rerere")

            return onSuccess(r);

        } else {
            onFailure("Something Wrong! Please Try again later" + res.data);
        }
    } catch (error) {
        console.log("fdgdf")
    }
};

export const getEditsalesOrder = async (id,data, onSuccess, onFailure) => {
    console.log(data, "data")
    try {
        const res = await webApi.post(

            `/sales-order-details-edit`,
            data,
        );
        if (res.status === 200) {


            const r = res.data;
            console.log(r, "rerere")

            return onSuccess(r);

        } else {
            onFailure("Something Wrong! Please Try again later" + res.data);
        }
    } catch (error) {
        console.log("fdgdf")
    }
};

export const getdeletesalesOrder = async (id,onSuccess, onFailure) => {
  
    try {
        const res = await webApi.get(

            `/sales-order-delete/${id}`
           
        );
        if (res.status === 200) {


            const r = res.data;
            console.log(r, "rerere")

            return onSuccess(r);

        } else {
            onFailure("Something Wrong! Please Try again later" + res.data);
        }
    } catch (error) {
        console.log("fdgdf")
    }
};
export const getSendSalesOrder = async (id, email, quoteNo, address,checks, onSuccess, onFailure) => {

    console.log(id, email, quoteNo, address, "d,email,quoteNo,address")
    try {
        const res = await webApi.post(

            `/sales-order-details-mail-sending/${id}`,
            {
                email: email,
                body: address,
                subject: quoteNo,
                selfCopy:checks
            }

        );
        if (res.status === 200) {


            const r = res.data;
            console.log(r, "rerere")

            return onSuccess(r);

        } else {
            onFailure("Something Wrong! Please Try again later" + res.data);
        }
    } catch (error) {
        console.log("fdgdf")
    }
};
export const getsalesOrderancelResend = async (id, data, onSuccess, onFailure) => {
    console.log(data, "data")
    try {
        const res = await webApi.post(

            `sales-order-action`,{
                id:id,
                text:data
            }
        );
        if (res.status === 200) {


            const r = res.data;
            console.log(r, "rerere")

            return onSuccess(r);

        } else {
            onFailure("Something Wrong! Please Try again later" + res.data);
        }
    } catch (error) {
        console.log("fdgdf")
    }
};
export const getSalesOrderList = async (data) => {
    return await webApi.get(`sales-order-details-search?${data}`,

    );
}
export const getsalesorderDateSearch = async (queryString) => {
    
    return await webApi.get(`sales-order-details-search?${queryString}`,
    );
}
export const getsalesorderData = async (data) => {
    
    return await webApi.get(`/sales-order-details/${data}`,
    );
}
export const getSalesOrderExcel = async () => {
    return await webApi.get( `/sales-order-list-export`, 
{

    });
}
//=====================Invoice=======================
export const addInvoice = async (data, onSuccess, onFailure) => {
    console.log(data, "data")
    try {
        const res = await webApi.post(

            `/invoice-details-store`,
            data,
        );
        if (res.status === 200) {


            const r = res.data;
            console.log(r, "rerere")

            return onSuccess(r);

        } else {
            onFailure("Something Wrong! Please Try again later" + res.data);
        }
    } catch (error) {
        console.log("fdgdf")
    }
};

export const getdeleteinvoice = async (id,onSuccess, onFailure) => {
  
    try {
        const res = await webApi.get(

            `/invoice-delete/${id}`
           
        );
        if (res.status === 200) {


            const r = res.data;
            console.log(r, "rerere")

            return onSuccess(r);

        } else {
            onFailure("Something Wrong! Please Try again later" + res.data);
        }
    } catch (error) {
        console.log("fdgdf")
    }
};

export const getEditInvoice = async (data, onSuccess, onFailure) => {
    console.log(data, "data")
    try {
        const res = await webApi.post(

            `/invoice-details-edit`,
            data,
        );
        if (res.status === 200) {


            const r = res.data;
            console.log(r, "rerere")

            return onSuccess(r);

        } else {
            onFailure("Something Wrong! Please Try again later" + res.data);
        }
    } catch (error) {
        console.log("fdgdf")
    }
};

export const getinvoiceCancelResend = async (id, data, onSuccess, onFailure) => {
    console.log(data, "data")
    try {
        const res = await webApi.post(

            `invoice-action`,{
                id:id,
                text:data
            }
        );
        if (res.status === 200) {


            const r = res.data;
            console.log(r, "rerere")

            return onSuccess(r);

        } else {
            onFailure("Something Wrong! Please Try again later" + res.data);
        }
    } catch (error) {
        console.log("fdgdf")
    }
};
export const getSendInvoice = async (id, email, quoteNo, address,checks, onSuccess, onFailure) => {

    console.log(id, email, quoteNo, address, "d,email,quoteNo,address")
    try {
        const res = await webApi.post(

            `/invoice-details-mail-sending/${id}`,
            {
                email: email,
                body: address,
                subject: quoteNo,
                selfCopy:checks
            }

        );
        if (res.status === 200) {


            const r = res.data;
            console.log(r, "rerere")

            return onSuccess(r);

        } else {
            onFailure("Something Wrong! Please Try again later" + res.data);
        }
    } catch (error) {
        console.log("fdgdf")
    }
};
export const getInvoiceList = async (data) => {
    return await webApi.get(`invoice-details-search?${data}`,

    );
}
export const getinvoiceDateSearch = async (queryString) => {
    
    return await webApi.get(`invoice-details-search?${queryString}`,
    );
}
export const getinvoiceData = async (data) => {
    
    return await webApi.get(`/invoice-details/${data}`,
    );
}
export const getInvoiceExcel = async () => {
    return await webApi.get( `/invoice-list-export`, 
{

    });
}

//===============================Sales Receipt==========================

export const getSalesReceiptId = async (data) => {
    return await webApi.get(`/invoice-notPaid`,

    );
};
export const getChequeList = async (data) => {
    return await webApi.get(`get-invoice-cheque-details/${data}`,

    );
};
export const manualSalesReceipt = async (id,data, onSuccess, onFailure) => {
    
    console.log(data, "rerereoo");
    try {
        const res = await webApi.post(`/invoice-manual-payment-details_store/${id}`,data);
        if (res.status === 200) {


            const r = res.data;
            console.log(r, "rerere")

            return onSuccess(r);

        } else {
            onFailure("Something Wrong! Please Try again later" + res.data);
        }
    } catch (error) {
        console.log("fdgdf")
    }
};

export const getAddSalesReceipt = async (id,mode,amount,date,selectedaccountId,currencyAmount, onSuccess, onFailure) => {

    console.log(id,mode,amount,date, "d,email,quoteNo,address")
    try {
        const res = await webApi.post(

            `/invoice-manual-payment-details_store/${id}`,
            {
                manual_payment_method: mode,
                amount: currencyAmount,
                manual_payment_date: date,
                manual_payment_account :selectedaccountId,
                manual_payment_type: "debit",
                customerCurrencyAmount: amount
            }

        );
        if (res.status === 200) {


            const r = res.data;
            console.log(r, "rerere")

            return onSuccess(r);

        } else {
            onFailure("Something Wrong! Please Try again later" + res.data);
        }
    } catch (error) {
        console.log("fdgdf")
    }
};

export const getSalesReceiptList = async (data) => {
    return await webApi.get(`/invoice-payment-details-search?${data}`,

    );
}
export const getSalesReceiptDateSearch = async (queryString) => {
    
    return await webApi.get(`/invoice-payment-details-search?${queryString}`,
    );
}
export const getSalesReceiptDownload = async (id, onSuccess, onFailure) => {
 
    try {
        const res = await webApi.post(

            `/invoice-details-download`,{
                id:id,
            }
        );
        if (res.status === 200) {
            const r = res.data;
            console.log(r, "rerere")

            return onSuccess(r);

        } else {
            onFailure("Something Wrong! Please Try again later" + res.data);
        }
    } catch (error) {
        console.log("fdgdf")
    }
};

//////////////////Refund==========================
export const getRefundInvoice = async (id,data, onSuccess, onFailure) => {
    console.log(data, "data")
    try {
        const res = await webApi.post(
`/invoice-products-return/${id}`,
            data,
        );
        if (res.status === 200) {


            const r = res.data;
            console.log(r, "rerere")

            return onSuccess(r);

        } else {
            onFailure("Something Wrong! Please Try again later" + res.data);
        }
    } catch (error) {
        console.log("fdgdf")
    }
};
export const getInvoiceAccount = async (data) => {
    return await webApi.get(`invoice-refund-account-list/${data}`,

    );
}
export const getRefundList = async (data) => {
    return await webApi.get(`invoice-return-search?${data}`,

    );
}
export const getRefundDateSearch = async (queryString) => {
    
    return await webApi.get(`invoice-return-search?${queryString}`,
    );
}