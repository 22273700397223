import { React, useState } from 'react'
import Sidebar from '../../layout/Sidebar'
import Dbheader from '../../layout/Dbheader'
import Form from 'react-bootstrap/Form';
import Headtxt from '../Headtxt';
import Col from 'react-bootstrap/Col';
import 'react-datepicker/dist/react-datepicker.css';
import Customdropdown from '../../Customdropdown';
import usrimg from '../../../images/profimg.png';
import { Link, useNavigate } from 'react-router-dom';
import { Modal, Button,} from 'react-bootstrap';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import { getAddUser } from '../../../../Service/User/UserService';
import { PhoneInput } from 'react-international-phone';
const AddUser = ({setIsSpinner}) => {

    const [image, setImage] = useState({ preview: "", raw: "" });
    const [getImage, setGetImage] = useState(null);
    const [getImageresult, setGetImageresult] = useState("")
    const [show, setShow] = useState(false);
    const [firstname, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
  const [selectedModules, setSelectedModules] = useState({
    sales: { checked: false, subModules: { customers: false, quote: false,salesOrders: false,invoices: false,salesReceipt: false,refund: false, } },
    purchase: { checked: false, subModules: { supplier: false,quote: false, purchaseOrders: false,bill: false,BillPayments: false,ManualExpenses: false, refund: false } },
    accounting: { checked: false, subModules: { gL_Account_Transactions: false,transactions: false,chart_of_Accounts: false } },
    product: { checked: false, subModules: {ProductService: false } },
    report: { checked: false, subModules: { balance_Sheet: false, Income_Profit_Loss: false,Cashflow_Statement:false} },
   // professional: { checked: false, subModules: { services: false,} }
  });
  const [msg, setMsg] = useState("");
  const [phone, setPhone] = useState("");
  const handleClose = () => setShow(false);
  const handleShow = (e) => {e.preventDefault(); setShow(true)};
  const navigate = useNavigate();
  const handleMainChange = (e) => {
    setSelectedModules({
      ...selectedModules,
      [e.target.name]: {
        ...selectedModules[e.target.name],
        checked: e.target.checked,
      },
    });
  };

  const handleSubModuleChange = (module, subModule, value) => {
    setSelectedModules({
      ...selectedModules,
      [module]: {
        ...selectedModules[module],
        subModules: {
          ...selectedModules[module].subModules,
          [subModule]: value,
        },
      },
    });
  };
  const onFirstName = (e) => {
    setFirstName(e.target.value)
  }
  const onLastName = (e) => {
    setLastName(e.target.value)
  }
  const onEmail = (e) => {
    setEmail(e.target.value)
  }
  const handleSubmit = () => {
    // Perform submission logic, such as sending data to an API or logging it
    console.log('Selected Modules:', selectedModules);
    // Optionally close the modal after submission
    handleClose();
  };
  const transformModulesForAPI  = (modules) => {
    const formattedModules = {};
    Object.entries(modules).forEach(([moduleKey, moduleData]) => {
      // if (moduleData.checked) {
        const formattedSubModules = {};
        Object.entries(moduleData.subModules).forEach(([subModuleKey, isChecked]) => {
          const formattedSubModuleKey = subModuleKey
            .replace(/_/g, ' ')
            .replace(/\b\w/g, char => char.toUpperCase()); // Capitalize each word
          formattedSubModules[formattedSubModuleKey] = isChecked;
        });
        const formattedModuleKey = moduleKey.charAt(0).toUpperCase() + moduleKey.slice(1);
        formattedModules[formattedModuleKey] = formattedSubModules;
    //  }
    })
  
    return formattedModules ;
  };
  
  
  
  // Usage
  const apiData = transformModulesForAPI(selectedModules);
  const isAnyModuleSelected = (modules) => {
    return Object.values(modules).some(module => module.checked);
  };
  console.log('apidata===',apiData)
  const _SUBMIT_User_Add = async (e) => {
    e.preventDefault();
    
    if (firstname === '') {
      toast.error('Please enter first name');
      return;
    }
    if (lastName === '') {
      toast.error('Please enter last name');
      return;
    }
    if (email === '') {
      toast.error('Please enter email');
      return;
    }
    if (phone == '') {
      toast.error('Please enter phone number');
      return;
    }
    if (!isAnyModuleSelected(selectedModules)) {
      toast.error('Please choose module');
      return;
    }
  
    setIsSpinner(true);
  
    // Prepare FormData
    let formData = new FormData();
    formData.append('first_name', firstname);
    formData.append('last_name', lastName);
    formData.append('phone', phone);
    formData.append('email', email);
    formData.append('profile_image', getImage); // Use the file object directly
    formData.append('modules', JSON.stringify(apiData));
  
    console.log('FormData content:', formData);
  
    await getAddUser(formData, (res) => {
      console.log(res.message, "????????????????");
      setIsSpinner(false);
  
      if (res.success === true) {
        Swal.fire({
          title: res.message,
          icon: 'success',
          confirmButtonText: 'OK',
          customClass: {
            confirmButton: 'my-confirm-button'
          }
        }).then(() => {
          navigate("/userlisting");
        });
      } else {
        toast.error(res.message);
      }
    });
  };
  
console.log('getImage',getImage)
    const onFile = (event) => {

        if (event["target"].files.length > 0) {
          const file = event["target"].files[0];
        //   setFieldValue("browse", file.name)
    
          // setUserData({...userData, profileImage : file});
          setImage({
            preview: URL.createObjectURL(event.target.files[0]),
            raw: event.target.files[0]
          });
    console.log(file,'selected')
          setGetImage(file)
          setGetImageresult(file.name)
    
          //     const reader = new FileReader();
          //     reader.readAsDataURL(file);
          //     reader.onload = (event) => {
          //         setGetImageresult(reader.result);
          //   };
        }
    
      }

    return (
        <div className="producservlisting pdt">
            <Dbheader />
            <Sidebar>
                <div className='customerMain'>
                    {/* <div className='custTop'>
                    <Link to='/balancesheet' >Balance Sheet</Link>
                    <Link to='/profitloss' >Income (Profit & Loss)</Link>
                    <Link to='' className='active'>Cashflow Statement</Link>
                    
                </div> */}



                    <div className='productServList'>
                        <div className='dbTopsec pb-4 pt-2 noBackbtn'>
                            <Headtxt link="" title="Add Sub User" />
                        </div>


                        <div className='commonWhite addCustomerForm mb-3 p-3'>
                            {/* <h6>Date Range</h6> */}
                            <Form>
                                <div className='profileTop2'>
                                    <div className='profileUpload'>
                                          <h6>Profile Photo</h6>
                                        <div className='profileUpblock'>
                                            <label htmlFor="upload-button" className='uploadBtn'>
                                                {image.preview ? (
                                                    <img src={image.preview} alt="dummy" width="100" height="100" />
                                                ) : (
                                                    <>
                                                        <span className="fa-stack fa-2x">
                                                            <i class="fa-thin fa-image"></i>
                                                        </span>
                                                    </>
                                                )}
                                                <span className="profEdit m-0"><i class="fa-light fa-pen-line"></i></span>
                                            </label>

                                            <input
                                                type="file"
                                                accept="image/png, image/jpeg"
                                                id="upload-button"
                                                style={{ display: "none" }}
                                                onChange={onFile}
                                            />
                                        </div>
                                    </div>

                                </div>
                                {/* <Form.Group className="row" controlId="exampleForm.ControlInput1">
                                    <Col md="12" className='mb-3'>
                                        <Customdropdown options={options} placeholdr="Consulting for" />
                                    </Col>
                                </Form.Group> */}
                                <Form.Group className="row" controlId="exampleForm.ControlInput1">
                                    <Col lg="6" className='mb-3'>
                                        <Form.Control type="text" placeholder="First Name" onChange={onFirstName} />
                                    </Col>
                                    <Col lg="6" className='mb-3'>
                                        <Form.Control type="text" placeholder="Last Name"  onChange={onLastName}/>
                                    </Col>
                                </Form.Group>
                                <Form.Group className="row" controlId="exampleForm.ControlInput1">
                                    {/* <Col lg="6" className='mb-3'>
                                        <Form.Control type="text" placeholder="Abc company" />
                                    </Col> */}
                                    <Col lg="6" className='mb-3'>
                                    
                                        <Form.Control type="mail" placeholder="Email" onChange={onEmail} />
                                    </Col>
                                    <Col md="6" className='mb-3'>
                    <PhoneInput
                      defaultCountry="ke"
                      value={phone}
                      className='phInp'
                      onChange={(phone) => setPhone(phone)}
                    />
                  </Col>
                                </Form.Group>
                                <div>
                                <button className='commonButton expBtn' onClick={(e)=>handleShow(e)}>Choose Modules</button>&nbsp;&nbsp;
                                </div>
                            <div className="chosenModules mt-3">
  {Object.entries(selectedModules)
    .filter(([moduleKey, moduleData]) => moduleData.checked)
    .map(([moduleKey, moduleData]) => (
      <div key={moduleKey} className="module-listing">
        <h6>{moduleKey.charAt(0).toUpperCase() + moduleKey.slice(1)} Module</h6>
        <ul className="submodule-list">
          {Object.entries(moduleData.subModules)
            .filter(([subModuleKey, isChecked]) => isChecked)
            .map(([subModuleKey]) => (
              <li key={subModuleKey}>
              {subModuleKey
                .replace(/_/g, ' &')
                .replace(/([A-Z])/g, ' $1')
                .trim()
                .replace(/^\w/, (c) => c.toUpperCase())}
            </li>
            
            ))}
        </ul>
      </div>
    ))}
</div>

                                <Form.Group className="row mt-3" controlId="exampleForm.ControlInput1">
                                    <Col md="12" className='datepickr'>
                                        <button className='commonButton expBtn' onClick={_SUBMIT_User_Add} >Save</button>&nbsp;&nbsp;
                                        <button className='commonButton addBtn' onClick={()=>navigate('/userlisting')}>Cancel</button>
                                    </Col>

                                </Form.Group>

                            </Form>
                        </div>




                    </div>
                </div>
            </Sidebar>

            <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Choose Modules</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>

              {/* Product Module */}
              <Form.Check
              type="checkbox"
              label="Product module"
              name="product"
              checked={selectedModules.product.checked}
              onChange={handleMainChange}
            />
{selectedModules.product.checked && (
  <div className="sub-modules">
    {Object.entries(selectedModules.product.subModules).map(([subModuleKey, isChecked]) => (
      <Form.Check
        key={subModuleKey}
        type="checkbox"
        label={subModuleKey.replace(/_/g, ' &').replace(/([A-Z])/g, ' $1').trim()} // Formatting label
        checked={isChecked}
        onChange={(e) => handleSubModuleChange('product', subModuleKey, e.target.checked)}
      />
    ))}
  </div>
)}


            {/* Sales Module */}
            <Form.Check
              type="checkbox"
              label="Sales module"
              name="sales"
              checked={selectedModules.sales.checked}
              onChange={handleMainChange}
            />
       {selectedModules.sales.checked && (
  <div className="sub-modules">
    {Object.entries(selectedModules.sales.subModules).map(([subModuleKey, isChecked]) => (
      <Form.Check
        key={subModuleKey}
        type="checkbox"
        label={subModuleKey.charAt(0).toUpperCase() + subModuleKey.slice(1)}
        checked={isChecked}
        onChange={(e) => handleSubModuleChange('sales', subModuleKey, e.target.checked)}
      />
    ))}
  </div>
)}

            {/* Purchase Module */}
            <Form.Check
              type="checkbox"
              label="Purchase module"
              name="purchase"
              checked={selectedModules.purchase.checked}
              onChange={handleMainChange}
            />
       {selectedModules.purchase.checked && (
  <div className="sub-modules">
    {Object.entries(selectedModules.purchase.subModules).map(([subModuleKey, isChecked]) => (
      <Form.Check
        key={subModuleKey}
        type="checkbox"
        label={subModuleKey.charAt(0).toUpperCase() + subModuleKey.slice(1)}
        checked={isChecked}
        onChange={(e) => handleSubModuleChange('purchase', subModuleKey, e.target.checked)}
      />
    ))}
  </div>
)}
            {/* Accounting Module */}
            <Form.Check
              type="checkbox"
              label="Accounting module"
              name="accounting"
              checked={selectedModules.accounting.checked}
              onChange={handleMainChange}
            />
           
   {selectedModules.accounting.checked && (
  <div className="sub-modules">
    {Object.entries(selectedModules.accounting.subModules).map(([subModuleKey, isChecked]) => (
      <Form.Check
        key={subModuleKey}
        type="checkbox"
        label={subModuleKey.charAt(0).toUpperCase() + subModuleKey.slice(1)}
        checked={isChecked}
        onChange={(e) => handleSubModuleChange('accounting', subModuleKey, e.target.checked)}
      />
    ))}
  </div>
)}
          
            {/* Report Module */}
            <Form.Check
              type="checkbox"
              label="Report module"
              name="report"
              checked={selectedModules.report.checked}
              onChange={handleMainChange}
            />
{selectedModules.report.checked && (
  <div className="sub-modules">
    {Object.entries(selectedModules.report.subModules).map(([subModuleKey, isChecked]) => (
      <Form.Check
        key={subModuleKey}
        type="checkbox"
        label={subModuleKey.charAt(0).toUpperCase() + subModuleKey.slice(1)}
        checked={isChecked}
        onChange={(e) => handleSubModuleChange('report', subModuleKey, e.target.checked)}
      />
    ))}
  </div>
)}
            {/* Professional Module */}
            {/* <Form.Check
              type="checkbox"
              label="Professional module"
              name="professional"
              checked={selectedModules.professional.checked}
              onChange={handleMainChange}
            /> */}
{/* {selectedModules.professional.checked && (
  <div className="sub-modules">
    {Object.entries(selectedModules.professional.subModules).map(([subModuleKey, isChecked]) => (
      <Form.Check
        key={subModuleKey}
        type="checkbox"
        label={subModuleKey.charAt(0).toUpperCase() + subModuleKey.slice(1)}
        checked={isChecked}
        onChange={(e) => handleSubModuleChange('professional', subModuleKey, e.target.checked)}
      />
    ))}
  </div>
)} */}
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <div className="footer-btn-center">
          <button className='commonButton expBtn' onClick={handleSubmit}>Submit</button>&nbsp;&nbsp;
          </div>
        </Modal.Footer>
      </Modal>



        </div>
    )
}


export default AddUser