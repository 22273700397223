import { React, useState,useEffect } from 'react'
import Sidebar from '../../layout/Sidebar'
import Dbheader from '../../layout/Dbheader'
import Form from 'react-bootstrap/Form';
import Headtxt from '../Headtxt';
import Col from 'react-bootstrap/Col';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Modal from 'react-bootstrap/Modal';
import Pagination from 'react-bootstrap/Pagination';
import {getQuoteData, getdeleteQuotation} from "../../../../Service/Quotation/QuotationService";
import { useNavigate,useLocation,useParams, json } from 'react-router-dom';
import moment from "moment";
import { PhoneInput } from 'react-international-phone';
import { City, Country, State } from "country-state-city";
import { addInvoice, addSalesOrder, getCustomerList, getEditsalesQuotation, getProductServiceList, getProductServiceListBYSupplierId, getsalesorderData, getsalesQuoteData, getSendInvoice, getSendSalesOrder } from "../../../../Service/Customer/CustomerService";
import { getAddquotation, getSendQuote,getEditQuotation } from "../../../../Service/Quotation/QuotationService";
import { ToastContainer, toast } from 'react-toastify';
import { getAddProductService, getInventoryAccounts, getUserAccountList } from '../../../../Service/ProductService/ProductService';
import {getEditSupplier,getSuppliereListById} from "../../../../Service/Suppliers/SuppliersService";
import getSymbolFromCurrency from 'currency-symbol-map'
import Swal from 'sweetalert2';
import Invoice from './Invoice';
import axios from 'axios';
import currencies from "../../../components/Common.currency.json";
import { getAddInventoryaccount } from '../../../../Service/Account/AccountService';
import { Dropdown, Row } from 'react-bootstrap';
const InvoiceSalesOrder = ({setIsSpinner}) => {
  const navigate = useNavigate();
    let countryData = Country.getAllCountries();
    const [inventory, setInventory] = useState("");
    let deliveryCountryData = Country.getAllCountries();
    const [deliveryAddress, setDeliveryAddress] = useState("");
    const [show, setShow] = useState(false);
    const [quoteid, setQuoteId] = useState();
    const [quotename, setQuoteName] = useState();
    const [quoteaddress, setQuoteAddress] = useState();
    const [paymentTerms, setPaymentTerms] = useState("");
    //const [notes, setNotes] = useState("");
    const [productdetails, setProductDetails] = useState([]);
    const [productServiceList, setAllProductServiceList] = useState([]);
    const [allProductServiceData, setAllProductServiceData] = useState([]);
    const [deleteId, setDeleteId] = useState([]);
    const [supplier2, setSupplier2] = useState(false);
    const [supply, setSupply] = useState(false);
    const [companyType, setCompanyType] = useState("");
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [companyName, setCompanyName] = useState("");
   // const [type, setType] = useState("");
    const [type, setType] = useState("");
    const [name, setName] = useState("");
    const [description, setDescription] = useState("");
    const [unit, setUnit] = useState("");
    const [salePrice, setSalePrice] = useState("");
    const [purchasePrice, setPurchasePrice] = useState("");
    const [purchaseTax, setPurchaseTax] = useState("");
    const [salesTax, setSalesTax] = useState("");
    const [orderQty, setOrderQty] = useState("");
    const [note, setNote] = useState("");
    const [productService2, setProductService2] = useState(false);
    const supplierShow2 = () => setSupplier2(true);
    const productServiceShow2 = () => setProductService2(true);
    const supplier2Close = () => setSupplier2(false);
    const productServiceClose = () => setProductService2(false);
    const [productServiceList2, setAllProductServiceList2] = useState([]);
    const [customerList, setAllCustomerList] = useState([]);
    const [companyFinal, setCompanyFinal] = useState("");
    const [showPdf, setShowPdf] = useState(false);
   const [checks, setChecks] = useState("false");
    const [views, setViews] = useState(false)
    const [emailQuote, setEmailQuote] = useState("")
    const [addressQuote, setAddressQuote] = useState("")
    const [dataPdf, setDataPdf] = useState("");
    const [imageUrls, setImageUrls] = useState('');
    const [image, setImage] = useState();
    const [olddoc, setOldDoc] = useState('');
    const handleClosePdf = () => setShowPdf(false);
    const [imageStateSet, setImageStateSet] = useState();
    const [discountQuantity, setDiscountQuantity] = useState(0);
    const [supplierId,setSupplierId]=useState();
    const [currency, setCurrency] = useState("CAD");
    const [code,setCode]=useState('')
    const [originalQuoteId,setOriginalQuoteId]= useState("");
    const [selectedCurrency, setSelectedCurrency] = useState('KES');
    const [currencyData, setCurrencyData] = useState(null);
    const [selectedCurrencyValue, setSelectedCurrencyValue] = useState(null);
    const [error, setError] = useState(null);
    const [inventoryOptions, setInventoryOptions] = useState([]);
    const [costOfGoodsSoldList, setCostOfGoodsSoldList] = useState([]);
    const [incomeAccList, setIncomeAccList] = useState([]);
    const [addaccount, setAddaccount] = useState(false);
    const [addincomeaccount, setAddIncomeaccount] = useState(false);
    const [addexpenseaccount, setAddExpenseaccount] = useState(false);
    const accountpop = () => setAddaccount(true);
    const addaccountClose = () => setAddaccount(false);
    const accountpop2 = () => setAddIncomeaccount(true);
    const addincomeaccountClose = () => setAddIncomeaccount(false);
    const accountpop3= () =>  setAddExpenseaccount(true);
    const addexpenaccountClose = () =>  setAddExpenseaccount(false);
    const [accountId, setAccountId] = useState("");
    const [accountName, setAccountName] = useState("");
    const [accountDate, setAccountDate] = useState("");
    const [accountdescription, setAccountDescription] = useState("");
    const [deliverystatus, setDeliveryStatus] = useState('Select Status');
    const [isDatePickerDisabled, setIsDatePickerDisabled] = useState(false); // Control datepicker enabled/disabled
    const [restrictedDates, setRestrictedDates] = useState();
    const [cashoption, setCashOption] = useState("No");
    const vclose = () => {
      setShowPdf(false);
      navigate('/invoice')
    }
    const handleTogglesCashOn = () => {
      // setToggled(true);
      setCashOption("No")
  };
  const handleTogglesCashOff = () => {
      // setToggled(false);

      setCashOption("Yes")
  };
    const handleDeliveryStatusChange = (status, index) => {
      const updatedData = [...allProductServiceData];
      updatedData[index].delivery_status = status;
      setAllProductServiceData(updatedData);
    };
    const onAccountName = (e) => {
      setAccountName(e.target.value)
    }
    const onAccountId = (e) => {
      setAccountId(e.target.value)
    }
    const onAccountDescription = (e) => {
      setAccountDescription(e.target.value)
    }
    const CustomDropdown = ({ placeholder, value, }) => {
      console.log('options==', value)
      return (
        <select value={value} className="form-control">
  
          <option key='1234' value={value}>{value}</option>
  
  
  
        </select>
      );
    };
    const onSave2 = (id) => {
      getAddInventoryaccount(id,accountName, accountId, accountdescription,
        (r) => {
          console.log(r, "response")
          // if (r.success === true) {
          //   toast.success(r.message)
          //   // setAddaccount(false)
          //   // getUserAccounts();
          //   // setSelectedSubtype('')
          //   addaccountClose()
          //   getInventoryDataList();
          // }
          if (r.success === true) {
            Swal.fire({
              title: r.message,
              //  text: 'The product has been added successfully!',
              icon: 'success',
              confirmButtonText: 'OK',
              customClass: {
                confirmButton: 'my-confirm-button'
              }
            }).then(() => {
              addaccountClose();
              addincomeaccountClose();
              addexpenaccountClose();
              getInventoryDataList();
              getUseraccountList()
            })
          }
          else {
            console.log("nononononono")
            toast.error(r.message)
            // setSelectedSubtype('')
          }
        })
  
    }
    const [selectedFiles, setSelectedFiles] = useState([]);

    const handelFileChange = (e)=>{
      e.preventDefault();
      // console.log(e.target.files,'ll');
      document.querySelector('input[type="file"]').click()
      let a = document.getElementById('fileData');
  
      console.log(a,'oo');
  
      //  add browsed files one by one
      const files = Array.from(e.target.files);
      setSelectedFiles(prevFiles => [...prevFiles, ...files]);
    }
  
    const handleRemoveFile = (fileToRemove) => {
      setSelectedFiles(prevFiles => prevFiles.filter(file => file !== fileToRemove));
    };
    const onCompanyName = (e) => {
        setCompanyName(e.target.value)
      }
    
      const onFirstName = (e) => {
        setFirstName(e.target.value)
      }
    
      const onType = (e) => {
        setType(e.target.value)
      }
    
      const onName = (e) => {
        setName(e.target.value)
      }
      const onID = (e) => {
        setCode(e.target.value)
      }
      const onDescription = (e) => {
        setDescription(e.target.value)
      }
    
      const onUnit = (e) => {
        setUnit(e.target.value)
      }
    
      const onSalesPrice = (e) => {
        setSalePrice(e.target.value)
      }
    
      const onPurchasePrice = (e) => {
        setPurchasePrice(e.target.value)
      }
    
      const onPurchaseTax = (e) => {
        setPurchaseTax(e.target.value)
      }
      const onSalesTax = (e) => {
        setSalesTax(e.target.value)
      }
      const onOrderQty = (e) => {
        setOrderQty(e.target.value)
      }
      const handleTogglesOn = () => {
        // setToggled(true);
        setInventory("No")
      };
      const handleTogglesOff = () => {
        // setToggled(false);
        setInventory("Yes")
      };
      const onAddressQuote = (e) => {
        setAddressQuote(e.target.value)
      }
    
      const onPaymentTerms = (e) => {
        const selectedTerms = e.target.value;
        setPaymentTerms(selectedTerms);
    
        // Enable or disable date picker based on payment terms
        if (['30 days', '60 days', '90 days', 'Due on receipt'].includes(selectedTerms)) {
          setIsDatePickerDisabled(true);  // Disable date picker for these terms
          if (startDate) {
            calculateDueDate(selectedTerms, startDate);
          }
        } else {
          setIsDatePickerDisabled(false); // Enable date picker for installation terms
         // setEndDate(null);  // Clear due date for manual selection
        }
      };
      const calculateDueDate = (terms, billDate) => {
        let newDueDate = null;
        switch (terms) {
          case '30 days':
            newDueDate = new Date(billDate);
            newDueDate.setDate(newDueDate.getDate() + 30);
            setRestrictedDates({
              minDate: new Date(billDate),
              maxDate: newDueDate
            });
            break;
          case '60 days':
            newDueDate = new Date(billDate);
            newDueDate.setDate(newDueDate.getDate() + 60);
            break;
          case '90 days':
            newDueDate = new Date(billDate);
            newDueDate.setDate(newDueDate.getDate() + 90);
            break;
          case 'Due on receipt':
            newDueDate = billDate;
            break;
          case '3 installation':
          case '2 installation':
            // Do not auto-calculate for installations
            newDueDate = endDate;
            break;
          default:
            break;
        }
      
        setEndDate(newDueDate);
        const toDate = moment(newDueDate).format('DD-MM-YYYY');
        console.log(toDate, "toDate");
        setFinalEndDate(toDate);
      };
    const handleShow = (e) =>{
        e.preventDefault()
        setShow(true);
    } 
    const handleClose = (e) =>{
   //   e.preventDefault();
      setShow(false);
    } 

    const [file, setFile] = useState(null);

    const handleDrop = (event) => {
        event.preventDefault();

        const droppedFile = event.dataTransfer.files[0];
        handleFile(droppedFile);
    };

    const handleFile = (selectedFile) => {
        setFile(selectedFile);

        // You can perform additional actions with the file, such as uploading it to a server.
        // For simplicity, we're just logging the file information here.
        console.log('File selected:', selectedFile);
    };
    const handleDragOver = (event) => {
        event.preventDefault();
    };
    useEffect(() => {
        getCustomerDataList()
        
    
      }, []);
    async function getallCustomerList(d) {

      getSuppliereListById(d).then(function (result) {
          const response = result.data;
          console.log(response, "response888888886")
          //setAllData(response?.data?.supplier_details)
          const combinedData = [...response?.data?.products, ...response?.data?.services];
          setAllProductServiceList(combinedData);
          // setCustomerId(response?.data);
  
          // setDepartmentId(response?.data?.businessUnit?.Departments);
      });
  }
  async function getCustomerDataList() {
    getCustomerList().then(function (result) {
        const response = result?.data;
        // console.log(response, "rrrr")
        setAllCustomerList(response?.data);
    });
}
    const onSearchByProSer = (e) => {
        if (e.target.value != "") {
          const filterCriteria = {
            supplierId: supplierId,
            searchText: e.target.value
        };
        console.log('filter---', filterCriteria);
        const queryString = new URLSearchParams(filterCriteria).toString();
    console.log(queryString, 'cre');
    getProductServiceList(queryString).then(function (result) {
            const response = result?.data;
            console.log(response, "rrryyyyyr")
    
            setAllProductServiceList(response?.data);
    
          }
          )
        } else {
          setAllProductServiceList(productServiceList2)
        }
      }
      const fetchCurrencyData = async () => {
        const UserCurrency = localStorage.getItem("1Simpleclick_Currency");
        setCurrencyData(UserCurrency)
        try {
          const apiId = 'onesimpleclick809194384'; // Replace with your actual API ID
          const apiKey = 'omumk49ld03teh0h681go0jmqp'; // Replace with your actual API Key
          const encodedCredentials = btoa(`${apiId}:${apiKey}`);
    
          const response = await axios.get('https://xecdapi.xe.com/v1/convert_from.json/', {
            params: {
              from: selectedCurrency ? selectedCurrency : "CAD", // base currency
              to: UserCurrency, // target currency
              amount: 1,
            },
            headers: {
              'Authorization': `Basic ${encodedCredentials}`
            }
          });
          setSelectedCurrencyValue(response.data.to[0].mid);
          console.log('UserCurrency', UserCurrency)
        } catch (error) {
          setError(error.message);
          console.error(error);
        }
      };
      useEffect(() => {
        if (selectedCurrency) {
          fetchCurrencyData();
          getSymbolFromCurrency('CAD')
        }
      }, [selectedCurrency]);
      const onInventoryChange = (e, index, rowData) => {

        const selectedInventoryId = e.target.value; // Get the selected inventory ID from the event
    
        // Update the inventory for the object at the given index
        const updatedData = [...allProductServiceData]; // Create a copy of the array
        updatedData[index] = { ...updatedData[index], inventory: selectedInventoryId }; // Update the inventory property for the object at the given index
        console.log('e.target.value', updatedData)
        // Call your function to update the state with the updated data
        setAllProductServiceData(updatedData);
      };
      const onIncome = (e, index, rowData) => {

        const selectedInventoryId = e.target.value; // Get the selected inventory ID from the event
    
        // Update the inventory for the object at the given index
        const updatedData = [...allProductServiceData]; // Create a copy of the array
        updatedData[index] = { ...updatedData[index], income: selectedInventoryId }; // Update the inventory property for the object at the given index
        console.log('e.target.value', updatedData)
        // Call your function to update the state with the updated data
        setAllProductServiceData(updatedData);
      };
      const onGoodsAccount = (e, index, rowData) => {
    
        const selectedInventoryId = e.target.value; // Get the selected inventory ID from the event
    
        // Update the inventory for the object at the given index
        const updatedData = [...allProductServiceData]; // Create a copy of the array
        updatedData[index] = { ...updatedData[index], goodsaccount: selectedInventoryId }; // Update the inventory property for the object at the given index
        console.log('e.target.value', updatedData)
        // Call your function to update the state with the updated data
        setAllProductServiceData(updatedData);
      };
    


    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const id = searchParams.get('id');
  
 
    const [allData,setAllData]=useState({});
    const getData = () => {
        getsalesorderData(id).then(function (result) {
            const response = result?.data?.data;
           console.log (result?.data?.data, "rrrr")
           setEndDate(new Date(result?.data?.data?.sales_order?.expiration_date))
           const fromDate = moment(result?.data?.data?.sales_order?.date).format('DD-MM-YYYY');
           setFinalStartDate(fromDate)
           const endDate = moment(result?.data?.data?.sales_order?.expiration_date).format('DD-MM-YYYY');
           setFinalEndDate(endDate)
           setCompanyFinal(result?.data?.data?.customer)
           setQuoteId(result?.data?.data?.sales_order?.order_id)
           setQuoteAddress(result?.data?.data?.customer?.billing_address?result?.data?.data?.customer?.billing_address:`${result?.data?.data?.customer?.country || ''}, ${result?.data?.data?.customer?.state || ''}, ${result?.data?.data?.customer?.city || ''}`)
           setStartDate(new Date(result?.data?.data?.sales_order.date))
           setSelectedDate(result?.data?.data?.sales_order.date)
           setEndDate(result?.data?.data?.sales_order?.expiration_date)
           setQuoteName(result?.data?.data?.customer?.type==='Company'?result?.data?.data?.customer?.company_name:`${result?.data?.data?.customer?.fname } ${result?.data?.data?.customer?.lname}`)
            setPaymentTerms(result?.data?.data?.sales_order?.payment_terms)
            setNote(result?.data?.data?.sales_order.notes)
            setCurrency(result?.data?.data?.customer?.currency)
            setOriginalQuoteId(result?.data?.data?.sales_order?.id);
            JSON.parse(result?.data?.data?.sales_order?.products_details).map((d,i)=>(setDiscountQuantity((prevFormData) => ({
              ...prevFormData,
              [i+1]: {
                ['id']: d?.id,
                ['qty']: d?.qty,
                ['discount']:d?.disc,
                ['price']:d?.price?d?.price:d?.sales_price,
                ['sales_tax']:d?.vat
              },
            }))))
             setAllProductServiceData(JSON.parse(result?.data?.data?.sales_order?.products_details).map(data=>({
              "id":data.id,
              "type": data?.type,
              "name": data.product_name,
              "description": data.description,
              "unit": data?.unit,
              "sales_price": data?.price?data?.price:data?.sales_price,
              "purchase_price":data?.price?data?.price:data?.sales_price,
             // "quantity":data.qty,
              "sales_tax": data.vat,
              "discount":data.disc,
              "disc":data.disc,
              'qty':data.qty,
              'price':data?.price?data?.price:data?.sales_price,
              "total":data.total,
              "track_inventory":data?.track_inventory,
              "quantity":data?.original_quantity,
            })))
            setAllData(response)
         
            setFile(result?.data?.data?.sales_order?.old_docs)
            setSelectedFiles(result?.data?.data?.sales_order?.old_docs)
            setSelectedCurrency(result?.data?.data?.customer?.currency)
setSupplierId(result?.data?.data?.sales_order?.customer_id)
        });
    }
    // const onQuantityDiscount = (event, keyValue,data) => {
    //   // const { name, value } = event.target;
    //   // setDiscountQuantity((prevFormData) => ({
    //   //     ...prevFormData,
    //   //     [keyValue]: {
    //   //         ...prevFormData[keyValue],
    //   //         [name]: value,
    //   //     },
    //   // }));

    //   const { name, value } = event.target;
    //   // console.log(name,"LLLLLLLnameLLLL")
    //   // console.log(value,"LLLLLvalueLLLLLL")
    //   if (discountQuantity) {
    //     setDiscountQuantity((prevFormData) => ({
    //       ...prevFormData,
    //       [keyValue]: {
    //         ...prevFormData[keyValue],
    //         [name]: value,
    //       },
    //     }));
    //     // console.log(prevFormData,"if previous data")
    //   } else {
    //     setDiscountQuantity((prevFormData) => ({
    //       ...prevFormData,
    //       [keyValue]: {
    //         // ...prevFormData[keyValue],
    //         [name]: value,
    //       },
    //     }));
    //     // console.log(prevFormData,"else previous data")
    //   }


    //   if(name==='quantity'){
    //     allProductServiceData[keyValue-1]={
    //       ...data,
    //       quantity:value,
    //       qty:value
    //     }
    //   }else{
    //     allProductServiceData[keyValue-1]={
    //       ...data,
    //       discount:value,
    //       disc:value
    //     }
    //   }
    //    setAllProductServiceData(allProductServiceData)

    // }

    const getTotalPrice1 = (price, tax, quantity, discount) => {
      if (quantity) {
           const totalPrice = Number(quantity) * price;
           const totalDiscount = (totalPrice * Number(discount)) / 100;
           const finalDiscount = isNaN(totalDiscount) ? 0 : totalDiscount;
           const totalFinalPrice = (totalPrice - finalDiscount);
           const totalTax = (totalFinalPrice * Number(tax)) / 100;
           const totalFinalTaxPrice = (totalFinalPrice + totalTax);
       console.log('totalFinalTaxPrice',totalFinalTaxPrice,totalTax,totalFinalPrice,tax)
           return totalFinalTaxPrice;
         }
         return 0;
       };
       const onQuantityDiscount = (event, keyValue, data) => {
        const { name, value } = event.target;
      
        // Update discountQuantity state
        setDiscountQuantity((prevFormData) => ({
          ...prevFormData,
          [keyValue]: {
            ...prevFormData[keyValue],
            [name]: value,
          },
        }));
      
        // Update allProductServiceData state
        const updatedProductServiceData = [...allProductServiceData];
        updatedProductServiceData[keyValue - 1] = {
          ...data,
          [name]: value,
          [name === 'qty' ? 'qty' : name === 'discount' ? 'disc' : name === 'price' ? 'sales_price' : 'sales_tax']: value,
        };
      
        // Calculate updated total price
        const qty = name === 'qty' ? value : discountQuantity[keyValue]?.qty || data.qty || 0;
        const discount = name === 'discount' ? value : discountQuantity[keyValue]?.discount || data.discount || 0;
        const price = name === 'price' ? value : discountQuantity[keyValue]?.price || data.price ||data.sales_price || 0;
        const tax = name === 'sales_tax' ? value : discountQuantity[keyValue]?.sales_tax || data.sales_tax || 0;
      
        const totalPrice = getTotalPrice1(
          price,
          tax,
          qty,
          discount
        );
      
        updatedProductServiceData[keyValue - 1].total = totalPrice;
        setAllProductServiceData(updatedProductServiceData);
      
        console.log(totalPrice, "Updated Total Price");
      };
     

    useEffect(() => {
      subTotal();
      subDiscount();
      subVat();
      subTotalValue();
    }, [discountQuantity]);
   console.log(productdetails)
    useEffect(() => {
        console.log('llll', id)
        getData();
      getProductServiceDataList();
     }, []);
     async function getProductServiceDataList() {
        getProductServiceList("").then(function (result) {
          const response = result?.data;
          // console.log(response, "rrrr")
          //setAllProductServiceList3(response?.data);
          const filteredData = response?.data.map(item => ({...item,qty :0}));
          setAllProductServiceList(filteredData);
         setAllProductServiceList2(response?.data);
     
        });
      }
     
      const toggleCompany = (e) => {
        setCompanyType("Company")
        e.preventDefault();
        setSupply(false)
      }
      const toggleIndividual = (e) => {
        setCompanyType("Individual")
    
        e.preventDefault();
        setSupply(true)
      }
      // const onCheck = (data) => {
      //   console.log('data====',data)
      //   if (allProductServiceData.includes(data)) {

      //     const filterData = allProductServiceData.filter(d => d !== data);
      //     setAllProductServiceData(filterData, () => {
      //       console.log(allProductServiceData, "allProductServiceData");
          
      //     });
      //     toast.success('Product added')
      //   } else {
      //     const updatedData = [...allProductServiceData, data];
      //     setAllProductServiceData(updatedData, () => {
      //       console.log(allProductServiceData, "allProductServiceData");
      //     });
      //     toast.success('Item added successfully')
      //   }
      // };


      const onCheck = (data) => {
        if (allProductServiceData.includes(data)) {
          const filterData = allProductServiceData.filter(d => d !== data);
          setAllProductServiceData(filterData, () => {
            console.log(allProductServiceData, "allProductServiceData");
          });
        } else {
          const updatedData = [...allProductServiceData, data];
          console.log(updatedData, "updatedData");
          setAllProductServiceData(updatedData);
      
          setDiscountQuantity((prevFormData) => ({
            ...prevFormData,
            [allProductServiceData.length + 1]: {
              'id':data.id,
              'qty': 0,
              'discount': 0,
              'price':0,
              'tax': data.sales_tax || 0 // Dynamically set the vat from the data object
            },
          }));
        }
      };
      useEffect(()=>{
        subTotal();
        subDiscount();
        subVat();
        subTotalValue();
      },[allData])

      const subTotal = () => {
        let totalPrice = 0;
      
        if (discountQuantity) {
          allProductServiceData?.forEach((d, i) => {
            const quantity = Number(discountQuantity[`${i + 1}`]?.qty || 0);
            const price = Number(d?.type === "service" ? d?.price : d?.sales_price);
            totalPrice += quantity * price;
          });
        }
      
        setSubTotal1(totalPrice);
        return totalPrice;
      };
      const subDiscount = () => {
        let totalDiscount = 0;
        // Use allProductServiceData to calculate total discount
        if (allProductServiceData) {
            allProductServiceData.forEach((d, i) => {
                if (discountQuantity[`${i + 1}`]?.discount) {
                    const totalPrice = Number(discountQuantity[`${i + 1}`].qty) * Number(d?.type === "service" ? d?.sales_price : d?.sales_price);
                    totalDiscount += (totalPrice * Number(discountQuantity[`${i + 1}`].discount)) / 100;
                }
            });
        }
        setSubDiscount1(totalDiscount)
        return totalDiscount;
    }
    const subVat = () => {
      let totalTax = 0;
  
      if (discountQuantity) {
        allProductServiceData?.map((d, i) => {
          if (discountQuantity[`${i + 1}`]) {
  
            if (discountQuantity[`${i + 1}`].qty) {
              let totalPrice = 0;
              totalPrice += (Number(discountQuantity[`${i + 1}`].qty) * Number(d?.type === "service" ? d?.price : d?.sales_price));
              const discountAmount = (totalPrice * Number(discountQuantity[`${i + 1}`].discount)) / 100;
              const discountedPrice = totalPrice - discountAmount;
              totalTax += (discountedPrice * Number(d?.sales_tax)) / 100;
            }
          }
        })
      }
      setSubVat1(totalTax)
      return totalTax;
    }
      const getTotalPrice = (price, index, tax) => {
        let totalprice= 0
         if (discountQuantity && discountQuantity[index]?.qty) {
             
           const totalPrice = Number(discountQuantity[index]?.qty) * price;
           const totalDiscount = (totalPrice * Number(discountQuantity[index].discount)) / 100;
           const finalDiscount = isNaN(totalDiscount) ? 0 : totalDiscount;
           const totalFinalprice = (totalPrice - finalDiscount);
           const totalTax = (totalPrice * Number(tax)) / 100;
           const totalFinalTaxPrice = (totalPrice + totalTax) - finalDiscount;
     
           // setDiscountQuantity((prevFormData) => ({
           //   ...prevFormData,
           //   [index]: {
           //     // ...prevFormData[index],
           //     ["totalDiscount"]: totalDiscount,
           //     ["totalTax"]: totalTax,
           //     ["price"]: price,
           //     ["totalFinalTaxPrice"]: totalFinalTaxPrice,
           //   },
           // }));
           
           return totalFinalTaxPrice;
         }
         return 0;
     
       }
      const subTotalValue = () => {
        let totalTax = 0;
        let totalPrice = 0;
        let totalDiscount = 0;
        let totalFinalTaxPrice = subTotal() - subDiscount() + subVat();
        let finalDiscount = 0;
    
        // if (discountQuantity) {
        //   allProductServiceData?.map((d, i) => {
        //     if (discountQuantity[`${i + 1}`]) {
    
    
        //       totalPrice += (Number(discountQuantity[`${i + 1}`].quantity) * Number(d?.type === "service" ? d?.price : d?.purchase_price));
        //       totalDiscount += (totalPrice * Number(discountQuantity[`${i + 1}`].discount)) / 100;
        //       finalDiscount = isNaN(totalDiscount) ? 0 : totalDiscount;
        //       // const totalFinalprice = (totalPrice - finalDiscount);
        //       totalTax += (totalPrice * Number(d?.tax)) / 100;
        //       totalFinalTaxPrice += (totalPrice + totalTax) - finalDiscount;
    
        //     }
        //   })
        // }
        setSubTotalValue1(totalFinalTaxPrice)
        return totalFinalTaxPrice;
      } 
      const [qtyIndex,setQtyIndex]=useState([]);
      const onCheckDelete = (id,indexValue) => {
        console.log(indexValue,"indexValue")
        setDeleteId((prevDeleteId) => {
          if (prevDeleteId.includes(id)) {
            let tempQtyIndex = qtyIndex.filter(item=>item!==indexValue)
            setQtyIndex(tempQtyIndex)
            // Remove the id from the array
            return prevDeleteId.filter((d) => d !== id);
          } else {
            setQtyIndex([...qtyIndex,indexValue]);
            // Add the id to the array
            return [...prevDeleteId, id];
          }
        }, () => {
          console.log("hihihihihihihi", deleteId);
        });
      };

      const [subTotal1,setSubTotal1]=useState(0);
      const [subSubDiscount1,setSubDiscount1]=useState(0);
      const [subSubVat1,setSubVat1]=useState(0);
      const [subSubTotalValue1,setSubTotalValue1]=useState(0);
  
      const handelDelete = async (e) => {
        e.preventDefault()
        let idSet = new Set(deleteId);
        idSet = Array.from(idSet)
        const filteredArray = allProductServiceData.filter(item => !idSet.includes(item.id));
        
        setAllProductServiceData([...filteredArray]);
        setDeleteId([]);
        qtyIndex.forEach(key => {
          if (discountQuantity.hasOwnProperty(key)) {
          delete discountQuantity[key];
          
          }
          const arr = Object.keys(discountQuantity).sort((a, b) => a - b).map(key => discountQuantity[key]);
      
          const obj = {};
           arr.forEach((value, index) => {
           obj[index + 1] = value;
          });
          setDiscountQuantity(obj)
        });
       
        setQtyIndex([]);
        subTotal();
        subDiscount();
        subVat();
        subTotalValue();
      }
      const handleSingleDelete = (id) => {
        const filteredArray = allProductServiceData.filter(item => item.id !== id);
        const updatedQtyIndex = qtyIndex.filter(index => index !== id);
        let updatedDiscountQuantity = { ...discountQuantity };
        const isIdPresent = allProductServiceData.some(item => item.id === id);
        if (isIdPresent) {
          // delete updatedDiscountQuantity[id];
          for (const key in updatedDiscountQuantity) {
            if (updatedDiscountQuantity[key].id === id) {
              delete updatedDiscountQuantity[key];
              break;  // Exit loop after finding and deleting the element
            }
          }
          const arr = Object.keys(updatedDiscountQuantity).sort((a, b) => a - b).map(key => updatedDiscountQuantity[key]);
          const obj = {};
          arr.forEach((value, index) => {
            obj[index + 1] = value;
          });
          updatedDiscountQuantity = obj;
        }
      
        setAllProductServiceData([...filteredArray]);
        setQtyIndex(updatedQtyIndex);
      
        setDiscountQuantity(updatedDiscountQuantity);
      };

      const url = imageUrls[0];
      if (typeof url === 'string') {
          let parts = url.split('.');
          let file_extension = parts[parts.length - 1];
          // Check if the state is already set to avoid unnecessary re-renders
          if (!imageStateSet) { // Assuming imageStateSet is a state variable indicating whether the image state has been set
              setImage(file_extension);
              setImageStateSet(true); // Update the state indicating that the image state has been set
          }
      } else {
          console.log('The provided URL is not a string.');
      }
      console.log('image====',selectedFiles?.length)
    const [selectedDate, setSelectedDate] = useState(null);

    const handleDateChange = (date) => {
        setSelectedDate(date);
    };

    const [selectedDateb, setSelectedDateb] = useState(null);

    const handleDateChangeb = (date) => {
        setSelectedDateb(date);
    };

    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
  
    const [finalStartDate, setFinalStartDate] = useState("");
    const [finalEndDate, setFinalEndDate] = useState("");
  
    const toDate = moment(endDate);
    const formattedToDate = toDate.format('YYYY-MM-DD');
  
  
    const onFromDate = (e) => {
      setStartDate(e)
      console.log(e, "eeeeeeeeeeeeeee")
      const fromDate = moment(e).format('DD-MM-YYYY');
      console.log(fromDate, "fromDate");
      setFinalStartDate(fromDate)
      if (paymentTerms) {
        calculateDueDate(paymentTerms, e);
      }
    }
    const onToDate = (e) => {
      setEndDate(e)
      console.log(e, "eeeeeeeeeeeeeee")
      const toDate = moment(e).format('DD-MM-YYYY');
      console.log(toDate, "toDate")
      setFinalEndDate(toDate)
    }
    const getTotalPrice2 = (price, index,) => {
        if (discountQuantity && discountQuantity[index]?.qty) {
          const totalPrice = Number(discountQuantity[index]?.qty) * price;
          const totalDiscount = (totalPrice * Number(discountQuantity[index].discount)) / 100;
          const finalDiscount = isNaN(totalDiscount) ? 0 : totalDiscount;
          const totalFinalprice = (totalPrice - finalDiscount);
          //  const totalTax = (totalFinalprice * Number(tax)) / 100;
          // const totalFinalTaxPrice = selectedCurrency !== undefined ? totalFinalprice + totalTax :selectedCurrency*(totalFinalprice + totalTax);
    
          const totalFinalTaxPrice = totalFinalprice
          // setDiscountQuantity((prevFormData) => ({
          //   ...prevFormData,
          //   [index]: {
          //     // ...prevFormData[index],
          //     ["totalDiscount"]: totalDiscount,
          //     ["totalTax"]: totalTax,
          //     ["price"]: price,
          //     ["totalFinalTaxPrice"]: totalFinalTaxPrice,
          //   },
          // }));
          console.log('totalFinalTaxPrice', totalFinalTaxPrice)
          return totalFinalTaxPrice;
        }
        return 0;
    
      }
      console.log('allProductServiceData', allProductServiceData)
    const onDone = () => {
     
      const hasUnselectedInventory = allProductServiceData.some(d => d.type !== "service" && !d.inventory);
      if (hasUnselectedInventory) {
        toast.error("Please select GL Codes for all products.");
        return; // Exit the function if validation fails
      }
        const hasUnselectedIncome = allProductServiceData.some(d => !d.income);
        if (hasUnselectedIncome) {
          toast.error(" Please select Income Account for all products/service.");
          return; // Exit the function if validation fails
        }
        const hasUnselectedGoodAccount = allProductServiceData.some(d => d.type !== "service" && !d.goodsaccount);
    if (hasUnselectedGoodAccount) {
      toast.error("Please select Cost of Goods Account for all products.");
      return; // Exit the function if validation fails
    }
    const hasInvalidPrice = allProductServiceData.some(d => {
      const price = d?.type === "service" ? d?.price : d?.sales_price;
      return price === 0 || price === "0" || price === "" || price === undefined;
    });
    
    if (hasInvalidPrice) {
      toast.error("Product price cannot be zero,or empty.");
      return; // Exit the function if validation fails
    }
    const hasInvalidDeliveryStatus = allProductServiceData.some(d => !d.delivery_status || d.delivery_status === '' || d.delivery_status === 'Select Status');
    if (hasInvalidDeliveryStatus) {
      toast.error("Please select delivery status for all products.");
      return; 
    }
    const currentDate = moment(startDate);
    const dueDate = moment(endDate);
  
    let expectedDifference;
    switch (paymentTerms) {
      case '30 days':
        expectedDifference = 30;
        break;
      case '60 days':
        expectedDifference = 60;
        break;
      case '90 days':
        expectedDifference = 90;
        break;
      case 'Due on receipt':
        expectedDifference = 0; // Due on receipt means no difference
        break;
      default:
        expectedDifference = null;
    }
  
    if (expectedDifference !== null && dueDate.diff(currentDate, 'days') !== expectedDifference) {
      toast.error(`The due date does not match the selected payment terms of ${paymentTerms}.`);
      return; // Exit if validation fails
    }
        let products_details = [];
        allProductServiceData.forEach((d, i) => {
          products_details.push({
            "product_name": d?.name,
            "qty": Number(discountQuantity[`${i + 1}`]?.qty),
            "price": d?.type === "service" ? d?.price : d?.sales_price,
            "disc": Number(discountQuantity[`${i + 1}`]?.discount ? discountQuantity[`${i + 1}`]?.discount : 0),
            "total": d?.total,
            "vat": d?.sales_tax,
            "description": d?.description,
            "id": d?.id,
            "type": d?.type,
            "unit": d.unit,
            "inventory": parseInt(d.inventory),
            //"refund": d?.type === "service" ? "true" : "false",
            "refund": "false",
            'userCurrency_productTotal': (getTotalPrice2((d?.type === "service" ? d?.price : d?.sales_price), i + 1,) * selectedCurrencyValue).toFixed(2),
            "track_inventory":d?.track_inventory,
            "original_quantity":d?.quantity,
            "income_acc": parseInt(d.income),
        "costofGoodsSold_acc":  parseInt(d.goodsaccount),
        "delivery_status":deliverystatus,
        "userCurrency_unitPrice":selectedCurrencyValue *d?.type === "service" ? d?.price : d?.sales_price
          });
        });
    
        let formData = new FormData();
    
        //formData.append('quote_docs', file);
        formData.append('customer_id', companyFinal?.type === "Company" ? companyFinal?.id : companyFinal.id);
        formData.append('order_id', originalQuoteId);
        formData.append('invoice_date', finalStartDate);
        formData.append('expiration_date', finalEndDate);
        formData.append('address', companyFinal?.billing_address);
        formData.append('payment_terms', paymentTerms);
        formData.append('products_details', JSON.stringify(products_details));
        formData.append('sub_total', (selectedCurrencyValue * subTotal()).toFixed(2));
        formData.append('discount_amount', (selectedCurrencyValue * subDiscount()).toFixed(2));
        formData.append('vat', (selectedCurrencyValue * subVat()).toFixed(2));
        formData.append('total', (selectedCurrencyValue * subTotalValue()).toFixed(2));
        formData.append('customerCurrency_subTotal', (subTotal()).toFixed(2));
        formData.append('customerCurrency_discountAmount', (subDiscount()).toFixed(2));
        formData.append('customerCurrency_vat', (subVat()).toFixed(2));
        formData.append('customerCurrency_totalAmount', (subTotalValue()).toFixed(2));
        formData.append('customer_currency', getSymbolFromCurrency(selectedCurrency));
        formData.append('user_currency', getSymbolFromCurrency(currencyData));
        formData.append('currency_exchangeRate', selectedCurrencyValue);
        formData.append('notes', note ? note : "");
        formData.append('cash', cashoption);
        // formData.append('invoice_docs[]', file);
        selectedFiles.filter(item=>!file.includes(item)).forEach((f,index) => {
          formData.append(`invoice_docs[${index}]`, f);
        })
        formData.append('old_docs', JSON.stringify(file));
        // if (companyFinal === "") {
        //   toast.error("Please select a supplier");
        //   // Re-enable the button if validation fails
        // }
        if (finalStartDate === "") {
          toast.error("Please select start date");
          // Re-enable the button if validation fails
        } else if (finalEndDate === "") {
          toast.error("Please select end date");
          // Re-enable the button if validation fails
        } else if (paymentTerms === "") {
          toast.error("Please select a payment terms");
          // Re-enable the button if validation fails
        } else if (products_details.length <= 0) {
          toast.error("Please add Products");
          // Re-enable the button if validation fails
        } else if (Object.values(discountQuantity).some(item => !Number(item.qty) || Number(item.qty) < 0)) {
          toast.error("Please add quantity");
          // Re-enable the button if validation fails
        } 
        else if (products_details.some(product => product.track_inventory==="Yes" && product.qty > product.original_quantity)) {
          toast.error("Product quantity cannot be greater than available stock");
        }
        else {
        addInvoice(formData, (r) => {
            // if (r.success === true) {
            //     toast.success(r.message);
            //     setDataPdf(r);
            //     setShowPdf(true);
            // }
            if (r.success === true) {
              Swal.fire({
                title: r.message,
                //  text: 'The product has been added successfully!',
                icon: 'success',
                confirmButtonText: 'OK',
                customClass: {
                  confirmButton: 'my-confirm-button'
                }
              }).then(() => {
                setDataPdf(r);
                setShowPdf(true);
              })
            }
    
            else {
              toast.error(r.message);
              
            }
          }).catch((error) => {
            toast.error("An error occurred. Please try again.");
           
          });
        }
      };
    
      const deleteImage = () => {
        // Add your logic here to delete the image
        // For example, you can reset the image URL to an empty string
        setImageUrls([]);
        setFile(null)
        setOldDoc([])
      };
      const onSendPdf = () => {
        setIsSpinner(true);
        getSendInvoice(dataPdf?.data?.details?.id, dataPdf?.data?.customerEmail, `Customer Invoice - ${dataPdf?.data?.details?.invoice_id}`,
          addressQuote,checks,
          (r) => {
            console.log(r, "response")
    //         if (r.success === true) {
    //           toast.success(r.message)
    //           navigate('/quotelisting')
    // }
    if (r.success === true) {
      setIsSpinner(false);
      Swal.fire({
       title: r.message,
     //  text: 'The product has been added successfully!',
       icon: 'success',
       confirmButtonText: 'OK',
       customClass: {
           confirmButton: 'my-confirm-button'
       }
   }).then(() => {
    navigate('/invoice')
   })
 }
    else {
      setIsSpinner(false);
              console.log("nononononono")
              toast.error(r.message)
    
            }
          })
      }
    
      const checkMe = (e) => {
        setChecks(e.target.checked);
        // console.log(e.target.checked, "Yesssssssssss");
        console.log(e.target.checked? "Yesssssssssss" : "Noooooo");
      }
      
    
      const toggleHandlerb = (e) => {
        e.preventDefault();
        setViews(true)
      }
      const toggleHandlerc = (e) => {
        e.preventDefault();
        setViews(false)
      }
      // const onButtonClick = () => {
      //   const pdfUrl = dataPdf?.data?.pdf;
      //   const link = document.createElement("a");
      //   link.href = pdfUrl;
      //   link.download = "document.pdf"; // specify the filename
      //   document.body.appendChild(link);
      //   link.click();
      //   document.body.removeChild(link);
      // };
      const onButtonClick = () => {
        const pdfUrl = dataPdf?.data?.pdf;
        if (pdfUrl) {
          window.open(pdfUrl, '_blank'); // Open PDF in a new tab
        } else {
          // Handle case when PDF URL is not available
          console.error('PDF URL is not available');
        }
      };

      const onSaveservice = () => {
        if (type === "") {
          toast.error("Please select a type")
    
        }
        else if (name === "") {
          toast.error("Please type name")
        } 
        else if (code === "") {
          toast.error("Please type code")
        } 
        else if (unit === "") {
            toast.error("Please select a unit")
          }
        //  else if (salePrice === "") {
        //   toast.error("Please type Sales Price(Optional)")
        // } else if (salePrice < 0) {
        //   toast.error("Please type valid Sales Price(Optional)")
        // } else if (purchaseTax < 0) {
        //   toast.error("Please type valid purchase tax")
        // }
        // else if (type === "Product" && purchasePrice === "") {
        //   toast.error("Please type purchase price")
        // } else if (type === "Product" && purchasePrice < 0) {
        //   toast.error("Please type valid purchase price")
        // }
        // else if (type === "Product" && orderQty === "") {
        //   toast.error("Please type order quantity")
        // } else if (type === "Product" && orderQty < 0) {
        //   toast.error("Please type valid order quantity")
        // }
        else if (type === "Product" && inventory === "") {
          toast.error("Please select track inventory")
        } 
        else if (supplierId === "" || supplierId === null ||supplierId=== undefined) {
          toast.error("Please select a supplier")
        } 
        else {
    
          getAddProductService(type, name, description, unit, salePrice,
            purchaseTax, purchasePrice, orderQty,salesTax,inventory,"",code,
            (r) => {
              console.log(r, "response")
              // if (r.success === true) {
              //   toast.success(r.message)
              //   // navigate("/productservicelisting")
              //   setProductService2(false);
              //   getProductServiceDataList();
              //   getallCustomerList(supplierId);
              // } 
              if (r.success === true) {
                Swal.fire({
                 title: r.message,
               //  text: 'The product has been added successfully!',
                 icon: 'success',
                 confirmButtonText: 'OK',
                 customClass: {
                     confirmButton: 'my-confirm-button'
                 }
             }).then(() => {
              setProductService2(false);
              getProductServiceDataList();
           //   getallCustomerList(supplierId);
             })
           }
              else {
                console.log("nononononono")
                toast.error(r.message)
    
              }
            })
        }
    
      }
      async function getInventoryDataList() {
        getInventoryAccounts("").then(function (result) {
          const response = result?.data;
          console.log(response, "rrrr")
          if (response?.message === 'No account found') { setInventoryOptions([]) }
          else {
    
            setInventoryOptions(response?.data?.inventoryAccounts);
          }
          // setAllProductServiceList2(response?.data);
          // setAllProductServiceList(response?.data);
        });
      }
      async function getUseraccountList() {
        getUserAccountList("").then(function (result) {
          const response = result?.data;
          console.log(response, "rrrr")
          if (response?.message === 'No account found') { setCostOfGoodsSoldList([]);setIncomeAccList([]); }
          else {
    
            setCostOfGoodsSoldList(response?.data?.costOfGoodsSoldList);
            setIncomeAccList(response?.data?.incomeAccList);
          }
          // setAllProductServiceList2(response?.data);
          // setAllProductServiceList(response?.data);
        });
      }
      useEffect(() => {
        getInventoryDataList();
        getUseraccountList();
      }, []);
      const _handle_file_change = (e)=>{
        e.preventDefault()
        document.querySelector('input[type="file"]').click()
      }
    
    return (
        <div className="producservlisting pdt">
            <Dbheader />
            <Sidebar>
                <div className='customerMain'>
                    <div className='productServList'>
                        <div className='dbTopsec pb-4 pt-2'>
                            <Headtxt link="/invoice" title="Add Invoice" />

                            {/* <div className='custButton'>
                                <button className='commonButton addBtn'><Link to="/addproductservice"><i class="fa-regular fa-circle-plus"></i> Add Product</Link></button>&nbsp;&nbsp;
                                <button className='commonButton expBtn'><i class="fa-light fa-download"></i> Export Excel</button>
                            </div> */}
                        </div>

                        <div className='commonWhite addCustomerForm p-3 mb-3'>
                            <Form>
                            <Form.Group className="row mb-3" controlId="exampleForm.ControlInput1">
                                    {/* <Col md="6">
                                        <Form.Control type="text" value={quoteid} />
                                    </Col> */}
                                    <Col md="6">
                                        <Form.Control type="text" value={quotename} />
                                    </Col>
                                    <Col md="3" className='datepickr'>
                                    <DatePicker
                      selectsStart
                      selected={startDate}
                      onChange={onFromDate}
                      placeholderText="From"
                    // dateFormat="yyyy-MM-dd"
                    />
                                    </Col>
                                    <Col md="3" className='datepickr mb-2'>
                  <DatePicker
                    // selectsEnd
                    selected={endDate?moment(endDate).toDate():null}
                    onChange={onToDate}
                    endDate={endDate}
                    // endDate={endDate}
                    startDate={startDate}
                    minDate={startDate}
                    placeholderText="Expiration Date"
                    disabledDates={(date) => {
                      const { minDate, maxDate } = restrictedDates;
                      return date >= minDate && date <= maxDate;
                    }}
                  />
                  {/* <DatePicker
                    selected={selectedDateb}
                    onChange={handleDateChangeb}
                    dateFormat="dd/MM/yyyy"
                    isClearable
                    placeholderText="Expiration a date"
                  /> */}
                </Col> 
                            </Form.Group>
                            <Form.Group className="row mb-3" controlId="exampleForm.ControlInput1">
                                    <Col md="4">
                                        <Form.Control type="text" placeholder="Address" value={quoteaddress} />
                                    </Col>
                                    <Col md="4">
                                        {/* <Form.Select aria-label="Default select example">
                                            <option selected hidden>Select days</option>
                                            <option value="Company"><button>+</button> Company</option>
                                            <option value="Individual">Individual</option>

                                        </Form.Select> */}
                                          <Form.Select aria-label="Default select example"onChange={onPaymentTerms} value={paymentTerms}>
                       <option selected hidden>Select Payment Terms </option>
                      <option value="30 days">30 days</option>
                      <option value="60 days">60 days</option>
                      <option value="90 days">90 days</option>
                      <option value="Due on receipt">Due on receipt</option>
                      <option value="3 installation">3 installation</option>
                      <option value="2 installation">2 installation</option>

                    </Form.Select>
                                    </Col>
                                    <Col md="4" className='mb-3'>
                    <div id="selectors">
                      <select value={selectedCurrency} disabled>
                        {Object.keys(currencies).map((currency, index) => (
                          <option value={currency} key={index}>
                            {currency} - {currencies[currency].name}
                          </option>
                        ))}
                      </select>
                    </div>
                  </Col>
                </Form.Group>
                
{selectedCurrencyValue !== undefined ? (
  <Row>
    <Col md="6">
      <p>Exchange Rate</p>
      <input id="c474_exchange_rate" style={{ marginBottom: '15px' }} name="exchange_rate" readOnly class="input-medium" type="text" tabindex="7" value={`${selectedCurrencyValue}`}>
                    </input>
                    <p>{getSymbolFromCurrency(selectedCurrency)} 1 {selectedCurrency} = {getSymbolFromCurrency(currencyData)}  {selectedCurrencyValue}  {currencyData}</p>
    </Col>
    <Col md="6" className="radioline">
  <span>Cash Option</span>
  <div>
    <input 
      type="radio" 
      id="test1" 
      name="radio-group" 
      onClick={handleTogglesCashOff}
      checked={cashoption === "Yes"}  
    />
    <label htmlFor="test1">Yes</label>
  </div>
  <div>
    <input 
      type="radio" 
      id="test2" 
      name="radio-group" 
      onClick={handleTogglesCashOn} 
      checked={cashoption === "No"}
    />
    <label htmlFor="test2">No</label>
  </div>
</Col>
  </Row>
) : null}

 <div className='salesOrdTable'>
                                    <div className='listViews'>
                                        <table class="table">
                                            <thead>
                                                <tr>
                                                    
                                                    <th scope="col">Product/Service name</th>
                                                    <th scope="col">GL Codes</th>
                                                    <th scope="col">Income Account</th>
                                                    <th scope="col">Cost of Goods Account</th>
                                                    <th scope="col">Available Qty</th>
                                                    <th scope="col">Qty</th>
                                                    {/* <th scope="col">Unit</th> */}
                                                    <th scope="col">Price</th>
                                                    <th scope="col">Disc.%</th>
                                                    <th scope="col">Total</th>
                                                    <th scope="col">VAT%</th>
                                                    <th scope="col">Delivery Status</th>
                                                    <th>&nbsp;</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                            {allProductServiceData.length != 0 && 
                                            allProductServiceData?.map((item, i) => (

                                                <tr >
                                                    {/* <td><input type='checkbox' checked={deleteId.includes(item?.id)} onChange={() => onCheckDelete(item?.id,i+1)} /></td> */}
                                                    <td><span className='lightBlock'>{item.name}</span></td>
                                                    <td>
                              <select className='lightBlock4' disabled={item?.type==='service'} onChange={(e) => { e.target.value==="Add Inventory" ? accountpop() : onInventoryChange(e, i, item) }}>
                                <option value="">Select GL Codes</option>
                                {/* Populate options dynamically based on your inventory data */}
                                
                                  <option value={"Add Inventory"} onChange={() => console.log('oooo')}><i class="fa-solid fa-plus"></i>+ Add an Inventory Account</option>
                                {
                                  inventoryOptions.map((inventoryOption, index) => (
                                    <option key={index} value={inventoryOption.id}>{inventoryOption.account_name}</option>
                                  ))}
                              </select>
                            </td> 
                            <td>
                              <select className='lightBlock4' onChange={(e) => { e.target.value==="Add Income" ? accountpop2() : onIncome(e, i, item) }}>
                                <option value="">Select Income Account</option>
                                {/* Populate options dynamically based on your inventory data */}
                          
                                  <option value={"Add Income"} onChange={() => console.log('oooo')}><i class="fa-solid fa-plus"></i>+ Add an Income Account</option>
                                 
                                  {
                                  incomeAccList?.map((inventoryOption, index) => (
                                    <option key={index} value={inventoryOption.account_id}>{inventoryOption.account_name}</option>
                                  ))}
                              </select>
                            </td>
                            <td>
                              <select className='lightBlock4' disabled={item?.type==='service'} onChange={(e) => { e.target.value==="Add Cost" ? accountpop3() : onGoodsAccount(e, i, item) }}>
                                <option value="">Select Cost of Goods Account</option>
                                {/* Populate options dynamically based on your inventory data */}
                              
                                  <option value={"Add Cost"} onChange={() => console.log('oooo')}><i class="fa-solid fa-plus"></i>+ Add an Inventory Account</option>
                                  {
                                  costOfGoodsSoldList?.map((inventoryOption, index) => (
                                    <option key={index} value={inventoryOption.account_id}>{inventoryOption.account_name}</option>
                                  ))}
                              </select>
                            </td>
                             <td><input type='text' className='lightBlock3' defaultValue={item?.quantity} readOnly /></td>
                                                    <td>
                                                  
                          {item?.qty ? <input className='lightBlock3' onChange={(e) => onQuantityDiscount(e, i + 1,item)} name='qty' value={item?.qty} type='number'/>:<input className='lightBlock2' onChange={(e) => onQuantityDiscount(e, i + 1,item)} name='qty' value={item?.qty?item?.qty:''} type='number'/> }
                        {/* {!d?.qty && <input className='lightBlock' onChange={(e) => onQuantityDiscount(e, i + 1,d)} name='quantity' value={d?.qty?d?.qty:''}/>} */}
                        </td>
                                                    {/* <td><input type='text' className='lightBlock' defaultValue={item.qty} name='quantity' onChange={(e) => onQuantityDiscount(e, i + 1)} /></td> */}
                                                    {/* <td><input type='text' className='lightBlock3' defaultValue={item.unit} readOnly /></td> */}
                                                    <td><input className='lightBlock3' value={ item?.type === "service" ? item?.price : item?.sales_price } onChange={(e) => onQuantityDiscount(e, i + 1,item)} name='price' type='number'/></td>
                                             {/* <td><input type='text' className='lightBlock' defaultValue={item.discount} name='discount'  onChange={(e) => onQuantityDiscount(e, i + 1)} /></td> */}
                                             <td>
                          {/* {d?.disc?d?.disc:"sou"} */}
                        {/* {d?.disc && <input className='lightBlock' onChange={(e) => onQuantityDiscount(e, i + 1)} name='discount' value={d?.discount}/> }
                        {!d?.disc && <input className='lightBlock' onChange={(e) => onQuantityDiscount(e, i + 1)} name='discount' value={d?.discount}/>} */}
                          {item?.disc ? <input className='lightBlock3' onChange={(e) => onQuantityDiscount(e, i + 1,item)} name='discount' value={item?.disc} type='number'/>:<input className='lightBlock3' type='number' onChange={(e) => onQuantityDiscount(e, i + 1,item)} name='discount' value={item?.disc?item?.disc:''}/>}

                          {/* {!d?.disc && <input className='lightBlock' onChange={(e) => onQuantityDiscount(e, i + 1,d)} name='discount' value={d?.disc?d?.disc:''}/>} */}
                                             </td>
                                             <td>
                                              {/* <input className='lightBlock' readOnly value={Number(getTotalPrice((item.type==='service'?item?.price:item?.sales_price), i + 1, item?.tax)).toFixed(2)} name='total' defaultValue={item?.total} /> */}
                                              <input className='lightBlock3' readOnly value={item?.total?Number(item?.total).toFixed(2):""} id={`all-${item?.id}`}  />
                                              </td>
                                              <td>
    {item?.tax ? <input className='lightBlock3' onChange={(e) => onQuantityDiscount(e, i + 1, item)} name='tax' value={item?.sales_tax?item?.sales_tax:item?.tax} type='number'/> : <input className='lightBlock3' onChange={(e) => onQuantityDiscount(e, i + 1, item)} name='sales_tax' value={item?.sales_tax?item?.sales_tax:item?.tax} type='number'/>}
  </td>
  <td>
        <Dropdown
          onSelect={(status) => handleDeliveryStatusChange(status, i)}
          className='dropselect'
        >
          <Dropdown.Toggle variant="success" id="dropdown-basic">
            {item?.delivery_status || 'Select Status'}
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Item eventKey="Delivered">Delivered</Dropdown.Item>
            <Dropdown.Item eventKey="Not Delivered">Not Delivered</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </td>
  <td>
     <i className="fa-regular fa-trash-can" onClick={() => handleSingleDelete(item?.id)}></i>
                            </td>
                                                </tr>
                                                 ))} 
                                            


                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div className='mb-4'>
                                    <button className='commonButton expBtn' onClick={e=>handleShow(e)}><i class="fa-regular fa-magnifying-glass"></i> Add Product or Service</button>&nbsp;&nbsp;
                                    {/* <button className='commonButton addBtn' onClick={e=>handelDelete(e)}>Delete</button>*/}
                                </div> 






                                <Form.Group className="row" controlId="exampleForm.ControlInput1">
                                <Col md="6" className='mb-3'>
  <Form.Control 
    as="textarea" 
    placeholder="Notes" 
    rows={3} 
    value={note} 
    onChange={(e) => setNote(e.target.value)} // Use e.target.value to access the typed text
  />
</Col>
                                    <Col md="6" className='mb-3'>
                                        <div className='subTotal'>
                                            <div className='saflex'>
                                                <span>Sub Total</span>
                                                <span>{`${getSymbolFromCurrency(currency)} ${Number(subTotal1).toFixed(2)}`}</span>
                                            </div>
                                            {/* <div className='saflex'>
                                                <span>Discount Rate</span>
                                                <span>20:00</span>
                                            </div> */}
                                            <div className='saflex'>
                                                <span>Discount Amount</span>
                                                <span>{`${getSymbolFromCurrency(currency)} ${Number(subSubDiscount1).toFixed(2)}`}</span>
                                            </div>
                                            <div className='saflex'>
                                                <span>VAT</span>
                                                <span>{`${getSymbolFromCurrency(currency)} ${Number(subSubVat1).toFixed(2)}`} </span>
                                            </div>
                                            <div className='saflex totlbg'>
                                                <span>Total</span>
                                                <span>{`${getSymbolFromCurrency(currency)} ${Number(subSubTotalValue1).toFixed(2)}`}</span>
                                            </div>
                                        </div>
                                    </Col>
                                </Form.Group>
                                <Form.Group className="row" controlId="exampleForm.ControlInput1">
                  <p>User Total</p>
                  <Col md="6" className='mb-3'>
                    <div className='subTotal'>
                      <div className='saflex'>
                        <span>Sub Total</span>
                        <span>{getSymbolFromCurrency(currencyData)}{(selectedCurrencyValue * Number(subTotal1)).toFixed(2)}</span>
                      </div>
                      {/* <div className='saflex'>
                                                <span>Discount Rate</span>
                                                <span>20:00</span>
                                            </div> */}
                      <div className='saflex'>
                        <span>Discount Amount</span>
                        <span>{getSymbolFromCurrency(currencyData)}{(selectedCurrencyValue * Number(subSubDiscount1)).toFixed(2)}</span>
                      </div>
                      <div className='saflex'>
                        <span>VAT</span>
                        <span>{getSymbolFromCurrency(currencyData)}{(selectedCurrencyValue * Number(subSubVat1)).toFixed(2)}</span>
                      </div>
                      <div className='saflex totlbg'>
                        <span>Total</span>
                        <span>
                          {getSymbolFromCurrency(currencyData)}{(selectedCurrencyValue * subSubTotalValue1).toFixed(2)}
                        </span>
                      </div>
                    </div>
                  </Col>
                </Form.Group>
                                <label>Attachments</label>
                    
<div className='mulFiles mt-2'>
<div className='mulFileselect'>
<i class="fa-light fa-cloud-arrow-up"></i> Choose Files
<input id='fileData'  onChange={(e) => handelFileChange(e)}
    type="file"
    multiple
    // onChange={handelFileChange}
/></div>
{/* Display the selected file names */}
{selectedFiles.length > 0 && (
                    <div className=''>
                    {/* <h3>Selected Files:</h3> */}
                    <ul>
                    {selectedFiles.map((file, index) => (
                    // </li>
                    <div style={{ position: 'relative', display: 'inline-block', }}>
                    {/* Render the cross icon */}
                    <span style={{ position: 'absolute', cursor: 'pointer',width: '200px' }} onClick={() => handleRemoveFile(file)}>
                      <i className="fas fa-times-circle" style={{ fontSize: '1.5em', color: 'red' }}></i>
                    </span>
                    {/* Render the current image */}
                    <i className="fa fa-file" style={{ fontSize: '4.5em', color: '#f39170',marginRight:'10px' }}></i>
                  </div>
                    ))}
                </ul>
                    </div>
                )}
</div>
     







                            </Form>
                        </div>
                        <button className='commonButton expBtn' onClick={onDone }>Done</button>&nbsp;&nbsp;
                        <button className='commonButton addBtn' onClick={()=>navigate('/quote')}>Cancel</button>


                    </div>
                </div>
            </Sidebar>




         

            
            <Modal size="xl" show={show} onHide={e=>handleClose(e)} centered className='customerModal sloModal'>
        <Modal.Header closeButton>
          <Modal.Title>
            <h5>List of Products and Services</h5>
            <div className='slSearch'>
              <i class="fa-regular fa-magnifying-glass"></i>
              <Form.Control
                type="search"
                placeholder="Search Product and Service"
                className=" mr-sm-2"
                onChange={onSearchByProSer}
              />
            </div>
          </Modal.Title>
          <button className='commonButton expBtn modAddbtn' onClick={productServiceShow2}><i class="fa-regular fa-plus"></i> Add</button>

        </Modal.Header>


        <Modal.Body>
          <div className='listViews salesPopTable listScroll'>
            <table class="table">
              <thead>
                <tr>
                  <th scope="col">Type</th>
                  <th scope="col">Name</th>
                  <th scope="col">description</th>
                  <th scope="col">Price</th>
                  <th scope="col">Unit</th>
                  <th scope="col">Quantity</th>
                  <th scope="col">Tax</th>
                </tr>
              </thead>
              <tbody>
              {productServiceList?.map((d) => (
                    allProductServiceData.some(item => item.id === d.id)?null:
                  <tr>
                         <td>
    {
        d?.type === 'product' ? 
        <>
            <span>&nbsp;</span>
            <span>&nbsp;</span>
            <input 
                type='checkbox' 
                onChange={() => onCheck(d)} 
                //disabled={allProductServiceData.length !== 0 ? d?.type !== allProductServiceData[0].type : false} 
                disabled={d.track_inventory==="Yes" && d.quantity === 0}  
                checked={allProductServiceData.includes(d)}
                style={{marginTop:'6px'}} 
            />
        </>
        :
        <input 
            type='checkbox' 
            onChange={() => onCheck(d)} 
            //disabled={allProductServiceData.length !== 0 ? d?.type !== allProductServiceData[0].type : false} 
            disabled={d.track_inventory==="Yes" && d.quantity === 0} 
            checked={allProductServiceData.includes(d)} 
            style={{marginTop:'6px'}}
        />
    }
    &nbsp;&nbsp;&nbsp;{d?.type}
</td>
                    <td>{d?.name}</td>
                    <td>{d?.description}</td>
                    <td>{getSymbolFromCurrency(currencyData)} {d?.type === "service" ? d?.price : d?.sales_price}</td>
                    <td>{d?.unit}</td>
                    <td>{d?.quantity}</td>
                    <td>{d?.sales_tax}</td>
                  </tr>
                  
                ))}




              </tbody>
            </table>
          </div>

         


        </Modal.Body>
        <Modal.Footer>
          {/* <button className='commonButton expBtn' onClick={handleClose}>Submit</button> */}
          <button className='commonButton expBtn' onClick={e=>handleClose(e)}>Close</button>
        </Modal.Footer>
      </Modal>
      <Modal size="lg" show={productService2} onHide={productServiceClose} centered className='customerModal sloModal asdModal'>
        <Modal.Header closeButton>
          <Modal.Title>
            <h5>Add Product or Service</h5>
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div className='commonWhite addCustomerForm p-3 mb-3'>
            <Form>
              <Form.Group className="row" controlId="exampleForm.ControlInput1">
                <Col md="6" className='mb-3'>
                  <Form.Select aria-label="Default select example" onChange={onType}>
                    <option selected hidden>Select Type</option>
                    <option value="Product">Product</option>
                    <option value="Service">Service</option>
                  </Form.Select>
                </Col>
                <Col md="3" className='mb-3'>
                  <Form.Control type="text" placeholder="Name" onChange={onName} />
                </Col>
                <Col md="3" className='mb-3'>
                  <Form.Control type="text" placeholder="Code" onChange={onID} />
                </Col>
              </Form.Group>
              <Form.Group className="row" controlId="exampleForm.ControlInput1">
                <Col md="12" className='mb-3'>
                  <Form.Control as="textarea" placeholder="Description(Optional)" rows={3} onChange={onDescription} />
                </Col>
              </Form.Group>



              <Form.Group className="row" controlId="exampleForm.ControlInput1">
              {type === "Service" ?
<Col md="4" className='mb-3'>
<Form.Select aria-label="Default select example" onChange={onUnit}>
    <option selected hidden>Select Unit</option>
    <option value="Hour">Hour</option>
    <option value="Day">Day</option>
    <option value="Contract">Contract</option>
    <option value="Percentage of completion">Percentage of completion</option>
</Form.Select>
</Col>
:

<Col md="4" className='mb-3'>
                                        <Form.Select aria-label="Default select example" onChange={onUnit}>
                                            <option selected hidden>Select Unit</option>
                                            <option value="Piece">Piece</option>
                                            <option value="Centiliter">Centiliter</option>
                                            <option value="Ltr">Ltr</option>
                                            <option value="Kg">Kg</option>
                                            <option value="Gms">Gms</option>
                                            <option value="Cubic meter">Cubic meter</option>
                                            <option value="Contract">Contract</option>
                                            <option value="Percentage of completion">Percentage of completion</option>
                                        </Form.Select>
                                    </Col>
                                    
}
                <Col md="4" className='mb-3'>
                  <Form.Control type="number" placeholder={type === "Service" ?"Price(Optional)":"Sales Price(Optional)"} onChange={onSalesPrice} />
                </Col>
                {/* <Col md="4" className='mb-3'>
                                        <Form.Control type="text" placeholder="Purchase tax" onChange={onPurchaseTax}/>
                                    </Col> */}
                <Col md="4" className='mb-3'>
                  <Form.Control type="number" placeholder="Purchase tax(Optional)" onChange={onPurchaseTax} />
                </Col>

              </Form.Group>

              {type === "Service" ? "" :
                <Form.Group className="row" controlId="exampleForm.ControlInput1">

                  <Col md="4" className='mb-3'>
                    <Form.Control type="number" placeholder="Purchase price(Optional)" onChange={onPurchasePrice} />
                  </Col>

                  <Col md="4" className='mb-3'>
                    <Form.Control type="number" placeholder="Quantity(Optional)" onChange={onOrderQty} />

                  </Col>
                  <Col md="4" className='mb-3'>
                                        <Form.Control type="text" placeholder={type === "Service" ?"Sales tax(Optional)":"Purchase tax(Optional)"} onChange={type === "Service" ?onSalesTax:onPurchaseTax}/>
                                    </Col>
                </Form.Group>}
              {type === "Service" ? "" :
                <div className='radioline mt-3 mb-3'><span>Track Inventory</span>
                  <div>
                    <input type="radio" id="test1" name="radio-group" onClick={handleTogglesOff} />
                    <label for="test1">Yes</label>
                  </div>
                  <div>
                    <input type="radio" id="test2" name="radio-group" onClick={handleTogglesOn} />
                    <label for="test2">No</label>
                  </div>

                </div>}


              <div className='rightBtn'>
                <button type='button' className='commonButton expBtn'
             onClick={onSaveservice}
                 >Save</button>
              </div>




            </Form>

          </div>
        </Modal.Body>
      </Modal>

      <Modal size="lg" show={showPdf} backdrop="static" onHide={handleClosePdf} className="salesExport">
        <div className='salesExpCol fullHgt'>
          <Modal.Header>
            {/* <Modal.Title>Modal heading</Modal.Title> */}
          </Modal.Header>
          {/* <div className='explft'>
                        <div className='salesModtop'>
                            <div className='salesModLft'>
                                <img src={bclogo} alt='' className='mb-2' />
                                <h5>ABC Company</h5>
                                <small>15 / 12 Lorem ipsum lfjfghyti <br />Ney ypork. Hilton </small>
                            </div>
                            <div className='salesModRgt'>
                                <h5>PURCHASE ORDER</h5>
                                <small>no. ClNV-NZ</small><br />
                                <small>24/11/2023</small><br />
                                <strong>Due on receipt</strong>
                            </div>
                        </div>
                        <div className='bsOrder'>
                            <div className='bsOrderlft'>
                                <h5>Billing address</h5>
                                <h6>Test Company</h6>
                                <small>15 / 12 Lorem ipsum lfjfghyti<br />
                                    Ney ypork. Hilton </small>
                            </div>
                            <div className='bsOrderRgt'>
                                <h5>Shipping address</h5>
                                <h6>Test Company</h6>
                                <small>15 / 12 Lorem ipsum lfjfghyti<br />
                                    Ney ypork. Hilton </small>
                            </div>
                        </div>
                        <div className='bsTables'>
                            <table>
                                <thead>
                                    <tr>
                                        <th>Item name & description</th>
                                        <th>VAT</th>
                                        <th>Qty</th>
                                        <th>Unit Price</th>
                                        <th>Amount</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>
                                            <h6>ABC Product</h6>
                                            em ipsum lfjfghyti
                                        </td>
                                        <td>20%</td>
                                        <td>1</td>
                                        <td>$20.00/price</td>
                                        <td>$20.00</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            &nbsp;
                                        </td>
                                        <td colSpan="3">
                                            <h6>Subtotal</h6>
                                        </td>
                                        <td>$20.00</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            &nbsp;
                                        </td>
                                        <td colSpan="3">
                                            standard rate($20.00)
                                        </td>
                                        <td>$4.00</td>
                                    </tr>
                                </tbody>
                                <tfoot>
                                    <tr>
                                        <td>&nbsp;</td>
                                        <td colSpan="3">Total</td>
                                        <td>$24</td>
                                    </tr>
                                </tfoot>
                            </table>
                        </div>
                    </div> */}
          {/* file:///C:/Users/BRAINIUM/Downloads/Example-PDF-document%20(4).htm */}
          {/* https://dev8.mydevfactory.com//1SimpleClick//public//quote_pdf//SQO20240226242.pdf */}


          <div className='modIframe'><iframe src={dataPdf?.data?.pdf} /></div>
          <div className='expRgt'>
            {/* <button className='commonButton expBtn btnshadow'><i class="fa-regular fa-envelope"></i> Email</button>
                        <button className='commonButton wtBtn btnshadow'><i class="fa-light fa-print"></i> Print</button>
                        <button className='commonButton wtBtn btnshadow'><i class="fa-light fa-down-to-bracket"></i> Download</button> */}

            {!views ?
              <div>
                <button className='commonButton expBtn btnshadow' onClick={toggleHandlerb}><i class="fa-regular fa-envelope"></i> Email</button>
                <button className='commonButton wtBtn btnshadow' onClick={onButtonClick}><i class="fa-light fa-print"></i> Print</button>
                <button className='commonButton wtBtn btnshadow' onClick={onButtonClick}><i class="fa-light fa-down-to-bracket"></i> Download</button><br/><br/><br/><br/>
                <button className='commonButton expBtn btnshadow' onClick={vclose}><i class="fa-solid fa-xmark"></i> Close</button>

              </div> :
              <Form>
                <button className='commonButton backArrow' onClick={toggleHandlerc}><i class="fa-solid fa-arrow-left"></i> Back</button>
                <Form.Group className="mb-3 salesRform" controlId="exampleForm.ControlInput1">
                  <Col md="12" className='mb-2'>
                    <label>To <span className='redColor'>*</span></label>
                    <Form.Control type="text" placeholder="Johndoe@gmail.com"  value={dataPdf?.data?.customerEmail}/>
                  </Col>
                  <Col md="12" className='mb-2'>
                    <label>Subject :</label>
                    <Form.Control type="text" placeholder="ABC Company" value={`Customer Invoice - ${dataPdf?.data?.details?.invoice_id}`} />
                  </Col>
                  <Col md="12" className='mb-3'>
                    <label>Message :</label>
                    <Form.Control as="textarea" placeholder="Enter your message" rows={3} className='mb-3' onChange={onAddressQuote} />
                  </Col>
                  <div className='salesCheck mb-3'><Form.Check type="checkbox" onClick={checkMe}/> Send me a copy</div>
                  <button type="button" className='commonButton expBtn btnshadow' onClick={onSendPdf}>Send</button>

                </Form.Group>
              </Form>
            }


          </div>
        </div>
      </Modal>
  {/* ===============================Inventory Account Modal=========================== */}
      <Modal size="lg" show={addaccount} onHide={addaccountClose} centered className='customerModal sloModal'>
        <Modal.Header closeButton>
          <Modal.Title>
            <h5>Add an Account</h5>
          </Modal.Title>


        </Modal.Header>
        <Modal.Body>
          <div className='commonWhite addCustomerForm mb-3'>
            <Form>
              <Form.Group className="row" controlId="exampleForm.ControlInput1">
                <Col md="12" className='mb-3'>
                  <CustomDropdown placeholder="Account Type" value="Inventory" />
                </Col>
              </Form.Group>
              <Form.Group className="row" controlId="exampleForm.ControlInput1">
                <Col md="6" className='mb-3'>
                  <Form.Control type="text" placeholder="Account name" onChange={onAccountName} />
                </Col>
                <Col md="6" className='mb-3'>
                  <Form.Control type="text" placeholder="Account ID" onChange={onAccountId} />
                </Col>
              </Form.Group>
              <Form.Group className="row" controlId="exampleForm.ControlInput1">
                <Col md="12" className='mb-0'>
                  <Form.Control as="textarea" placeholder="Description" rows={3} onChange={onAccountDescription} />
                </Col>
              </Form.Group>
            </Form>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button className='commonButton addBtn' onClick={addaccountClose}>Cancel</button>
          <button className='commonButton expBtn' onClick={()=>onSave2('4')} >Save</button>
        </Modal.Footer>
      </Modal>

{/* ===============================Income Account Modal=========================== */}
<Modal size="lg" show={addincomeaccount} onHide={addincomeaccountClose} centered className='customerModal sloModal'>
        <Modal.Header closeButton>
          <Modal.Title>
            <h5>Add an Account</h5>
          </Modal.Title>


        </Modal.Header>
        <Modal.Body>
          <div className='commonWhite addCustomerForm mb-3'>
            <Form>
              <Form.Group className="row" controlId="exampleForm.ControlInput1">
                <Col md="12" className='mb-3'>
                  <CustomDropdown placeholder="Account Type" value="Income" />
                </Col>
              </Form.Group>
              <Form.Group className="row" controlId="exampleForm.ControlInput1">
                <Col md="6" className='mb-3'>
                  <Form.Control type="text" placeholder="Account name" onChange={onAccountName} />
                </Col>
                <Col md="6" className='mb-3'>
                  <Form.Control type="text" placeholder="Account ID" onChange={onAccountId} />
                </Col>
              </Form.Group>
              <Form.Group className="row" controlId="exampleForm.ControlInput1">
                <Col md="12" className='mb-0'>
                  <Form.Control as="textarea" placeholder="Description" rows={3} onChange={onAccountDescription} />
                </Col>
              </Form.Group>
            </Form>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button className='commonButton addBtn' onClick={addincomeaccountClose}>Cancel</button>
          <button className='commonButton expBtn' onClick={()=>onSave2('20')} >Save</button>
        </Modal.Footer>
      </Modal>
      {/* ===============================Expense Account Modal=========================== */}
      <Modal size="lg" show={addexpenseaccount} onHide={addexpenaccountClose} centered className='customerModal sloModal'>
        <Modal.Header closeButton>
          <Modal.Title>
            <h5>Add an Account</h5>
          </Modal.Title>


        </Modal.Header>
        <Modal.Body>
          <div className='commonWhite addCustomerForm mb-3'>
            <Form>
              <Form.Group className="row" controlId="exampleForm.ControlInput1">
                <Col md="12" className='mb-3'>
                  <CustomDropdown placeholder="Account Type" value="Expenses" />
                </Col>
              </Form.Group>
              <Form.Group className="row" controlId="exampleForm.ControlInput1">
                <Col md="6" className='mb-3'>
                  <Form.Control type="text" placeholder="Account name" onChange={onAccountName} />
                </Col>
                <Col md="6" className='mb-3'>
                  <Form.Control type="text" placeholder="Account ID" onChange={onAccountId} />
                </Col>
              </Form.Group>
              <Form.Group className="row" controlId="exampleForm.ControlInput1">
                <Col md="12" className='mb-0'>
                  <Form.Control as="textarea" placeholder="Description" rows={3} onChange={onAccountDescription} />
                </Col>
              </Form.Group>
            </Form>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button className='commonButton addBtn' onClick={addexpenaccountClose}>Cancel</button>
          <button className='commonButton expBtn' onClick={()=>onSave2('25')} >Save</button>
        </Modal.Footer>
      </Modal>

        </div>
    )
}


export default InvoiceSalesOrder