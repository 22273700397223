import { React, useState, useEffect } from 'react'
import Sidebar from '../../layout/Sidebar'
import Dbheader from '../../layout/Dbheader'
import { Link } from 'react-router-dom'
import Form from 'react-bootstrap/Form';
import Dropdown from 'react-bootstrap/Dropdown';
import { useNavigate } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import bclogo from '../../../images/bclogo.png';
import cals from '../../../images/cals.png';
import Col from 'react-bootstrap/Col';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import { CSVLink } from 'react-csv';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Pagination from 'react-bootstrap/Pagination';
import Swal from 'sweetalert2';
import { ToastContainer, toast } from 'react-toastify';
// import Headtxt from '../Headtxt';

// import Listview from '../../sales/Listview';
// import Gridview from '../../sales/Gridview';
import Card from 'react-bootstrap/Card'
import Headtxt from '../../dbstructure/Headtxt';
import { getSalesExcelQuote, getSalesQuotationList, getdeletesalesQuotation, getsalesDateSearch, getsalesQuotationCancelResend } from '../../../../Service/Customer/CustomerService';
import { getdeleteQuotation } from '../../../../Service/Quotation/QuotationService';
import moment from "moment"
const Quote = ({ setIsSpinner }) => {

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [quotation, setQuotation] = useState([]);
    const [fromDate, setFromDate] = useState('');
    const [toDate, setToDate] = useState('');
    const [shows, setShows] = useState(false);
    const [querySearch, setQuerySearch] = useState('');
    const [quoteExport, setQuoteExport] = useState([]);
    const handleShowinv = () => {
        setShows(true);
        setShowsvat(false);
    }
    const handleCloseinv = () => {
        setShows(false);
        setShowsvat(true);
    }
    const [userData, setUserData] = useState(null);
    const [usertype, setUserType] = useState(null);
   const [userid, setUserId] = useState(null);
    useEffect(() => {
      // Retrieve the JSON string from localStorage
      const jsonString = localStorage.getItem('1Simpleclick_User'); // Replace 'yourStorageKey' with the actual key.
  console.log("localdataaa======",localStorage.getItem('1Simpleclick_User'))
      // Parse the JSON string into an object
      const user_type = localStorage.getItem('1Simpleclick_UserType');
      const data = JSON.parse(jsonString);
      setUserId(localStorage.getItem("1Simpleclick_UserID"));
      // Update the state with the retrieved data
      setUserData(data);
      setUserType(user_type)
    }, []);

    const checkModule = (module, subModule) => {
        if (userData && userData.assigned_modules) {
          const assignedModules = userData.assigned_modules;
          
          // Handle the case where assigned_modules is an empty string
          if (assignedModules === "") {
            return true; // Or false, depending on the desired behavior
          }
      
          try {
            const modules = JSON.parse(assignedModules);
            
            // if (modules && modules[module]) {
                return modules[module]?.[subModule] || false; // Fallback to false if subModule is not found
         //   }
          } catch (error) {
            console.error("Error parsing assigned_modules:", error);
          }
        }
        return true; // Default to true if no assigned_modules or any errors
      };

      
    const [showsvat, setShowsvat] = useState(false);
    const handleClosevat = () => setShowsvat(false);
    const handleFromDateChange = (e) => {
        const value = e;
        setFromDate(value);
        triggerSearch(querySearch, e, toDate);
    };

    const handleToDateChange = (e) => {
        const value = e;
        setToDate(value);
        triggerSearch(querySearch, fromDate, e);
    };


    const [selectedPage, setSelectedPage] = useState('/');
    const navigate = useNavigate();

    const handlePageChange = (page) => {
        setSelectedPage(page);
        navigate(page);
    };


    const [supply, setSupply] = useState(false)
    const toggleCompany = (e) => {
        e.preventDefault();
        setSupply(false)
    }
    const toggleIndividual = (e) => {
        e.preventDefault();
        setSupply(true)
    }


    const [view, setView] = useState(false)
    const toggleHandler = (e) => {
        e.preventDefault();
        setView(true)
    }
    const toggleHandler1 = (e) => {
        e.preventDefault();
        setView(false)
    }


    // custom-select-drupdown
    const [isOpen, setIsOpen] = useState(false);
    const [selectedOption, setSelectedOption] = useState('More');

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };

    const handleOptionClick = (option) => {
        setSelectedOption(option);
        setIsOpen(false);
    };


    // FOR-LOAD-MORE
    const [items, setItems] = useState(['Item 1', 'Item 2', 'Item 3', 'Item 4', 'Item 5', 'Item 6', 'Item 7', 'Item 8', 'Item 9', 'Item 10', 'Item 11', 'Item 12', 'Item 13', 'Item 14', 'Item 15']);
    const [visibleItems, setVisibleItems] = useState(5);
    const [loading, setLoading] = useState(false);

    const loadMoreItems = () => {
        setLoading(true);
        setTimeout(() => {
            // Simulate loading delay
            setVisibleItems((prev) => prev + 5);
            setLoading(false);
        }, 50); // Adjust the delay as needed
    };

    const handleScroll = () => {
        if (
            window.innerHeight + document.documentElement.scrollTop ===
            document.documentElement.offsetHeight
        ) {
            // Auto-load more items when scrolling to the bottom
            loadMoreItems();
        }
    };

    useEffect(() => {
        getQuotationDataList();
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);


    // DATE-PICKER
    const [selectedDate, setSelectedDate] = useState(null);
    const handleDateChange = (date) => {
        setSelectedDate(date);
    };

    // RANGE-DATEPICKER
    const [dateRange, setDateRange] = useState([null, null]);

    const handleDateChangeb = (dates) => {
        // Set the time to midnight (00:00:00) to avoid time-related issues
        const midnightDates = dates.map(date => (date ? new Date(date.setHours(0, 0, 0, 0)) : null));
        setDateRange(midnightDates);
    };


    // For toggle
    const [isToggled, setToggled] = useState(false);
    const onExcelExport = () => {
        getSalesExcelQuote().then(function (result) {
            const response = result?.data?.data
            console.log(response, "response")
            setQuoteExport(response);
        });

    }
    const handleToggleOn = () => {
        setToggled(true);
    };
    const handleToggleOff = () => {
        setToggled(false);
    };
    const handleClick = () => {
        navigate('/addcustomerquote');
    };
    const handleClick2 = (id) => {
        navigate(`/quotesalesordergeneration?id=${id}`);
    };
    const onStatus = (e) => {
        getSalesQuotationList(`searchText=${e.target.value}`).then(function (result) {
            const response = result?.data;
            console.log(response, "newrrrr")
            setQuotation(response?.data ? response?.data : []);
        });


    }
    const getSearchList = () => {
        // Construct the filter criteria based on fromDate and toDate
        const filterCriteria = {
            fromDate: fromDate ? moment(fromDate).format('DD-MM-YYYY') : '',
            toDate: toDate ? moment(toDate).format('DD-MM-YYYY') : ''
        };
        console.log('filter---', filterCriteria);
        const queryString = new URLSearchParams(filterCriteria).toString();
        const finalQueryString = querySearch ? `searchText=${querySearch}&${queryString}` : `searchText=&${queryString}`;
        getsalesDateSearch(finalQueryString).then(function (result) {
            const response = result?.data;
            console.log('resultttt---', response);
            setQuotation(response?.data);
        });
    };

    const onSearch = (e) => {
        const query = e.target.value;
        setQuerySearch(query);
        triggerSearch(query, fromDate, toDate);
    };

    useEffect(() => {
        onExcelExport();
        getSearchList();
    }, [fromDate, toDate]);

    const triggerSearch = (query, from, to) => {
        const filterCriteria = {
            //  query: query || '',
            fromDate: from ? moment(from).format('DD-MM-YYYY') : '',
            toDate: to ? moment(to).format('DD-MM-YYYY') : ''
        };
        const queryString = new URLSearchParams(filterCriteria).toString();
        const finalQueryString = query ? `searchText=${query}&${queryString}` : `searchText=&${queryString}`;

        console.log(finalQueryString, 'finalQueryString');
        getSalesQuotationList(finalQueryString).then(function (result) {
            const response = result?.data;
            console.log(response, "rrrr")
            setQuotation(response?.data);
        });
    };
    async function getQuotationDataList() {
        getSalesQuotationList("").then(function (result) {
            const response = result?.data;

            setQuotation(response?.data);
        });
    }

    const onDelete = (id) => {
        setIsSpinner(true)
        getdeletesalesQuotation(id,
            (r) => {
                console.log(r, "rererererere")
                // if (r.success === true) {
                //     toast.success(r.message)
                //     getQuotationDataList();

                // }
                if (r.success === true) {
                    setIsSpinner(false)
                    Swal.fire({
                        title: r.message,
                        //  text: 'The product has been added successfully!',
                        icon: 'success',
                        confirmButtonText: 'OK',
                        customClass: {
                            confirmButton: 'my-confirm-button'
                        }
                    }).then(() => {
                        getQuotationDataList();
                    })
                }
                else {
                    console.log("nononononono")
                    toast.error(r.message)
                    setIsSpinner(false)
                }
            })
    }
    const onCancel = (id) => {
        setIsSpinner(true)
        getsalesQuotationCancelResend(id, "cancel",
            (r) => {
                console.log(r, "response")
                // if (r.success === true) {
                //     toast.success(r.message)
                //     getQuotationDataList();

                // }
                if (r.success === true) {
                    setIsSpinner(false)
                    Swal.fire({
                        title: r.message,
                        //  text: 'The product has been added successfully!',
                        icon: 'success',
                        confirmButtonText: 'OK',
                        customClass: {
                            confirmButton: 'my-confirm-button'
                        }
                    }).then(() => {

                        getQuotationDataList();
                    })
                }
                else {
                    console.log("nononononono")
                    toast.error(r.message)
                    setIsSpinner(false)
                }
            })
    }

    const onResend = (id) => {
        setIsSpinner(true)
        getsalesQuotationCancelResend(id, "resend",
            (r) => {
                console.log(r, "rererererere")
                // if (r.success === true) {
                //     toast.success(r.message)
                //     getQuotationDataList();

                // } 
                if (r.success === true) {
                    setIsSpinner(false)
                    Swal.fire({
                        title: r.message,
                        //  text: 'The product has been added successfully!',
                        icon: 'success',
                        confirmButtonText: 'OK',
                        customClass: {
                            confirmButton: 'my-confirm-button'
                        }
                    }).then(() => {
                        getQuotationDataList();
                    })
                }
                else {
                    console.log("nononononono")
                    toast.error(r.message)
                    setIsSpinner(false)
                }
            })
    }
    return (
        <div className="producservlisting pdt">
            <Dbheader />
            <Sidebar>
                <div className='customerMain'>
                    <div className='custTop'>
                        <Link to='/customerlisting' className={usertype == 2 ?(checkModule('Sales','Customers') ? '' :'disabled'):''}>Customer</Link>
                        <Link to='/quote' className={usertype == 2 ?(checkModule('Sales','Quote') ? 'active' :'disabled'):'active'}>Quote</Link>
                        <Link to="/salesorderlisting" className={usertype == 2 ?(checkModule('Sales','SalesOrders') ? '' :'disabled'):''}>Sales Orders</Link>
                        <Link to='/invoice' className={usertype == 2 ?(checkModule('Sales','Invoices') ? '' :'disabled'):''}>Invoice</Link>
                        {/* <Form.Select aria-label="Default select example">
                            <option>More</option>
                            <option value="1">One</option>
                            <option value="2">Two</option>
                            <option value="3">Three</option>
                        </Form.Select> */}
                        <div className="custom-dropdown-container customDropdown ms-4">
                            <div className="custom-dropdown-header" onClick={toggleDropdown}>
                                <span>{selectedOption}</span>
                                <span className={`dropdown-arrow ${isOpen ? 'open' : ''}`}> <i class="fa-regular fa-angle-down"></i></span>
                            </div>

                            {isOpen && (
                                <ul className="custom-dropdown-options">
                                    <li
                                        className={`option ${selectedOption === 'Option 1' ? 'selected' : ''}`}
                                        onClick={() => handleOptionClick('Option 1')} hidden
                                    >
                                        Option 1
                                    </li>
                                 
                                    <li
                                        className={`option ${selectedOption === 'Option 3' ? 'selected' : ''}`}
                                       // onClick={() => handleOptionClick('')}
                                    >
                                        <Link to="/salesreceiptlist" className={usertype == 2 ?(checkModule('Sales','SalesReceipt') ? '' :'disabled'):''}>Sales Receipts</Link>
                                    </li>
                                    <li
                                        className={`option ${selectedOption === 'Option 4' ? 'selected' : ''}`}
                                      //  onClick={() => handleOptionClick('Option 4')}
                                    >
                                        <Link to="/refundlist" className={usertype == 2 ?(checkModule('Sales','Refund') ? '' :'disabled'):''}>Refunds</Link>
                                    </li>
                                </ul>
                            )}

                            {/* <p>You selected: {selectedOption}</p> */}
                        </div>
                    </div>
                    <div className='productServList'>
                        <div className='dbTopsec pb-2 pt-2 noBackbtn'>
                            <Headtxt link="" title="Quote" />

                            {/* <div className='custButton'>
                                <button className='commonButton addBtn'><Link to="/addproductservice"><i class="fa-regular fa-circle-plus"></i> Add Product</Link></button>&nbsp;&nbsp;
                                <button className='commonButton expBtn'><i class="fa-light fa-download"></i> Export Excel</button>
                            </div> */}
                        </div>

                        <div className='customerSrchTop pb-3 poResponsive'>
                            <div className='customSrchfield'>
                                <div>
                                    <span><i class="fa-solid fa-magnifying-glass"></i></span><input type='search' placeholder='Search by keywords' onChange={onSearch} value={querySearch} />
                                </div>&nbsp;&nbsp;
                                {/* <div className='datepickr datepickBk'>
                        <DatePicker
                            selected={selectedDate}
                            onChange={handleDateChange}
                            dateFormat="dd/MM/yyyy" 
                            isClearable
                            placeholderText="Date"
                        />
                    </div> */}
                                <div className='invDatepicker'>
                                    <DatePicker
                                        selected={fromDate}
                                        onChange={handleFromDateChange}
                                        selectsStart
                                        startDate={fromDate}
                                        endDate={toDate}
                                        placeholderText="From Date"
                                        isClearable={fromDate ? true : false}
                                    />
                                </div>
                                <div className='invDatepicker' style={{ marginLeft: '10px' }}>
                                    <DatePicker
                                        selected={toDate}
                                        onChange={handleToDateChange}
                                        selectsEnd
                                        startDate={fromDate}
                                        endDate={toDate}
                                        placeholderText="To Date"
                                        isClearable={toDate ? true : false}
                                        minDate={fromDate}

                                    />
                                </div>
                            </div>
                            <div className='custButton flexfield'>



                                {/* <button className='commonButton addBtn' onClick={handleShowinv}><i class="fa-regular fa-circle-plus"></i> Create Invoice</button>&nbsp;&nbsp; */}
                                <button className='commonButton expBtn' onClick={handleClick}><i className="fa-regular fa-circle-plus"></i> Add New Quote</button>&nbsp;&nbsp;
                                <CSVLink data={quoteExport} filename={"sales_quotations.csv"}>
                                    <button className='commonButton expBtn'><i class="fa-light fa-upload"></i> Export Excel</button>
                                </CSVLink>
                            </div>
                        </div>


                        {/* <div className='addsuppBtn'>
                            <button className={`${!supply ? "commonButton active" : "commonButton "} `} onClick={toggleCompany}>Product</button>
                            <button className={`${supply ? "commonButton active" : "commonButton "} `} onClick={toggleIndividual}>Service</button>
                        </div> */}
                        <div className='mt-2'>
                            <div className='gridList mb-3 flexfield gridListResp' style={{ justifyContent: "flex-end" }}>
                                <div className='srtBy'>
                                    <label><i className="fa-regular fa-filter"></i> Filter: </label>&nbsp;
                                    <Form.Select aria-label="Default select example" className='sortby' onChange={onStatus}>
                                        <option value="">Select Status</option>
                                        <option value="Not Ordered">Not Ordered</option>
                                        {/* <option value="Ordered">Ordered</option> */}
                                        <option value="Invoiced">Invoiced</option>
                                        <option value="Not Invoiced">Not Invoiced</option>
                                        <option value="Cancelled">Cancelled</option>
                                        <option value="Paid">Paid</option>
                                        <option value="Partially Paid">Partially Paid</option>
                                    </Form.Select>&nbsp;
                                </div>
                                <div>
                                    <button size="100%" style={{ backgroundColor: "#DCE5EC" }} onClick={toggleHandler1} className={!view ? "listactive" : ""}><i class="fa-solid fa-list"></i></button>
                                    <button size="100%" style={{ backgroundColor: "#DCE5EC" }} onClick={toggleHandler} className={view ? "listactive" : ""}><i class="fa-solid fa-grid"></i></button>
                                </div>
                            </div>
                            {!view ?
                                <div className='listViews'>
                                    <table class="table">
                                        <thead>
                                            <tr>
                                                <th scope="col">Quote ID</th>
                                                <th scope="col">Date of Generation</th>
                                                <th scope="col">Customer Name</th>
                                                <th scope="col">Quote Amount</th>
                                                <th scope="col">Expiration Date</th>
                                                <th scope="col">Created/Updated By</th>
                                                <th scope="col">Status</th>
                                                <th scope="col">Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>

                                            {quotation?.map((item, index) => (
                                                  usertype==1? 
                                                <tr key={index} text={item}>
                                                    <td><Link to={`/viewCustomerquotedetails?id=${item.id}`}>{item.quote_id}</Link></td>
                                                    <td>{item.date}</td>
                                                    <td>{item?.type == "Company" ? item?.company_name : item?.fname + " " + item?.lname}</td>
                                                    <td>{item.customer_currency} {item.total}</td>
                                                    <td>{item.expiration_date}</td>
                                                    <td>{item.creatorFname} {item.creatorLname}</td>
                                                    <td>
                                                        <span className='pending' style={{
                                                            backgroundColor: item?.status === 'Cancelled' ? '#f2b8be' :
                                                                item?.status === 'Not Ordered' ? '#f8d5c9' :
                                                                    item?.status === 'Paid' || item?.status === 'Invoiced' ? '#d7f4b2' :
                                                                        item?.status === 'Partially Paid' ? '#cfdcf8' :
                                                                            //  item?.status === 'Invoiced' ? '#ce7ac' :
                                                                            item?.status === 'Unpaid' || item?.status === 'Not Invoiced' ? '#f0caee' : '',
                                                            color: item?.status === 'Cancelled' ? '#e51c0e' :
                                                                item?.status === 'Partially Paid' ? '#1650ce' :
                                                                    item?.status === 'Not Ordered' ? '#FF6C3C' :
                                                                        item?.status === 'Paid' || item?.status === 'Invoiced' ? '#638D2C' :
                                                                            // item?.status === 'Invoiced' ? '#ce7ac' :
                                                                            item?.status === 'Unpaid' || item?.status === 'Not Invoiced' ? '#e61cdf' : ''
                                                        }}>
                                                            {item?.status}
                                                        </span></td>
                                                    {/* <td><span className='generated'>Order</span></td> */}
                                                    <td>
                                                        <div className='tdFlex'>
                                                            {/* <span className='viewIcon'><Link to={`/customerdetails`}><i className="fa-light fa-eye"></i></Link></span> */}
                                                            {item?.status == 'Not Ordered' ? <span className='viewIcon orange'>

                                                                <Link to={`/customereditquote?id=${item.id}`} ><i className="fa-light fa-pen"></i></Link></span> : " "}
                                                            {item?.status === 'Cancelled' || item?.status === 'Paid' || item?.status === 'Partially Paid' || item?.status === 'Invoiced' || item?.status === 'Not Invoiced' ? <span className='viewIcon' style={{ borderRight: "0" }} onClick={() => onDelete(item?.id)}><i className="fa-regular fa-trash-can"></i></span> : <span className='viewIcon' style={{ marginRight: "10px" }} onClick={() => onDelete(item?.id)}><i className="fa-regular fa-trash-can"></i></span>}
                                                            {!(item?.status === 'Cancelled' || item?.status === 'Paid' || item?.status === 'Partially Paid' || item?.status === 'Invoiced' || item?.status === 'Not Invoiced') &&
                                                                <Dropdown className='dropRow'>
                                                                    <Dropdown.Toggle id="dropdown-basic">
                                                                        {
                                                                            item?.status == 'Not Ordered' ?
                                                                                <i className="fa-light fa-ellipsis-vertical"></i> : ""}
                                                                    </Dropdown.Toggle>


                                                                    <Dropdown.Menu>
                                                                        {item?.status == 'Not Ordered' ?
                                                                            <Dropdown.Item onClick={() => onCancel(item?.id)}><i className="fa-regular fa-circle-xmark"></i> &nbsp; Cancel</Dropdown.Item> : ''}
                                                                        <Dropdown.Item onClick={() => onResend(item?.id)}><i className="fa-light fa-share"></i> &nbsp; Resend</Dropdown.Item>
                                                                        {item?.status == 'Not Ordered' ? <Dropdown.Item onClick={() => handleClick2(item?.id)} className={usertype == 2 ?(checkModule('Sales','SalesOrders') ? '' :'disabled'):''}><i className="fa-light fa-share"></i> &nbsp; Generate Order</Dropdown.Item> : ''}
                                                                        <Dropdown.Item onClick={e=>{ navigate(`/viewCustomerquotedetails?id=${item.id}`)}}><Link to={`/viewCustomerquotedetails?id=${item.id}`}><i className="fa-light fa-eye"></i></Link>&nbsp; View Quote Details</Dropdown.Item>
                                                                    </Dropdown.Menu>
                                                                </Dropdown>
                                                            }
                                                        </div>
                                                    </td>
                                                </tr>
:  
usertype==2&&item.created_by==userid?
<tr key={index} text={item}>
                                                    <td><Link to={`/viewCustomerquotedetails?id=${item.id}`}>{item.quote_id}</Link></td>
                                                    <td>{item.date}</td>
                                                    <td>{item?.type == "Company" ? item?.company_name : item?.fname + " " + item?.lname}</td>
                                                    <td>{item.customer_currency} {item.total}</td>
                                                    <td>{item.expiration_date}</td>
                                                    <td>{item.creatorFname} {item.creatorLname}</td>
                                                    <td>
                                                        <span className='pending' style={{
                                                            backgroundColor: item?.status === 'Cancelled' ? '#f2b8be' :
                                                                item?.status === 'Not Ordered' ? '#f8d5c9' :
                                                                    item?.status === 'Paid' || item?.status === 'Invoiced' ? '#d7f4b2' :
                                                                        item?.status === 'Partially Paid' ? '#cfdcf8' :
                                                                            //  item?.status === 'Invoiced' ? '#ce7ac' :
                                                                            item?.status === 'Unpaid' || item?.status === 'Not Invoiced' ? '#f0caee' : '',
                                                            color: item?.status === 'Cancelled' ? '#e51c0e' :
                                                                item?.status === 'Partially Paid' ? '#1650ce' :
                                                                    item?.status === 'Not Ordered' ? '#FF6C3C' :
                                                                        item?.status === 'Paid' || item?.status === 'Invoiced' ? '#638D2C' :
                                                                            // item?.status === 'Invoiced' ? '#ce7ac' :
                                                                            item?.status === 'Unpaid' || item?.status === 'Not Invoiced' ? '#e61cdf' : ''
                                                        }}>
                                                            {item?.status}
                                                        </span></td>
                                                    {/* <td><span className='generated'>Order</span></td> */}
                                                    <td>
                                                        <div className='tdFlex'>
                                                            {/* <span className='viewIcon'><Link to={`/customerdetails`}><i className="fa-light fa-eye"></i></Link></span> */}
                                                            {item?.status == 'Not Ordered' ? <span className='viewIcon orange'>

                                                                <Link to={`/customereditquote?id=${item.id}`} ><i className="fa-light fa-pen"></i></Link></span> : " "}
                                                            {item?.status === 'Cancelled' || item?.status === 'Paid' || item?.status === 'Partially Paid' || item?.status === 'Invoiced' || item?.status === 'Not Invoiced' ? <span className='viewIcon' style={{ borderRight: "0" }} onClick={() => onDelete(item?.id)}><i className="fa-regular fa-trash-can"></i></span> : <span className='viewIcon' style={{ marginRight: "10px" }} onClick={() => onDelete(item?.id)}><i className="fa-regular fa-trash-can"></i></span>}
                                                            {!(item?.status === 'Cancelled' || item?.status === 'Paid' || item?.status === 'Partially Paid' || item?.status === 'Invoiced' || item?.status === 'Not Invoiced') &&
                                                                <Dropdown className='dropRow'>
                                                                    <Dropdown.Toggle id="dropdown-basic">
                                                                        {
                                                                            item?.status == 'Not Ordered' ?
                                                                                <i className="fa-light fa-ellipsis-vertical"></i> : ""}
                                                                    </Dropdown.Toggle>


                                                                    <Dropdown.Menu>
                                                                        {item?.status == 'Not Ordered' ?
                                                                            <Dropdown.Item onClick={() => onCancel(item?.id)}><i className="fa-regular fa-circle-xmark"></i> &nbsp; Cancel</Dropdown.Item> : ''}
                                                                        <Dropdown.Item onClick={() => onResend(item?.id)}><i className="fa-light fa-share"></i> &nbsp; Resend</Dropdown.Item>
                                                                        {item?.status == 'Not Ordered' ? <Dropdown.Item onClick={() => handleClick2(item?.id)} className={usertype == 2 ?(checkModule('Sales','SalesOrders') ? '' :'disabled'):''}><i className="fa-light fa-share"></i> &nbsp; Generate Order</Dropdown.Item> : ''}
                                                                        <Dropdown.Item onClick={e=>{ navigate(`/viewCustomerquotedetails?id=${item.id}`)}}><Link to={`/viewCustomerquotedetails?id=${item.id}`}><i className="fa-light fa-eye"></i></Link>&nbsp; View Quote Details</Dropdown.Item>
                                                                    </Dropdown.Menu>
                                                                </Dropdown>
                                                            }
                                                        </div>
                                                    </td>
                                                </tr>
                                                :null
                                            ))}
                                        </tbody>
                                    </table>

                                    {loading &&
                                        <div className='loadItems'>
                                            <button className="commonButton addBtn">
                                                Loading...
                                            </button>
                                        </div>
                                    }
                                    {!loading && (
                                        <div className='loadItems'>
                                            <button onClick={loadMoreItems} className="commonButton addBtn">
                                                No More Quotes
                                            </button>
                                        </div>
                                    )}

                                </div>
                                :
                                <div className='gridView'>
                                    {quotation?.map((item, index) => (
                                        usertype==1? 
                                        <Card>
                                            <Card.Body>
                                                <div className='commonFlex'>
                                                    <h6>{item.quote_id}</h6>
                                                    <span><img src={cals} alt='' className='mb-2' />&nbsp;&nbsp;{item.date}</span>
                                                </div>
                                                <div className='commonFlex'>
                                                    <span className='customr'>{item?.type == "Company" ? item?.company_name : item?.fname + " " + item?.lname}</span>
                                                    <span>&nbsp;</span>
                                                </div>
                                                <div className='commonFlex'>
                                                    <span className='pending' style={{
                                                        backgroundColor: item?.status === 'Cancelled' ? '#f2b8be' :
                                                            item?.status === 'Not Ordered' ? '#f8d5c9' :
                                                                item?.status === 'Paid' || item?.status === 'Invoiced' ? '#d7f4b2' :
                                                                    item?.status === 'Partially Paid' ? '#cfdcf8' :
                                                                        //  item?.status === 'Invoiced' ? '#ce7ac' :
                                                                        item?.status === 'Unpaid' || item?.status === 'Not Invoiced' ? '#f0caee' : '',
                                                        color: item?.status === 'Cancelled' ? '#e51c0e' :
                                                            item?.status === 'Partially Paid' ? '#1650ce' :
                                                                item?.status === 'Not Ordered' ? '#FF6C3C' :
                                                                    item?.status === 'Paid' || item?.status === 'Invoiced' ? '#638D2C' :
                                                                        // item?.status === 'Invoiced' ? '#ce7ac' :
                                                                        item?.status === 'Unpaid' || item?.status === 'Not Invoiced' ? '#e61cdf' : ''
                                                    }}>
                                                        {item?.status}
                                                    </span>
                                                    <h6>{item.customer_currency}{item.total}</h6>
                                                </div>
                                                <div className='commonFlex gridFooter'>

                                                    <div className='invgridicn'>
                                                        {/* <Link to=""><i class="far fa-envelope"></i></Link>&nbsp; */}

                                                        <div className='tdFlex'>
                                                            {/* <span className='viewIcon'><Link to={`/customerdetails`}><i className="fa-light fa-eye"></i></Link></span> */}
                                                            {item?.status == 'Not Ordered' ? <span className='viewIcon orange'>

                                                                <Link to={`/customereditquote?id=${item.id}`} ><i className="fa-light fa-pen"></i></Link></span> : " "}
                                                            {item?.status === 'Cancelled' || item?.status === 'Paid' || item?.status === 'Partially Paid' || item?.status === 'Invoiced' || item?.status === 'Not Invoiced' ? <span className='viewIcon' style={{ borderRight: "0" }} onClick={() => onDelete(item?.id)}><i className="fa-regular fa-trash-can"></i></span> : <span className='viewIcon' style={{ marginRight: "10px" }} onClick={() => onDelete(item?.id)}><i className="fa-regular fa-trash-can"></i></span>}
                                                            {!(item?.status === 'Cancelled' || item?.status === 'Paid' || item?.status === 'Partially Paid' || item?.status === 'Invoiced' || item?.status === 'Not Invoiced') &&
                                                                <Dropdown className='dropRow'>
                                                                    <Dropdown.Toggle id="dropdown-basic">
                                                                        {
                                                                            item?.status == 'Not Ordered' ?
                                                                                <i className="fa-light fa-ellipsis-vertical"></i> : ""}
                                                                    </Dropdown.Toggle>


                                                                    <Dropdown.Menu>
                                                                    <Dropdown.Item onClick={e=>{ navigate(`/viewCustomerquotedetails?id=${item.id}`)}}><Link to={`/viewCustomerquotedetail?id=${item.id}`}><i className="fa-light fa-eye"></i></Link>&nbsp; View Quote Details</Dropdown.Item>
                                                                        {item?.status == 'Not Ordered' ?
                                                                            <Dropdown.Item onClick={() => onCancel(item?.id)}><i className="fa-regular fa-circle-xmark"></i> &nbsp; Cancel</Dropdown.Item> : ''}
                                                                        <Dropdown.Item onClick={() => onResend(item?.id)}><i className="fa-light fa-share"></i> &nbsp; Resend</Dropdown.Item>
                                                                        {item?.status == 'Not Ordered' ? <Dropdown.Item onClick={() => handleClick2(item?.id)} className={usertype == 2 ?(checkModule('Sales','SalesOrders') ? '' :'disabled'):''}><i className="fa-light fa-share"></i> &nbsp; Generate Order</Dropdown.Item> : ''}
                                                                        
                                                                    </Dropdown.Menu>
                                                                </Dropdown>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* <div className='cardBlock actn'>
                                                <div>
                                                    <span className='viewIcon'><Link to={`/customerdetails`}><i class="fa-light fa-eye"></i></Link></span>
                                                    <span className='viewIcon orange'><Link to={`/editcustomer`}><i class="fa-light fa-pen"></i></Link></span>
                                                    <span className='viewIcon'><i class="fa-regular fa-trash-can"></i></span>
                                                </div>
                                            </div> */}
                                            </Card.Body>
                                        </Card>
                                        :  
                                        usertype==2&&item.created_by==userid?
                                        <Card>
                                            <Card.Body>
                                                <div className='commonFlex'>
                                                    <h6>{item.quote_id}</h6>
                                                    <span><img src={cals} alt='' className='mb-2' />&nbsp;&nbsp;{item.date}</span>
                                                </div>
                                                <div className='commonFlex'>
                                                    <span className='customr'>{item?.type == "Company" ? item?.company_name : item?.fname + " " + item?.lname}</span>
                                                    <span>&nbsp;</span>
                                                </div>
                                                <div className='commonFlex'>
                                                    <span className='pending' style={{
                                                        backgroundColor: item?.status === 'Cancelled' ? '#f2b8be' :
                                                            item?.status === 'Not Ordered' ? '#f8d5c9' :
                                                                item?.status === 'Paid' || item?.status === 'Invoiced' ? '#d7f4b2' :
                                                                    item?.status === 'Partially Paid' ? '#cfdcf8' :
                                                                        //  item?.status === 'Invoiced' ? '#ce7ac' :
                                                                        item?.status === 'Unpaid' || item?.status === 'Not Invoiced' ? '#f0caee' : '',
                                                        color: item?.status === 'Cancelled' ? '#e51c0e' :
                                                            item?.status === 'Partially Paid' ? '#1650ce' :
                                                                item?.status === 'Not Ordered' ? '#FF6C3C' :
                                                                    item?.status === 'Paid' || item?.status === 'Invoiced' ? '#638D2C' :
                                                                        // item?.status === 'Invoiced' ? '#ce7ac' :
                                                                        item?.status === 'Unpaid' || item?.status === 'Not Invoiced' ? '#e61cdf' : ''
                                                    }}>
                                                        {item?.status}
                                                    </span>
                                                    <h6>{item.customer_currency}{item.total}</h6>
                                                </div>
                                                <div className='commonFlex gridFooter'>

                                                    <div className='invgridicn'>
                                                        {/* <Link to=""><i class="far fa-envelope"></i></Link>&nbsp; */}

                                                        <div className='tdFlex'>
                                                            {/* <span className='viewIcon'><Link to={`/customerdetails`}><i className="fa-light fa-eye"></i></Link></span> */}
                                                            {item?.status == 'Not Ordered' ? <span className='viewIcon orange'>

                                                                <Link to={`/customereditquote?id=${item.id}`} ><i className="fa-light fa-pen"></i></Link></span> : " "}
                                                            {item?.status === 'Cancelled' || item?.status === 'Paid' || item?.status === 'Partially Paid' || item?.status === 'Invoiced' || item?.status === 'Not Invoiced' ? <span className='viewIcon' style={{ borderRight: "0" }} onClick={() => onDelete(item?.id)}><i className="fa-regular fa-trash-can"></i></span> : <span className='viewIcon' style={{ marginRight: "10px" }} onClick={() => onDelete(item?.id)}><i className="fa-regular fa-trash-can"></i></span>}
                                                            {!(item?.status === 'Cancelled' || item?.status === 'Paid' || item?.status === 'Partially Paid' || item?.status === 'Invoiced' || item?.status === 'Not Invoiced') &&
                                                                <Dropdown className='dropRow'>
                                                                    <Dropdown.Toggle id="dropdown-basic">
                                                                        {
                                                                            item?.status == 'Not Ordered' ?
                                                                                <i className="fa-light fa-ellipsis-vertical"></i> : ""}
                                                                    </Dropdown.Toggle>


                                                                    <Dropdown.Menu>
                                                                    <Dropdown.Item onClick={e=>{ navigate(`/viewCustomerquotedetails?id=${item.id}`)}}><Link to={`/viewCustomerquotedetail?id=${item.id}`}><i className="fa-light fa-eye"></i></Link>&nbsp; View Quote Details</Dropdown.Item>
                                                                        {item?.status == 'Not Ordered' ?
                                                                            <Dropdown.Item onClick={() => onCancel(item?.id)}><i className="fa-regular fa-circle-xmark"></i> &nbsp; Cancel</Dropdown.Item> : ''}
                                                                        <Dropdown.Item onClick={() => onResend(item?.id)}><i className="fa-light fa-share"></i> &nbsp; Resend</Dropdown.Item>
                                                                        {item?.status == 'Not Ordered' ? <Dropdown.Item onClick={() => handleClick2(item?.id)} className={usertype == 2 ?(checkModule('Sales','SalesOrders') ? '' :'disabled'):''}><i className="fa-light fa-share"></i> &nbsp; Generate Order</Dropdown.Item> : ''}
                                                                        
                                                                    </Dropdown.Menu>
                                                                </Dropdown>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* <div className='cardBlock actn'>
                                                <div>
                                                    <span className='viewIcon'><Link to={`/customerdetails`}><i class="fa-light fa-eye"></i></Link></span>
                                                    <span className='viewIcon orange'><Link to={`/editcustomer`}><i class="fa-light fa-pen"></i></Link></span>
                                                    <span className='viewIcon'><i class="fa-regular fa-trash-can"></i></span>
                                                </div>
                                            </div> */}
                                            </Card.Body>
                                        </Card>
                                        :null
                                    ))}
                                </div>
                            }

                        </div>
                    </div>
                </div>
            </Sidebar>



            <Modal size="lg" show={show} onHide={handleClose} className="salesExport">
                <div className='salesExpCol'>
                    <Modal.Header closeButton>
                        {/* <Modal.Title>Modal heading</Modal.Title> */}
                    </Modal.Header>
                    <div className='explft'>
                        <div className='salesModtop'>
                            <div className='salesModLft'>
                                <img src={bclogo} alt='' className='mb-2' />
                                <h5>ABC Company</h5>
                                <small>15 / 12 Lorem ipsum lfjfghyti <br />Ney ypork. Hilton </small>
                            </div>
                            <div className='salesModRgt'>
                                <h5>SALES ORDER</h5>
                                <small>no. ClNV-NZ</small><br />
                                <small>24/11/2023</small><br />
                                <strong>Due on receipt</strong>
                            </div>
                        </div>
                        <div className='bsOrder'>
                            <div className='bsOrderlft'>
                                <h5>Billing address</h5>
                                <h6>Test Company</h6>
                                <small>15 / 12 Lorem ipsum lfjfghyti<br />
                                    Ney ypork. Hilton </small>
                            </div>
                            <div className='bsOrderRgt'>
                                <h5>Shipping address</h5>
                                <h6>Test Company</h6>
                                <small>15 / 12 Lorem ipsum lfjfghyti<br />
                                    Ney ypork. Hilton </small>
                            </div>
                        </div>
                        <div className='bsTables'>
                            <table>
                                <thead>
                                    <tr>
                                        <th>Item name & description</th>
                                        <th>VAT</th>
                                        <th>Qty</th>
                                        <th>Unit Price</th>
                                        <th>Amount</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>
                                            <h6>ABC Product</h6>
                                            em ipsum lfjfghyti
                                        </td>
                                        <td>20%</td>
                                        <td>1</td>
                                        <td>$20.00/price</td>
                                        <td>$20.00</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            &nbsp;
                                        </td>
                                        <td colSpan="3">
                                            <h6>Subtotal</h6>
                                        </td>
                                        <td>$20.00</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            &nbsp;
                                        </td>
                                        <td colSpan="3">
                                            standard rate($20.00)
                                        </td>
                                        <td>$4.00</td>
                                    </tr>
                                </tbody>
                                <tfoot>
                                    <tr>
                                        <td>&nbsp;</td>
                                        <td colSpan="3">Total</td>
                                        <td>$24</td>
                                    </tr>
                                </tfoot>
                            </table>
                        </div>
                    </div>
                    <div className='expRgt'>
                        <button className='commonButton expBtn btnshadow'><i class="fa-regular fa-envelope"></i> Email</button>
                        <button className='commonButton wtBtn btnshadow'><i class="fa-light fa-print"></i> Print</button>
                        <button className='commonButton wtBtn btnshadow'><i class="fa-light fa-down-to-bracket"></i> Download</button>
                    </div>
                </div>
            </Modal>

            <Modal size="lg" show={shows} onHide={handleCloseinv} centered className='customerModal sloModal civModal'>
                <Modal.Header closeButton>
                    <Modal.Title className='px-5'>
                        <h5 className='mb-2'>What is the information to put on the invoices?</h5>
                        <p>Invoice numbers occur in a continuing sequence. What is the last Invoice number that
                            you have sent a customer?</p>
                    </Modal.Title>

                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group className="row mb-3" controlId="exampleForm.ControlInput1">
                            <Col md="6">
                                <span className='unAtr'>*</span>
                                <FloatingLabel
                                    controlId="floatingInput"
                                    label="Invoice Prefix"
                                    className="mb-3"
                                >
                                    <Form.Control type="text" placeholder="Invoice Prefix" />
                                </FloatingLabel>
                            </Col>
                            <Col md="6">
                                <Form.Control type="text" placeholder="001" />
                            </Col>
                        </Form.Group>
                    </Form>


                </Modal.Body>
                <Modal.Footer>
                    <button className='commonButton expBtn' onClick={handleCloseinv}>Next</button>
                </Modal.Footer>
            </Modal>



            <Modal size="md" show={showsvat} onHide={handleClosevat} centered className='customerModal sloModal civModal vatModal'>
                <Modal.Header closeButton>
                    <Modal.Title className='px-5'>
                        <h5 className='mb-2'>Does your company collect VAT?</h5>&nbsp;
                        <div className='radioline yesNo'>
                            <input type="radio" checked id="yes" name="radio-group" onClick={handleToggleOff} />
                            <label for="yes">Yes</label>
                            <input type="radio" id="no" name="radio-group" onClick={handleToggleOn} />
                            <label for="no">No</label>
                        </div>
                    </Modal.Title>

                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group className="row mb-3" controlId="exampleForm.ControlInput1">
                            <Col md="12">
                                <h6>Accounting basis</h6>
                                <div className='radioline invBasis'>
                                    {/* <span>Use same address for delivery</span> */}
                                    <div className='invLft'>
                                        <input type="radio" id="test1" name="radio-group" onClick={handleToggleOff} />
                                        <label for="test1">Cash</label>
                                    </div>
                                    <div className='invrgt'>
                                        <input type="radio" id="test2" name="radio-group" onClick={handleToggleOn} />
                                        <label for="test2">Accural</label>
                                    </div>
                                </div>
                            </Col>

                        </Form.Group>
                        <Form.Group className="row mb-3" controlId="exampleForm.ControlInput1">
                            <Col md="6">
                                <label>VAT registration number</label>
                                <Form.Control type="text" placeholder="Enter VAT registration number" />
                            </Col>
                        </Form.Group>
                    </Form>


                </Modal.Body>
                <Modal.Footer>
                    <button className='backBtn' onClick={handleShowinv}><i class="fa-light fa-circle-arrow-left"></i> Back</button>
                    <button className='commonButton expBtn' onClick={handleClosevat}><Link to="/newinvoicecreation">Next</Link></button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default Quote