import React, { useState, useEffect } from 'react';
import Sidebar from '../../layout/Sidebar'
import Dbheader from '../../layout/Dbheader'
import Form from 'react-bootstrap/Form';
import Headtxt from '../Headtxt';
import Col from 'react-bootstrap/Col';
import { useParams, useSearchParams, useLocation } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import { getEditCustomer, getCustomerListById } from "../../../../Service/Customer/CustomerService";
import { PhoneInput } from 'react-international-phone';
import { City, Country, State } from "country-state-city";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import CurrencySelect from 'react-select-currency';
const EditCustomer = ({setIsSpinner}) => {
    const navigate = useNavigate();

    const location = useLocation();
    const customerId = new URLSearchParams(location.search);
    console.log(customerId.get(`id`), "customerId")
    const [cityData, setCityData] = useState([]);
    const [country, setCountry] = useState("");
    const [countrycode, setCountryCode] = useState("");
    const [state, setState] = useState('');
    const [citycode, setCityCode] = useState("");
    const [city, setCity] = useState("");
    const [stateData, setStateData] = useState([]);
    const [deliveryState, setDeliveryState] = useState("");
    const [deliveryCity, setDeliveryCity] = useState("");
    const [selectedCurrency, setSelectedCurrency] = useState('KES');
    const [show, setShow] = useState(false);
    const [getCustomerId, setCustomerId] = useState([]);
    const [phone, setPhone] = useState("");
    const [deliveryStateData, setDeliveryStateData] = useState([]);
    const [deliveryCityData, setDeliveryCityData] = useState([]);
    const [DeliveryCountry, setDeliveryCountry] = useState("");
    const [deliverycountrycode, setDeliveryCountryCode] = useState("");
    const [deliverystatecode, setDeliveryStateCode] = useState("");
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [companyType, setCompanyType] = useState("");

    // For toggle
    const [isToggled, setToggled] = useState(false);
    const [allData, setAllData] = useState(
        {
            fname: '',
            lname: '',
            companyname: '',
            accountingKey: '',
            industry: '',
            industryId: '',
            country: '',
            state: '',
            city: '',
            zipcode: '',
            address: '',

        }
    )

    let countryData = Country.getAllCountries();
    let deliveryCountryData = Country.getAllCountries();


    const handleToggleOn = () => {
        setToggled(true);
    };
    const handleToggleOff = () => {
        setToggled(false);
       
    };


    // For drag-drop
    const [file, setFile] = useState(null);

    const handleDrop = (event) => {
        event.preventDefault();

        const droppedFile = event.dataTransfer.files[0];
        handleFile(droppedFile);
    };

    const handleFile = (selectedFile) => {
        setFile(selectedFile);

        // You can perform additional actions with the file, such as uploading it to a server.
        // For simplicity, we're just logging the file information here.
        console.log('File selected:', selectedFile);
    };

    const handleDragOver = (event) => {
        event.preventDefault();
    };


    useEffect(() => {
        getallCustomerList()
    }, [])

    async function getallCustomerList() {
        getCustomerListById(customerId.get(`id`)).then(function (result) {
            const response = result.data;
            console.log(response?.data?.country, "response")
            setAllData(response?.data)
            setToggled(response?.data?.billing_address === response?.data?.delivery_address ? false : true)
            setStateData(State.getStatesOfCountry(response?.data?.country_code))
            setCountryCode(response?.data?.country_code);
            setCityData(City.getCitiesOfCountry(response?.data?.country_code,response?.data?.state_code))
            setCityCode(response?.data?.state_code)
            setDeliveryStateData(State.getStatesOfCountry(response?.data?.delivery_country_code));
            setDeliveryCountryCode(response?.data?.delivery_country_code);
            setDeliveryCityData(City.getCitiesOfCountry(response?.data?.delivery_country_code,response?.data?.delivery_state_code));
            setDeliveryStateCode(response?.data?.delivery_state_code)
        //    setCityData(City.getCitiesOfState(response?.data?.country_code, e.target.value));
            // setCustomerId(response?.data);

            // setDepartmentId(response?.data?.businessUnit?.Departments);
        });
    }
    console.log('statedata',cityData,citycode)
    //edit part
    const onfName = (e) => {
        setAllData({ ...allData, fname: e.target.value })
    }
    const onlName = (e) => {
        setAllData({ ...allData, lname: e.target.value })
    }

    const onCompanyName = (e) => {
        setAllData({ ...allData, company_name: e.target.value })
    }
    const onEmail = (e) => {
        setAllData({ ...allData, email: e.target.value })
    }
    const onPhone = (e) => {
        setAllData({ ...allData, phone: e.target.value })
    }

    const onWebUrl = (e) => {
        setAllData({ ...allData, website_url: e.target.value })
    }

    const onVat = (e) => {
        setAllData({ ...allData, vat_number: e.target.value })
    }
    const onAddress = (e) => {
        setAllData({ ...allData, billing_address: e.target.value })
    }
    const onZipCode = (e) => {
        setAllData({ ...allData, zipcode: e.target.value })
    }

    const onDeliveryAddress = (e) => {
        setAllData({ ...allData, delivery_address: e.target.value })
    }

    const onDeliveryZipCode = (e) => {
        setAllData({ ...allData, delivery_zipcode: e.target.value })
    }
    console.log(allData, "Alldata")

    const onCountry = (e) => {
        // setAllData({ ...allData, lastname: e.target.value })
        console.log('e.target.value',e.target.value)
        setCountry(e.target.value)
        setCountryCode(e.target.value)
        setStateData(State.getStatesOfCountry(e.target.value));
    }

    const onState = (e) => {
        console.log('e.target.value',e.target.value,countrycode)
        setState(e.target.value);
        setCityCode(e.target.value)
        setCityData(City.getCitiesOfState(countrycode, e.target.value));
    }

    const onCity = (e) => {
        setCity(e.target.value)
    }

    const onDeliveryCountry = (e) => {
        console.log(e.target.value, "cdscds");
        setDeliveryCountry(e.target.value)
        setDeliveryCountryCode(e.target.value)
        setDeliveryStateData(State.getStatesOfCountry(e.target.value));
    }

    const onDeliveryState = (e) => {
        console.log(e.target.value, "stateiso")
        setDeliveryState(e.target.value);
        setDeliveryCityData(City.getCitiesOfState(deliverycountrycode, e.target.value));
    }

    const onDeliveryCity = (e) => {
        setDeliveryCity(e.target.value)
    }

    const onCompanyType = (e) => {
        setCompanyType(e.target.value)
    }
console.log('alldata',allData)


    const onSave = () => {
       
        const delivery_address = isToggled ? allData?.delivery_address : "";
        const delivery_country = isToggled ? deliveryCountryData.find(c => c.isoCode === DeliveryCountry)?.name || allData?.delivery_country : "";
        const delivery_state = isToggled ? deliveryStateData.find(c => c.isoCode === deliveryState)?.name || allData?.delivery_state : "";
        const delivery_city = isToggled ? deliveryCity || allData?.delivery_city : "";
        const delivery_zipcode = isToggled ?allData?.delivery_zipcode : "";
        if ( allData?.type === "Company" && allData?.company_name === null||allData?.company_name === '') {
            toast.error("Please type company name")

        }
        else if ( allData?.type === "Individual" && allData?.fname === "") {
            toast.error("Please type first name")

        } else if ( allData?.type === "Individual" && allData?.lname === "") {
            toast.error("Please type last name")

        } else if (allData?.email === "") {
            toast.error("Please type valid email")
        } else if (allData?.phone === "" || phone.length < 7) {
            toast.error("Please type phone number")
        } 
        // else if ( allData?.type === "Company" && allData?.vat_number <= 0) {
        //     toast.error("Please type a valid VAT Number(Optional)")
        // }
        else if (allData?.billing_address === "") {
            toast.error("Please type billing address")
        }
        else if (allData?.country === "") {
            toast.error("Please select country")

        }
        //  else if (allData?.state === "") {
        //     toast.error("Please select state")
        // } 
        // else if (allData?.city === "") {
        //     toast.error("Please select city")
        // }
        //   else if (selectedCurrency === "" ||selectedCurrency === null ||selectedCurrency === undefined) {
        //     toast.error("Please select currency");
        // } 
        //   // else if (zipCode === "") {
        //   //   toast.error("Please type zipcode")
        //   // }
          else if(isToggled==='')
            {
              toast.error("Please choose address for delivery")
            }
      else if(isToggled &&delivery_address==='')
      {
        toast.error("Please type delivery address")
      }
      
      else if(isToggled &&delivery_country==='')
        {
          toast.error("Please type delivery country")
        }
        else if(isToggled &&delivery_state==='')
          {
            toast.error("Please type delivery state")
          }
          else if(isToggled &&delivery_city==='')
            {
              toast.error("Please type delivery city")
            }
            else if(isToggled &&delivery_zipcode==='')
              {
                toast.error("Please type delivery zipcode")
              }
          else {
            setIsSpinner(true)
        getEditCustomer(companyType ? companyType : allData?.type, allData?.company_name, allData?.fname, allData?.lname, allData?.email, phone ? phone : allData?.phone, allData?.website_url
            , allData?.vat_number, allData?.billing_address,
            countryData.find(c => c.isoCode === country)?.name ? countryData.find(c => c.isoCode === country)?.name : allData?.country,
            stateData.find(c => c.isoCode === state)?.name ? stateData.find(c => c.isoCode === state)?.name : allData?.state
            , city ? city : allData?.city, allData?.zipcode,
            delivery_address,
      delivery_country, 
      delivery_state,
      delivery_city,
      delivery_zipcode,customerId.get(`id`),countrycode,isToggled===''?deliverycountrycode:countrycode,citycode,isToggled===''?deliverystatecode:citycode,
            (r) => {
                console.log(r, "response")
                // if (r.success === true) {
                //     toast.success(r.message)
                //     navigate("/customerlisting")
                // }
                setIsSpinner(false)
                if (r.success === true) {
                    Swal.fire({
                      title: r.message,
                    //  text: 'The product has been added successfully!',
                      icon: 'success',
                      confirmButtonText: 'OK',
                      customClass: {
                          confirmButton: 'my-confirm-button'
                      }
                  }).then(() => {
                      navigate("/customerlisting");
                  })
                }
                else {
                    console.log("nononononono")
                    toast.error(r.message)
                    setIsSpinner(false);
                }
            })}

    }

    return (
        <div className="customerListing pdt">
            <Dbheader />
            <Sidebar>
                <div className='customerMain'>
                    <div className='custBtm'>
                        <div className='dbTopsec pb-3'>
                            <Headtxt link="/customerlisting" title="Edit Customer" />
                            {/* <div className='custButton'>
                <button className='commonButton expBtn' onClick={handleShow}><i class="fa-light fa-upload"></i> Import</button>
              </div> */}
                        </div>

                        <div className='commonWhite addCustomerForm p-3 mb-3'>

                            <Form>
                                <Form.Group className="row mb-3" controlId="exampleForm.ControlInput1">
                                    <Col md="4">
                                        <Form.Select aria-label="Default select example" onChange={onCompanyType}>
                                            <option selected hidden>{allData?.type}</option>
                                            <option value="Company">Company</option>
                                            <option value="Individual">Individual</option>
                                            {/* <option value="1">One</option>
                      <option value="2">Two</option>
                      <option value="3">Three</option> */}
                                        </Form.Select>
                                    </Col>
                                    {companyType === "" && allData?.type === "Individual" || companyType === "Individual" ? <><Col md="4">
                                        <Form.Control type="text" name='fname' placeholder="First Name" value={allData?.fname} onChange={onfName} />
                                    </Col>
                                        <Col md="4">
                                            <Form.Control type="text" name='lname' placeholder="Last Name" onChange={onlName} value={allData?.lname} />
                                        </Col></>
                                        : <Col md="8">
                                            <Form.Control type="text" name='companyname' placeholder="Company Name" onChange={onCompanyName} value={allData?.company_name} />
                                        </Col>
                                    }
                                </Form.Group>

                                <Form.Group className="row mb-3" controlId="exampleForm.ControlInput1">
                                    <Col md="6">
                                        <Form.Control type="email" placeholder="Email address" value={allData?.email} onChange={onEmail} readOnly />
                                    </Col>
                                    <Col md="6">
                                        {/* <Form.Control type="text" placeholder="Phone Number" value={allData?.phone} onChange={onPhone} /> */}
                                        <PhoneInput
                                            defaultCountry="ke"
                                            value={allData?.phone}
                                            className='phInp'
                                            onChange={(phone) => setPhone(phone)}
                                        />
                                    </Col>
                                </Form.Group>

                                <Form.Group className="row mb-3" controlId="exampleForm.ControlInput1">
                                    <Col md="6">
                                        <Form.Control type="text" placeholder="Website URL (Optional)" value={allData?.website_url} onChange={onWebUrl} />
                                    </Col>
                                    <Col md="6">
                                        <Form.Control type="text" placeholder="VAT Number(Optional)" value={allData?.vat_number} onChange={onVat} />
                                    </Col>
                                </Form.Group>

                                <Form.Group className="row mb-3" controlId="exampleForm.ControlInput1">
                                    <Col md="6">
                                        <Form.Control type="text" placeholder="Billing Address" value={allData?.billing_address} onChange={onAddress} />
                                    </Col>
                                    <Col md="6">
                                        <Form.Select aria-label="Default select example" onChange={onCountry}>
                                            <option selected hidden>{country ? country : allData?.country}</option>
                                            {countryData.map((con) => (
                                                <option value={con.isoCode}>{con.name}</option>

                                            ))}

                                        </Form.Select>
                                    </Col>
                                </Form.Group>

                                <Form.Group className="row mb-3" controlId="exampleForm.ControlInput1">
                                    <Col md="4">
                                        <Form.Select aria-label="Default select example" onChange={onState}>
                                            <option selected hidden>{state ? state : allData?.state?allData?.state:'Select State'}</option>
                                            {stateData.map((sta) => (
                                                <option value={sta.isoCode}>{sta.name}</option>

                                            ))}

                                        </Form.Select>
                                    </Col>
                                    <Col md="4">
                                        <Form.Select aria-label="Default select example" onChange={onCity}>
                                            <option selected hidden>{city ? city : allData?.city?allData?.city:'Select City'}</option>
                                            {cityData.map((cit) => (
                                                <option value={cit.name}>{cit.name}</option>

                                            ))}

                                        </Form.Select>
                                    </Col>
                                    <Col md="4">
                                        <Form.Control type="text" placeholder="Zip Code(Optional)" value={allData?.zipcode} onChange={onZipCode} />
                                    </Col>
                                </Form.Group>
                                {/* <Col md="6" className='mb-3' disabled>
        <CurrencySelect
      value={allData?.currency}
     // onChange={handleCurrencyChange}
      placeholder="Select a currency"      
      className="slctCurrency"
      style={{width:'200%'}}
      disabled
    />
    {/* </div> 
    </Col> */}

                                <div className='radioline'><span>Use same address for delivery</span>
                                   {!isToggled?
                                    <>
                                        <div>
                                            <input type="radio" id="test1" name="radio-group" checked onClick={handleToggleOff} />
                                            <label  for="test1">Yes</label>
                                        </div>
                                        <div>
                                            <input type="radio" id="test2" name="radio-group" onClick={handleToggleOn} />
                                            <label  for="test2">No</label>
                                        </div>
                                    </>:
                                      <>
                                      <div>
                                          <input type="radio" id="test1" name="radio-group"  onClick={handleToggleOff} />
                                          <label  for="test1">Yes</label>
                                      </div>
                                      <div>
                                          <input type="radio" id="test2" name="radio-group" checked onClick={handleToggleOn} />
                                          <label  for="test2">No</label>
                                      </div>
                                  </>}
                                </div>

                                <div className='pt-3'>
                                    {isToggled &&
                                        <div>
                                            <Form.Group className="row mb-3" controlId="exampleForm.ControlInput1">
                                                <Col md="6">
                                                    <Form.Control type="text" placeholder="Delivery Address" value={allData?.delivery_address} onChange={onDeliveryAddress} />
                                                </Col>
                                                <Col md="6">
                                                    <Form.Select aria-label="Default select example" onChange={onDeliveryCountry}>
                                                        <option selected hidden>{DeliveryCountry ? DeliveryCountry : allData?.delivery_country ||'Select country'}</option>
                                                        {deliveryCountryData.map((con) => (
                                                            <option value={con.isoCode}>{con.name}</option>

                                                        ))}

                                                    </Form.Select>
                                                </Col>
                                            </Form.Group>

                                            <Form.Group className="row mb-3" controlId="exampleForm.ControlInput1">
                                                <Col md="4">
                                                    <Form.Select aria-label="Default select example" onChange={onDeliveryState}>
                                                        <option selected hidden>{deliveryState ? deliveryState : allData?.delivery_state ||'Select state'}</option>

                                                        {deliveryStateData.map((sta) => (
                                                            <option value={sta.isoCode}>{sta.name}</option>

                                                        ))}

                                                    </Form.Select>
                                                </Col>
                                                <Col md="4">
                                                    <Form.Select aria-label="Default select example" onChange={onDeliveryCity}>
                                                        <option selected hidden>{deliveryCity ? deliveryCity : allData?.delivery_city||'Select city'}</option>

                                                        {deliveryCityData.map((cit) => (
                                                            <option value={cit.name}>{cit.name}</option>

                                                        ))}

                                                    </Form.Select>
                                                </Col>
                                                <Col md="4">
                                                    <Form.Control type="text" placeholder="Zip Code(Optional)" value={allData?.delivery_zipcode} onChange={onDeliveryZipCode} />
                                                </Col>
                                            </Form.Group>
                                        </div>
                                    }
                                </div>


                            </Form>
                        </div>
                        <button className='commonButton expBtn' onClick={onSave}>Save</button>



                    </div>
                </div>
            </Sidebar>

            <Modal show={show} onHide={handleClose} centered className='customerModal'>
                <Modal.Header closeButton>
                    <Modal.Title><h5>Import Products</h5></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='modalContent'>
                        <div
                            onDrop={handleDrop}
                            onDragOver={handleDragOver}
                            className='drgdrop'
                        >
                            <i class="fa-light fa-cloud-arrow-up"></i>
                            <div style={{ display: "flex" }}>
                                <p>Drag &amp; drop files or </p>
                                <input type="file" onChange={(e) => handleFile(e.target.files[0])} style={{ display: 'none' }} />
                                <button onClick={() => document.querySelector('input[type="file"]').click()}>Browse</button>
                            </div>

                            {file && <p>Selected File: {file.name}</p>}
                        </div>
                    </div>
                    <div>
                        <p><button className='commonButton modalsubmit'>Submit</button></p>
                        <p><button className='commonButton downloadformat'>Download format</button></p>
                    </div>
                </Modal.Body>
                {/* <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleClose}>
            Save Changes
          </Button>
        </Modal.Footer> */}
            </Modal>

        </div>
    )
}

export default EditCustomer