import React, { useState, useEffect } from 'react';
import Sidebar from '../../layout/Sidebar'
import Dbheader from '../../layout/Dbheader'
import { Link } from 'react-router-dom'
import Form from 'react-bootstrap/Form';
import Gridview from './Gridview';
import Listview from './Listview';
import Headtxt from '../Headtxt';
import { Button, Dropdown, Modal } from 'react-bootstrap';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { deleteCustomer, getCustomerList, getSearchCustomer, getExcelCustomer } from "../../../../Service/Customer/CustomerService";
import Card from 'react-bootstrap/Card'
import { useNavigate } from 'react-router-dom';
import { CSVLink } from "react-csv";
import '../../../../assets/css/style.css';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Swal from 'sweetalert2';
import { deleteUser, getActivityLogs, getUserList } from '../../../../Service/User/UserService';
import moment from 'moment';
const Logslisting = ({setIsSpinner}) => {

    const [selectedPage, setSelectedPage] = useState('/');
    const navigate = useNavigate();

    const handlePageChange = (page) => {
        setSelectedPage(page);
        navigate(page);
    };



    const [view, setView] = useState(false)
    const [querySearch, setQuerySearch] = useState('');
      const [fromDate, setFromDate] = useState('');
        const [toDate, setToDate] = useState('');
    const [hasMore, setHasMore] = useState(true);
    const [loading, setLoading] = useState(true);
    const [items, setItems] = useState(['Item 1', 'Item 2', 'Item 3', 'Item 4', 'Item 5', 'Item 6', 'Item 7', 'Item 8', 'Item 9', 'Item 10', 'Item 11', 'Item 12', 'Item 13', 'Item 14', 'Item 15']);
    const [visibleItems, setVisibleItems] = useState(5);
    const onSearch = (e) => {
        const query = e.target.value;
        setQuerySearch(query);
      triggerSearch(query, fromDate, toDate);
    };
    const fetchMoreItems = (start) => {
        // Simulate fetching more items from an API
        const itemsPerPage = 5;
        const totalItems = 20; // Adjust as needed for your use case
        const newItems = [];

        for (let i = start; i < start + itemsPerPage; i++) {
            if (i < totalItems) {
                newItems.push(`Item ${i + 1}`);
            }
        }

        return newItems;
    };
    useEffect(() => {
        // Initial load of items
        loadMoreItems();
    }, []);

    const loadMoreItems = () => {
        setLoading(true);
        setTimeout(() => {
            // Simulate loading delay
            setVisibleItems((prev) => prev + 5);
            setLoading(false);
        }, 1000); // Adjust the delay as needed
    };
    const toggleHandler = (e) => {
        e.preventDefault();
        setView(true)
    }
    const toggleHandler1 = (e) => {
        e.preventDefault();
        setView(false)
    }
    const [userList, setAllUserList] = useState([]);
    const [deleteCustomerId, setDeleteCustomerId] = useState("");
    const [showDelete, setShowDelete] = useState(false);
    const [customerExport, setCustomerExport] = useState([]);
    const [showBlock, setShowBlock] = useState(false);
    const [activitylogs, setActivityLogs] = useState([]);
    const handleCloseDelete = () => setShowDelete(false);
    const handleCloseBlock = () => setShowBlock(false);
    console.log(userList, "customerList")
    useEffect(() => {
        getCustomerDataList();
        onExcelExport();
        getLoglist();
    }, []);

    async function getCustomerDataList() {
        getUserList().then(function (result) {
            const response = result?.data;
            // console.log(response, "rrrr")
            setAllUserList(response?.data);
          
        });
    }
    async function getLoglist() {
        setIsSpinner(true)
        getActivityLogs().then(function (result) {
            const response = result?.data;
           console.log(response, "rrrr")
           setActivityLogs(response?.data);
           setIsSpinner(false)
        });
    }
    const onDelete = (data) => {
        setDeleteCustomerId(data);
        console.log(data, "datadatadatadatadatadata")
        setShowDelete(true)
    }
    const onBlock = (data) => {
        setDeleteCustomerId(data);
        console.log(data, "datadatadatadatadatadata")
        setShowBlock(true)
    }
    const [show, setShow] = useState(false);
    const [selectedModules, setSelectedModules] = useState(null);
  
    const handleClose = () => setShow(false);
    const handleShow = (modulesData) => {
        setSelectedModules(JSON.parse(modulesData).modules);
        setShow(true);
      };

    const onYes = () => {
        let data = {
            id:deleteCustomerId,
            status: 'Delete',
          }
          setIsSpinner(true)
        deleteUser(data, (res) => {
            console.log(res, "response")
            // if (res.success === true) {
            //     toast.success(res.message)
            //     setShowDelete(false)
            //     getCustomerDataList();

            // } 
            if (res.success === true) {
                setIsSpinner(false)
                Swal.fire({
                    title: res.message,
                    //  text: 'The product has been added successfully!',
                    icon: 'success',
                    confirmButtonText: 'OK',
                    customClass: {
                        confirmButton: 'my-confirm-button'
                    }
                }).then(() => {
                    setIsSpinner(false)
                    setShowDelete(false)
                    getCustomerDataList();
                })
            }
            else {
                setIsSpinner(false)
                toast.error(res.message)

            }
        })
    }

    const onNo = () => {
        setShowDelete(false)

    }
    const onYesBlock = () => {
        let data = {
            id:deleteCustomerId,
            status: 'Block',
          }
          setIsSpinner(true)
        deleteUser(data, (res) => {
            console.log(res, "response")
            // if (res.success === true) {
            //     toast.success(res.message)
            //     setShowDelete(false)
            //     getCustomerDataList();

            // } 
            if (res.success === true) {
                setIsSpinner(false)
                Swal.fire({
                    title: res.message,
                    //  text: 'The product has been added successfully!',
                    icon: 'success',
                    confirmButtonText: 'OK',
                    customClass: {
                        confirmButton: 'my-confirm-button'
                    }
                }).then(() => {
                    setShowBlock(false)
                    getCustomerDataList();
                    setIsSpinner(false)
                })
            }
            else {
                setIsSpinner(false)
                toast.error(res.message)

            }
        })
    }

    const onNoBlock = () => {
        setShowBlock(false)

    }
    const convertToCSV = (data) => {
        // const header = Object.keys(data[0]).join(',');
        // const rows = data.map((row) => Object.values(row).join(','));
        return [data].join('\n');
    };

    const onExcelExport = () => {
        getExcelCustomer().then(function (result) {
            const response = result?.data?.data;
            console.log(response, "response")
            setCustomerExport(response);
        });

    }
    // custom-select-drupdown
    const [isOpen, setIsOpen] = useState(false);
    const [selectedOption, setSelectedOption] = useState('More');
    const handleFromDateChange = (e) => {
        const value = e;
        setFromDate(value);
        triggerSearch(querySearch, e, toDate);
    };

    const handleToDateChange = (e) => {
        const value = e;
        setToDate(value);
        triggerSearch(querySearch, fromDate, e);
    };
  const getSearchList = () => {
        // Construct the filter criteria based on fromDate and toDate
        const filterCriteria = {
            fromDate: fromDate ? moment(fromDate).format('DD-MM-YYYY') : '',
            toDate: toDate ? moment(toDate).format('DD-MM-YYYY') : ''
        };
        console.log('filter---', filterCriteria);
        const queryString = new URLSearchParams(filterCriteria).toString();
        const finalQueryString = querySearch ? `searchText=${querySearch}&${queryString}` : `searchText=&${queryString}`;
        getActivityLogs(finalQueryString).then(function (result) {
            const response = result?.data;
            console.log('resultttt---', response);
            setActivityLogs(response?.data);
        });
    };
    useEffect(() => {
        getSearchList();
    }, [fromDate, toDate]);

    const triggerSearch = (query, from, to) => {
        const filterCriteria = {
            //  query: query || '',
            fromDate: from ? moment(from).format('DD-MM-YYYY') : '',
            toDate: to ? moment(to).format('DD-MM-YYYY') : ''
        };
        const queryString = new URLSearchParams(filterCriteria).toString();
        const finalQueryString = query ? `searchText=${query}&${queryString}` : `searchText=&${queryString}`;

        console.log(finalQueryString, 'finalQueryString');
        getActivityLogs(finalQueryString).then(function (result) {
            const response = result?.data;
            console.log(response, "rrrr")
            setActivityLogs(response?.data);
        });
    };

    return (
        <div className="customerListing pdt">
            <Dbheader />
            <Sidebar>
                <div className='customerMain'>

                    <div className='custBtm'>
                        <div className='dbTopsec pb-2 noBackbtn'>
                            <Headtxt link="" title="Activity Logs" />
                        </div>
                        <div className='customerSrchTop pb-3'>
                            <div className='customSrchfield'>
                                <div>
                                <span><i class="fa-solid fa-magnifying-glass"></i></span><input type='search' placeholder='Search Name' onChange={onSearch} value={querySearch}/>
                                </div>&nbsp;&nbsp;
                            <div className='invDatepicker'>
                                    <DatePicker
                                        selected={fromDate}
                                        onChange={handleFromDateChange}
                                        selectsStart
                                        startDate={fromDate}
                                        endDate={toDate}
                                        placeholderText="From Date"
                                        isClearable={fromDate ? true : false}
                                    />
                                </div>
                                <div className='invDatepicker' style={{ marginLeft: '10px' }}>
                                    <DatePicker
                                        selected={toDate}
                                        onChange={handleToDateChange}
                                        selectsEnd
                                        startDate={fromDate}
                                        endDate={toDate}
                                        placeholderText="To Date"
                                        isClearable={toDate ? true : false}
                                        minDate={fromDate}

                                    />
                                </div>
                                </div>
                           <div></div>
                            
                        </div>

                        <div className='mt-2'>
                            <div className='gridList mb-3 text-end'>
                                <button size="100%" style={{ backgroundColor: "#DCE5EC" }} onClick={toggleHandler1} className={!view ? "listactive" : ""}><i class="fa-solid fa-list"></i></button>
                                <button size="100%" style={{ backgroundColor: "#DCE5EC" }} onClick={toggleHandler} className={view ? "gridactive" : ""}><i class="fa-solid fa-grid"></i></button>
                            </div>
                            {!view ?

                                <div className='listViews'>
                                    <table class="table">
                                        <thead>
                                            <tr>
                                                <th scope="col">Name</th>
                                                <th scope="col">Date & Time</th>
                                                <th scope="col">Description</th>
                                              
                                            </tr>
                                        </thead>
                                        <tbody>
  {activitylogs?.map((d, index) => {
    const details = d?.details ? JSON.parse(d.details) : {};
    const isSalesQuote = details?.type === 'salesQuote';
    const isProduct = details?.type === 'product';
    const isService = details?.type === 'service';
    const isInvoice = details?.type === 'invoice'||details?.type ==='invoiceReturn';
    const isSalesOrder = details?.type === 'salesOrder';
    const isCustomer = details?.type === 'customer';
    const isSupplier = details?.type === 'supplier';
    const isQuote = details?.type === 'quote';
    const isPurchaseOrder = details?.type === 'purchaseOrder';
    const isBill = details?.type === 'bill'||details?.type === 'billReturn';
    const isAccount = details?.type === 'account';
    const isSaleReceipt = details?.type === 'paymentInvoice';
    const isBillPayment = details?.type === 'paymentBill';
    const linkUrl = isSalesQuote
      ? `/viewCustomerquotedetails?id=${details?.id}`
      : isProduct
      ? `/productdetails?id=${details?.id}`
      :isService
      ?`/servicedetails?id=${details?.id}`
      :isInvoice?
      `/viewinvoicedetails?id=${details?.id}`
      :isCustomer?`/customerdetails?id=${details?.id}`
      :isSalesOrder?`/viewsalesorder?id=${details?.id}`
      :isSupplier?`/supplierdetails?id=${details?.id}`
      :isQuote?`/quotedetails?id=${details?.id}`
      :isPurchaseOrder?`/purchaseorderdetails?id=${details?.id}`
      :isBill?`/billdetails?id=${details?.id}`
      :isAccount?`/accounting?id=${details?.id}`
      :isBillPayment?`/viewbillpaymentdetails?id=${details?.id}`
      :isSaleReceipt?`/viewsalesreceiptdetails?id=${details?.id}`
      : '#';

   // const isClickable = isSalesQuote || isProduct||isService;

    return (
      <tr key={index}>
        <td style={{ maxWidth: '150px', wordBreak: 'break-word' }}>
          {d?.fname || ''} {d?.lname || ''}
        </td>
        <td style={{ maxWidth: '150px', wordBreak: 'break-word' }}>
          {d?.date_time || 'N/A'}
        </td>
        <td>
          <Link
            to={linkUrl}
            // style={{
            //   pointerEvents: isClickable ? 'auto' : 'none',
            //   color: isClickable ? 'blue' : 'grey',
            // }}
          >
            {d?.description || 'No description available'}
          </Link>
        </td>
      </tr>
    );
  })}


                                     </tbody>
                                    </table>

                                    {loading &&
                                        <div className='loadItems'>
                                            <button className="commonButton addBtn">
                                                Loading...
                                            </button>
                                        </div>
                                    }
                                    {!loading && (
                                        <div className='loadItems'>
                                            <button onClick={loadMoreItems} className="commonButton addBtn">
                                                No More Logs
                                            </button>
                                        </div>
                                    )}
                                    <Modal size="sm" show={showDelete} onHide={handleCloseDelete} className='delModal'>
                                        <Modal.Header closeButton>
                                            <Modal.Title><h5>Are you sure to delete ?</h5></Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body>

                                            <button variant="primary" className='commonButton expBtn' onClick={onYes} >
                                                Yes
                                            </button>
                                            &nbsp;
                                            &nbsp;
                                            &nbsp;
                                            &nbsp;
                                            <button variant="primary" className='commonButton expBtn' onClick={onNo}>
                                                No
                                            </button>
                                        </Modal.Body>
                                    </Modal>
                                </div>


                                :

                                <div className='gridView'>
                                    {/* {customerList?.map((d) => ( */}
                                    {activitylogs.map((d) => (
                                    <Card>
                                        <Card.Body>
                                       
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <div className='cardBlock'>
                                                        <h6>User Name</h6>
                                                        {d?.fname} {d?.lname}
                                                    </div>
                                                </div>
                                            </div><div className="row">
                                                    <div className="col-md-6">
                                                        <div className='cardBlock'>
                                                            <h6>Date & Time</h6>
                                                            {d?.date_time}
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className='cardBlock'>
                                                            <h6>Description</h6>
                                                            {d?.description}
                                                        </div>
                                                    </div>
                                                </div>
                                      
                                            
                                        </Card.Body>
                                    </Card>
                                ))}
                                    {/* ===========================delete modal==================================== */}
                                    <Modal size="sm" show={showDelete} onHide={handleCloseDelete} className='delModal'>
                                        <Modal.Header closeButton>
                                            <Modal.Title><h5>Are you sure to delete ?</h5></Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body>

                                            <button variant="primary" className='commonButton expBtn' onClick={onYes} >
                                                Yes
                                            </button>
                                            &nbsp;
                                            &nbsp;
                                            &nbsp;
                                            &nbsp;
                                            <button variant="primary" className='commonButton expBtn' onClick={onNo}>
                                                No
                                            </button>
                                        </Modal.Body>
                                    </Modal>

                                    
                                </div>


                            }

                        </div>

                    </div>
                </div>
            </Sidebar>
             {/* ===========================block modal==================================== */}
             <Modal size="sm" show={showBlock} onHide={handleCloseBlock} className='delModal'>
                                        <Modal.Header closeButton>
                                            <Modal.Title><h5>Are you sure to Block ?</h5></Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body>

                                            <button variant="primary" className='commonButton expBtn' onClick={onYesBlock} >
                                                Yes
                                            </button>
                                            &nbsp;
                                            &nbsp;
                                            &nbsp;
                                            &nbsp;
                                            <button variant="primary" className='commonButton expBtn' onClick={onNoBlock}>
                                                No
                                            </button>
                                        </Modal.Body>
                                    </Modal>
            {/* ====================Assigned Modules ========================*/}
            <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Assigned Modules</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {selectedModules ? (
            <Form>
              {Object.entries(selectedModules).map(([moduleName, subModules]) => (
                <div key={moduleName} style={{ marginBottom: '10px' }}>
                  <Form.Check
                    type="checkbox"
                    label={`${moduleName} Module`}
                    checked
                    disabled
                  />
                  <div style={{ marginLeft: '20px' }}>
                    {Object.entries(subModules).map(([subModuleName, isChecked]) => (
                      <Form.Check
                        key={subModuleName}
                        type="checkbox"
                        label={subModuleName.replace(/_/g, ' ')}
                        checked={isChecked}
                        disabled
                      />
                    ))}
                  </div>
                </div>
              ))}
            </Form>
          ) : (
            <p>No modules assigned.</p>
          )}
        </Modal.Body>
      </Modal>
        </div>
    )
}

export default Logslisting