import webApi, { baseURL } from "../../WebApi/WebApi";



export const getAddUser = async (tax, onSuccess, onFailure) => {
console.log(tax,"tatataa")
    try {
        const res = await webApi.post(
            `/subuser-store`, 
            tax
        );
        if (res.status === 200) {
            const r = res.data;
            console.log(r, "rerere")
            return onSuccess(r);
        } else {
            onFailure("Something Wrong! Please Try again later" + res.data);
        }
    } catch (error) {
        console.log("fdgdf")
    }
};

export const getUserList = async (data) => {
    return await webApi.get(`/subuser-list`, {

    });
}
export const deleteUser = async (data, onSuccess, onFailure) => {
    try {
        const res = await webApi.post(
            `/subuser-data-update`,data
        );
        if (res.status === 200) {
            const r = res.data;
            return onSuccess(r);
        } else {
            onFailure("Something Wrong! Please Try again later" + res.data);
        }
    } catch (error) {
        console.log("fdgdf")
    }
};
// export const getUserdata = async (supplierId, onSuccess, onFailure) => {
//     console.log(supplierId,"supplierId")
//     try {
//         const res = await webApi.get(
//             `/subuser-profile-details?id=${supplierId}`, 
//         );
//         if (res.status === 200) {
//             const r = res.data;
//             return onSuccess(r);
//         } else {
//             onFailure("Something Wrong! Please Try again later" + res.data);
//         }
//     } catch (error) {
//         console.log("fdgdf")
//     }
// };
export const getUserdata = async (data) => {
    return await webApi.get(`/subuser-profile-details?id=${data}`, {

    });
};

export const getUpdateUser = async (tax, onSuccess, onFailure) => {
    console.log(tax,"tatataa")
        try {
            const res = await webApi.post(
                `/subuser-data-update`, 
                tax
            );
            if (res.status === 200) {
                const r = res.data;
                console.log(r, "rerere")
                return onSuccess(r);
            } else {
                onFailure("Something Wrong! Please Try again later" + res.data);
            }
        } catch (error) {
            console.log("fdgdf")
        }
    };
    export const getActivityLogs = async (queryString) => {
        return await webApi.get(`/subuser-activity-log?${queryString}`, {
    
        });
    }
    export const getUserprofiledata = async (data) => {
        return await webApi.get(`/user-details`, {
    
        });
    };
    export const getUserSubscription = async (data) => {
        return await webApi.get(`/user-subscription-details`, {
    
        });
    };
    export const getUpdateMasterUser = async (tax, onSuccess, onFailure) => {
        console.log(tax,"tatataa")
            try {
                const res = await webApi.post(
                    `/user-details-update`, 
                    tax
                );
                if (res.status === 200) {
                    const r = res.data;
                    console.log(r, "rerere")
                    return onSuccess(r);
                } else {
                    onFailure("Something Wrong! Please Try again later" + res.data);
                }
            } catch (error) {
                console.log("fdgdf")
            }
        };

        export const getMobilePayment = async (tax, onSuccess, onFailure) => {
            console.log(tax,"tatataa")
                try {
                    const res = await webApi.post(
                        `/store-subscription-mobile-payment`, 
                        tax
                    );
                    if (res.status === 200) {
                        const r = res.data;
                        console.log(r, "rerere")
                        return onSuccess(r);
                    } else {
                        onFailure("Something Wrong! Please Try again later" + res.data);
                    }
                } catch (error) {
                    console.log("fdgdf")
                }
            };