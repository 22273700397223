import React, { useState, useEffect } from 'react'
import Dbheader from '../../layout/Dbheader'
import Sidebar from '../../layout/Sidebar'
import { Link ,useNavigate} from 'react-router-dom';
import Headtxt from '../Headtxt';
import { Tabs, Tab } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Dropdown from 'react-bootstrap/Dropdown';
import Col from 'react-bootstrap/Col';
import Customdropdown from '../../Customdropdown';
import { getAccountType, getAccountTypeByID, getAddaccount, getUpdateaccount, getUserAccount } from '../../../../Service/Account/AccountService';
import { ToastContainer, toast } from 'react-toastify';
import { string } from 'yup';
import asset from '../../../images/assets.png';
import asset2 from '../../../images/assets2.png';
import liabilitiess from '../../../images/liability.png';
import liabilities2 from '../../../images/liability2.png';
import incomes from '../../../images/Income.png';
import income2 from '../../../images/income2.png';
import equitys from '../../../images/enquity.png';
import equity2 from '../../../images/equity.png';
import Swal from 'sweetalert2';

import dollar from '../../../images/4.png';
import ar from '../../../images/ar.png';
import assets2 from '../../../images/assets2.png';
import liability2 from '../../../images/liability2.png';


const Accountchart = ({ data }) => {
const navigate=useNavigate()
  const options2 = ['Company', 'Individuals'];
  const options3 = ['Company', 'Individuals'];

  const [addaccount, setAddaccount] = useState(false);
  const accountpop = () => setAddaccount(true);
  const addaccountClose = () => setAddaccount(false);
  const [accounttype, setAccountType] = useState([]);
  const [editaccount, setEditaccount] = useState(false);
  const [addaccounttype, setAddAccountType] = useState([]);
  const [accountcount, setAccountcount] = useState([]);
  const [accountId, setAccountId] = useState("");
  const [accountName, setAccountName] = useState("");
  const [accountDate, setAccountDate] = useState("");
  const [accountdescription, setAccountDescription] = useState("");
  const [activeKey, setActiveKey] = useState('Assets');
  const accountedit = () => setEditaccount(true);
  const editaccountClose = () => setEditaccount(false);
  const [editId, setEditId] = useState("");
  const [userData, setUserData] = useState(null);
  const [usertype, setUserType] = useState(null);
  useEffect(() => {
    // Retrieve the JSON string from localStorage
    const jsonString = localStorage.getItem('1Simpleclick_User'); // Replace 'yourStorageKey' with the actual key.
console.log("localdataaa======",localStorage.getItem('1Simpleclick_User'))
    // Parse the JSON string into an object
    const user_type = localStorage.getItem('1Simpleclick_UserType');
    const data = JSON.parse(jsonString);

    // Update the state with the retrieved data
    setUserData(data);
    setUserType(user_type)
  }, []);

  const checkModule = (module, subModule) => {
      if (userData && userData.assigned_modules) {
        const assignedModules = userData.assigned_modules;
        
        // Handle the case where assigned_modules is an empty string
        if (assignedModules === "") {
          return true; // Or false, depending on the desired behavior
        }
    
        try {
          const modules = JSON.parse(assignedModules);
          
          // if (modules && modules[module]) {
              return modules[module]?.[subModule] || false; // Fallback to false if subModule is not found
       //   }
        } catch (error) {
          console.error("Error parsing assigned_modules:", error);
        }
      }
      return true; // Default to true if no assigned_modules or any errors
    };
  async function getUserAccounts() {
    getUserAccount().then(function (result) {
      const response = result?.data;
      console.log(response?.data?.accounts, "rrrr")
      // const filteredAccounts = response?.data?.accounts.filter(account => account.isAccount === 1);
      // setAccountType(filteredAccounts);
     setAccountType(response?.data?.accounts);
      setAccountcount(response?.data)
    });
  }
  async function getAccountsType() {
    getAccountType().then(function (result) {
      const response = result?.data;
      console.log(response, "rrrr")
      setAddAccountType(response?.data?.Accounts);

    });
  }

  useEffect(() => {
    getUserAccounts();
    getAccountsType()
  }, []);

  const [selectedType, setSelectedType] = useState('Assets');
  const handleTabChange = (typeName) => {
    //  setSelectedType(typeName);
    setActiveKey(typeName);
  };
  console.log('activeKey', activeKey)
  const Accounts = addaccounttype
  console.log(Accounts, 'Accounts')
  const [selectedSubtype, setSelectedSubtype] = useState(1);

  // Function to handle selection change
  const handleSubtypeChange = (e) => {

    setSelectedSubtype(e.target.value);
  };
  async function getAccountsById(id) {
    getAccountTypeByID(id).then(function (result) {
      const response = result?.data;
      console.log(response?.data?.account.account_name, "rrrr")
      setSelectedSubtype(response?.data?.account?.subtype_id)
      setAccountName(response?.data?.account?.account_name)
      setAccountId(response?.data?.account?.account_id)
      setAccountDescription(response?.data?.account?.description)
      setAccountDate(response?.data?.account?.last_transaction_date)
      accountedit()
    });
  }
  const onAccountName = (e) => {
    setAccountName(e.target.value)
  }
  const onAccountId = (e) => {
    setAccountId(e.target.value)
  }
  const onAccountDescription = (e) => {
    setAccountDescription(e.target.value)
  }
  const onSave = () => {
    if (selectedSubtype === "") {
      toast.error("Please select account type")

    }
    else if (accountName === "") {
      toast.error("Please type account name")

    }
    else {
      getAddaccount(selectedSubtype, accountName, accountId, accountdescription,
        (r) => {
          console.log(r, "response")
          // if (r.success === true) {
          //   toast.success(r.message)
          //   setAddaccount(false)
          //   getUserAccounts();
          //   setSelectedSubtype('')

          // }

          if (r.success === true) {
            Swal.fire({
              title: r.message,
              //  text: 'The product has been added successfully!',
              icon: 'success',
              confirmButtonText: 'OK',
              customClass: {
                confirmButton: 'my-confirm-button'
              }
            }).then(() => {
              setAddaccount(false)
              getUserAccounts();
              setSelectedSubtype('')
            });
          }
          else {
            console.log("nononononono")
            toast.error(r.message)
            // setSelectedSubtype('')
          }
        })
    }

  }
const handleClick=(date,id)=>{

if(date){
  console.log('dateid',date,id)
  navigate(`/accounting?id=${id}`)
}
else{
  toast.error('No Transaction Found')
}
}

  const onUpdate = () => {
    if (selectedSubtype === "") {
      toast.error("Please select account type")

    }
    else if (accountName === "") {
      toast.error("Please type account name")

    }
    else {
      getUpdateaccount(editId,selectedSubtype, accountName, accountId, accountdescription, isChecked ? 1 : '',
        (r) => {
          console.log(r, "response")
          // if (r.success === true) {
          //   toast.success(r.message)
          //   setEditaccount(false);
          //   getUserAccounts();
          //   setSelectedSubtype('')
          // } 

          if (r.success === true) {
            Swal.fire({
              title: r.message,
              //  text: 'The product has been added successfully!',
              icon: 'success',
              confirmButtonText: 'OK',
              customClass: {
                confirmButton: 'my-confirm-button'
              }
            }).then(() => {
              setEditaccount(false);
              getUserAccounts();
              setSelectedSubtype('')
            });
          }
          else {
            console.log("nononononono")
            toast.error(r.message)

          }
        })
    }

  }
  const { assets, liabilities, income, expenses, equity, accounts } = accountcount;
  const getNumberForType = (typeName) => {
    switch (typeName) {
      case 'Assets':
        return assets;
      case 'Liabilities':
        return liabilities;
      case 'Income':
        return income;
      case 'Expenses':
        return expenses;
      case 'Equity':
        return equity;
      default:
        return 0; // default value
    }
  };
  const [isChecked, setIsChecked] = useState(false);

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked); // Toggle the state
  };





  ////////////////////////Component/////////////////////////////
  const getImageForTitle = (title, isActive) => {
    switch (title) {
      case 'Assets':
        return isActive ? asset : asset2;
      case 'Liabilities':
        return isActive ? liabilitiess : liabilities2;
      case 'Revenue':
        return isActive ? incomes : income2;
      case 'Expenses':
        return isActive ? incomes : income2;
      case 'Equity':
        return isActive ? equitys : equity2;
        case 'Other':
        return isActive ? equitys : equity2;
      default:
        return 'defaultImage.png'; // Ensure to import or handle a default image
    }
  };
  const CustomDropdown = ({ options, placeholder, value, onChange }) => {
    console.log('options==', value)
    return (
      <select value={value} onChange={onChange} className="form-control">
        <option value="">{placeholder}</option>
        {options.map(account => (
          <optgroup key={account.id} label={account.type_name}>
            {account.subtype.map(subtype => (
              <option key={subtype.id} value={subtype.id}>{subtype.subtype_name}</option>
            ))}
          </optgroup>
        ))}
      </select>
    );
  };
  const CustomDropDown = ({ options, placeholder, value }) => {
    return (
      <select className="form-control" disabled>
        <option value="">{placeholder}</option>
        {options.map(account => (
          <optgroup key={account.id} label={account.type_name}>
            {account.subtype.map(subtype => (
              <option key={subtype.id} value={subtype.id} selected={subtype.id === value}>
                {subtype.subtype_name}
              </option>
            ))}
          </optgroup>
        ))}
      </select>
    );
  };



  // Account-chart-menu-Start

  // const [openMenu, setOpenMenu] = useState(null);
  // const handleParentClick = (menu) => {
  //   if (openMenu === menu) {
  //     setOpenMenu(null); 
  //   } else {
  //     setOpenMenu(menu);
  //   }
  // };
  // const handleChildClick = (child) => {
  //   console.log(`${child} clicked`);
  // };

  const [showMenu, setShowMenu] = useState('Assets');
  const [openMenu, setOpenMenu] = useState('Assets');
  const [activeChild, setActiveChild] = useState('Cash');
  const [submenu, setSubMenu] = useState('Cash');
  const [selectedSubmenu, setSelectedSubmenu] = useState('Cash');
  const [showSubmenu, setShowSubmenu] = useState('');
  const handleParentClick = (menu) => {
    console.log(`${menu} clicked`);
    if(menu===openMenu){
    setOpenMenu(null);
    setShowMenu(menu);
    }else{
      setOpenMenu(menu);
      setSelectedSubmenu(null);
      setShowMenu(menu);
    }
    
    
  };

  const handleChildClick = (submenu, id,isaccount) => {
    console.log(`${isaccount} clicked`);
    setActiveChild(submenu);
    setSubMenu(submenu);
    setSelectedSubmenu(submenu);
    setSelectedSubtype(id);
    setShowSubmenu(isaccount)
  };



  // Account-chart-menu-End




  return (
    <div className="producservlisting pdt">
      <Dbheader />
      <Sidebar>
        <div className='customerMain'>
          <div className='custTop'>
            <Link to='/accounting' className={`${usertype == 2 ?(checkModule('Accounting','GL Account Transactions') ? '' :'disabled'):''}`}>GL_Account Transactions</Link>
            <Link to='/transactions' className={`${usertype == 2 ?(checkModule('Accounting','Transactions') ? '' :'disabled'):''}`}>Transactions</Link>
            <Link to='/accountchart' className={`${usertype == 2 ?(checkModule('Accounting','Chart Of Accounts') ? 'active' :'disabled'):'active'}`}>Chart of Accounts</Link>
          </div>
          <div className='productServList'>
            <div className='dbTopsec pb-4 pt-2 noBackbtn'>
              <Headtxt link="" title="Chart of account" />

              <div className='custButton'>
                {/* <button className='commonButton addBtn'><Link to="/addproductservice"><i class="fa-regular fa-circle-plus"></i> Add Product</Link></button>&nbsp;&nbsp; */}
                <button className= {`${usertype == 2 ?(checkModule('Accounting','Chart Of Accounts') ? 'commonButton expBtn' :'commonButton expBtn disabled'):'commonButton expBtn'}`} onClick={()=>accountpop()}><i class="fa-solid fa-plus"></i> Add a New Account</button>
              </div>
            </div>
            <div className='newTabsFrame'>
              <div className='row'>
                <div className='col-md-3'>


                  <div className="menuContainer">
                    {/* Menu 1 */}
                    {accounttype?.map((d, index) => (
                      <React.Fragment key={index}>
                        <div
                          className={`menuItem ${openMenu === d?.type_name ? 'active' : ''}`}
                          onClick={() => {handleParentClick(d?.type_name)}}
                        >
                          <div className='menuTxt'>
                            <img src={getImageForTitle(d?.type_name)} alt='' />
                            <h6>{d?.type_name}</h6>
                          </div>
                          <span className='arrw'>
                            <img src={ar} alt='' />
                          </span>
                        </div>
                        {d.subtype?.map((subtype, subIndex) => (
                          openMenu === d?.type_name && (
                            <div key={subIndex} className="childMenu">
                              <div
                                className={`childMenuItem ${activeChild === subtype.subtype_name ? 'active' : ''}`}
                                onClick={() => handleChildClick(subtype.subtype_name, subtype.id,subtype.isAccount)}
                                style={{fontWeight:'bold'}}
                              >
                                <i className="fa-regular fa-chevron-right"></i> {subtype.subtype_name}
                              </div>
                            </div>
                          )
                        ))}
                      </React.Fragment>
                    ))}
                  </div>

                </div>


                <div className='col-md-9'>
                  <div className='chartRight'>
                    
                  {
  accounttype?.map((item, itemIndex) => (
    item?.subtype?.map((data, subtypeIndex) => (
      submenu === data?.subtype_name ? (
        <React.Fragment key={`${itemIndex}-${subtypeIndex}`}>
          <h4>Under: <span>{showMenu} &gt; {openMenu===null?null:selectedSubmenu}</span></h4>
        
          {data?.account?.length > 0  ? (
            data?.account?.map((d, accountIndex) => (
              <div key={accountIndex} className='chrtBox'>
                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                <div onClick={() => handleClick(d?.last_transaction_date, d?.id)} >
      <h5 style={{cursor:'pointer'}}>{d?.account_name}</h5>
      <p>Balance:  {d?.amount}</p>
    </div>
  
    {showSubmenu===0?null:
                  <button className='editaccount' onClick={() =>{ getAccountsById(d.id);setEditId(d?.id)}}>
                    <i className="fa-light fa-pen"></i>
                  </button>
}
                </div>
                <p style={{color:'#000',fontWeight:250,cursor:'pointer'}}>{d?.description}</p>
              </div>
            ))
          ) : (
            <div className='chrtBox'>
              <h5>You haven't added account.</h5>
            </div>
          )}
        </React.Fragment>
      ) : null
    ))
  ))
}
                    {/* {accounttype[0]?.subtype[0]?.account?.map((d) => (
                      <div className='chrtBox'>
                        <h5>{d?.account_name}</h5>
                        <p>Cash you haven’t deposited in the bank. Add your bank and credit card accounts to accurately categorize transactions that aren't cash.</p>
                      </div>
                    ))} */}
{showSubmenu===0?null:
  <button className= {`${usertype == 2 ?(checkModule('Accounting','Chart Of Accounts') ? 'commonButton expBtn mt-4' :'commonButton expBtn mt-4 disabled'):'commonButton expBtn mt-4'}`} onClick={()=>accountpop()}><i class="fa-solid fa-plus"></i> Add a New Account</button>
                    // <button className="commonButton expBtn mt-4" onClick={() => {
                    //   accountpop();
                    // }}>
                    //   <i className="fa-solid fa-plus"></i> Add New Account
                    // </button>m
                   }
                  </div>
                </div>



              </div>
            </div>
          </div>
        </div>
      </Sidebar>




      <Modal size="lg" show={addaccount} onHide={addaccountClose} centered className='customerModal sloModal'>
        <Modal.Header closeButton>
          <Modal.Title>
            <h5>Add an Account</h5>
          </Modal.Title>


        </Modal.Header>
        <Modal.Body>
          <div className='commonWhite addCustomerForm mb-3'>
            <Form>
              <Form.Group className="row" controlId="exampleForm.ControlInput1">
                <Col md="12" className='mb-3'>
                  <CustomDropdown options={Accounts} placeholder="Account Type" value={selectedSubtype} onChange={handleSubtypeChange} />
                </Col>
              </Form.Group>
              <Form.Group className="row" controlId="exampleForm.ControlInput1">
                <Col md="6" className='mb-3'>
                  <Form.Control type="text" placeholder="Account name" onChange={onAccountName} />
                </Col>
                <Col md="6" className='mb-3'>
                  <Form.Control type="text" placeholder="Account ID" onChange={onAccountId} />
                </Col>
              </Form.Group>
              <Form.Group className="row" controlId="exampleForm.ControlInput1">
                <Col md="12" className='mb-0'>
                  <Form.Control as="textarea" placeholder="Description" rows={3} onChange={onAccountDescription} />
                </Col>
              </Form.Group>
            </Form>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button className='commonButton addBtn' onClick={addaccountClose}>Cancel</button>
          <button className='commonButton expBtn' onClick={onSave} >Save</button>
        </Modal.Footer>
      </Modal>

      <Modal size="lg" show={editaccount} onHide={editaccountClose} centered className='customerModal sloModal'>
        <Modal.Header closeButton>
          <Modal.Title>
            <h5>{accountName}</h5>
          </Modal.Title>


        </Modal.Header>
        <Modal.Body>
          <div className='commonWhite addCustomerForm mb-3'>
            <Form>
              <Form.Group className="row" controlId="exampleForm.ControlInput1">
                <Col md="12" className='mb-3'>
                  <CustomDropDown options={Accounts} placeholder="Account Type" value={selectedSubtype} />

                </Col>
              </Form.Group>
              <Form.Group className="row" controlId="exampleForm.ControlInput1">
                <Col lg="6" className='mb-3'>
                  <Form.Control type="text" placeholder="Cash on Hand" value={accountName} onChange={onAccountName} />
                </Col>
                {/* <Col lg="4" className='mb-3'>
                                    <Customdropdown options={options3} placeholdr="CAD - Canadian Dollar" />
                                </Col> */}
                <Col lg="6" className='mb-3'>
                  <Form.Control type="text" placeholder="123456" value={accountId} onChange={onAccountId} />
                </Col>
              </Form.Group>
              <Form.Group className="row" controlId="exampleForm.ControlInput1">
                <Col md="12" className='mb-0'>
                  <Form.Control as="textarea" placeholder="" rows={3} style={{ height: "100px" }} value={accountdescription} onChange={onAccountDescription} />
                </Col>
              </Form.Group>
              <div className='tabTxtinn2'>
                <h6 style={{ color: 'blue', marginLeft: '15px', marginTop: '10px' }}>Archive Account</h6>
                <input style={{ marginLeft: '15px' }} type='checkbox' checked={isChecked}
                  onChange={handleCheckboxChange} />
                <h6 style={{ marginLeft: '15px', marginTop: '10px' }}>Prevent further usage of this account.</h6>

              </div>
              <p class="wv-text wv-text--hint fs-exclude wv-text--inline">You will still be able to generate reports for this account, and all previously categorized transactions will remain unchanged.</p>
              <div class="wv-divider"></div>
              {accountDate === null ?
                <p class="wv-text wv-text--body account-modal-form__transactions-info fs-exclude">No transactions for this account. </p>

                : <p class="wv-text wv-text--body account-modal-form__transactions-info fs-exclude">
                  {accountDate}
                </p>
              }
            </Form>

          </div>
        </Modal.Body>
        <Modal.Footer>
          <button className='commonButton addBtn' onClick={editaccountClose}>Cancel</button>
          <button className='commonButton expBtn' onClick={onUpdate}>Save</button>
        </Modal.Footer>
      </Modal>
    </div>
  )
}

export default Accountchart