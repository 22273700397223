import { useEffect, useState } from 'react';
import { Sidebar, Menu, MenuItem, SubMenu } from 'react-pro-sidebar';
import { Link, useLocation } from 'react-router-dom';
import pf from '../../images/pf.png';
import Dropdown from 'react-bootstrap/Dropdown';
import { useNavigate } from 'react-router-dom';
import {getUserAllList} from "../../../Service/Dashboard/DashboardService";
import { toast } from 'react-toastify';
const Leftmenu = () => {

  const navigate = useNavigate();
  const [userData, setUserData] = useState(null);
  const [usertype, setUserType] = useState(null);
const [selectedModules, setSelectedModules] = useState({
    sales: { checked: false, subModules: { customers: false, quote: false,salesOrders: false,invoices: false,salesReceipt: false,refund: false, } },
    purchase: { checked: false, subModules: { supplier: false,quote: false, purchaseOrders: false,bill: false,BillPayments: false,refund: false } },
    accounting: { checked: false, subModules: { gL_Account_Transactions: false,transactions: false,chart_of_Accounts: false } },
    product: { checked: false, subModules: {ProductService: false } },
    report: { checked: false, subModules: { balanceSheet: false, Income_Profit_Loss: false,Cashflow_Statement:false} },
  //  professional: { checked: false, subModules: { services: false,} }
  });
  useEffect(() => {
    // Retrieve the JSON string from localStorage
    const jsonString = localStorage.getItem('1Simpleclick_User'); // Replace 'yourStorageKey' with the actual key.
console.log("localdataaa",localStorage.getItem('1Simpleclick_User'))
    // Parse the JSON string into an object
    const user_type = localStorage.getItem('1Simpleclick_UserType');
    const data = JSON.parse(jsonString);

    // Update the state with the retrieved data
    setUserData(data);
    setUserType(user_type)
  }, []);

  const checkModule = () => {
    if (userData && userData.assigned_modules) {
      const assignedModules = userData.assigned_modules;

      // Handle the case where assigned_modules is an empty string
      if (assignedModules === "") {
        return true; // Or false, depending on the desired behavior
      }

      try {
        const modules = JSON.parse(assignedModules);
      
          const transformedModules = {
            sales: {
              checked: Object.values(modules?.Sales ?? {}).some((value) => value === true),
              subModules: {
                customers: modules?.Sales?.Customers ?? false,
                quote: modules?.Sales?.Quote ?? false,
                salesOrders: modules?.Sales?.SalesOrders ?? false,
                invoices: modules?.Sales?.Invoices ?? false,
                salesReceipt: modules?.Sales?.SalesReceipt ?? false,
                refund: modules?.Sales?.Refund ?? false,
              },
            },
            purchase: {
              checked: Object.values(modules?.Purchase ?? {}).some((value) => value === true),
              subModules: {
                supplier: modules?.Purchase?.Supplier ?? false,
                quote: modules?.Purchase?.Quote ?? false,
                purchaseOrders: modules?.Purchase?.PurchaseOrders ?? false,
                bill: modules?.Purchase?.Bill ?? false,
                billPayments: modules?.Purchase?.BillPayments ?? false,
                refund: modules?.Purchase?.Refund ?? false,
              },
            },
            accounting: {
              checked: Object.values(modules?.Accounting ?? {}).some((value) => value === true),
              subModules: {
                gL_Account_Transactions: modules?.Accounting?.["GL Account Transactions"] ?? false,
                transactions: modules?.Accounting?.Transactions ?? false,
                chart_of_Accounts: modules?.Accounting?.["Chart Of Accounts"] ?? false,
              },
            },
            product: {
              checked: Object.values(modules?.Product ?? {}).some((value) => value === true),
              subModules: {
                productService: modules?.Product?.ProductService ?? false,
              },
            },
            report: {
              checked: Object.values(modules?.Report ?? {}).some((value) => value === true),
              subModules: {
                balance_Sheet: modules?.Report?.BalanceSheet ?? false,
                income_Profit_Loss: modules?.Report?.["Income Profit Loss"] ?? false,
                cashflow_Statement: modules?.Report?.["Cashflow Statement"] ?? false,
              },
            },}
           
           // Update the state
           setSelectedModules(transformedModules);
          
          
        // if (modules && modules[module]) {
    //    return modules[module]?.[subModule] || false; // Fallback to false if subModule is not found
        //   }
      } catch (error) {
        console.error("Error parsing assigned_modules:", error);
      }
    }
    return true; // Default to true if no assigned_modules or any errors
  };
  useEffect(() => {
    checkModule();

  }, [userData]);
console.log(selectedModules,'===----')

  const onLogout = () => {

    localStorage.clear()
    navigate("/")
  }



  const location = useLocation();
  const pathnameParts = location.pathname.split('/'); // Split the pathname into parts
  const lastPageName = pathnameParts[pathnameParts.length - 1];

  const [currentpage, setCurrentpage] = useState("");
  const [collapse, setCollapsed] = useState(false);
  const [userList, setAllUserList] = useState("");
  const handleResize = () => {
    setCollapsed(window.innerWidth <= 991);
  };
  const isActivePage = (pages) => (pages.includes(lastPageName) ? "show" : "");
  console.log(isActivePage,'pagesactibe-=======')
  useEffect(() => {
    console.log(window.innerWidth, "smallscreen")
    // Add event listener for window resize
    window.addEventListener('resize', handleResize);

  });

  console.log(lastPageName, currentpage, "Somnath")

  useEffect(() => {
    if (currentpage) {
      setCurrentpage(lastPageName)
    }

  }, []);

  useEffect(() => {
    getUserDataList();

  }, [usertype]);

  // async function getUserDataList() {
  //   getUserAllList().then(function (result) {
  //     const response = result?.data;
  //     // console.log(response, "rrrr")
      
  //     setAllUserList(response?.data);

  //   });
  // }
  async function getUserDataList() {
    try {
      const result = await getUserAllList(); // Ensure the function is awaited
      const response = result?.data;
  
      // Check the subUser_status
      if(usertype==1){setAllUserList(response?.data);}
     else if (usertype==2&&response?.data?.subUser_status == "Delete") {
        // Perform logout or redirect
        toast.error("Your account deleted by master user")
        onLogout(); // Call your logout function
      } 
      else if (usertype==2&&response?.data?.subUser_status == "Block") {
        // Perform logout or redirect
        toast.error("Your account blocked by master user")
        onLogout(); // Call your logout function
      } 
      else if (usertype==2&&response?.data?.subUser_status == "Active") {
        // Stay on the page and set the user list
        setAllUserList(response?.data);
        // const userdata = {
        //   fname: response?.fname,
        //   lname: response?.lname,
        //   id: response?.id,
        //   email: response?.email,
        //   phone: response?.phone,
        //   country: response?.country,
        //   company: response?.company,
        //   logo: response.logo||response.profile_image,
        //   assigned_modules:response?.assigned_modules?response?.assigned_modules:''
        // };
        // localStorage.setItem("1Simpleclick_User", JSON.stringify(userdata));
     //   setUserData(JSON.stringify(userdata))
      }
    } catch (error) {
      console.error("Error fetching user data:", error);
    }
  }
  

  return (

    <>
      {/* <button onClick={() => setCollapsed(!collapse)} className='menuToggle'>{!collapse? <i class="fa-regular fa-arrows-minimize"></i> : <i class="fa-regular fa-arrows-maximize"></i> }</button> */}
      <Sidebar collapsed={collapse} className='dbsidemenu'>
        <div className='sideLogin'>
          <Dropdown className="userBase ms-0">
            <Dropdown.Toggle variant="" id="">
              <Link to="/myprofile"><img src={userList?.user_type==2?userList?.profile_image:userList?.logo} /> <span className="john">{userList?.fname} {userList?.lname}</span></Link>
            </Dropdown.Toggle>
            {/* <Dropdown.Menu>
            <Dropdown.Item onClick={onLogout}>Logout <i class="fa-solid fa-right-from-bracket"></i></Dropdown.Item>
          </Dropdown.Menu> */}
          </Dropdown>
        </div>
        <Menu menuItemStyles={{
          button: ({ level, active, disabled }) => {
            // only apply styles on first level elements of the tree
            if (level === 0)
              return {
                color: disabled ? '#f5d9ff' : '#d359ff',
                backgroundColor: active ? '#eecef9' : undefined,
              };
          },
        }}>
          <button onClick={() => setCollapsed(!collapse)} className='menuToggle'>{!collapse ? <i class="fa-regular fa-arrows-minimize"></i> : <i class="fa-regular fa-arrows-maximize"></i>}</button>
          <MenuItem className={isActivePage(["dashboard"])} component={<Link to="/dashboard" />}><i class="fa-light fa-house"></i> Home </MenuItem>
     {usertype==1 ?
          <MenuItem className={lastPageName === "userlisting" ? "show" : ""
            || lastPageName === "adduser" ? "show" : ""
            || lastPageName === "edituser" ? "show" : ""
          } component={<Link to="/userlisting" />}><i class="fa-light fa-users"></i>Manage Sub User </MenuItem>
          :null}
          {
            usertype==1?
          <MenuItem
            className={lastPageName === "productservicelistings" ? "show" : ""
                  || lastPageName === "addproductservice2" ? "show" : ""
                    || lastPageName === "productdetails" ? "show" : ""
                    || lastPageName === "servicedetails" ? "show" : ""
             ||lastPageName==='editproduct'? "show" : ""
             ||lastPageName==='editservice'? "show" : ""
            } component={<Link to="/productservicelistings" />}>
            <i class="fa-light fa-bag-shopping"></i>
            Product & Services </MenuItem>
            :
            selectedModules.product.checked &&
            <MenuItem
            className={lastPageName === "productservicelistings" ? "show" : ""
                  || lastPageName === "addproductservice2" ? "show" : ""
                    || lastPageName === "productdetails" ? "show" : ""
                    || lastPageName === "servicedetails" ? "show" : ""
             ||lastPageName==='editproduct'? "show" : ""
             ||lastPageName==='editservice'? "show" : ""
            } component={<Link to="/productservicelistings" />}>
            <i class="fa-light fa-bag-shopping"></i>
            Product & Services </MenuItem>
}
            {
            usertype==1?
            <MenuItem
            className={lastPageName === "customerlisting" ? "show" : ""
              || lastPageName === "addcustomer" ? "show" : ""
                      || lastPageName === "salesorders" ? "show" : ""
                        || lastPageName === "salesorderlisting" ? "show" : ""
                          || lastPageName === "salesordergeneration" ? "show" : ""
                            || lastPageName === "salesreceiptlist" ? "show" : ""
                              || lastPageName === "salesreceiptadd" ? "show" : ""
                                || lastPageName === "refundlist" ? "show" : ""
                                  || lastPageName === "addrefund" ? "show" : ""
                                    || lastPageName === "refunddetails" ? "show" : ""
                                      || lastPageName === "invoice" ? "show" : ""
                                        || lastPageName === "newinvoicecreation" ? "show" : ""
                                          || lastPageName === "quote" ? "show" : ""
                                            || lastPageName === "newinvoicecreationfronsalesorder" ? "show" : ""
                                             || lastPageName === "editinvoice" ? "show" : ""
                                             || lastPageName === "addcustomerquote" ? "show" : ""
                                             || lastPageName === "viewinvoicedetails" ? "show" : ""
                                              || lastPageName === "viewsalesreceiptdetails" ? "show" : ""
                                        }
            component={<Link to="/customerlisting" />}>
            <i class="fa-light fa-badge-percent"></i>
            Sales </MenuItem>
            :
            selectedModules.sales.checked &&
          <MenuItem
            className={lastPageName === "customerlisting" ? "show" : ""
              || lastPageName === "addcustomer" ? "show" : ""
                      || lastPageName === "salesorders" ? "show" : ""
                        || lastPageName === "salesorderlisting" ? "show" : ""
                          || lastPageName === "salesordergeneration" ? "show" : ""
                            || lastPageName === "salesreceiptlist" ? "show" : ""
                              || lastPageName === "salesreceiptadd" ? "show" : ""
                                || lastPageName === "refundlist" ? "show" : ""
                                  || lastPageName === "addrefund" ? "show" : ""
                                    || lastPageName === "refunddetails" ? "show" : ""
                                      || lastPageName === "invoice" ? "show" : ""
                                        || lastPageName === "newinvoicecreation" ? "show" : ""
                                          || lastPageName === "quote" ? "show" : ""
                                            || lastPageName === "newinvoicecreationfronsalesorder" ? "show" : ""
                                             || lastPageName === "editinvoice" ? "show" : ""
                                             || lastPageName === "addcustomerquote" ? "show" : ""
                                             || lastPageName === "viewinvoicedetails" ? "show" : ""
                                              || lastPageName === "viewsalesreceiptdetails" ? "show" : ""
                                        }
            component={<Link to="/customerlisting" />}>
            <i class="fa-light fa-badge-percent"></i>
            Sales </MenuItem>
}
{
            usertype==1?
            <MenuItem
            className={lastPageName === "supplierlisting" ? "show" : ""
              || lastPageName === "addsuppliers" ? "show" : ""
                || lastPageName === "purchaseorderlisting" ? "show" : ""
                  || lastPageName === "addpurchaseorder" ? "show" : ""
                    || lastPageName === "quotelisting" ? "show" : "" 
                    || lastPageName === "addsupplierquote" ? "show" : "" 
                    || lastPageName === "bill" ? "show" : "" 
                    || lastPageName === "billdetails" ? "show" : "" 
                    || lastPageName === "billpayment" ? "show" : "" 
                    || lastPageName === "billpaymentadd" ? "show" : "" 
                    || lastPageName === "billadd" ? "show" : ""
                      || lastPageName === "editsupplier" ? "show" : ""
                      || lastPageName === "quotedetails" ? "show" : ""
                       || lastPageName === "editproductservice" ? "show" : ""
                        || lastPageName === "billaddsubmit" ? "show" : ""
                        || lastPageName === "viewbilldetails" ? "show" : ""
                        || lastPageName === "viewbillpaymentdetails" ? "show" : ""
                        || lastPageName === "manualexpenses" ? "show" : ""
                        || lastPageName === "manualexpensesadd" ? "show" : ""
                        || lastPageName === "manualexpensesedit" ? "show" : ""
            } component={<Link to="/supplierlisting" />}>
            <i class="fa-light fa-bag-shopping"></i>
            Purchase </MenuItem>
            :
            selectedModules.purchase.checked &&
          <MenuItem
            className={lastPageName === "supplierlisting" ? "show" : ""
              || lastPageName === "addsuppliers" ? "show" : ""
                || lastPageName === "purchaseorderlisting" ? "show" : ""
                  || lastPageName === "addpurchaseorder" ? "show" : ""
                    || lastPageName === "quotelisting" ? "show" : "" 
                    || lastPageName === "addsupplierquote" ? "show" : "" 
                    || lastPageName === "bill" ? "show" : "" 
                    || lastPageName === "billdetails" ? "show" : "" 
                    || lastPageName === "billpayment" ? "show" : "" 
                    || lastPageName === "billpaymentadd" ? "show" : "" 
                    || lastPageName === "billadd" ? "show" : ""
                      || lastPageName === "editsupplier" ? "show" : ""
                      || lastPageName === "quotedetails" ? "show" : ""
                       || lastPageName === "editproductservice" ? "show" : ""
                        || lastPageName === "billaddsubmit" ? "show" : ""
                        || lastPageName === "viewbilldetails" ? "show" : ""
                        || lastPageName === "viewbillpaymentdetails" ? "show" : ""
                        || lastPageName === "manualexpenses" ? "show" : ""
                        || lastPageName === "manualexpensesadd" ? "show" : ""
                        || lastPageName === "manualexpensesedit" ? "show" : ""
            } component={<Link to="/supplierlisting" />}>
            <i class="fa-light fa-bag-shopping"></i>
            Purchase </MenuItem>
}
{
            usertype==1?
          <MenuItem  className={isActivePage(["accounting","transactions","accountchart"])}
          component={<Link to="/accounting" />}><i class="fa-light fa-user"></i> Accounting </MenuItem>
          :
            selectedModules.accounting.checked &&
            <MenuItem  className={isActivePage(["accounting","transactions","accountchart"])}
            component={<Link to="/accounting" />}><i class="fa-light fa-user"></i> Accounting </MenuItem>
}
       {/* className={lastPageName === "accounting" ? "show" : ""  
          // || lastPageName === "transactions" ? "show" : "" 
          // || lastPageName === "accountchart" ? "show" : ""} */}
          
         {usertype==1 ?
            <MenuItem className={ lastPageName === "logslisting" ? "show" : ""
          } component={<Link to="/logslisting" />}><i class="fa-light fa-memo"></i>Activity Logs</MenuItem>
          :null}
          {
            usertype==1?
          <MenuItem 
          className={lastPageName === "balancesheet" ? "show" : "" 
          || lastPageName === "profitloss" ? "show" : "" 
          || lastPageName === "cashflow" ? "show" : "" 
        } component={<Link to="/balancesheet" />}><i class="fa-light fa-file-lines"></i> Reports </MenuItem>
        :
        selectedModules.report.checked &&
        <MenuItem 
          className={lastPageName === "balancesheet" ? "show" : "" 
          || lastPageName === "profitloss" ? "show" : "" 
          || lastPageName === "cashflow" ? "show" : "" 
        } component={<Link to="/balancesheet" />}><i class="fa-light fa-file-lines"></i> Reports </MenuItem>}
          <SubMenu
            label="Professional Service"
            icon={<i class="fa-light fa-user-tie" />}
            className={lastPageName === "cfoservice" ? "show" : "" || lastPageName === "prfbooking" ? "show" : "" || lastPageName === "advconsulting" ? "show" : "" || lastPageName === "icimprovement" ? "show" : "" || lastPageName === "auditpreparation" ? "show" : ""  || lastPageName === "cfoservice" ? "show" : ""}
          >
            <MenuItem className={lastPageName === "cfoservice" ? "show" : "" || lastPageName === "ceoservice" ? "show" : ""} component={<Link to="/cfoservice" />}><i class="fa-regular fa-angle-right"></i> <span>CFO Services</span> </MenuItem>
            <MenuItem className={lastPageName === "prfbooking" ? "show" : ""} component={<Link to="/prfbooking" />}><i class="fa-regular fa-angle-right"></i> <span>Professional Bookkeeping Services</span> </MenuItem>
            <MenuItem className={lastPageName === "advconsulting" ? "show" : ""} component={<Link to="/advconsulting" />}><i class="fa-regular fa-angle-right"></i> Advisory and Consulting </MenuItem>
            <MenuItem className={lastPageName === "icimprovement" ? "show" : ""} component={<Link to="/icimprovement" />}><i class="fa-regular fa-angle-right"></i> Internal Control Improvements </MenuItem>
            <MenuItem className={lastPageName === "auditpreparation" ? "show" : ""} component={<Link to="/auditpreparation" />}><i class="fa-regular fa-angle-right"></i> Audit Preparations </MenuItem>
            <MenuItem className={lastPageName === "budgetforecasting" ? "show" : ""} component={<Link to="/budgetforecasting" />}><i class="fa-regular fa-angle-right"></i> Budgeting and Forecasting </MenuItem>
          </SubMenu>

          <MenuItem className={lastPageName === "aboutus" ? "show" : ""} component={<Link to="/aboutus" />}><i class="fa-light fa-memo"></i> About Us </MenuItem>
          <MenuItem className={lastPageName === "termsconditions" ? "show" : ""} component={<Link to="/termscondition" />}><i class="fa-light fa-circle-check"></i> Terms &amp; Conditions </MenuItem>
          <MenuItem className={lastPageName === "privacypolicy" ? "show" : ""} component={<Link to="/privacy" />}><i class="fa-light fa-layer-group"></i> Privacy Policy </MenuItem>
          <MenuItem className={lastPageName === "contactus" ? "show" : ""} component={<Link to="/contactus" />}><i class="fa-light fa-phone"></i> Contact Us </MenuItem>

          {/* <SubMenu label="Charts" className={lastPageName === "accounting" ? "show" : "" || lastPageName === "addcustomer" ? "show" : ""}>
          <MenuItem className={lastPageName === "accounting" ? "show" : ""} component={<Link to="/accounting" />}> Accounting </MenuItem>
          <MenuItem className={lastPageName === "addcustomer" ? "show" : ""} component={<Link to="/addcustomer" />}> Addcustomer </MenuItem>
        </SubMenu> */}

          {/* <SubMenu label="Charts" className={lastPageName === "accounting" ? "show" : ""}>
          <MenuItem className={lastPageName === "accounting" ? "show" : ""} component={<Link to="/accounting" />}> Accounting </MenuItem>
          <MenuItem> Line charts </MenuItem>
        </SubMenu>
        <SubMenu label="demo" className={lastPageName === "demo" ? "show" : ""} >
          <MenuItem className={lastPageName === "demo" ? "show" : ""} component={<Link to="/demo" />}> Piechart </MenuItem>
          <MenuItem> Line charts </MenuItem>
        </SubMenu> */}


        </Menu>
        <div className='logoutLeft'>
          <button className='commonButton' onClick={onLogout}><i class="fa-regular fa-arrow-right-from-bracket"></i> Logout</button>
        </div>
      </Sidebar>
    </>
  )
}

export default Leftmenu