import { React, useState, useEffect, useCallback } from 'react';
import Sidebar from '../../layout/Sidebar';
import Dbheader from '../../layout/Dbheader';
import Form from 'react-bootstrap/Form';
import { useLocation } from 'react-router-dom';
import Headtxt from '../Headtxt';
import Col from 'react-bootstrap/Col';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Modal from 'react-bootstrap/Modal';
import Pagination from 'react-bootstrap/Pagination';
import { Link } from 'react-router-dom';
import Customdropdown from '../../Customdropdown';
import { getAccountSubType, getGLAccount, getGlFiltration, getSuppliername } from '../../../../Service/Account/AccountService';
import moment from "moment"
import getSymbolFromCurrency from 'currency-symbol-map'
import { toast } from 'react-toastify';
const Accounting = () => {
    const location = useLocation()
    const accountId = new URLSearchParams(location.search).get('id') || '';
    const [selectedSubtype, setSelectedSubtype] = useState(accountId);
    const options = ['All accounts', 'Company', 'Individuals'];
    const options2 = ['All contacts', 'Company', 'Individuals'];
    const [addaccounttype, setAddAccountType] = useState([]);
    const [supplierlist, setSupplierList] = useState([]);
    const [customerlist, setCustomerList] = useState([]);
    const [selectedDate, setSelectedDate] = useState(null);
    const [glaccount, setGLAccount] = useState([]);
    const [account, setAccount] = useState([]);
    const [accountPayable, setAccountPayable] = useState([]);
    const [lossForeignExchange, setLossForeignExchange] = useState([]);
    const [profitForeignExchange, setProfitForeignExchange] = useState([]);
    const [inventory, setInventory] = useState([]);
    const [expense, setExpense] = useState([]);
    const [incomeAccounts, setIncomeAccounts] = useState([]);
    const [costOfGoodsSoldAccounts, setcostOfGoodsSoldAccounts] = useState([]);
    const [currencyData, setCurrencyData] = useState(null);
    const [accountReceivable, setAccountReceivable] = useState([]);
    const [selectedSupplier, setSelectedSuplier] = useState('');
    const [userData, setUserData] = useState(null);
    const [usertype, setUserType] = useState(null);
     const [userid, setUserId] = useState(null);
    useEffect(() => {
      // Retrieve the JSON string from localStorage
      const jsonString = localStorage.getItem('1Simpleclick_User'); // Replace 'yourStorageKey' with the actual key.
  console.log("localdataaa======",localStorage.getItem('1Simpleclick_User'))
      // Parse the JSON string into an object
      const user_type = localStorage.getItem('1Simpleclick_UserType');
      const data = JSON.parse(jsonString);
      setUserId(localStorage.getItem("1Simpleclick_UserID"));
      // Update the state with the retrieved data
      setUserData(data);
      setUserType(user_type)
    }, []);
  
    const checkModule = (module, subModule) => {
        if (userData && userData.assigned_modules) {
          const assignedModules = userData.assigned_modules;
          
          // Handle the case where assigned_modules is an empty string
          if (assignedModules === "") {
            return true; // Or false, depending on the desired behavior
          }
      
          try {
            const modules = JSON.parse(assignedModules);
            
            // if (modules && modules[module]) {
                return modules[module]?.[subModule] || false; // Fallback to false if subModule is not found
         //   }
          } catch (error) {
            console.error("Error parsing assigned_modules:", error);
          }
        }
        return true; // Default to true if no assigned_modules or any errors
      };
    const handleDateChange = (date) => {
        setSelectedDate(date);
    };
    useEffect(() => {
        if (accountId) {
            getFiltratioTransactionListn()
        }
    }, [accountId])

    console.log("accountId", accountId)

    const [selectedDateb, setSelectedDateb] = useState(null);

    const handleDateChangeb = (date) => {
        setSelectedDateb(date);
    };

    async function getTransactionList() {
    // setSelectedSubtype('All account')
        getGLAccount("").then(function (result) {
            const response = result?.data;
            //  console.log(response, "rrrr")
            //setAddAccountType(response?.data?.Accounts);
            setGLAccount(response?.data?.cashAccounts);
            setAccountPayable(response?.data?.accountsPayable)
            setAccount(response?.data?.tax);
            setInventory(response?.data?.inventory);
            setExpense(response?.data?.expense);
            setProfitForeignExchange(response?.data?.profitForeignExchange);
            setLossForeignExchange(response?.data?.lossForeignExchange);
            setAccountReceivable(response?.data?.accountsReceivable);
            setcostOfGoodsSoldAccounts(response?.data?.costOfGoodsSoldAccounts);
            setIncomeAccounts(response?.data?.incomeAccounts);
        });
    }
    async function getSupplierList() {
        getSuppliername("").then(function (result) {
            const response = result?.data;
            console.log('response===', response)
            setSupplierList(response.data.supplierlist);
            setCustomerList(response.data.customerlist);
        });
    }
    async function onClear() {
        setSelectedDateb(null);
        setSelectedDate(null);
        setSelectedSubtype('');
        setSelectedSuplier('');
        setAccountPayable([])
        setInventory([]);
        setAccount([]);
        setGLAccount([]);
        setProfitForeignExchange([]);
        setLossForeignExchange([]);
        setExpense([]);
        setAccountReceivable([])
        setcostOfGoodsSoldAccounts([]);
        setIncomeAccounts([])
        getTransactionList();
    }
    console.log('accountsPayable', accountPayable)
    const getFiltratioTransactionListn = useCallback(async () => {
        // e.preventDefault();
        console.log( accountId, 'id===')
       
            const filterCriteria = selectedDate ? moment(selectedDate).format('DD-MM-YYYY') : '';
            const toDate = selectedDateb ? moment(selectedDateb).format('DD-MM-YYYY') : '';
            if(selectedSubtype !=='All account')
                {
        try {
            const result = await getGlFiltration(selectedSubtype || accountId, filterCriteria, toDate, selectedSupplier);
            const response = result?.data;

            console.log(response?.data, "rrrr===");

            const data = response?.data || [];

            const hasAccountsPayable = 'accountsPayable' in data;
            const hasCashAccounts = 'cashAccounts' in data;
            const hasInventory = 'inventory' in data;
            const hasTax = 'tax' in data;
            const hasExpense = 'expense' in data;
            const hasLossForeignExchange = 'lossForeignExchange' in data;
            const hasProfitForeignExchange = 'profitForeignExchange' in data;
            const hasAccountsReceivable = 'profitForeignExchange' in data;
            const hasCostOfGoodsSoldAccounts = 'costOfGoodsSoldAccounts' in data;
            const hasIncomeAccounts = 'incomeAccounts' in data;
            // Check if all keys are present
            console.log(hasAccountsReceivable, "rrrr===");

            console.log('All keys are present', data?.accountsReceivable);
            setAccountPayable(data?.accountsPayable || []);
            setGLAccount(data?.cashAccounts || []);
            setInventory(data?.inventory || []);
            setAccount(data?.tax || []);
            setExpense(data?.expense || []);
            setLossForeignExchange(data?.lossForeignExchange || []);
            setProfitForeignExchange(data?.profitForeignExchange || []);
            setAccountReceivable(data?.accountsReceivable || []);
            setcostOfGoodsSoldAccounts(data?.costOfGoodsSoldAccounts || []);
            setIncomeAccounts(data?.incomeAccounts || [])

        } catch (error) {
            console.error("Error fetching filtered transaction list:", error);
       }
    }
    else{
        getTransactionList();
    }   
        


    }, [selectedDate, selectedDateb, accountId, selectedSupplier,selectedSubtype])
    async function getAccountsType() {
        getAccountSubType("").then(function (result) {
            const response = result?.data;
            console.log(response, "rrrr")
            setAddAccountType(response?.data?.accountTypeLists);

        });
    }

    const fetchCurrencyData = async () => {
        const UserCurrency = localStorage.getItem("1Simpleclick_Currency");
        setCurrencyData(UserCurrency)
        
    };

    useEffect(() => {
        if (!accountId) {
            getTransactionList();
        }
        getAccountsType();
        getSupplierList();
        fetchCurrencyData()
    }, []);
    let totalBalance = 0;
    let totalCredit2 = 0; // Initialize totalCredit for each item
    let totalDebit2 = 0;
    let totalCredit = 0; // Initialize totalCredit for each item
    let totalDebit = 0;
    const CustomDropdown = ({ options, placeholder, value, onChange }) => {
        console.log(value, 'value');
        return (
            <select 
                value={value || ""} 
                onChange={(e) => { 
                    if (e.target.value === 'All account') {
                        setSelectedSubtype('All account')
                        getTransactionList();
                    } else {
                        onChange(e); // Pass the event object 'e' here
                    }
                }} 
                className="form-control"
            >
                <option value="" >Select Account Type</option>
                <option value="All account">All Account Type</option>
                {options?.map(account => (
                    <optgroup key={account.id} label={account.type_name}>
                        {account.accountName?.map(subtype => (
                            <option key={subtype.id} value={subtype.id}>
                                {subtype.account_name}
                            </option>
                        ))}
                    </optgroup>
                ))}
            </select>
        );
    };
    const CustomDropdown2 = ({ options, placeholder, value, onChange, options2, placeholder2 }) => {
        console.log('options==', value)
        return (
            <><select value={value} onChange={onChange} className="form-control">
                <option value="" >{placeholder}</option>
                {options?.map(account => (
                    //   <optgroup key={account.id} label={account.name}>
                    //     {/* {account.accountName?.map(subtype => (
                    //       <option key={subtype.id} value={subtype.id}>{subtype.account_name}</option>
                    //     ))} */}
                    //   </optgroup>
                    <option key={account.userId} value={account.userId} style={{ fontSize: 12 }}>{account.name}</option>
                ))}
                <br></br>
                <br></br>
                <option value="">{placeholder2}</option>
                {options2?.map(account => (
                    //   <optgroup key={account.id} label={account.name}>
                    //     {/* {account.accountName?.map(subtype => (
                    //       <option key={subtype.id} value={subtype.id}>{subtype.account_name}</option>
                    //     ))} */}
                    //   </optgroup>
                    <option key={account.userId} value={account.userId} style={{ fontSize: 12 }}>{account.name}</option>
                ))}
            </select></>
        );
    };


    // Function to handle selection change
    const handleSubtypeChange = (e) => {
        console.log('e.target.value', e.target.value)
        if (e.target.value === '6') {
            console.log('e.target.value=', e.target.value)
            setSelectedSubtype("accountsPayable")
        }
        else {
            console.log('e.target.value==', e.target.value)
            setSelectedSubtype(e.target.value)
        }
    };
    const handleSupplierChange = (e) => {
        console.log('e.target.value==', e.target.value)
        setSelectedSuplier(e.target.value)
    };
    const canViewTransactions = (usertype, created_by, userid) =>
        usertype == 1 || (usertype == 2 && created_by == userid);
    console.log('selectedSubtype',selectedSubtype)
    return (
        <div className="producservlisting pdt">
            <Dbheader />
            <Sidebar>
                <div className='customerMain'>
                    <div className='custTop'>
                        <Link to='/accounting' className={`${usertype == 2 ?(checkModule('Accounting','GL Account Transactions') ? 'active' :'disabled'):'active'}`}>GL_Account Transactions</Link>
                        <Link to='/transactions' className={`${usertype == 2 ?(checkModule('Accounting','Transactions') ? '' :'disabled'):''}`}>Transactions</Link>
                        <Link to='/accountchart' className={`${usertype == 2 ?(checkModule('Accounting','Chart Of Accounts') ? '' :'disabled'):''}`}>Chart of Accounts</Link>

                    </div>



                    <div className='productServList'>
                        <div className='dbTopsec pb-4 pt-2 noBackbtn'>
                            <Headtxt link="" title="GL_Account Transactions" />

                            <div className='custButton'>
                                <button className={`${usertype == 2 ?(checkModule('Accounting','GL Account Transactions') ? 'commonButton expBtn' :'commonButton expBtn disabled'):'commonButton expBtn'}`}><i class="fa-light fa-upload"></i> Export</button>
                            </div>
                        </div>


                        <div className='commonWhite addCustomerForm mb-3 p-3'>
                            <Form>
                                <Form.Group className={`${usertype == 2 ?(checkModule('Accounting','GL Account Transactions') ? 'row' :"row disabled"):'row'}`} controlId="exampleForm.ControlInput1">
                                    <Col md="4" className='mb-3'>
                                        <CustomDropdown options={addaccounttype} placeholder="Account Type"
                                            value={selectedSubtype || ""} onChange={handleSubtypeChange} id={accountId} />
                                    </Col>
                                    <Col md="4" className='datepickr mb-3'>
                                        <DatePicker
                                            selected={selectedDate}
                                            onChange={handleDateChange}
                                            dateFormat="dd/MM/yyyy" // You can customize the date format
                                            isClearable
                                            placeholderText="From"
                                        />
                                    </Col>
                                    <Col md="4" className='datepickr mb-3'>
                                        <DatePicker
                                            selected={selectedDateb}
                                            onChange={handleDateChangeb}
                                            dateFormat="dd/MM/yyyy" // You can customize the date format
                                            startDate={selectedDate}
                                            minDate={selectedDate}
                                            isClearable
                                            placeholderText="To"
                                        />
                                    </Col>
                                </Form.Group>

                                <Form.Group className={`${usertype == 2 ?(checkModule('Accounting','GL Account Transactions') ? 'row' :"row disabled"):'row'}`} controlId="exampleForm.ControlInput1">

                                    <Col md="6" className='mb-3'>
                                        <CustomDropdown2 options={supplierlist} placeholder="Supplierlist" options2={customerlist} placeholder2="Customerlist" value={selectedSupplier} onChange={handleSupplierChange} />
                                    </Col>
                                    <Col md="1">
                                    <button className={`${usertype == 2 ?(checkModule('Accounting','GL Account Transactions') ? 'commonButton expBtn' :'commonButton expBtn disabled'):'commonButton expBtn'}`} onClick={(e) => {
        e.preventDefault(); // Prevent form submission
        getFiltratioTransactionListn();
    }}>Apply</button>
                                    </Col>
                                    <Col md="2" style={{ marginLeft: '20px' }}>
                                        <button className={`${usertype == 2 ?(checkModule('Accounting','GL Account Transactions') ? 'commonButton expBtn' :'commonButton expBtn disabled'):'commonButton expBtn'}`} onClick={onClear} >Clear All</button>
                                    </Col>
                                </Form.Group>
                            </Form>
                        </div>

                        <div>
                            <div className='salesOrdTable'>
                                <div className='listViews'>
                                    <table class="table">
                                        <thead>
                                            <tr>
                                                <th>Date</th>
                                                <th scope="col">Bill ID / Invoice ID</th>
                                                <th scope="col">Debit</th>
                                                <th scope="col">Credit</th>
                                                {usertype == 1 ? (
  <th scope="col">Balance</th>
) : null}

                                                
                                            </tr>
                                        </thead>
                                        <tbody>
                                        {glaccount?.length > 0 &&
  glaccount.map((item, index) => {
    let totalCredit = 0; 
    let totalDebit = 0;

    return (
      <>
        <tr key={`gl-${index}`}>
          <td colSpan="5" className="actTop2">{item?.subHeading}</td>
        </tr>
        <tr>
          <td className="actTop" colSpan="5">{item?.account_name}</td>
        </tr>
        <tr>
          <td><strong>Starting Balance</strong></td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          {usertype == 1 && (
            <td>{getSymbolFromCurrency(currencyData)} {item?.startingBalance}</td>
          )}
        </tr>
        {item?.transaction?.length > 0 ? (
        
            item?.transaction.map((subtype, subIndex) => {
              const creditValue = parseFloat(subtype.credit || 0);
              const debitValue = parseFloat(subtype.debit || 0);

              totalCredit += creditValue;
              totalDebit += debitValue;
           
              return (
                usertype==1 ? 
                <tr key={`trans-${index}-${subIndex}`}>
                  <td>{subtype.date}</td>
                  <td>
                    <Link
                      to={subtype.invoice_id
                        ? `/viewinvoicedetails?id=${subtype.acc_transactions_invoiceId}`
                        : `/viewbilldetails?id=${subtype.acc_transactions_billId}`}
                    >
                      {subtype.bill_id || subtype.invoice_id}
                    </Link>
                  </td>
                  <td><span className="debt">{getSymbolFromCurrency(currencyData)} {debitValue}</span></td>
                  <td>{getSymbolFromCurrency(currencyData)} {creditValue}</td>
                  {usertype == 1 && (
                    <td>{getSymbolFromCurrency(currencyData)} {subtype.balance}</td>
                  )}
                </tr>
                : usertype==2&&subtype.created_by==userid?
                <tr key={`trans-${index}-${subIndex}`}>
                  <td>{subtype.date}</td>
                  <td>
                    <Link
                      to={subtype.invoice_id
                        ? `/viewinvoicedetails?id=${subtype.acc_transactions_invoiceId}`
                        : `/viewbilldetails?id=${subtype.acc_transactions_billId}`}
                    >
                      {subtype.bill_id || subtype.invoice_id}
                    </Link>
                  </td>
                  <td><span className="debt">{getSymbolFromCurrency(currencyData)} {debitValue}</span></td>
                  <td>{getSymbolFromCurrency(currencyData)} {creditValue}</td>
                  {usertype == 1 && (
                    <td>{getSymbolFromCurrency(currencyData)} {subtype.balance}</td>
                  )}
                </tr>
                :null
              );
           
            })
         
        ) : (
          <tr>
            <td colSpan="5">No transactions available</td>
          </tr>
        )}
      </>
    );
  })}




                                            {
                                                accountPayable[0]?.length !== 0 ?
                                                    <>
                                                        <tr>
                                                            <td colSpan="5" className='actTop2'>{accountPayable[0]?.subHeading}</td>
                                                        </tr>
                                                        <tr>
                                                            <td className='actTop' colSpan="5">{accountPayable[0]?.account_name}</td>
                                                        </tr>


                                                        {

                                                            accountPayable[0]?.transaction?.map((item,index) => {
                                                                totalCredit += parseFloat(item?.credit);
                                                                totalDebit += parseFloat(item?.debit);
                                                                return (
                                                                    usertype==1?
                                                                    <tr>
                                                                        <td>{item?.date}</td>
                                                                        <td>
                                                                            <Link to={`/viewbilldetails?id=${item?.acc_transactions_billId}`}>
                                                                                {item?.bill_id}
                                                                            </Link>
                                                                        </td>
                                                                        <td><span className='debt'>{getSymbolFromCurrency(currencyData)} {item?.debit}</span></td>
                                                                        <td>{getSymbolFromCurrency(currencyData)}  {item?.credit}</td>
                                                                        {usertype == 1 ?  <td>{getSymbolFromCurrency(currencyData)}  {item?.balance}</td>:null}
                                                                    </tr>
                                                                    : usertype==2&&item?.created_by==userid?
                                                                    <tr>
                                                                        <td>{item?.date}</td>
                                                                        <td>
                                                                            <Link to={`/viewbilldetails?id=${item?.acc_transactions_billId}`}>
                                                                                {item?.bill_id}
                                                                            </Link>
                                                                        </td>
                                                                        <td><span className='debt'>{getSymbolFromCurrency(currencyData)} {item?.debit}</span></td>
                                                                        <td>{getSymbolFromCurrency(currencyData)}  {item?.credit}</td>
                                                                        {usertype == 1 ?  <td>{getSymbolFromCurrency(currencyData)}  {item?.balance}</td>:null}
                                                                    </tr>
                                                                    :null
                                                                );
                                                            })}
                                                            {usertype == 1 ? 
                                                        <tr>
                                                            <td><strong>Totals and Ending Balance</strong></td>
                                                            <td>&nbsp;</td>
                                                            <td>{getSymbolFromCurrency(currencyData)}  {totalDebit.toFixed(2)}</td>
                                                            <td>{getSymbolFromCurrency(currencyData)}  {totalCredit.toFixed(2)}</td>
                                                            <td>{getSymbolFromCurrency(currencyData)}  {accountPayable[0]?.endingBalance}</td>
                                                        </tr>
                                                        :null}
                                                        <tr>
                                                            <td>
                                                                <h6>Balance Change</h6>
                                                                <span>Difference between starting and ending balances</span>
                                                            </td>
                                                            <td>&nbsp;</td>
                                                            <td>&nbsp;</td>
                                                            <td>&nbsp;</td>
                                                            {usertype == 1 ? <td><span className='debt'>{getSymbolFromCurrency(currencyData)} {accountPayable[0]?.endingBalance - accountPayable[0]?.startingBalance}</span></td>:null}
                                                        </tr>
                                                    </>
                                                    : null
                                            }
                                            <tr><td colSpan="5" className='nobgColor px-0'>&nbsp;</td></tr>

                                            {
                                                accountReceivable[0]?.length !== 0 ?

                                                    accountReceivable?.map((item, index) => {
                                                        let totalCredit = 0; // Initialize totalCredit for each item
                                                        let totalDebit = 0;
                                                        return (
                                                            <>
                                                                <tr key={`item?.${index}`}>
                                                                    <td colSpan="5" className='actTop2'>{item?.subHeading}</td>
                                                                </tr>
                                                                <tr >
                                                                    <td className='actTop' colSpan="5">{item?.account_name}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td><strong>Starting Balance</strong></td>
                                                                    <td>&nbsp;</td>
                                                                    <td>&nbsp;</td>
                                                                    <td>&nbsp;</td>
                                                                    {usertype == 1 ? <td>{getSymbolFromCurrency(currencyData)} {item?.startingBalance}</td>:null}
                                                                </tr>

                                                                {item?.transaction?.length > 0 ? (
                                                                    item?.transaction?.map((subtype, subIndex) => {
                                                                        // Accumulate the credit of each transaction into the totalCredit variable
                                                                        totalCredit += parseFloat(subtype.credit);
                                                                        totalDebit += parseFloat(subtype.debit);
                                                                        return (
                                                                            usertype==1?
                                                                            <tr key={`item?.${index}-subtype-${subIndex}`}>
                                                                                <td>{subtype.date}</td>
                                                                                <td>
                                                                                    <Link to={subtype.invoice_id === null ? `/viewbilldetails?id=${subtype.acc_transactions_billId}` : `/viewinvoicedetails?id=${subtype.acc_transactions_invoiceId}`}>
                                                                                        {subtype.invoice_id}
                                                                                    </Link>
                                                                                </td>
                                                                                <td><span className='debt'>{getSymbolFromCurrency(currencyData)}  {subtype.debit}</span></td>
                                                                                <td>{getSymbolFromCurrency(currencyData)}  {subtype.credit}</td>
                                                                                {usertype == 1 ? <td>{getSymbolFromCurrency(currencyData)}  {subtype.balance}</td>:null}
                                                                            </tr>
                                                                            : usertype==2&&subtype.created_by==userid?
                                                                            <tr key={`item?.${index}-subtype-${subIndex}`}>
                                                                                <td>{subtype.date}</td>
                                                                                <td>
                                                                                    <Link to={subtype.invoice_id === null ? `/viewbilldetails?id=${subtype.acc_transactions_billId}` : `/viewinvoicedetails?id=${subtype.acc_transactions_invoiceId}`}>
                                                                                        {subtype.invoice_id}
                                                                                    </Link>
                                                                                </td>
                                                                                <td><span className='debt'>{getSymbolFromCurrency(currencyData)}  {subtype.debit}</span></td>
                                                                                <td>{getSymbolFromCurrency(currencyData)}  {subtype.credit}</td>
                                                                                {usertype == 1 ? <td>{getSymbolFromCurrency(currencyData)}  {subtype.balance}</td>:null}
                                                                            </tr>
                                                                            :null
                                                                        );
                                                                    })
                                                                ) : (
                                                                    <tr>
                                                                        <td colSpan="5">No transactions available</td>
                                                                    </tr>
                                                                )}

                                                                {/* Display the total credit for the current item?.*/}
                                                                {usertype == 1 ?
                                                                <tr>
                                                                    <td><strong>Totals and Ending Balance</strong></td>
                                                                    <td>&nbsp;</td>
                                                                    <td>{getSymbolFromCurrency(currencyData)}  {totalDebit.toFixed(2)}</td>
                                                                    <td>{getSymbolFromCurrency(currencyData)}  {totalCredit.toFixed(2)}</td>
                                                                     <td>{getSymbolFromCurrency(currencyData)}  {item?.endingBalance}</td>
                                                                </tr>
                                                                :null}
                                                                <tr>
                                                                    <td>
                                                                        <h6>Balance Change</h6>
                                                                        <span>Difference between starting and ending balances</span>
                                                                    </td>
                                                                    <td>&nbsp;</td>
                                                                    <td>&nbsp;</td>
                                                                    <td>&nbsp;</td>
                                                                    {usertype == 1 ? <td><span className='debt'>{getSymbolFromCurrency(currencyData)} {item?.endingBalance - item?.startingBalance}</span></td>:null}
                                                                </tr>
                                                                <tr><td colSpan="5" className='nobgColor px-0'>&nbsp;</td></tr>
                                                            </>
                                                        );
                                                    })
                                                    : null
                                            }
                                            <tr><td colSpan="5" className='nobgColor px-0'>&nbsp;</td></tr>
                                            {
                                                //account?.length > 0 ? 
                                                account[0]?.length !== 0 ?
                                                    <>
                                                        <tr>
                                                            <td colSpan="5" className='actTop2'>{account[0]?.subHeading}</td>
                                                        </tr>
                                                        <tr>
                                                            <td className='actTop' colSpan="5">{account[0]?.account_name}</td>
                                                        </tr>
                                                        {/* account */}
                                                        <tr>
                                                            <td><strong>Starting Balance</strong></td>
                                                            <td>&nbsp;</td>
                                                            <td>&nbsp;</td>
                                                            <td>&nbsp;</td>
                                                            {usertype == 1 ?<td>{getSymbolFromCurrency(currencyData)}  {account[0]?.startingBalance}</td>:null}
                                                        </tr>

                                                        {

                                                            account[0]?.transaction?.map((item, index) => {
                                                                totalCredit2 = parseFloat(item?.credit);
                                                                totalDebit2 += parseFloat(item?.debit);
                                                                console.log('totalCredi,,,,', parseFloat(item?.credit))
                                                                return (
                                                                    usertype==1?
                                                                    <tr>
                                                                        <td>{item?.date}</td>
                                                                        <td>
                                                                            <Link to={item?.invoice_id === null ? `/viewbilldetails?id=${item?.acc_transactions_billId}` : `/viewinvoicedetails?id=${item?.acc_transactions_invoiceId}`}>
                                                                                {item?.bill_id === null ? item?.invoice_id : item?.bill_id}
                                                                            </Link>
                                                                        </td>
                                                                        <td><span className='debt'>{getSymbolFromCurrency(currencyData)} {item?.debit}</span></td>
                                                                        <td>{getSymbolFromCurrency(currencyData)}  {item?.credit}</td>
                                                                        {usertype == 1 ? <td>{getSymbolFromCurrency(currencyData)}  {item?.balance}</td>:null}
                                                                    </tr>
                                                                    : usertype==2&&item?.created_by==userid?
                                                                    <tr>
                                                                        <td>{item?.date}</td>
                                                                        <td>
                                                                            <Link to={item?.invoice_id === null ? `/viewbilldetails?id=${item?.acc_transactions_billId}` : `/viewinvoicedetails?id=${item?.acc_transactions_invoiceId}`}>
                                                                                {item?.bill_id === null ? item?.invoice_id : item?.bill_id}
                                                                            </Link>
                                                                        </td>
                                                                        <td><span className='debt'>{getSymbolFromCurrency(currencyData)} {item?.debit}</span></td>
                                                                        <td>{getSymbolFromCurrency(currencyData)}  {item?.credit}</td>
                                                                        {usertype == 1 ? <td>{getSymbolFromCurrency(currencyData)}  {item?.balance}</td>:null}
                                                                    </tr>
                                                                    :null
                                                                );
                                                            })}
{usertype == 1 ?
                                                        <tr>
                                                            <td><strong>Totals and Ending Balance</strong></td>
                                                            <td>&nbsp;</td>
                                                            <td>{getSymbolFromCurrency(currencyData)}  {totalDebit2.toFixed(2)}</td>
                                                            <td>{getSymbolFromCurrency(currencyData)}  {totalCredit2.toFixed(2)}</td>
                                                             <td>{getSymbolFromCurrency(currencyData)}  {account[0]?.endingBalance}</td>
                                                        </tr>
                                                        :null}
                                                        <tr>
                                                            <td>
                                                                <h6>Balance Change</h6>
                                                                <span>Difference between starting and ending balances</span>
                                                            </td>
                                                            <td>&nbsp;</td>
                                                            <td>&nbsp;</td>
                                                            <td>&nbsp;</td>
                                                            {usertype == 1 ?<td><span className='debt'>{getSymbolFromCurrency(currencyData)} {account[0]?.endingBalance - account[0]?.startingBalance}</span></td>:null}
                                                        </tr>
                                                    </>
                                                    : null

                                            }
                                            <tr><td colSpan="5" className='nobgColor px-0'>&nbsp;</td></tr>

                                            {
                                                inventory?.length > 0 ?
                                                    inventory.map((item,index) => {
                                                        let totalCredit = 0; // Initialize totalCredit for each item
                                                        let totalDebit = 0;
                                                        return (
                                                            <>
                                                                <tr key={`item?.${index}`}>
                                                                    <td colSpan="5" className='actTop2'>{item?.subHeading}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td className='actTop' colSpan="5">{item?.account_name}</td>
                                                                </tr>

                                                                <tr>
                                                                    <td><strong>Starting Balance</strong></td>
                                                                    <td>&nbsp;</td>
                                                                    <td>&nbsp;</td>
                                                                    <td>&nbsp;</td>
                                                                    {usertype == 1 ? <td>{getSymbolFromCurrency(currencyData)} {item?.startingBalance}</td>:null}
                                                                </tr>

                                                                {item?.transaction?.length > 0 ? (
                                                                    item?.transaction?.map((subtype, subIndex) => {
                                                                        // Accumulate the credit of each transaction into the totalCredit variable
                                                                        totalCredit += parseFloat(subtype.credit);
                                                                        totalDebit += parseFloat(subtype.debit);
                                                                        return (
                                                                            usertype==1?
                                                                            <tr key={`item?.${index}-subtype-${subIndex}`}>
                                                                                <td>{subtype.date}</td>
                                                                                <td>
                                                                                    <Link to={subtype.invoice_id === null ? `/viewbilldetails?id=${subtype.acc_transactions_billId}` : `/viewinvoicedetails?id=${subtype.acc_transactions_invoiceId}`}>
                                                                                        {subtype.bill_id === null ? subtype.invoice_id : subtype.bill_id}
                                                                                    </Link>
                                                                                </td>
                                                                                <td><span className='debt'>{getSymbolFromCurrency(currencyData)}   {subtype.debit}</span></td>
                                                                                <td>{getSymbolFromCurrency(currencyData)}   {subtype.credit}</td>
                                                                                {usertype == 1 ? <td>{getSymbolFromCurrency(currencyData)}   {subtype.balance}</td>:null}
                                                                            </tr>
                                                                            : usertype==2&&subtype.created_by==userid?
                                                                            <tr key={`item?.${index}-subtype-${subIndex}`}>
                                                                                <td>{subtype.date}</td>
                                                                                <td>
                                                                                    <Link to={subtype.invoice_id === null ? `/viewbilldetails?id=${subtype.acc_transactions_billId}` : `/viewinvoicedetails?id=${subtype.acc_transactions_invoiceId}`}>
                                                                                        {subtype.bill_id === null ? subtype.invoice_id : subtype.bill_id}
                                                                                    </Link>
                                                                                </td>
                                                                                <td><span className='debt'>{getSymbolFromCurrency(currencyData)}   {subtype.debit}</span></td>
                                                                                <td>{getSymbolFromCurrency(currencyData)}   {subtype.credit}</td>
                                                                                {usertype == 1 ? <td>{getSymbolFromCurrency(currencyData)}   {subtype.balance}</td>:null}
                                                                            </tr>
                                                                            :null
                                                                        );
                                                                    })
                                                                ) : (
                                                                    <tr>
                                                                        <td colSpan="5">No transactions available</td>
                                                                    </tr>
                                                                )}

                                                                {/* Display the total credit for the current item?.*/}
                                                                {usertype == 1 ?
                                                                <tr>
                                                                    <td><strong>Totals and Ending Balance</strong></td>
                                                                    <td>&nbsp;</td>
                                                                    <td>{getSymbolFromCurrency(currencyData)}  {totalDebit.toFixed(2)}</td>
                                                                    <td>{getSymbolFromCurrency(currencyData)}  {totalCredit.toFixed(2)}</td>
                                                                     <td>{getSymbolFromCurrency(currencyData)}  {item?.endingBalance}</td>
                                                                </tr>
                                                                :null}
                                                                <tr>
                                                                    <td>
                                                                        <h6>Balance Change</h6>
                                                                        <span>Difference between starting and ending balances</span>
                                                                    </td>
                                                                    <td>&nbsp;</td>
                                                                    <td>&nbsp;</td>
                                                                    <td>&nbsp;</td>
                                                                    {usertype == 1 ? <td><span className='debt'>{getSymbolFromCurrency(currencyData)} {item?.endingBalance - item?.startingBalance}</span></td>:null}
                                                                </tr>
                                                                <tr><td colSpan="5" className='nobgColor px-0'>&nbsp;</td></tr>
                                                            </>
                                                        );
                                                    })
                                                    : null
                                            }
                                            {
                                                expense?.length > 0 ?

                                                    expense.map((item, index) => {
                                                        let totalCredit = 0; // Initialize totalCredit for each item
                                                        let totalDebit = 0;
                                                        return (
                                                            <>
                                                                <tr key={`item?.${index}`}>
                                                                    <td colSpan="5" className='actTop2'>{item?.subHeading}</td>
                                                                </tr>
                                                                <tr key={`item?.${index}`}>
                                                                    <td className='actTop' colSpan="5">{item?.account_name}</td>
                                                                </tr>

                                                                <tr>
                                                                    <td><strong>Starting Balance</strong></td>
                                                                    <td>&nbsp;</td>
                                                                    <td>&nbsp;</td>
                                                                    <td>&nbsp;</td>
                                                                    {usertype == 1 ? <td>{getSymbolFromCurrency(currencyData)} {item?.startingBalance}</td>:null}
                                                                </tr>

                                                                {item?.transaction?.length > 0 ? (
                                                                    item?.transaction?.map((subtype, subIndex) => {
                                                                        // Accumulate the credit of each transaction into the totalCredit variable
                                                                        totalCredit += parseFloat(subtype.credit);
                                                                        totalDebit += parseFloat(subtype.debit);
                                                                        return (
                                                                            usertype==1?
                                                                            <tr key={`item?.${index}-subtype-${subIndex}`}>
                                                                                <td>{subtype.date}</td>
                                                                                <td>
                                                                                    <Link to={subtype.invoice_id === null ? `/viewbilldetails?id=${subtype.acc_transactions_billId}` : `/viewinvoicedetails?id=${subtype.acc_transactions_invoiceId}`}>
                                                                                        {subtype.bill_id}
                                                                                    </Link>
                                                                                </td>
                                                                                <td><span className='debt'>{getSymbolFromCurrency(currencyData)}  {subtype.debit}</span></td>
                                                                                <td>{getSymbolFromCurrency(currencyData)}  {subtype.credit}</td>
                                                                                {usertype == 1 ? <td>{getSymbolFromCurrency(currencyData)}  {subtype.balance}</td>:null}
                                                                            </tr>
                                                                            : usertype==2&&subtype.created_by==userid?
                                                                            <tr key={`item?.${index}-subtype-${subIndex}`}>
                                                                                <td>{subtype.date}</td>
                                                                                <td>
                                                                                    <Link to={subtype.invoice_id === null ? `/viewbilldetails?id=${subtype.acc_transactions_billId}` : `/viewinvoicedetails?id=${subtype.acc_transactions_invoiceId}`}>
                                                                                        {subtype.bill_id}
                                                                                    </Link>
                                                                                </td>
                                                                                <td><span className='debt'>{getSymbolFromCurrency(currencyData)}  {subtype.debit}</span></td>
                                                                                <td>{getSymbolFromCurrency(currencyData)}  {subtype.credit}</td>
                                                                                {usertype == 1 ? <td>{getSymbolFromCurrency(currencyData)}  {subtype.balance}</td>:null}
                                                                            </tr>
                                                                            :null
                                                                        );
                                                                    })
                                                                ) : (
                                                                    <tr>
                                                                        <td colSpan="5">No transactions available</td>
                                                                    </tr>
                                                                )}

                                                                {/* Display the total credit for the current item?.*/}
                                                                {usertype == 1 ? 
                                                                <tr>
                                                                    <td><strong>Totals and Ending Balance</strong></td>
                                                                    <td>&nbsp;</td>
                                                                    <td>{getSymbolFromCurrency(currencyData)}  {totalDebit.toFixed(2)}</td>
                                                                    <td>{getSymbolFromCurrency(currencyData)}  {totalCredit.toFixed(2)}</td>
                                                                   <td>{getSymbolFromCurrency(currencyData)}  {item?.endingBalance}</td>
                                                                </tr>
                                                                :null}
                                                                <tr>
                                                                    <td>
                                                                        <h6>Balance Change</h6>
                                                                        <span>Difference between starting and ending balances</span>
                                                                    </td>
                                                                    <td>&nbsp;</td>
                                                                    <td>&nbsp;</td>
                                                                    <td>&nbsp;</td>
                                                                    {usertype == 1 ?<td><span className='debt'>${item?.endingBalance - item?.startingBalance}</span></td>:null}
                                                                </tr>
                                                                <tr><td colSpan="5" className='nobgColor px-0'>&nbsp;</td></tr>
                                                            </>
                                                        );
                                                    })
                                                    : null
                                            }


                                            <tr><td colSpan="5" className='nobgColor px-0'>&nbsp;</td></tr>

                                            {
                                                lossForeignExchange[0]?.length !== 0 ?

                                                    lossForeignExchange.map((item, index) => {
                                                        let totalCredit = 0; // Initialize totalCredit for each item
                                                        let totalDebit = 0;
                                                        return (
                                                            <>
                                                                <tr key={`item?.${index}`}>
                                                                    <td colSpan="5" className='actTop2'>{item?.subHeading}</td>
                                                                </tr>
                                                                <tr >
                                                                    <td className='actTop' colSpan="5">{item?.account_name}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td><strong>Starting Balance</strong></td>
                                                                    <td>&nbsp;</td>
                                                                    <td>&nbsp;</td>
                                                                    <td>&nbsp;</td>
                                                                    {usertype == 1 ? <td>{getSymbolFromCurrency(currencyData)} {item?.startingBalance}</td>:null}
                                                                </tr>

                                                                {item?.transaction?.length > 0 ? (
                                                                    item?.transaction?.map((subtype, subIndex) => {
                                                                        // Accumulate the credit of each transaction into the totalCredit variable
                                                                        totalCredit += parseFloat(subtype.credit);
                                                                        totalDebit += parseFloat(subtype.debit);
                                                                        return (
                                                                            usertype==1?
                                                                            <tr key={`item?.${index}-subtype-${subIndex}`}>
                                                                                <td>{subtype.date}</td>
                                                                                <td>
                                                                                    <Link to={subtype.invoice_id === null ? `/viewbilldetails?id=${subtype.acc_transactions_billId}` : `/viewinvoicedetails?id=${subtype.acc_transactions_invoiceId}`}>
                                                                                        {subtype.bill_id}
                                                                                    </Link>
                                                                                </td>
                                                                                <td><span className='debt'>{getSymbolFromCurrency(currencyData)}  {subtype.debit}</span></td>
                                                                                <td>{getSymbolFromCurrency(currencyData)}  {subtype.credit}</td>
                                                                                {usertype == 1 ? <td>{getSymbolFromCurrency(currencyData)}  {subtype.balance}</td>:null}
                                                                            </tr>
                                                                            : usertype==2&&subtype.created_by==userid?
                                                                            <tr key={`item?.${index}-subtype-${subIndex}`}>
                                                                                <td>{subtype.date}</td>
                                                                                <td>
                                                                                    <Link to={subtype.invoice_id === null ? `/viewbilldetails?id=${subtype.acc_transactions_billId}` : `/viewinvoicedetails?id=${subtype.acc_transactions_invoiceId}`}>
                                                                                        {subtype.bill_id}
                                                                                    </Link>
                                                                                </td>
                                                                                <td><span className='debt'>{getSymbolFromCurrency(currencyData)}  {subtype.debit}</span></td>
                                                                                <td>{getSymbolFromCurrency(currencyData)}  {subtype.credit}</td>
                                                                                {usertype == 1 ? <td>{getSymbolFromCurrency(currencyData)}  {subtype.balance}</td>:null}
                                                                            </tr>
                                                                            :null
                                                                        );
                                                                    })
                                                                ) : (
                                                                    <tr>
                                                                        <td colSpan="5">No transactions available</td>
                                                                    </tr>
                                                                )}

                                                                {/* Display the total credit for the current item?.*/}
                                                                {usertype == 1 ? 
                                                                <tr>
                                                                    <td><strong>Totals and Ending Balance</strong></td>
                                                                    <td>&nbsp;</td>
                                                                    <td>{getSymbolFromCurrency(currencyData)}  {totalDebit.toFixed(2)}</td>
                                                                    <td>{getSymbolFromCurrency(currencyData)}  {totalCredit.toFixed(2)}</td>
                                                                     <td>{getSymbolFromCurrency(currencyData)}  {item?.endingBalance}</td>
                                                                </tr>
                                                                :null}
                                                                <tr>
                                                                    <td>
                                                                        <h6>Balance Change</h6>
                                                                        <span>Difference between starting and ending balances</span>
                                                                    </td>
                                                                    <td>&nbsp;</td>
                                                                    <td>&nbsp;</td>
                                                                    <td>&nbsp;</td>
                                                                    {usertype == 1 ?    <td><span className='debt'>{getSymbolFromCurrency(currencyData)} {item?.endingBalance - item?.startingBalance}</span></td>:null}
                                                                </tr>
                                                                <tr><td colSpan="5" className='nobgColor px-0'>&nbsp;</td></tr>
                                                            </>
                                                        );
                                                    })
                                                    : null
                                            }

                                            {
                                                profitForeignExchange[0]?.length !== 0 ?

                                                    profitForeignExchange.map((item, index) => {
                                                        let totalCredit = 0; // Initialize totalCredit for each item
                                                        let totalDebit = 0;
                                                        return (
                                                            <>
                                                                <tr key={`item?.${index}`}>
                                                                    <td colSpan="5" className='actTop2'>{item?.subHeading}</td>
                                                                </tr>
                                                                <tr >
                                                                    <td className='actTop' colSpan="5">{item?.account_name}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td><strong>Starting Balance</strong></td>
                                                                    <td>&nbsp;</td>
                                                                    <td>&nbsp;</td>
                                                                    <td>&nbsp;</td>
                                                                    {usertype == 1 ? <td>{getSymbolFromCurrency(currencyData)} {item?.startingBalance}</td>:null}
                                                                </tr>

                                                                {item?.transaction?.length > 0 ? (
                                                                    item?.transaction?.map((subtype, subIndex) => {
                                                                        // Accumulate the credit of each transaction into the totalCredit variable
                                                                        totalCredit += parseFloat(subtype.credit);
                                                                        totalDebit += parseFloat(subtype.debit);
                                                                        return (
                                                                            usertype==1?
                                                                            <tr key={`item?.${index}-subtype-${subIndex}`}>
                                                                                <td>{subtype.date}</td>
                                                                                <td>
                                                                                    <Link to={subtype.invoice_id === null ? `/viewbilldetails?id=${subtype.acc_transactions_billId}` : `/viewinvoicedetails?id=${subtype.acc_transactions_invoiceId}`}>
                                                                                        {subtype.bill_id}
                                                                                    </Link>
                                                                                </td>
                                                                                <td><span className='debt'>{getSymbolFromCurrency(currencyData)}  {subtype.debit}</span></td>
                                                                                <td>{getSymbolFromCurrency(currencyData)}  {subtype.credit}</td>
                                                                                {usertype == 1 ? <td>{getSymbolFromCurrency(currencyData)}  {subtype.balance}</td>:null}
                                                                            </tr>
                                                                            : usertype==2&&subtype.created_by==userid?
                                                                            <tr key={`item?.${index}-subtype-${subIndex}`}>
                                                                                <td>{subtype.date}</td>
                                                                                <td>
                                                                                    <Link to={subtype.invoice_id === null ? `/viewbilldetails?id=${subtype.acc_transactions_billId}` : `/viewinvoicedetails?id=${subtype.acc_transactions_invoiceId}`}>
                                                                                        {subtype.bill_id}
                                                                                    </Link>
                                                                                </td>
                                                                                <td><span className='debt'>{getSymbolFromCurrency(currencyData)}  {subtype.debit}</span></td>
                                                                                <td>{getSymbolFromCurrency(currencyData)}  {subtype.credit}</td>
                                                                                {usertype == 1 ? <td>{getSymbolFromCurrency(currencyData)}  {subtype.balance}</td>:null}
                                                                            </tr>
                                                                            :null
                                                                        );
                                                                    })
                                                                ) : (
                                                                    <tr>
                                                                        <td colSpan="5">No transactions available</td>
                                                                    </tr>
                                                                )}

                                                                {/* Display the total credit for the current item?.*/}
                                                                {usertype == 1 ?
                                                                <tr>
                                                                    <td><strong>Totals and Ending Balance</strong></td>
                                                                    <td>&nbsp;</td>
                                                                    <td>{getSymbolFromCurrency(currencyData)}  {totalDebit.toFixed(2)}</td>
                                                                    <td>{getSymbolFromCurrency(currencyData)}  {totalCredit.toFixed(2)}</td>
                                                                     <td>{getSymbolFromCurrency(currencyData)}  {item?.endingBalance}</td>
                                                                </tr>
                                                                :null}
                                                                <tr>
                                                                    <td>
                                                                        <h6>Balance Change</h6>
                                                                        <span>Difference between starting and ending balances</span>
                                                                    </td>
                                                                    <td>&nbsp;</td>
                                                                    <td>&nbsp;</td>
                                                                    <td>&nbsp;</td>
                                                                    {usertype == 1 ? <td><span className='debt'>{getSymbolFromCurrency(currencyData)} {item?.endingBalance - item?.startingBalance}</span></td>:null}
                                                                </tr>
                                                                <tr><td colSpan="5" className='nobgColor px-0'>&nbsp;</td></tr>
                                                            </>
                                                        );
                                                    })
                                                    : null
                                            }

                                            <tr><td colSpan="5" className='nobgColor px-0'>&nbsp;</td></tr>
                                            {
                                                incomeAccounts[0]?.length !== 0 ?

                                                    incomeAccounts?.map((item, index) => {
                                                        let totalCredit = 0; // Initialize totalCredit for each item
                                                        let totalDebit = 0;
                                                        return (
                                                            <>
                                                                <tr key={`item?.${index}`}>
                                                                    <td colSpan="5" className='actTop2'>{item?.subHeading}</td>
                                                                </tr>
                                                                <tr >
                                                                    <td className='actTop' colSpan="5">{item?.account_name}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td><strong>Starting Balance</strong></td>
                                                                    <td>&nbsp;</td>
                                                                    <td>&nbsp;</td>
                                                                    <td>&nbsp;</td>
                                                                    {usertype == 1 ? <td>{getSymbolFromCurrency(currencyData)} {item?.startingBalance}</td>:null}
                                                                </tr>

                                                                {item?.transaction?.length > 0 ? (
                                                                    item?.transaction?.map((subtype, subIndex) => {
                                                                        // Accumulate the credit of each transaction into the totalCredit variable
                                                                        totalCredit += parseFloat(subtype.credit);
                                                                        totalDebit += parseFloat(subtype.debit);
                                                                        return (
                                                                            usertype==1?
                                                                            <tr key={`item?.${index}-subtype-${subIndex}`}>
                                                                                <td>{subtype.date}</td>
                                                                                <td>
                                                                                    <Link to={subtype.invoice_id === null ? `/viewbilldetails?id=${subtype.acc_transactions_billId}` : `/viewinvoicedetails?id=${subtype.acc_transactions_invoiceId}`}>
                                                                                        {subtype.invoice_id}
                                                                                    </Link>
                                                                                </td>
                                                                                <td><span className='debt'>{getSymbolFromCurrency(currencyData)}  {subtype.debit}</span></td>
                                                                                <td>{getSymbolFromCurrency(currencyData)}  {subtype.credit}</td>
                                                                                {usertype == 1 ? <td>{getSymbolFromCurrency(currencyData)}  {subtype.balance}</td>:null}
                                                                            </tr>
                                                                            : usertype==2&&subtype.created_by==userid?
                                                                            <tr key={`item?.${index}-subtype-${subIndex}`}>
                                                                                <td>{subtype.date}</td>
                                                                                <td>
                                                                                    <Link to={subtype.invoice_id === null ? `/viewbilldetails?id=${subtype.acc_transactions_billId}` : `/viewinvoicedetails?id=${subtype.acc_transactions_invoiceId}`}>
                                                                                        {subtype.invoice_id}
                                                                                    </Link>
                                                                                </td>
                                                                                <td><span className='debt'>{getSymbolFromCurrency(currencyData)}  {subtype.debit}</span></td>
                                                                                <td>{getSymbolFromCurrency(currencyData)}  {subtype.credit}</td>
                                                                                {usertype == 1 ? <td>{getSymbolFromCurrency(currencyData)}  {subtype.balance}</td>:null}
                                                                            </tr>
                                                                            :null
                                                                        );
                                                                    })
                                                                ) : (
                                                                    <tr>
                                                                        <td colSpan="5">No transactions available</td>
                                                                    </tr>
                                                                )}

                                                                {/* Display the total credit for the current item?.*/}
                                                                {usertype == 1 ? 
                                                                <tr>
                                                                    <td><strong>Totals and Ending Balance</strong></td>
                                                                    <td>&nbsp;</td>
                                                                    <td>{getSymbolFromCurrency(currencyData)}  {totalDebit.toFixed(2)}</td>
                                                                    <td>{getSymbolFromCurrency(currencyData)}  {totalCredit.toFixed(2)}</td>
                                                                   <td>{getSymbolFromCurrency(currencyData)}  {item?.endingBalance}</td>
                                                                </tr>
                                                                :null}
                                                                <tr>
                                                                    <td>
                                                                        <h6>Balance Change</h6>
                                                                        <span>Difference between starting and ending balances</span>
                                                                    </td>
                                                                    <td>&nbsp;</td>
                                                                    <td>&nbsp;</td>
                                                                    <td>&nbsp;</td>
                                                                    {usertype == 1 ?<td><span className='debt'>{getSymbolFromCurrency(currencyData)} {item?.endingBalance - item?.startingBalance}</span></td>:null}
                                                                </tr>
                                                                <tr><td colSpan="5" className='nobgColor px-0'>&nbsp;</td></tr>
                                                            </>
                                                        );
                                                    })
                                                    : null
                                            }
                                            <tr><td colSpan="5" className='nobgColor px-0'>&nbsp;</td></tr>
                                            {
                                                costOfGoodsSoldAccounts[0]?.length !== 0 ?

                                                    costOfGoodsSoldAccounts?.map((item, index) => {
                                                        let totalCredit = 0; // Initialize totalCredit for each item
                                                        let totalDebit = 0;
                                                        return (
                                                            <>
                                                                <tr key={`item?.${index}`}>
                                                                    <td colSpan="5" className='actTop2'>{item?.subHeading}</td>
                                                                </tr>
                                                                <tr >
                                                                    <td className='actTop' colSpan="5">{item?.account_name}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td><strong>Starting Balance</strong></td>
                                                                    <td>&nbsp;</td>
                                                                    <td>&nbsp;</td>
                                                                    <td>&nbsp;</td>
                                                                    {usertype == 1 ? <td>{getSymbolFromCurrency(currencyData)} {item?.startingBalance}</td>:null}
                                                                </tr>

                                                                {item?.transaction?.length > 0 ? (
                                                                    item?.transaction?.map((subtype, subIndex) => {
                                                                        // Accumulate the credit of each transaction into the totalCredit variable
                                                                        totalCredit += parseFloat(subtype.credit);
                                                                        totalDebit += parseFloat(subtype.debit);
                                                                        return (
                                                                            usertype==1?
                                                                            <tr key={`item?.${index}-subtype-${subIndex}`}>
                                                                                <td>{subtype.date}</td>
                                                                                <td>
                                                                                    <Link to={subtype.invoice_id === null ? `/viewbilldetails?id=${subtype.acc_transactions_billId}` : `/viewinvoicedetails?id=${subtype.acc_transactions_invoiceId}`}>
                                                                                        {subtype.invoice_id}
                                                                                    </Link>
                                                                                </td>
                                                                                <td><span className='debt'>{getSymbolFromCurrency(currencyData)}  {subtype.debit}</span></td>
                                                                                <td>{getSymbolFromCurrency(currencyData)}  {subtype.credit}</td>
                                                                                {usertype == 1 ? <td>{getSymbolFromCurrency(currencyData)}   {subtype.balance}</td>:null}
                                                                            </tr>
                                                                            : usertype==2&&subtype.created_by==userid?
                                                                            <tr key={`item?.${index}-subtype-${subIndex}`}>
                                                                                <td>{subtype.date}</td>
                                                                                <td>
                                                                                    <Link to={subtype.invoice_id === null ? `/viewbilldetails?id=${subtype.acc_transactions_billId}` : `/viewinvoicedetails?id=${subtype.acc_transactions_invoiceId}`}>
                                                                                        {subtype.invoice_id}
                                                                                    </Link>
                                                                                </td>
                                                                                <td><span className='debt'>{getSymbolFromCurrency(currencyData)}  {subtype.debit}</span></td>
                                                                                <td>{getSymbolFromCurrency(currencyData)}  {subtype.credit}</td>
                                                                                {usertype == 1 ? <td>{getSymbolFromCurrency(currencyData)}   {subtype.balance}</td>:null}
                                                                            </tr>
                                                                            :null
                                                                        );
                                                                    })
                                                                ) : (
                                                                    <tr>
                                                                        <td colSpan="5">No transactions available</td>
                                                                    </tr>
                                                                )}

                                                                {/* Display the total credit for the current item?.*/}
                                                                {usertype == 1 ?
                                                                <tr>
                                                                    <td><strong>Totals and Ending Balance</strong></td>
                                                                    <td>&nbsp;</td>
                                                                    <td>{getSymbolFromCurrency(currencyData)}  {totalDebit.toFixed(2)}</td>
                                                                    <td>{getSymbolFromCurrency(currencyData)}  {totalCredit.toFixed(2)}</td>
                                                                    <td>{getSymbolFromCurrency(currencyData)}  {item?.endingBalance}</td>
                                                                </tr>
                                                                :null}
                                                                <tr>
                                                                    <td>
                                                                        <h6>Balance Change</h6>
                                                                        <span>Difference between starting and ending balances</span>
                                                                    </td>
                                                                    <td>&nbsp;</td>
                                                                    <td>&nbsp;</td>
                                                                    <td>&nbsp;</td>
                                                                    {usertype == 1 ? <td><span className='debt'>{getSymbolFromCurrency(currencyData)} {item?.endingBalance - item?.startingBalance}</span></td>:null}
                                                                </tr>
                                                                <tr><td colSpan="5" className='nobgColor px-0'>&nbsp;</td></tr>
                                                            </>
                                                        );
                                                    })
                                                    : null
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Sidebar>
        </div>
    )
}

export default Accounting;