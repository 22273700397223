import React, { useState, useEffect } from 'react';
import Sidebar from '../../layout/Sidebar'
import Dbheader from '../../layout/Dbheader'
import { Link } from 'react-router-dom'
import Form from 'react-bootstrap/Form';
import Gridview from './Gridview';
import Listview from './Listview';
import Headtxt from '../Headtxt';
import { Button, Modal } from 'react-bootstrap';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { deleteCustomer, getCustomerList, getSearchCustomer, getExcelCustomer } from "../../../../Service/Customer/CustomerService";
import Card from 'react-bootstrap/Card'
import { useNavigate } from 'react-router-dom';
import { CSVLink } from "react-csv";

import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Swal from 'sweetalert2';



const Customerlisting = () => {

    const [selectedPage, setSelectedPage] = useState('/');
    const navigate = useNavigate();

    const handlePageChange = (page) => {
        setSelectedPage(page);
        navigate(page);
    };

    const [userData, setUserData] = useState(null);
    const [usertype, setUserType] = useState(null);
  
    useEffect(() => {
      // Retrieve the JSON string from localStorage
      const jsonString = localStorage.getItem('1Simpleclick_User'); // Replace 'yourStorageKey' with the actual key.
  console.log("localdataaa======",localStorage.getItem('1Simpleclick_User'))
      // Parse the JSON string into an object
      const user_type = localStorage.getItem('1Simpleclick_UserType');
      const data = JSON.parse(jsonString);
  
      // Update the state with the retrieved data
      setUserData(data);
      setUserType(user_type)
    }, []);
    const [view, setView] = useState(false)

    const [hasMore, setHasMore] = useState(true);
    const [loading, setLoading] = useState(true);
    const [items, setItems] = useState(['Item 1', 'Item 2', 'Item 3', 'Item 4', 'Item 5', 'Item 6', 'Item 7', 'Item 8', 'Item 9', 'Item 10', 'Item 11', 'Item 12', 'Item 13', 'Item 14', 'Item 15']);
    const [visibleItems, setVisibleItems] = useState(5);
    const fetchMoreItems = (start) => {
        // Simulate fetching more items from an API
        const itemsPerPage = 5;
        const totalItems = 20; // Adjust as needed for your use case
        const newItems = [];
    
        for (let i = start; i < start + itemsPerPage; i++) {
            if (i < totalItems) {
                newItems.push(`Item ${i + 1}`);
            }
        }
    
        return newItems;
    };
useEffect(() => {
    // Initial load of items
    loadMoreItems();
}, []);

const loadMoreItems = () => {
    setLoading(true);
    setTimeout(() => {
        // Simulate loading delay
        setVisibleItems((prev) => prev + 5);
        setLoading(false);
    }, 1000); // Adjust the delay as needed
};
    const toggleHandler = (e) => {
        e.preventDefault();
        setView(true)
    }
    const toggleHandler1 = (e) => {
        e.preventDefault();
        setView(false)
    }
    const [customerList, setAllCustomerList] = useState([]);
    const [deleteCustomerId, setDeleteCustomerId] = useState("");
    const [showDelete, setShowDelete] = useState(false);
    const [customerExport,setCustomerExport]=useState([]);
    const handleCloseDelete = () => setShowDelete(false);

    console.log(customerList, "customerList")
    useEffect(() => {
        getCustomerDataList();
        onExcelExport();
    }, [usertype]);

    async function getCustomerDataList() {
        getCustomerList().then(function (result) {
            const response = result?.data;
            // console.log(response, "rrrr")
          //  setAllCustomerList(response?.data);
            if (usertype ==1) {  
                setAllCustomerList(response?.data); // Set the supplier list if the module is accessible
            }
              else if (usertype == 2 && (checkModule('Sales', 'Customers'))) { // Check user type
                      // if (checkModule('Purchase', 'Supplier')) {
                      console.log('pretirori=====') 
                          setAllCustomerList(response?.data); // Set the supplier list if the module is accessible
                      // } 
                     
                  }
                  else {
                    console.log('pretirori=====-----') 
                    setAllCustomerList([]); // Clear the supplier list otherwise
                }
        });
    }

    const onDelete = (data) => {
        setDeleteCustomerId(data);
        console.log(data, "datadatadatadatadatadata")
        setShowDelete(true)
    }


    const onYes = () => {
        deleteCustomer(deleteCustomerId, (res) => {
            console.log(res, "response")
            // if (res.success === true) {
            //     toast.success(res.message)
            //     setShowDelete(false)
            //     getCustomerDataList();

            // } 
            if (res.success === true) {
                Swal.fire({
                  title: res.message,
                //  text: 'The product has been added successfully!',
                  icon: 'success',
                  confirmButtonText: 'OK',
                  customClass: {
                      confirmButton: 'my-confirm-button'
                  }
              }).then(() => {
                setShowDelete(false)
                getCustomerDataList();
              })
            }
            else {
                toast.error(res.message)

            }
        })
    }

    const onNo = () => {
        setShowDelete(false)

    }

    const onSearch = (e) => {
        console.log(e.target.value, "dsdsdsdsd")
        getSearchCustomer(e.target.value).then(function (result) {
            const response = result?.data;
            console.log(response, "rrrr")
            setAllCustomerList(response?.data);
        });
    }

    const convertToCSV = (data) => {
        // const header = Object.keys(data[0]).join(',');
        // const rows = data.map((row) => Object.values(row).join(','));
        return [data].join('\n');
    };

    const onExcelExport = () => {
        getExcelCustomer().then(function (result) {
            const response = result?.data?.data;
            console.log(response,"response")
            setCustomerExport(response);
        });

    }



// custom-select-drupdown
const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState('More');

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleOptionClick = (option) => {
    setSelectedOption(option);
    setIsOpen(false);
  };

//   const checkModule=(module,subModule)=>{
    
//     if(userData && JSON.parse(userData?.assigned_modules)){
//         console.log("nwwwww-->",JSON.parse(userData?.assigned_modules))
//         const modules=JSON.parse(userData?.assigned_modules)?.modules

//         return modules[module]?.[subModule]
//     }
//     return true
    

//   }
  const checkModule = (module, subModule) => {
    if (userData && userData.assigned_modules) {
      const assignedModules = userData.assigned_modules;
      
      // Handle the case where assigned_modules is an empty string
      if (assignedModules === "") {
        return true; // Or false, depending on the desired behavior
      }
  
      try {
        const modules = JSON.parse(assignedModules);
        
        // if (modules && modules[module]) {
            return modules[module]?.[subModule] || false; // Fallback to false if subModule is not found
     //   }
      } catch (error) {
        console.error("Error parsing assigned_modules:", error);
      }
    }
    return true; // Default to true if no assigned_modules or any errors
  };
  


    return (
        <div className="customerListing pdt">
            <Dbheader />
            <Sidebar>
                <div className='customerMain'>
                    {/* <div className='custTop'>
                        <Navbar expand="lg" className="bg-body-tertiary">
                                <Navbar.Toggle aria-controls="basic-navbar-nav"><i class="fa-regular fa-bars"></i></Navbar.Toggle>
                                <Navbar.Collapse id="basic-navbar-nav">
                                    <Navbar.Toggle aria-controls="basic-navbar-nav"><i class="fa-light fa-circle-xmark"></i></Navbar.Toggle>
                                    <Link to='' className='active'>Customer</Link>
                                    <Link to='/quote'>Quote</Link>
                                   
                                    <Link to='/invoice'>Invoice</Link>
                                    <div className="custom-dropdown-container customDropdown ms-4">
                                        <div className="custom-dropdown-header" onClick={toggleDropdown}>
                                            <span>{selectedOption}</span>
                                            <span className={`dropdown-arrow ${isOpen ? 'open' : ''}`}> <i class="fa-regular fa-angle-down"></i></span>
                                        </div>

                                        {isOpen && (
                                            <ul className="custom-dropdown-options">
                                                <li
                                                    className={`option ${selectedOption === 'Option 1' ? 'selected' : ''}`}
                                                    onClick={() => handleOptionClick('Option 1')} hidden
                                                >
                                                    Option 1
                                                </li>
                                                <li
                                                    className={`option ${selectedOption === 'Option 2' ? 'selected' : ''}`}
                                                    onClick={() => handleOptionClick('Option 2')}
                                                >
                                                    <Link to="/salesorderlisting">Sales Orders</Link>
                                                </li>
                                                <li
                                                    className={`option ${selectedOption === 'Option 3' ? 'selected' : ''}`}
                                                    onClick={() => handleOptionClick('Option 3')}
                                                >
                                                    <Link to="/salesreceiptlist">Sales Receipts</Link>
                                                </li>
                                                <li
                                                    className={`option ${selectedOption === 'Option 4' ? 'selected' : ''}`}
                                                    onClick={() => handleOptionClick('Option 4')}
                                                >
                                                    <Link to="/refundlist">Refunds</Link>
                                                </li>
                                            </ul>
                                        )}
                                        
                                    </div>
                                </Navbar.Collapse>
                        </Navbar>
                    </div> */}


                    <div className='custTop'>
                        <Link to=''  className={`${usertype == 2 ? (checkModule('Sales', 'Customers') ? 'active' : 'disabled') : 'active'} active`}
                        >Customer</Link>
                        <Link to='/quote' className={`${usertype == 2 ?(checkModule('Sales','Quote') ? '' :'disabled'):''}`}>Quote</Link>
                        <Link to="/salesorderlisting" className={usertype == 2 ?(checkModule('Sales','SalesOrders') ? '' :'disabled'):''} >Sales Orders</Link>
                        <Link to='/invoice' className={usertype == 2 ?(checkModule('Sales','Invoices') ? '' :'disabled'):''}>Invoice</Link> 
                        <div className="custom-dropdown-container customDropdown ms-4">
                            <div className="custom-dropdown-header" onClick={toggleDropdown}>
                                <span>{selectedOption}</span>
                                <span className={`dropdown-arrow ${isOpen ? 'open' : ''}`}> <i class="fa-regular fa-angle-down"></i></span>
                            </div>

                            {isOpen && (
                                <ul className="custom-dropdown-options">
                                    <li
                                        className={`option ${selectedOption === 'Option 1' ? 'selected' : ''}`}
                                        onClick={() => handleOptionClick('Option 1')} hidden
                                    >
                                        Option 1
                                    </li>
                                   
                                    <li
                                        className={`option ${selectedOption === 'Option 3' ? 'selected' : ''}`}
                                       // onClick={() => handleOptionClick('Option 3')}
                                    >
                                        <Link to="/salesreceiptlist" className={usertype == 2 ?(checkModule('Sales','SalesReceipt') ? '' :'disabled'):''} >Sales Receipts</Link>
                                    </li>
                                    <li
                                        className={`option ${selectedOption === 'Option 4' ? 'selected' : ''}`}
                                       // onClick={() => handleOptionClick('Option 4')}
                                    >
                                        <Link to="/refundlist" className={usertype == 2 ?(checkModule('Sales','Refund') ? '' :'disabled'):''}>Refunds</Link>
                                    </li>
                                </ul>
                            )}

{/* <p>You selected: {selectedOption}</p> */}
                        </div>
                    </div>
                    <div className='custBtm'>
                        <div className='dbTopsec pb-2 noBackbtn'>
                            <Headtxt link="" title="Customers" />
                        </div>
                        <div className='customerSrchTop pb-3'>
                            <div className='customSrchfield'><span><i class="fa-solid fa-magnifying-glass"></i></span><input type='search' placeholder='Search for a specific Name' onChange={onSearch} /></div>
                            <div className='custButton'>
                            {checkModule('Sales', 'Customers') ? (
  <Link to="/addcustomer" className="active">
    <button className="commonButton addBtn">
      <i className="fa-regular fa-circle-plus"></i> Add Customer
    </button>
  </Link>
) : (
  <button className="commonButton addBtn disabled" disabled>
    <i className="fa-regular fa-circle-plus"></i> Add Customer
  </button>
)}
&nbsp;&nbsp;
{checkModule('Sales', 'Customers') ? (
  <CSVLink
    filename="customer_list.csv"
    data={customerExport}
    
  >
    <button className="commonButton expBtn">
      <i className="fa-light fa-upload"></i> Export Excel
    </button>
  </CSVLink>
) : (
  <button className="commonButton expBtn disabled" disabled>
    <i className="fa-light fa-upload"></i> Export Excel
  </button>
)}

                            </div>
                        </div>

                        <div className='mt-2'>
                            <div className='gridList mb-3 text-end'>
                                <button size="100%" style={{ backgroundColor: "#DCE5EC" }} onClick={toggleHandler1} className={!view ? "listactive" : ""}><i class="fa-solid fa-list"></i></button>
                                <button size="100%" style={{ backgroundColor: "#DCE5EC" }} onClick={toggleHandler} className={view ? "gridactive" : ""}><i class="fa-solid fa-grid"></i></button>
                            </div>
                            {!view ?

                                <div className='listViews'>
                                    <table class="table">
                                        <thead>
                                            <tr>
                                                <th scope="col">Customer Type</th>
                                                <th scope="col">Customer Name</th>
                                                <th scope="col">Email</th>
                                                <th scope="col">Phone</th>
                                                <th scope="col">Country</th>
                                                <th scope="col">Created/Updated By</th>
                                                <th scope="col">Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {customerList?.map((d) => (
                                                
                                                <tr>
                                                    <td>{d?.type}</td>
                                                    {/* <td>{d?.fname} {d?.lname}</td> */}
                                                    <td>{d?.type === "Company" ? d?.company_name : d?.fname + " " + d?.lname} </td>
                                                    <td>{d?.email}</td>
                                                    <td>{d?.phone}</td>
                                                    <td> {d?.country}</td>
                                                    <td>{d?.creatorFname} {d?.creatorLname}</td>
                                                    <td>
                                                        <span className='viewIcon'><Link to={`/customerdetails?id=${d?.id}`}><i class="fa-light fa-eye"></i></Link></span>
                                                        <span className='viewIcon orange'><Link to={`/editcustomer?id=${d?.id}`}><i class="fa-light fa-pen"></i></Link></span>
                                                        <span className='viewIcon'><i class="fa-solid fa-trash-can" onClick={onDelete.bind(d?.id, `${d?.id}`)}></i></span>
                                                    </td>
                                                </tr>
                                            ))}


                                        </tbody>
                                    </table>
                                    
                                    {loading &&
                                        <div className='loadItems'>
                                            <button className="commonButton addBtn">
                                                Loading...
                                            </button>
                                        </div>
                                    }
                                    {!loading && (
                                        <div className='loadItems'>
                                            <button onClick={loadMoreItems} className="commonButton addBtn">
                                                No More Customer
                                            </button>
                                        </div>
                                    )}
                                    <Modal size="sm" show={showDelete} onHide={handleCloseDelete} className='delModal'>
                                        <Modal.Header closeButton>
                                            <Modal.Title><h5>Are you sure to delete ?</h5></Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body>

                                            <button variant="primary" className='commonButton expBtn' onClick={onYes} >
                                                Yes
                                            </button>
                                            &nbsp;
                                            &nbsp;
                                            &nbsp;
                                            &nbsp;
                                            <button variant="primary" className='commonButton expBtn' onClick={onNo}>
                                                No
                                            </button>
                                        </Modal.Body>
                                    </Modal>
                                </div>


                                :

                                <div className='gridView'>
                                    {customerList?.map((d) => (

                                        <Card>
                                            <Card.Body>
                                                <div className='cardBlock'>
                                                    <h6>Customer Type</h6>
                                                    {d?.type}
                                                </div>
                                                <div className='cardBlock'>
                                                    <h6>Customer Name</h6>
                                                    {d?.type === "Company" ? d?.company_name : d?.fname + " " + d?.lname}
                                                </div>
                                                <div className='cardBlock'>
                                                    <h6>Email</h6>
                                                    {d?.email}
                                                </div>
                                                <div className='cardBlock'>
                                                    <h6>Phone</h6>
                                                    {d?.phone}
                                                </div>
                                                <div className='cardBlock'>
                                                    <h6>Country</h6>
                                                    {d?.country}
                                                </div>
                                                <div className='cardBlock actn'>
                                                    {/* <h6>Action</h6> */}
                                                    <div>
                                                        <span className='viewIcon'><Link to={`/customerdetails?id=${d?.id}`}><i class="fa-light fa-eye"></i></Link></span>
                                                        <span className='viewIcon orange'><Link to={`/editcustomer?id=${d?.id}`}><i class="fa-light fa-pen"></i></Link></span>
                                                        <span className='viewIcon'><i class="fa-solid fa-trash-can" onClick={onDelete.bind(d?.id, `${d?.id}`)}></i></span>

                                                    </div>
                                                </div>
                                            </Card.Body>
                                        </Card>
                                    ))}
                                    <Modal size="sm" show={showDelete} onHide={handleCloseDelete} className='delModal'>
                                        <Modal.Header closeButton>
                                            <Modal.Title><h5>Are you sure to delete ?</h5></Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body>

                                            <button variant="primary" className='commonButton expBtn' onClick={onYes} >
                                                Yes
                                            </button>
                                            &nbsp;
                                            &nbsp;
                                            &nbsp;
                                            &nbsp;
                                            <button variant="primary" className='commonButton expBtn' onClick={onNo}>
                                                No
                                            </button>
                                        </Modal.Body>
                                    </Modal>
                                </div>


                            }

                        </div>

                    </div>
                </div>
            </Sidebar>
        </div>
    )
}

export default Customerlisting