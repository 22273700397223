import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import dashblue from '../../images/dashblue.png';
import Navigation from '../layout/Navigation';
import { useNavigate } from 'react-router-dom';

import { getSubscriptionPlanList, getSubscriptionPayment, getUpdatePlan } from "../../../Service/Subscription/SubscriptionStripe";
import { toast } from 'react-toastify';
import { useFormik } from 'formik';
import { Modal } from 'react-bootstrap';
import Usr from '../../images/usr.png';
import Lock from '../../images/lock.png';
import Env from '../../images/envelop.png';
import Case from '../../images/suitcase.png';
import { Col } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import * as yup from 'yup';
const Pricinghome = ({setIsSpinner}) => {
    const [show, setShow] = useState(false);
    const navigate = useNavigate();
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [subscriptionPlan, setAllSubscriptionPlanList] = useState([]);
    const [buttonChange, setButtonChange] = useState(false);
    const [showupgrade, setShowUpgrade] = useState(false);
    const handleCloseUpgrade = () => setShowUpgrade(false);
        // const onSubscribeNow = (planId) => {
        //     console.log(planId, "planId")
        //     getSubscriptionPayment(planId, (res) => {
        //         console.log(res, "planREsponse")
        //         if (res.success === true) {
        //             window.location.href = res?.data?.url;
    
        //         } else {
    
        //         }
        //     })
        // }
    
        //subscription plan api call
        useEffect(() => {
            getSubscriptionPlanDataList();
    
        }, []);
    
        async function getSubscriptionPlanDataList() {
            getSubscriptionPlanList().then(function (result) {
                setIsSpinner(true);
                const response = result?.data;
                // console.log(response, "rrrr")
                setAllSubscriptionPlanList(response?.data?.data);
                setIsSpinner(false);
            });
        }
        console.log(subscriptionPlan, "subscriptionPlanssss")
        const formik = useFormik({
            initialValues: {
              user:'',
              firstname: '',
              companyname:'',
              email: '',
              industry_type: '',
          others: '',
            },
            validationSchema: yup.object({
                user:yup.string().required("Number of users is a required field"),
              firstname: yup.string().min(2, "Name must have atleast 2 chracter").required("First Name is a required field"),
             // lastname: yup.string().min(2, "Name must have atleast 2 chracter").required("Last Name is a required field"),
              companyname: yup.string().min(5, "Name must have atleast 5 chracter").required("Company Name is a required field"),
              email: yup.string().email().required("Email is a required field"),
              industry_type: yup.string().required("Select a industry type"),
             // phone: yup.string().min(11, "Phone Number is a required field").required("Phone Number is a required field"),
              
        
            }),
        
        
        
            onSubmit: async (values, { resetForm }) => {
              // login(values);
              setButtonChange(true)
              await getUpdatePlan(' ', values.user,values.firstname, values.companyname,
                  values.email, values.industry_type, values.others, (res) => {
                 console.log(res,'kkkk')
                    if (res.success === true) {
                      setButtonChange(false)
          
                      // setAuthed({isAuth: true, token: res.data.token, user: userdata})
                      toast.success(res.message)
                     setShowUpgrade(false);
                     // navigate("/myprofile");
          
                      formik.resetForm();
                    } else {
                      setButtonChange(false)
                      toast.error(res.message)
                      
                    }
                  })
              
            }
        
          })
            
      const renderFirstNameError = formik.touched.firstname && formik.errors.firstname && (<span className='errorTxt' style={{ color: "red" }}>{formik.errors.firstname}</span>);
      
        const renderuserError = formik.touched.user && formik.errors.user && (<span className='errorTxt' style={{ color: "red" }}>{formik.errors.user}</span>);
      
        const renderCompanyNameError = formik.touched.companyname && formik.errors.companyname && (<span className='errorTxt' style={{ color: "red" }}>{formik.errors.companyname}</span>);
      
        const renderEmailError = formik.touched.email && formik.errors.email && (<span className='errorTxt'>{formik.errors.email}</span>);
      
        const renderIndustryError = formik.touched.industry_type && formik.errors.industry_type && (<span className='errorTxt'>{formik.errors.industry_type}</span>);
        return (
    
            <div className='authBg pricinghome'>
                <header>
                    <Navigation/>
                </header>

                {/* <div className='proflogo'><Link to="/"><img src={logo} /></Link></div> */}
                <div className='authLogo pt-lg-5 mt-5 pricingLogo'>&nbsp;</div>
    
                <div className='signForm pricingshadow respnoMrgn newPricing'>
                     <div className='choosePlanTop pricingA'>
                        <h2>Pricing</h2>
                        <img src={dashblue} />
                        {/* <p>Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur...</p> */}
                     </div>
                     <div className='choosePlanBtm pricingB'>
                        {/* <div className='row'>
                            <div className='col-md-6'>
                                <div className='planBg'>
                                    <div className='plantop orangebg'>
                                        <h5 className='m-0'>Monthly plan</h5>
                                        <button>Popular</button>
                                    </div>
                                    <div className='plancontent'>
                                        <h2>$29<small>,00/month</small></h2>
                                        <p>Lorem ipsum dolor sit amet,consectetur adipiscing elit.</p>
                                        <ul>
                                            <li>Lorem ipsum dolor sit amet</li>
                                            <li>Lorem ipsum dolor sit amet</li>
                                            <li>Lorem ipsum dolor sit amet</li>
                                        </ul>
                                        <button className='commonButton buttonSubmit'>Get Started</button>
                                    </div>
                                </div>
                            </div>
                            <div className='col-md-6'>
                                <div className='planBg'>
                                    <div className='plantop'>
                                        <h5 className='m-0'>Annual plan</h5>
                                    </div>
                                    <div className='plancontent'>
                                        <h2>$70<small>,00/month</small></h2>
                                        <p>Lorem ipsum dolor sit amet,consectetur adipiscing elit.</p>
                                        <ul>
                                            <li>Lorem ipsum dolor sit amet</li>
                                            <li>Lorem ipsum dolor sit amet</li>
                                            <li>Lorem ipsum dolor sit amet</li>
                                        </ul>
                                        <button className='commonButton buttonSubmit'>Get Started</button>
                                    </div>
                                </div>
                            </div>
                        </div> */}

                        <div className='row'>
                            {subscriptionPlan?.map((d) => (
                            <div className='col-md-4'>
                                <div className='planBg'>
                                        <div className={d?.name === "Yearly" ? 'plantop orangebg' : 'plantop'}>
                                            <h5 className='m-0'>{d?.name} </h5>
                                            {d?.name === "Yearly" ?
                                                <button>Popular</button> : ""}
                                        </div>

                                        <div className='plancontent'>
                                            <h2>${d?.plan_price}<small>/ Monthly</small></h2>

                                            {/* <p>Lorem ipsum dolor sit amet,consectetur adipiscing elit.</p> */}
                                            <ul>
                                                {d?.features?.map((s)=>(
                                                    <>

                                                <li>{s?.name}</li>
                                                
                                                </>
                                                ))}

                                            </ul>
                                            {/* <button className='commonButton buttonSubmit' onClick={onSubscribeNow.bind(d?.default_price, `${d?.default_price}`)}>Get Started</button> */}
                                            <Link to="/signup" className='commonButton buttonSubmit' style={{display: "block", textAlign: "center"}}>Get Started</Link>
                                        </div>

                                    </div>
                            </div>
                            ))}
                            

                            <div className='col-md-4'>
                            <div className='planBg'>
                                <div className="plantop orangebg">
                                    <h5 className='m-0'>Professional Package</h5>

                                    <button>Popular</button>
                                </div>

                                <div className='plancontent'>
                                    {/* <h2>&nbsp;</h2> */}

                                    <h6 className='highlight mt-3' style={{paddingBottom: "21px"}}>Let the Professionals handle it for you</h6>
                                    <ul>
                                        <li>Full bookkeeping services</li>
                                        <li>Financial planning, budgeting & forecasting</li>
                                        <li>Financing and Cash flow planning</li>
                                        <li>Audit preparations</li>
                                        <li>Internal Control Improvements</li>
                                        <li>Risk Management <small>
                                            {/* <i class="fa-regular fa-bracket-round"></i>  <i class="fa-regular fa-bracket-round-right"></i> */}
                                            </small></li>
                                            <li><small>*For fees, contact us for a customized fee structure that meets your business needs*</small></li>
                                        <li>Financial Reporting and Advisory Services</li>
                                    </ul>
                                    {/* <Link to="/login" className='commonButton buttonSubmit' style={{display: "block", textAlign: "center"}}>Get Started</Link> */}
                                    <button className='commonButton buttonSubmit' onClick={()=>setShowUpgrade(true)}>Get Started</button>
                                </div>

                            </div>
                            </div>


                        </div>
                     </div>
                </div>

                <Modal  show={showupgrade} onHide={handleCloseUpgrade} className='delModal'>
                                        <Modal.Header closeButton>
                                            <Modal.Title><h5>Upgrade Your Plan</h5></Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body>
                                        <div className='signMainform register'>
                    <form onSubmit={buttonChange ? formik.handleSubmit : formik.handleSubmit}>                    
                    <div className='fieldBlock mb-2'>
                      <label htmlFor="name"><img src={Usr} />&nbsp;</label>
                      <input type="number" id="user" className='form-control' name="user" onChange={formik.handleChange} value={formik.values.user} placeholder='Number of users to be created' />
                      {renderuserError}
                    </div>
                    <div className='fieldBlock mb-2'>
                      <label htmlFor="name"><img src={Case} />&nbsp;</label>
                      <input type="text" id="companyname" className='form-control' name="companyname" onChange={formik.handleChange} value={formik.values.companyname} placeholder='Company Name' />
                      {renderCompanyNameError}
                    </div>

                    <div className='fieldBlock mb-2'>
                      <label htmlFor="name"><img src={Usr} />&nbsp;</label>
                      <input type="text" id="firstname" className='form-control' name="firstname" onChange={formik.handleChange} value={formik.values.firstname} placeholder='Contact Person' />
                      {renderFirstNameError}
                    </div>

                    


                    

                        <div className='fieldBlock mb-2'>
                        <label><img src={Env} />&nbsp; </label>
                        <input type="email" id="email" name="email" className='form-control' placeholder='Email address'  onChange={formik.handleChange} value={formik.values.email} />
                        {renderEmailError}
                        </div>
                        <Col lg="6" className="form-control">
  <Form.Select
    aria-label="Select Business Industry"
    id="industry_type"
    name="industry_type"
    value={formik.values.industry_type}
    onChange={formik.handleChange} // Formik change handler
    style={{border:'none',padding:0}}

  >
    <option hidden value="">
      Select Business Industry
    </option>
    <option value="Technology">Technology</option>
    <option value="Finance">Finance</option>
    <option value="Healthcare">Healthcare</option>
    <option value="Retail">Retail</option>
    <option value="Hospitality and Tourism">Hospitality and Tourism</option>
    <option value="Manufacturing">Manufacturing</option>
    <option value="Real Estate">Real Estate</option>
    <option value="Entertainment and Media">Entertainment and Media</option>
  </Form.Select>
  {formik.errors.industry_type && formik.touched.industry_type && (
    <div className="error-message">{formik.errors.industry_type}</div>
  )}
</Col>


                        {/* <div className='fieldBlock mb-2 phoneInputDropdown'>
                        <label><img src={Call} />&nbsp; </label>
                        <PhoneInput
                            defaultCountry="ua"
                            value={formik.values.phone}
                            onChange={(value) => formik.handleChange({ target: { name: 'phone', value } })}
                            onBlur={formik.handleBlur}
                            className='form-control'
                        />
                        {renderPhoneError}

                        </div> */}

<div className="fieldBlock mb-2 fieldTextarea" style={{ marginTop: '20px' }}>
  <textarea
    className="form-control"
    name="others" // Name matches the key in formik.values
    placeholder="Other Details"
    onChange={formik.handleChange} // Formik handles the change event
    value={formik.values.others} // Bind value to Formik state
  ></textarea>
  {formik.errors.others && formik.touched.others && (
    <div className="error-message">{formik.errors.others}</div> // Show validation error if it exists
  )}
</div>





                        <button type="submit" className='commonButton buttonSubmit' >Submit</button>
                    </form>
                    </div>
                                        </Modal.Body>
                                    </Modal>
            </div>
        )
    }
    

export default Pricinghome