import { React, useState, useEffect } from 'react'
import Dbheader from '../../layout/Dbheader'
import Sidebar from '../../layout/Sidebar'
import { Link } from 'react-router-dom'
import Form from 'react-bootstrap/Form'
import Headtxt from '../Headtxt'
import Nav from 'react-bootstrap/Nav';
import { Col } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { getServiceListById } from "../../../../Service/ProductService/ProductService";
import { getAddProductService } from "../../../../Service/ProductService/ProductService";
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import getSymbolFromCurrency from 'currency-symbol-map'

const ServiceDetails = () => {
    const location = useLocation();
    const ServiceId = new URLSearchParams(location.search);
    console.log(ServiceId.get(`id`), "ServiceId")

    const [show, setShow] = useState(false);
    const handleShow = () => setShow(true);
    const handleClose = () => setShow(false);
    const [file, setFile] = useState(null);
    const navigate = useNavigate();
    const [type, setType] = useState("");
    const [serviceId, setServiceId] = useState([]);
    const [currency, setCurrency] = useState("");
    const [purchaseDetails, setPurchaseDetails] = useState([]);
    const [salesdetails, setSalesDetails] = useState([]);
    const [supply, setSupply] = useState(false)
    const toggleProduct = (e) => {
        e.preventDefault();
        setSupply(false)
       // getProductDataList()
    }
    const toggleService = (e) => {
        e.preventDefault();
        setSupply(true)
       // getServiceDataList()
    }



    const handleDrop = (event) => {
        event.preventDefault();

        const droppedFile = event.dataTransfer.files[0];
        handleFile(droppedFile);
    };

    const handleFile = (selectedFile) => {
        setFile(selectedFile);

        // You can perform additional actions with the file, such as uploading it to a server.
        // For simplicity, we're just logging the file information here.
        console.log('File selected:', selectedFile);
    };
    const handleDragOver = (event) => {
        event.preventDefault();
    };


    // For toggle
    // const [isToggled, setToggled] = useState(false);

    const handleToggleOn = () => {
        // setToggled(true);
        // setInventory("No")
    };
    const handleToggleOff = () => {
        // setToggled(false);
        // setInventory("Yes")
    };


    useEffect(() => {
        getallProductList()
    }, [])

    async function getallProductList() {
        getServiceListById(ServiceId.get(`id`)).then(function (result) {
            const response = result.data;
            console.log(response, "response")
            setServiceId(response?.data?.service_details);
            setPurchaseDetails(response?.data?.purchaseDetails);
            setSalesDetails(response?.data?.saleDetails);
            setCurrency(response?.data?.userCurrency)
            // setDepartmentId(response?.data?.businessUnit?.Departments);
        });
    }
    console.log(serviceId, "srsrsrsrr")

    return (
        <div className="customerListing pdt">
            <Dbheader />
            <Sidebar>
                <div className='customerMain'>
                    <div className='custBtm addSupply'>
                        <div className='dbTopsec pb-4'>
                            <Headtxt link="/productservicelistings" title="Service Details" />
                            {/* <button className='commonButton expBtn' onClick={handleShow}><i class="fa-light fa-upload"></i> Import Excel</button> */}
                        </div>
                        <div className='commonWhite addCustomerForm p-3 mb-3'>
                            <Form>
                                <Form.Group className="row" controlId="exampleForm.ControlInput1">

                                    <Col md="12" className='mb-3'>
                                        <Form.Control type="text" placeholder="Name" value={serviceId?.name} />
                                    </Col>
                                </Form.Group>
                                <Form.Group className="row" controlId="exampleForm.ControlInput1">
                                    <Col md="12" className='mb-3'>
                                        <Form.Control as="textarea" placeholder="Description" rows={3} value={serviceId?.description ? serviceId?.description : "description"} />
                                    </Col>
                                </Form.Group>



                                <Form.Group className="row" controlId="exampleForm.ControlInput1">
                                    <Col md="3" className='mb-3'>
                                        <Form.Control type="text" placeholder="Unit" value={serviceId?.unit} />
                                    </Col>
                                    <Col md="3" className='mb-3'>
                                        <Form.Control type="number" placeholder="Sales Price(Optional)" value={serviceId?.price} />
                                    </Col>
                                    {/* <Col md="4" className='mb-3'>
                                        <Form.Control type="text" placeholder="Purchase tax" onChange={onPurchaseTax}/>
                                    </Col> */}
                                    <Col md="3" className='mb-3'>
                                        <Form.Control type="number" placeholder="Purchase tax" value={serviceId?.tax} />
                                    </Col>
                                    <Col md="3" className='mb-3'>
                                        <Form.Control type="number" name='tax' placeholder="Sales tax(Optional)" value={serviceId?.sales_tax} />
                                    </Col>
                                </Form.Group>





                                {/* <button type='button' className='commonButton expBtn'>Save</button>&nbsp;&nbsp; */}
                                {/* <button className='commonButton addBtn'>Cancel</button> */}



                            </Form>

                        </div>
                    </div>
                    <div className='addsuppBtn'>
                            <button className={`${!supply ? "commonButton active" : "commonButton "} `} onClick={toggleProduct}>Purchase Details</button>
                            <button className={`${supply ? "commonButton active" : "commonButton "} `} onClick={toggleService}>Sales Details</button>
                        </div>
                        <div className='listViews'>
                                    <table class="table">
                                        <thead>
                                            <tr>
                                            {supply ? <th scope="col">Date of Sold</th> :
                                                    <th scope="col">Date of Purchase</th>}
                                                 {supply ? <th scope="col">Customer name</th> :
                                                    <th scope="col">Supplier Name</th>}
                                                     {supply ? <th scope="col">Qty Sold</th> :
                                                    <th scope="col">Qty Purchased</th>}
                                                     <th scope="col">Unit Price of the product</th>
                                                     {supply ? <th scope="col">Total price of the sold Qty</th> :
                                                     <th scope="col">Total price of the ordered Qty </th>}
                                                     
                                            </tr>
                                        </thead>

                                        <tbody>
                                        {(supply ? salesdetails.length > 0 : purchaseDetails.length > 0) ? (
  (supply ? salesdetails : purchaseDetails)?.map((d, index) => (
    <tr key={index} text={d}>
      <td>{d?.bill_invoice_date}</td>
      <td>
        {d?.type === "Company" ? d?.company_name : `${d?.fname} ${d?.lname}`}
      </td>
      <td>{d?.purchase_sold_qty}</td>
      <td>{getSymbolFromCurrency(currency)} {d?.unit_price}</td>
      <td>{getSymbolFromCurrency(currency)} {d?.total_price}</td>
    </tr>
  ))
) : (
  <tr>
    <td colSpan={5} style={{ textAlign: 'center' }}>
      {supply ? 'No Sales order available' : 'No Purchase order available'}
    </td>
  </tr>
)}

 </tbody>
   </table>
</div>
               

                </div>

                
            </Sidebar>




            <Modal show={show} onHide={handleClose} centered className='customerModal'>
                <Modal.Header closeButton>
                    <Modal.Title><h5>Import Products</h5></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='modalContent'>
                        <div
                            onDrop={handleDrop}
                            onDragOver={handleDragOver}
                            className='drgdrop'
                        >
                            <i class="fa-light fa-cloud-arrow-up"></i>
                            <div style={{ display: "flex" }}>
                                <p>Drag &amp; drop files or </p>
                                <input type="file" onChange={(e) => handleFile(e.target.files[0])} style={{ display: 'none' }} />
                                <button onClick={() => document.querySelector('input[type="file"]').click()}>Browse</button>
                            </div>

                            {file && <p>Selected File: {file.name}</p>}
                        </div>
                    </div>
                    <div>
                        <p><button className='commonButton modalsubmit'>Submit</button></p>
                        <p><button className='commonButton downloadformat'>Download format</button></p>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default ServiceDetails
