import React, { useState ,useEffect} from 'react';
import { Link } from 'react-router-dom';
import * as yup from 'yup';
import { useFormik } from 'formik';
import axios from 'axios';
import Usr from '../../images/usr.png';
import Lock from '../../images/lock.png';
import { toast } from 'react-toastify';
import {getResetPassword} from "../../../Service/ResetPassword/ResetPasswordService";
import { useNavigate } from 'react-router-dom';
import { getChangePassword } from '../../../Service/Login/LoginService';

const Changepassword = (props) => {
  const { logo } = props;
  const navigate = useNavigate();
  const [userData, setUserData] = useState('');


  const [showpassword, setShowPassword] = useState(false);
  const [showconfirmpassword, setShowConfirmPassword] = useState(false);



  console.log(userData?.email, "userData")

  const formik = useFormik({
    initialValues: {
        password: '',
        confirmpassword: ''
    },
    validationSchema: yup.object({
        password: yup.string().min(8, "Password must have atleast 8 character").required("Password is a required field"),
   confirmpassword: yup.string().required("Confirm Password is a required field").oneOf([yup.ref('password'), null], 'Must match "password" field value'),
    }),
    onSubmit: async(values, {resetForm}) => {      
      try {
        const res = await getChangePassword(values.password);
        console.log(res, "Change Response==");
        if (res.success === true) {
          console.log(res, "Change Response");
   
          toast.success(res.message);
          navigate('/dashboard');
        } else {
          toast.error(res?.message || "Invalid login credentials.");
        }
      } catch (error) {
        console.error("Login error:", error);
        toast.error("An error occurred during login.");
      }
      
    }
})


console.error(formik.errors);
const renderPasswordError = formik.touched.password && formik.errors.password && (<span className='errorTxt'>{formik.errors.password}</span>);

const renderConfirmPasswordError = formik.touched.confirmpassword && formik.errors.confirmpassword && (<span className='errorTxt'>{formik.errors.confirmpassword}</span>);







  return (
    <div className='authBg'>
      <div className='authLogo'><Link to="/"><img src={logo} /></Link></div>
      <div className='signForm'>
        <div className='signFormbase'>
          <div className='signHdn'>Change Password</div>
          <div className='signMainform'>
          <form onSubmit={formik.handleSubmit}>

                    <div className='mb-3'>
                      <label><img src={Lock} />&nbsp; New Password</label>
                      <div className="showHide">
                        <input className="form-control" name='password' type={showpassword ? "text" : "password"} id="password" placeholder="Enter your new password" onChange={formik.handleChange} value={formik.values.password} />                      
                        <div className="input-group-addon eyeAbs" onClick={() => { setShowPassword(!showpassword) }}>
                          {showpassword ? <span><i className="fa fa-eye" aria-hidden="true"></i></span> : <span><i className="fa fa-eye-slash" aria-hidden="true"></i></span>}
                        </div>
                        {renderPasswordError}
                      </div>
                    </div>

                    <div className='mb-3'>
                      <label><img src={Lock} />&nbsp; Confirm New Password</label>
                      <div className="showHide">                    
                        <input className="form-control" name="confirmpassword" type={showconfirmpassword ? "text" : "password"} id="confirmpassword" placeholder="Retype your new password" onChange={formik.handleChange} value={formik.values.confirmpassword} />                  
                        <div className="input-group-addon eyeAbs" onClick={() => { setShowConfirmPassword(!showconfirmpassword) }}>                          
                          {showconfirmpassword ? <span><i className="fa fa-eye" aria-hidden="true"></i></span> : <span><i className="fa fa-eye-slash" aria-hidden="true"></i></span>}
                        </div>
                       {renderConfirmPasswordError}        
                      </div>
                    </div>



              <div className='forgotPass text-start'><span style={{color: "red"}}>*</span>Passwords should be at least 8 characters long</div>

              <button type="submit" className='commonButton buttonSubmit'>Submit</button>
          </form>
          </div>
        </div>
        <div className='accountTxt'>&nbsp;</div>
      </div>
    </div>
  )
}

export default Changepassword