import React, { useState, useMemo } from 'react';
import Select from 'react-select';
import countryList from 'react-select-country-list';
import { Link } from 'react-router-dom';
import * as yup from 'yup';
import { useFormik } from 'formik';
import axios from 'axios';
import Usr from '../../images/usr.png';
import Lock from '../../images/lock.png';
import Env from '../../images/envelop.png';
import Case from '../../images/suitcase.png';
import Call from '../../images/call.png';
import Pin from '../../images/pin.png';
import Watermark from '../../images/watermark.png';
import regdtemplate from '../../images/regdtemplate.png';
import Form from 'react-bootstrap/Form';
import { PhoneInput } from 'react-international-phone';
import 'react-international-phone/style.css';
import { toast } from 'react-toastify';
import { getRegister } from "../../../Service/Signup/SignupService";
import { useNavigate } from 'react-router-dom';
import Footer2 from '../layout/Footer2';
import Navigation from '../layout/Navigation';
import Dbheader from '../layout/Dbheader';
import Sidebar from '../layout/Sidebar';
import { Col } from 'react-bootstrap';

const Contactus2 = (props) => {
 
    const [value, setValue] = useState('')
    const [buttonChange, setButtonChange] = useState(false)
    const options = useMemo(() => countryList().getData(), [])
  
    const changeHandler = value => {
      setValue(value)
    }
  
    const { logo } = props;
    const [phone, setPhone] = useState('');
    const [showpassword, setShowPassword] = useState(false);
    const [showconfirmpassword, setShowConfirmPassword] = useState(false);
    const navigate = useNavigate();
  
  
    const formik = useFormik({
      initialValues: {
        user:'',
        firstname: '',
        companyname: '',
        email: '',
        phone: '',
        country: '',
      },
      validationSchema: yup.object({
        firstname: yup.string().min(2, "Name must have atleast 2 chracter").required("First Name is a required field"),
        lastname: yup.string().min(2, "Name must have atleast 2 chracter").required("Last Name is a required field"),
        companyname: yup.string().min(5, "Name must have atleast 5 chracter").required("Company Name is a required field"),
        email: yup.string().email().required("Email is a required field"),
        country: yup.string().required("Select a Country"),
        phone: yup.string().min(11, "Phone Number is a required field").required("Phone Number is a required field"),
        
  
      }),
  
  
  
      onSubmit: async (values, { resetForm }) => {
        // login(values);
        setButtonChange(true)
  
        
      }
  
    })
  
    console.log(formik.values, "phoneeeeeeeeeeee")
  
  
    const renderFirstNameError = formik.touched.firstname && formik.errors.firstname && (<span className='errorTxt' style={{ color: "red" }}>{formik.errors.firstname}</span>);
  
    const renderLastNameError = formik.touched.lastname && formik.errors.lastname && (<span className='errorTxt' style={{ color: "red" }}>{formik.errors.lastname}</span>);
  
    const renderCompanyNameError = formik.touched.companyname && formik.errors.companyname && (<span className='errorTxt' style={{ color: "red" }}>{formik.errors.companyname}</span>);
  
    const renderEmailError = formik.touched.email && formik.errors.email && (<span className='errorTxt'>{formik.errors.email}</span>);
  
    const renderCountryError = formik.touched.country && formik.errors.country && (<span className='errorTxt'>{formik.errors.country}</span>);
  
    const renderPhoneError = formik.touched.phone && formik.errors.phone && formik.values.phone > 5 && (<span className='errorTxt'>{formik.errors.phone}</span>);

  return (
    <>
    <div className='authBg pt-5 pb-0'>
        <Dbheader />
        <div className='contactContainer mt-5 pb-5'>
        <Sidebar>
        <div className='container mt-5'>
            <div className='row'>
            {/* <div className='col-lg-6 regdLeft pt-5'>
                {/* <div className='regdLogn mb-3'><Link to="/"><img src={logo} /></Link></div> 
                <div className='addresscode'>
                    <h5>Address:</h5>
                    <p className='mb-0'>Address will be here</p>
                    <p><strong>Street:</strong> Here</p>
                    <h5>Contact No:</h5>
                    <p><strong>+380</strong> 02545658978</p>
                    <h5>Email:</h5>
                    <p>mail@1SimpleClick.com</p>
                </div>
            </div> */}
            <div className='col-lg-6 pt-5'>
                <div className='signForm m-0 fheight contFrmbg'>
                <div className='signFormbase fheight'>
                    <div className='signHdn noBordr pb-0 pt-4'>Contact Info</div>
                    <div className='signMainform register'>
                    <form onSubmit={buttonChange ? formik.handleSubmit : formik.handleSubmit}>                    
                    <div className='fieldBlock mb-2'>
                      <label htmlFor="name"><img src={Usr} />&nbsp;</label>
                      <input type="number" id="user" className='form-control' name="user" onChange={formik.handleChange} value={formik.values.user} placeholder='Number of users to be created' />
                    
                    </div>
                    <div className='fieldBlock mb-2'>
                      <label htmlFor="name"><img src={Case} />&nbsp;</label>
                      <input type="text" id="companyname" className='form-control' name="companyname" onChange={formik.handleChange} value={formik.values.companyname} placeholder='Company Name' />
                      {renderCompanyNameError}
                    </div>

                    <div className='fieldBlock mb-2'>
                      <label htmlFor="name"><img src={Usr} />&nbsp;</label>
                      <input type="text" id="contact person" className='form-control' name="contact person" onChange={formik.handleChange} value={formik.values.firstname} placeholder='Contact Person' />
                      {renderFirstNameError}
                    </div>

                    


                    

                        <div className='fieldBlock mb-2'>
                        <label><img src={Env} />&nbsp; </label>
                        <input type="email" id="email" name="email" className='form-control' placeholder='Email address'  onChange={formik.handleChange} value={formik.values.email} />
                        {renderEmailError}
                        </div>
                        <Col lg="6" className='form-control'>
                                                {/* <Form.Control type="text" placeholder="Financial" /> */}
                                                <Form.Select aria-label="Default select example" id="industry" name="industry">
                                                    <option hidden selected>Select Business Industry</option>
                                                    <option value="Technology">Technology</option>
                                                    <option value="Finance">Finance</option>
                                                    <option value="Healthcare">Healthcare</option>
                                                    <option value="Retail">Retail</option>
                                                    <option value="Hospitality and Tourism">Hospitality and Tourism</option>
                                                    <option value="Manufacturing">Manufacturing</option>
                                                    <option value="Real Estate">Real Estate</option>
                                                    <option value="Entertainment and Media">Entertainment and Media</option>
                                                </Form.Select>
                                            </Col>
                        

                        {/* <div className='fieldBlock mb-2 phoneInputDropdown'>
                        <label><img src={Call} />&nbsp; </label>
                        <PhoneInput
                            defaultCountry="ua"
                            value={formik.values.phone}
                            onChange={(value) => formik.handleChange({ target: { name: 'phone', value } })}
                            onBlur={formik.handleBlur}
                            className='form-control'
                        />
                        {renderPhoneError}

                        </div> */}


                        <div className='fieldBlock mb-2 fieldTextarea' style={{marginTop:'20px'}}>
                        {/* <label><img src={Env} />&nbsp; </label> */}
                        <textarea className='form-control' placeholder='Other Details'></textarea>
                        
                        </div>


                        





                        <button type="submit" className='commonButton buttonSubmit' >Submit</button>
                    </form>
                    </div>
                </div>
                {/* <div className='termsTxt pb-2'>By clicking Create account you agree to the &nbsp; <Link to="/terms">Terms of Service</Link> and &nbsp; <Link to="/privacy">Privacy policies.</Link></div> */}

            
                </div>
            </div>
            </div>
        </div>
        </Sidebar>
      </div>

    </div>
  
    </>
  )
}

export default Contactus2