import { React, useState,useEffect } from 'react'
import Sidebar from '../../../layout/Sidebar'
import Dbheader from '../../../layout/Dbheader'
import { Link , useNavigate} from 'react-router-dom'
import Form from 'react-bootstrap/Form';
import Dropdown from 'react-bootstrap/Dropdown';
// import Headtxt from '../Headtxt';
import cals from '../../../../images/cals.png';
import Listview from '../../sales/Listview';
import Gridview from '../../sales/Gridview';
import Card from 'react-bootstrap/Card'
import Headtxt from '../../Headtxt';
import Modal from 'react-bootstrap/Modal';
import Col from 'react-bootstrap/Col';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import bclogo from '../../../../images/bclogo.png';
import moment from "moment"
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { ToastContainer, toast } from 'react-toastify';
import { CSVLink } from 'react-csv';
import { getAccounttId, getBillCancelResend, getBillDelete, getBillList,getExcelBill,getSearchBillList } from '../../../../../Service/Quotation/QuotationService';
import getSymbolFromCurrency from 'currency-symbol-map'
import axios from 'axios';
import { manualPayment } from '../../../../../Service/payment/payment';
import Swal from 'sweetalert2';
import { getAddInventoryaccount } from '../../../../../Service/Account/AccountService';
import { useCallback } from 'react';
import income2 from '../../../../images/income2.png';
const Bill = ({ setIsSpinner }) => {
const navigate= useNavigate()
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [loading,setLoading]=useState(false);
    
    const [visibleItems, setVisibleItems] = useState(0);
    const [show, setShow] = useState(false);
    const [bill, setBill] = useState([]);
    const [showPdf, setShowPdf] = useState(false);
    const [dataPdf, setDataPdf] = useState("");
    const handleClosePdf = () => setShowPdf(false);
    const vclose = () => {
        setShowPdf(false);
      //  navigate('/invoice')
      }
      const onButtonClick = () => {
        const pdfUrl = dataPdf?.pdf;
        const link = document.createElement("a");
        link.href = pdfUrl;
        link.target = '_black';
        // link.download = "document.pdf"; // specify the filename
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }; 
    const handleShow = () => setShow(true);
    const handleClose = () => {
        setMsg("");
        setShow(false);
        setViews(false)
    };
    const [fromDate, setFromDate] = useState('');
    const [toDate, setToDate] = useState('');
    const [formatedfromDate, setformatedFromDate] = useState(new Date());
    const [formatedtoDate, setformatedToDate] = useState(new Date());
    const [billExport,setBillExport]=useState([]);
    const [formateDate, setformatedDate] = useState('');
    const [addaccount, setAddaccount] = useState(false);
    const [select, setSelect] = useState(false);
    const accountpop = () =>  {setAddaccount(true);};
    const addaccountClose = () => setAddaccount(false);
    const [accountId, setAccountId] = useState("");
    const [accountName, setAccountName] = useState("");
    const [accountDate, setAccountDate] = useState("");
    const [accountdescription, setAccountDescription] = useState("");
    const onAccountName = (e) => {
        setAccountName(e.target.value)
      }
      const onAccountId = (e) => {
        setAccountId(e.target.value)
      }
      const onAccountDescription = (e) => {
        setAccountDescription(e.target.value)
      }
      const CustomDropdown = ({ placeholder, value, }) => {
        console.log('options==', value)
        return (
          <select value={value} className="form-control">
    
            <option key='1234' value={value}>Cash</option>
    
    
    
          </select>
        );
      };
      const onSave2 = () => {
        getAddInventoryaccount(1, accountName, accountId, accountdescription,
          (r) => {
            console.log(r, "response")
            // if (r.success === true) {
            //   toast.success(r.message)
            //   // setAddaccount(false)
            //   // getUserAccounts();
            //   // setSelectedSubtype('')
            //   addaccountClose()
            //   getInventoryDataList();
            // }
            if (r.success === true) {
              Swal.fire({
                title: r.message,
                //  text: 'The product has been added successfully!',
                icon: 'success',
                confirmButtonText: 'OK',
                customClass: {
                  confirmButton: 'my-confirm-button'
                }
              }).then(() => {
                addaccountClose()
                getAccount();
                setSelect(true)
              })
            }
            else {
                console.log("nononononono")
                toast.error(r.message)
                // setSelectedSubtype('')
              }
            })
      
        }
      const handleFromDateChange = (e) => {
        const value  = e;
        setFromDate(value);
        // Perform filtration based on the selected from date
        // You can put your filtration logic here
        console.log('Filter data based on from date:',value);
      };
      useEffect(() => {
        const date = new Date();
        const formatted = moment(date).format('DD-MM-YYYY');
        setformatedDate(formatted);
      }, []);
      const handleDateChange = (date) => {
        setSelectedDate(date);
        setformatedDate(moment(date).format('DD-MM-YYYY'))
    };
    console.log('date==',selectedDate)
      const handleToDateChange = (e) => {
        const value  = e;
        setToDate(value);
        // Perform filtration based on the selected to date
        // You can put your filtration logic here
     
        console.log('Filter data based on to date:', value);
      //  getSearchList()
        //setToDate('')
      };
   
    async function getBill() {
        getBillList("").then(function (result) {
            const response = result?.data;
       console.log(response, "rrrr")
            setBill(response?.data);
           
        });
    }
    
    useEffect(() => {
        getBill();
        onExcelExport();
        getAccount();
    }, []);

    const [billId,setBillId]=useState(0);
    
    const [showrecord, setShowRecord] = useState(false);
    const handleShowRecord  = (e,id) =>{
        e.preventDefault();
        setBillId(id)
        setShowRecord(true);
    } 
    const handleCloseRecord = () => {
        setMsg("");
        setShowRecord(false);
        setSelectedAccountId('');
        setAmount('');
        setSelectedCurrencyValue('')
        setSelectedCurrency('')
        setSelect(false)
    };


    const [supply, setSupply] = useState(false)
    const toggleCompany = (e) => {
        e.preventDefault();
        setSupply(false)
    }
    const toggleIndividual = (e) => {
        e.preventDefault();
        setSupply(true)
    }


const [hasMore, setHasMore] = useState(true);
useEffect(() => {
    // Initial load of items
    loadMoreItems();
}, []);

const loadMoreItems = () => {
    if (!hasMore || loading) return;
    setLoading(true);
    setTimeout(() => {
        const newItems = fetchMoreItems(visibleItems);
        if (newItems.length > 0) {
            //setItems((prevItems) => [...prevItems, ...newItems]);
            setVisibleItems((prev) => prev + newItems.length);
        } else {
            setHasMore(false);
        }
        setLoading(false);
    }, 1000); // Adjust the delay as needed
};

const handleScroll = () => {
    if (
        window.innerHeight + document.documentElement.scrollTop >=
        document.documentElement.offsetHeight - 90
    ) {
        // Auto-load more items when scrolling close to the bottom
        loadMoreItems();
    }
};

useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
        window.removeEventListener('scroll', handleScroll);
    };
}, [loading, hasMore]);

const fetchMoreItems = (start) => {
    // Simulate fetching more items from an API
    const itemsPerPage = 5;
    const totalItems = 20; // Adjust as needed for your use case
    const newItems = [];

    for (let i = start; i < start + itemsPerPage; i++) {
        if (i < totalItems) {
            newItems.push(`Item ${i + 1}`);
        }
    }

    return newItems;
};

const [view, setView] = useState(false)
  const toggleHandler = (e) => {
    e.preventDefault();
    setView(true)
  }
  const toggleHandler1 = (e) => {
    e.preventDefault();
    setView(false)
  }

  const [views, setViews] = useState(false)
  const toggleHandlerb = (e) => {
    e.preventDefault();
    setViews(true)
  }
 
  const getSearchList = () => {
    // Construct the filter criteria based on fromDate and toDate
    const filterCriteria = {
        fromDate: fromDate ? moment(fromDate).format('DD-MM-YYYY') : '',
        toDate: toDate ? moment(toDate).format('DD-MM-YYYY') : ''
    };
    console.log('filter---', filterCriteria);
    const queryString = new URLSearchParams(filterCriteria).toString();
    console.log(queryString, 'cre');
    getBillList(queryString).then(function (result) {
        const response = result?.data;
        console.log('resultttt---', response);
        setBill(response?.data);
    });
};

  // custom-select-drupdown
const [isOpen, setIsOpen] = useState(false);
const [sortingCleared, setSortingCleared] = useState(false);
const [selectedOption, setSelectedOption] = useState('More');
const onSearch = (e) => {
    const filterCriteria = {
        fromDate: fromDate ? moment(fromDate).format('DD-MM-YYYY') : '',
        toDate: toDate ? moment(toDate).format('DD-MM-YYYY') : ''
    };
    const queryString = new URLSearchParams(filterCriteria).toString();
    getSearchBillList(queryString,e.target.value).then(function (result) {
        const response = result?.data;
        // console.log(response, "rrrr")
        setBill(response?.data);
    });}
    const onStatus=(e)=>{
        console.log(e.target.value,"babababababa")
        getSearchBillList(e.target.value).then(function (result) {
            const response = result?.data;
            console.log(response, "newrrrr")
            setBill(response?.data);
            setSortingCleared(false);
        });}
        const clearSort = () => {
            // Reset the value of the sorting select to an empty string or any default value
            document.querySelector('.sortby').value = ''; // Set it to an empty string or any default value that signifies no sorting
            setSortingCleared(true);
            // Fetch data with default sorting or any default behavior
            // For example:
            getSearchBillList('').then(function(result) {
                const response = result?.data;
                console.log(response, "newrrrr")
                setBill(response?.data);
            });
        };
const toggleDropdown = () => {
  setIsOpen(!isOpen);
};

const handleOptionClick = (option) => {
  setSelectedOption(option);
  setIsOpen(false);
};
useEffect(() => {
    getSearchList();
}, [fromDate, toDate]);
const [shows, setShows] = useState(false);
const handleShowinv = () => {
    setShows(true);
    setShowsvat(false);
}
const handleCloseinv = () => {
    setShows(false);
    setShowsvat(true);
}
const onResend = (id) => {
    setIsSpinner(true)
    getBillCancelResend(id, "resend",
        (r) => {
            console.log(r, "rererererere")
            // if (r.success === true) {
            //     toast.success(r.message)
            //     getBill();

            // }
           
            if (r.success === true) {
                setIsSpinner(false)
                Swal.fire({
                 title: r.message,
               //  text: 'The product has been added successfully!',
                 icon: 'success',
                 confirmButtonText: 'OK',
                 customClass: {
                     confirmButton: 'my-confirm-button'
                 }
             }).then(() => {
               
                getBill();
             })
             
           } 
            else {
                console.log("nononononono")
                toast.error(r.message)
                setIsSpinner(false)
            }
        })
}
const onCancel = (id) => {
    setIsSpinner(true)
    getBillCancelResend(id, "cancel",
        (r) => {
            console.log(r, "rererererere")
            // if (r.success === true) {
            //     toast.success(r.message)
            //     getBill();

            // }
            
            if (r.success === true) {
                setIsSpinner(false)
                Swal.fire({
                 title: r.message,
               //  text: 'The product has been added successfully!',
                 icon: 'success',
                 confirmButtonText: 'OK',
                 customClass: {
                     confirmButton: 'my-confirm-button'
                 }
             }).then(() => {
                getBill();
             })
           } 
            else {
                console.log("nononononono")
                toast.error(r.message)
                setIsSpinner(false)
            }
        })
}
const onDelete = (id) => {
    setIsSpinner(true)
    getBillDelete(id,
        (r) => {
            console.log(r, "rererererere")
            // if (r.success === true) {
            //     toast.success(r.message)
            //     getBill();

            // }
            if (r.success === true) {
                setIsSpinner(false)
                Swal.fire({
                 title: r.message,
               //  text: 'The product has been added successfully!',
                 icon: 'success',
                 confirmButtonText: 'OK',
                 customClass: {
                     confirmButton: 'my-confirm-button'
                 }
             }).then(() => {
                getBill();
             })
           } 
            else {
                console.log("nononononono")
                toast.error(r.message)
                setIsSpinner(false)
            }
        })
}
const onExcelExport = () => {
    getExcelBill().then(function (result) {
        const response = result?.data?.data
        console.log(response,"response")
        setBillExport(response);
    });

}
const [showsvat, setShowsvat] = useState(false);
const handleClosevat = () => setShowsvat(false);

const [manulalPaymentMethod,setManulaPaymentMethod] = useState("");
const [amoutnt,setAmount] = useState();
const [manualPaymentDate,setManualPaymentDate] = useState("");
const [manualPaymentAccount,setManualPaymentAccount] = useState("");
const [manualPaymentNotes,setManualPaymentNotes] = useState("");
const [selectedaccountId, setSelectedAccountId] = useState('');
const [Account, setAccount] = useState([]);
const [currency,setcurrency] = useState();
const [currencyData, setCurrencyData] = useState(null);
const [selectedCurrency, setSelectedCurrency] = useState('KES');
const [selectedCurrencyValue, setSelectedCurrencyValue] = useState('');
const [error, setError] = useState(null);
 const [chequeid, setChequeId] = useState("");
  const [chequeNumber, setChequeNumber] = useState("");
     const [accountno, setAccountNo] = useState("");
    const [accountholdername, setAccountHoldername] = useState("");
    const [checkMethod, setChequeMethod] = useState("");
    const [bankName, setBankName] = useState("");
    const [selectedchequeDate, setSelectedChequeDate] = useState(null);
    const [formatechequeDate, setformatedChequeDate] = useState('');
    const [selectedchequecreditDate, setSelectedChequeCreditDate] = useState(null);
    const [formatechequeCreditDate, setformatedChequeCreditDate] = useState('');
    const [chequeList, setChequeList] = useState([]);
    const [buttonText, setButtonText] = useState("Save");
     const handleChequeChange = (date) => {
            setSelectedChequeDate(date);
            setformatedChequeDate(moment(date).format('DD-MM-YYYY'))
        };
        console.log(buttonText,'button===')
        const handleChequeCreditChange = (date) => {
            setSelectedChequeCreditDate(date);
            setformatedChequeCreditDate(moment(date).format('DD-MM-YYYY'))
        };
const onManulalPaymentMethod = (e)=>{
    // e.preventDefault();
    setManulaPaymentMethod(e.target.value)
}
const onChequeMethod = (e) => {
    // e.preventDefault();
    setChequeMethod(e.target.value)
}
const onChequeNumberChange = (e) => {
    // e.preventDefault();
    setChequeNumber(e.target.value)
}
const onAccountHolderName = (e) => {
    // e.preventDefault();
    setAccountHoldername(e.target.value)
}
const onAccountNo = (e) => {
    // e.preventDefault();
    setAccountNo(e.target.value)
}
const onChequeId = (e) => {
    // e.preventDefault();
    setChequeId(e.target.value)
}
const onBankName = (e) => {
    // e.preventDefault();
    setBankName(e.target.value)
}
const onAmoutnt = (e)=>{
    // e.preventDefault();
    setAmount(e.target.value)
}
const handleAccountChange = (e) => {
    const selectedId = e.target.value;
    setSelectedAccountId(selectedId);
    
    console.log(e.target,'billlamont===');

};
const handleCurrencyChange = (e) => {
    const selectedId = e.target.value;
    setSelectedCurrencyValue(selectedId);
    
    console.log(e.target.value,'billlamont===');

};

console.log(selectedCurrencyValue,'billlamont====');
const getAccount=useCallback( async()=> {
    getAccounttId("").then(function (result) {
        const response = result?.data;
        console.log(response, "rrrr")
        setAccount(response?.data?.accounts);
        console.log("select------>",select)
        if(select){
            console.log("select------>if",select)
            setSelectedAccountId(response?.data?.accounts[response?.data?.accounts.length-1]?.account_id)
        }
      
    });
},[select])
useEffect(()=>{
    if(select){
        getAccount()
    }
},[select])
const onManualPaymentNotes = (e)=>{
    // e.preventDefault();
    setManualPaymentNotes(e.target.value)
}
const fetchCurrencyData = async () => {
    const UserCurrency=  localStorage.getItem("1Simpleclick_Currency");
    setCurrencyData(UserCurrency)
    //setIsSpinner(true)
      try {
        const apiId = 'onesimpleclick809194384'; // Replace with your actual API ID
        const apiKey = 'omumk49ld03teh0h681go0jmqp'; // Replace with your actual API Key
        const encodedCredentials = btoa(`${apiId}:${apiKey}`);

        const response = await axios.get('https://xecdapi.xe.com/v1/convert_from.json/', {
          params: {
            from:selectedCurrency?selectedCurrency:"CAD" , // base currency
            to: UserCurrency, // target currency
            amount: 1,
          },
          headers: {
            'Authorization': `Basic ${encodedCredentials}`
          }
        });
        console.log('UserCurrency',UserCurrency);
        console.log('selectedCurrency',selectedCurrency)
        setSelectedCurrencyValue(response.data.to[0].mid);
        setIsSpinner(false)
      } catch (error) {
        setError(error.message);
        console.error(error);
        setIsSpinner(false)
      }
    };
    useEffect(() => {
        if (selectedCurrency) {
          fetchCurrencyData();
          getSymbolFromCurrency('')
        }
      }, [selectedCurrency]);
      const refreshExchangeRate = () => {
        fetchCurrencyData();
    };
const [msg,setMsg] = useState("");
const _SUBMIT_RECORD_PAYMENT = async (e)=>{
    e.preventDefault();
    console.log('selectedDate',selectedDate)
    
    if(selectedaccountId==='')
        {
          toast.error('please select any account')  
        }
       else if(amoutnt==='')
            {
              toast.error('please add amount')  
            }

            else if(formateDate ==='')
                {
                  toast.error('please add Date')  
                }
        else
   { 
    setIsSpinner(true)
    let data={
        manual_payment_method :  manulalPaymentMethod?manulalPaymentMethod:'Credit Cards',
        amount : selectedCurrencyValue * amoutnt,
        manual_payment_date : formateDate,
        manual_payment_account : selectedaccountId,
        manual_payment_notes : manualPaymentNotes,
        manual_payment_type : "debit",
        supplierCurrencyaAmount:amoutnt
    } 
    console.log('selectedDate==',selectedDate,data.manual_payment_date)
    await manualPayment(billId,data,(res)=>{
        console.log(res.message,"????????????????")
        if("Paid amount should not be more than due amount"===res.message || "Sorry. You can't pay total amount in a single installation!"===res.message){
            setIsSpinner(false)
            setMsg(res.message)
        }
        else{
            setIsSpinner(false)
           // toast.success(res.message)
          
            Swal.fire({
                title: res.message,
              //  text: 'The product has been added successfully!',
                icon: 'success',
                confirmButtonText: 'OK',
                customClass: {
                    confirmButton: 'my-confirm-button'
                }
            }).then(() => {
                setDataPdf(res);
                setShowPdf(true);
                handleCloseRecord();
            getBill();
            })
        }
  
    })
}
}
const [userData, setUserData] = useState(null);
const [usertype, setUserType] = useState(null);
const [userid, setUserId] = useState(null);
useEffect(() => {
  // Retrieve the JSON string from localStorage
  const jsonString = localStorage.getItem('1Simpleclick_User'); // Replace 'yourStorageKey' with the actual key.
console.log("localdataaa======",localStorage.getItem('1Simpleclick_User'))
  // Parse the JSON string into an object
  const user_type = localStorage.getItem('1Simpleclick_UserType');
  const data = JSON.parse(jsonString);
  setUserId(localStorage.getItem("1Simpleclick_UserID"));
  // Update the state with the retrieved data
  setUserData(data);
  setUserType(user_type)
}, []);

const checkModule = (module, subModule) => {
    if (userData && userData.assigned_modules) {
      const assignedModules = userData.assigned_modules;
      
      // Handle the case where assigned_modules is an empty string
      if (assignedModules === "") {
        return true; // Or false, depending on the desired behavior
      }
  
      try {
        const modules = JSON.parse(assignedModules);
        
        // if (modules && modules[module]) {
            return modules[module]?.[subModule] || false; // Fallback to false if subModule is not found
     //   }
      } catch (error) {
        console.error("Error parsing assigned_modules:", error);
      }
    }
    return true; // Default to true if no assigned_modules or any errors
  };

   return (
        <div className="producservlisting pdt">
            <Dbheader />
            <Sidebar>
                <div className='customerMain'>
                <div className='custTop'>
            <Link to='/supplierlisting' className={usertype == 2 ?(checkModule('Purchase','Supplier') ? '' :'disabled'):''}>Supplier</Link>
            {/* <Link to='/productservicelistings'>Product &amp; Service</Link> */}
            <Link to='/quotelisting' className={usertype == 2 ?(checkModule('Purchase','Quote') ? '' :'disabled'):''}>Quote</Link>
            <Link to='/purchaseorderlisting' className={usertype == 2 ?(checkModule('Purchase','PurchaseOrders') ? '' :'disabled'):''}>Purchase Order</Link>
            <Link to='/bill'  className={usertype == 2 ?(checkModule('Purchase','Bill') ? 'active' :'disabled'):'active'}>Bill</Link>
            <div className="custom-dropdown-container customDropdown ms-4">
                            <div className="custom-dropdown-header" onClick={toggleDropdown}>
                                <span>{selectedOption}</span>
                                <span className={`dropdown-arrow ${isOpen ? 'open' : ''}`}> <i class="fa-regular fa-angle-down"></i></span>
                            </div>

                            {isOpen && (
                                <ul className="custom-dropdown-options">
                                    <li
                                        className={`option ${selectedOption === 'Option 1' ? 'selected' : ''}`}
                                    //    onClick={() => handleOptionClick('Option 1')} hidden
                                    >
                                      <Link to='/manualexpenses' className={`${usertype == 2 ?(checkModule('Purchase','ManualExpenses') ? '' :'disabled'):''}`}><img src={income2} alt='' style={{width:'20px',height:'20px',color:'#7E89A1'}}/> Manual Expenses</Link>
                                    </li>
                                    <li
                                        className={`option ${selectedOption === 'Option 2' ? 'selected' : ''}`}
                                       // onClick={() => handleOptionClick('Option 2')}
                                    >
                                        <Link to="/billpayment" className={usertype == 2 ?(checkModule('Purchase','BillPayments') ? '' :'disabled'):''}><i class="fa-light fa-calendar-lines-pen"></i> Bill Payment</Link>
                                    </li>
                                    <li
                                        className={`option ${selectedOption === 'Option 3' ? 'selected' : ''}`}
                                       // onClick={() => handleOptionClick('Option 3')} hidden
                                    >
                                        <Link to="/refundlist" className={usertype == 2 ?(checkModule('Purchase','Refund') ? '' :'disabled'):''}><i className="fa-light fa-calendar-lines-pen"></i> Refunds</Link>
                                    </li>
                                    {/* <li
                                        className={`option ${selectedOption === 'Option 4' ? 'selected' : ''}`}
                                      //  onClick={() => handleOptionClick('Option 4')}
                                    >
                                         <Link to="/purchaserefundlist" className={usertype == 2 ?(checkModule('Purchase','Refund') ? '' :'disabled'):''}><i className="fa-light fa-calendar-lines-pen"></i> Refunds</Link>
                                    </li> */}
                                </ul>
                            )}

{/* <p>You selected: {selectedOption}</p> */}
                        </div>
          </div>
                    <div className='productServList'>
                        <div className='dbTopsec pb-4 pt-2 noBackbtn'>
                            <Headtxt link="/supplierlisting" title="Bill" />
                            
                            {/* <div className='custButton'>
                                <button className='commonButton addBtn'><Link to="/addproductservice"><i class="fa-regular fa-circle-plus"></i> Add Product</Link></button>&nbsp;&nbsp;
                                <button className='commonButton expBtn'><i class="fa-light fa-download"></i> Export Excel</button>
                            </div> */}
                        </div>

                        <div className='customerSrchTop pb-3 poResponsive'>
              <div className='customSrchfield'>
                <div>
                <span><i class="fa-solid fa-magnifying-glass"></i></span><input type='search' placeholder='Search by keywords' onChange={onSearch}/>
                </div>&nbsp;&nbsp;
                {/* <Form.Select aria-label="Default select example" className='moreDrop'>
              <option>Date</option>
            </Form.Select> */}
             <div className='invDatepicker' >
            <DatePicker
                    selected={fromDate}
                    onChange={handleFromDateChange}
                    selectsStart
                    startDate={fromDate}
                    endDate={toDate}
                    placeholderText="From Date"
                    isClearable={fromDate?true:false}
                />
                 </div>
                   <div className='invDatepicker' style={{ marginLeft: '10px' }}>
                <DatePicker
                    selected={toDate}
                    onChange={handleToDateChange}
                    selectsEnd
                    startDate={fromDate}
                    endDate={toDate}
                    placeholderText="To Date"
                    isClearable={toDate?true:false}
                    
                />
                </div>
                </div>
              <div>
                
            <Link to='/billadd' className='commonButton addBtn'><i class="fa-regular fa-circle-plus"></i> Add Bill</Link>&nbsp;&nbsp;
            <CSVLink data={billExport} filename={"bills.csv"}>
                                     <button className='commonButton expBtn'><i class="fa-light fa-upload"></i> Export</button>
                                     </CSVLink>
              </div>
            </div>


                        {/* <div className='addsuppBtn'>
                            <button className={`${!supply ? "commonButton active" : "commonButton "} `} onClick={toggleCompany}>Product</button>
                            <button className={`${supply ? "commonButton active" : "commonButton "} `} onClick={toggleIndividual}>Service</button>
                        </div> */}
                        <div className='mt-2'>
                            
                            <div className='gridList mb-3 flexfield gridListResp' style={{justifyContent: "flex-end"}}>
                                <div className='srtBy'>
                                    <label>Sort by: </label>&nbsp;
                                    <Form.Select aria-label="Default select example" className='sortby' onChange={onStatus}>
                                    {<option selected value="">Select an option</option>} 
                                    <option>Cancelled</option>
                                    <option>Paid</option>
                                    <option>Unpaid</option>
                                    <option>Partially Paid</option>
                                    </Form.Select>&nbsp;
                                  {/* { !sortingCleared && <button onClick={clearSort} style={{ border: "none", background: "none", cursor: "pointer" }}>
            <i className="fas fa-times-circle" style={{ color: "#FF6C3C", fontSize: "1.2em" }}></i>
        </button>} */}
                                </div>
                               
                                <div>
                                <button size="100%" style={{ backgroundColor: "#DCE5EC" }} onClick={toggleHandler1} className={!view? "listactive" : "" }><i class="fa-solid fa-list"></i></button>
                                <button size="100%" style={{ backgroundColor: "#DCE5EC" }} onClick={toggleHandler} className={view? "listactive" : "" }><i class="fa-solid fa-grid"></i></button>
                                </div>
                            </div>
                            {!view ? 
                            <div className='listViews'>
                            <table class="table">
                                <thead>
                                    <tr>
                                        <th scope="col">Bill Date</th>
                                        <th scope="col">Bill Id</th>
                                        <th scope="col">Supplier</th>
                                        <th scope="col">Amount</th>
                                        <th scope="col">Due Date</th>
                                        <th scope="col">Due Amount</th>
                                        <th scope="col">Created/Updated By</th>
                                        <th scope="col">Status</th>
                                        <th scope="col">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    
                                 {bill?.length != 0 && bill?.slice(0, visibleItems)?.map((item, index) => (
                                    usertype==1? 
                                                <tr key={index} text={item}>
                                                    <td>{item?.bill_date}</td>
                                                    <td><Link to={`/viewbilldetails?id=${item.id}`}>{item?.bill_id}</Link></td>
                                                    <td>{item?.type == "Company" ? item?.company_name : item?.fname + " " + item?.lname} </td>
                                                    <td>{`${item.supplier_currency} ${item?.supplierCurrency_totalAmount}`}</td>
                                                    <td>{item?.expiration_date}</td>
                                                    <td>{`${item.supplier_currency} ${item.supplierCurrency_dueAmount}`}</td>
                                                    <td>{item.creatorFname} {item.creatorLname}</td>
                                                    <td>
                                                    <span className='pending' style={{ backgroundColor: item?.status === 'Cancelled' ?  '#f2b8be': item?.status === 'Not Ordered' ? '#f8d5c9' : item?.status === 'Partially Paid' ? '#cfdcf8': item?.status === 'Paid' ? '#d7f4b2': item?.status === 'Unpaid'||'Not Billed' ? '#f0caee' : '#ce7ac',
    color: item?.status === 'Cancelled' ? '#e51c0e' : item?.status === 'Partially Paid' ? '#1650ce': item?.status === 'Not Ordered' ? '#FF6C3C': item?.status === 'Paid' ? '#638D2C' : item?.status === 'Unpaid'||'Not Billed' ? '#e61cdf': '#6b992c' }}>
        {item?.status}
    </span>
</td>
                                                    <td>
                                                        <div className='tdFlex'>
                                                            {/* <span className='viewIcon'><Link to={`/customerdetails`}><i className="fa-light fa-eye"></i></Link></span> */}
                                                            {(item?.status =='Not Ordered'|| item?.status =='Paid'||item?.status == "Cancelled" ||item?.status ==='Partially Paid'||item?.status==='Fully Returned'||item?.status==='Partially Returned')? <span style={{marginRight:'35px'}}></span>:
                                                             <span className='viewIcon orange' >
                                                                <Link to={`/billdetails?id=${item.id}`} ><i className="fa-light fa-pen"></i></Link></span>
                                                            }

                                                             
                 

                                                            {/* {item?.status == "Unpaid" ? <span className='viewIcon' style={{marginRight: "10px"}} onClick={() => onDelete(item?.id)}><i className="fa-regular fa-trash-can"></i></span>:
                                                            <span className='viewIcon' style={{marginRight: "10px"}} onClick={() =>onDelete(item?.id)}></span>} */}
                                                            <Dropdown className='dropRow'>
                                                                <Dropdown.Toggle id="dropdown-basic">
                                                                    {
                                                                        item?.status != "Cancelled" ?
                                                                            <i className="fa-light fa-ellipsis-vertical"></i> : ""}
                                                                </Dropdown.Toggle>

                                                                
                                                                <Dropdown.Menu>
                                                                    {item?.status =='Paid'||item?.status =='Cancelled'||item?.status==='Fully Returned'||item?.status==='Partially Returned'? " ": 
                                                                    <Dropdown.Item onClick={async (e) => { 
                                                                        setAmount(item?.supplierCurrency_dueAmount);
                                                                        setSelectedCurrency(item?.currency);
                                                                        
                                                                        // Wait for fetchCurrencyData to complete
                                                                      //  await fetchCurrencyData();
                                                            
                                                                        // Then show the record after 1 second delay
                                                                        // setTimeout(() => {
                                                                            handleShowRecord(e, item?.id);
                                                                        // }, 1000); // 1000 milliseconds = 1 second
                                                                    }}>
                                                                    {/* Dropdown item content */}
                                                               
                                                                <i className="fa-regular fa-file"></i> &nbsp; Record a payment</Dropdown.Item>}
                                                                    {item?.status =='Paid'||item?.status==='Partially Returned'? <>
                                                                    {/* <span style={{marginLeft:'10px',fontSize:'13px'}}><Link to={`/viewbilldetails?id=${item.id}`}><i class="fa-regular fa-file"></i></Link>&nbsp; Returns & Refund</span> 
                                                                 <br></br> */}
                                                                    <Dropdown.Item onClick={e=>{ navigate(`/returnrefunds?id=${item.id}`)  }} ><Link to={`/returnrefunds?id=${item.id}`}><i className="fa-regular fa-file"></i></Link>&nbsp; Returns & Refund</Dropdown.Item>
                                                                    </>
                                                                    :" "}
                                                                    
                                                                    <Dropdown.Item onClick={e=>{ navigate(`/viewbilldetails?id=${item.id}`)}}><Link to={`/viewbilldetails?id=${item.id}`}><i className="fa-light fa-eye"></i></Link>&nbsp; View Bill Details</Dropdown.Item>
                                                                 
                                                                    
                                                                    {item.status=='Unpaid'?
                                                                    <Dropdown.Item onClick={() => onCancel(item?.id)} ><i className="fa-regular fa-circle-xmark"></i> &nbsp; Cancel</Dropdown.Item>
                                                                    :null}
                                                                    
                                                                    <Dropdown.Item onClick={() => onResend(item?.id)}><i className="fa-light fa-share"></i> &nbsp; Resend</Dropdown.Item>
                                                                 
                                                           {/* <Dropdown.Item ><i className="fa-light fa-share"></i> &nbsp; Generate Order</Dropdown.Item> */}
                                                           
                                                                </Dropdown.Menu>
                                                            </Dropdown>
                                                        </div>
                                                    </td>
                                                </tr>
:  
usertype==2&&item.created_by==userid?
<tr key={index} text={item}>
                                                    <td>{item?.bill_date}</td>
                                                    <td><Link to={`/viewbilldetails?id=${item.id}`}>{item?.bill_id}</Link></td>
                                                    <td>{item?.type == "Company" ? item?.company_name : item?.fname + " " + item?.lname} </td>
                                                    <td>{`${item.supplier_currency} ${item?.supplierCurrency_totalAmount}`}</td>
                                                    <td>{item?.expiration_date}</td>
                                                    <td>{`${item.supplier_currency} ${item.supplierCurrency_dueAmount}`}</td>
                                                    <td>{item.creatorFname} {item.creatorLname}</td>
                                                    <td>
                                                    <span className='pending' style={{ backgroundColor: item?.status === 'Cancelled' ?  '#f2b8be': item?.status === 'Not Ordered' ? '#f8d5c9' : item?.status === 'Partially Paid' ? '#cfdcf8': item?.status === 'Paid' ? '#d7f4b2': item?.status === 'Unpaid'||'Not Billed' ? '#f0caee' : '#ce7ac',
    color: item?.status === 'Cancelled' ? '#e51c0e' : item?.status === 'Partially Paid' ? '#1650ce': item?.status === 'Not Ordered' ? '#FF6C3C': item?.status === 'Paid' ? '#638D2C' : item?.status === 'Unpaid'||'Not Billed' ? '#e61cdf': '#6b992c' }}>
        {item?.status}
    </span>
</td>
                                                    <td>
                                                        <div className='tdFlex'>
                                                            {/* <span className='viewIcon'><Link to={`/customerdetails`}><i className="fa-light fa-eye"></i></Link></span> */}
                                                            {(item?.status =='Not Ordered'|| item?.status =='Paid'||item?.status == "Cancelled" ||item?.status ==='Partially Paid'||item?.status==='Fully Returned'||item?.status==='Partially Returned')? <span style={{marginRight:'35px'}}></span>:
                                                             <span className='viewIcon orange' >
                                                                <Link to={`/billdetails?id=${item.id}`} ><i className="fa-light fa-pen"></i></Link></span>
                                                            }

                                                             
                 

                                                            {/* {item?.status == "Unpaid" ? <span className='viewIcon' style={{marginRight: "10px"}} onClick={() => onDelete(item?.id)}><i className="fa-regular fa-trash-can"></i></span>:
                                                            <span className='viewIcon' style={{marginRight: "10px"}} onClick={() =>onDelete(item?.id)}></span>} */}
                                                            <Dropdown className='dropRow'>
                                                                <Dropdown.Toggle id="dropdown-basic">
                                                                    {
                                                                        item?.status != "Cancelled" ?
                                                                            <i className="fa-light fa-ellipsis-vertical"></i> : ""}
                                                                </Dropdown.Toggle>

                                                                
                                                                <Dropdown.Menu>
                                                                    {item?.status =='Paid'||item?.status =='Cancelled'||item?.status==='Fully Returned'||item?.status==='Partially Returned'? " ": 
                                                                    <Dropdown.Item onClick={async (e) => { 
                                                                        setAmount(item?.supplierCurrency_dueAmount);
                                                                        setSelectedCurrency(item?.currency);
                                                                        
                                                                        // Wait for fetchCurrencyData to complete
                                                                      //  await fetchCurrencyData();
                                                            
                                                                        // Then show the record after 1 second delay
                                                                        // setTimeout(() => {
                                                                            handleShowRecord(e, item?.id);
                                                                        // }, 1000); // 1000 milliseconds = 1 second
                                                                    }}>
                                                                    {/* Dropdown item content */}
                                                               
                                                                <i className="fa-regular fa-file"></i> &nbsp; Record a payment</Dropdown.Item>}
                                                                    {item?.status =='Paid'||item?.status==='Partially Returned'? <>
                                                                    {/* <span style={{marginLeft:'10px',fontSize:'13px'}}><Link to={`/viewbilldetails?id=${item.id}`}><i class="fa-regular fa-file"></i></Link>&nbsp; Returns & Refund</span> 
                                                                 <br></br> */}
                                                                    <Dropdown.Item onClick={e=>{ navigate(`/returnrefunds?id=${item.id}`)  }} ><Link to={`/returnrefunds?id=${item.id}`}><i className="fa-regular fa-file"></i></Link>&nbsp; Returns & Refund</Dropdown.Item>
                                                                    </>
                                                                    :" "}
                                                                    
                                                                    <Dropdown.Item onClick={e=>{ navigate(`/viewbilldetails?id=${item.id}`)}}><Link to={`/viewbilldetails?id=${item.id}`}><i className="fa-light fa-eye"></i></Link>&nbsp; View Bill Details</Dropdown.Item>
                                                                 
                                                                    
                                                                    {item.status=='Unpaid'?
                                                                    <Dropdown.Item onClick={() => onCancel(item?.id)} ><i className="fa-regular fa-circle-xmark"></i> &nbsp; Cancel</Dropdown.Item>
                                                                    :null}
                                                                    
                                                                    <Dropdown.Item onClick={() => onResend(item?.id)}><i className="fa-light fa-share"></i> &nbsp; Resend</Dropdown.Item>
                                                                 
                                                           {/* <Dropdown.Item ><i className="fa-light fa-share"></i> &nbsp; Generate Order</Dropdown.Item> */}
                                                           
                                                                </Dropdown.Menu>
                                                            </Dropdown>
                                                        </div>
                                                    </td>
                                                </tr>
                                                :null
                                            ))} 
                                        </tbody> 
                                    </table>

                                    {loading ?
                                    (
                                        <div className='loadItems'>                
                    <button className="commonButton addBtn" disabled>
                        Loading...
                    </button>
                    </div>
                ) : hasMore ? (
                    <div className='loadItems'>
                    <button onClick={loadMoreItems} className="commonButton addBtn">
                    No More Bill
                    </button>
                    </div>
                ) : (
                    <div className='loadItems'>
                    <button className="commonButton addBtn" disabled>
                        No More Bill
                    </button>
                    </div>
                )
                }
                                </div>
                         : 
                         <div className='gridView'>
                                    {bill?.map((item) => (
usertype==1? 
                                        <Card>
                                            <Card.Body>
                                                <div className='commonFlex'>
                                                    <h6>{item?.bill_id}</h6>
                                                    <span><img src={cals} alt='' className='mb-2' /> {item?.bill_date}</span>
                                                </div>
                                                <div className='commonFlex'>
                                                    <span className='customr'>{item?.type === "Company" ? item?.company_name : item?.fname + " " + item?.lname}</span>
                                                    <span>&nbsp;</span>
                                                </div>
                                                <div className='commonFlex'>
                                                <span className='pending' style={{
    backgroundColor: item?.status === 'Cancelled' ? '#f2b8be' :
                     item?.status === 'Not Ordered' ? '#f8d5c9' :
                     item?.status === 'Paid'||item?.status === 'Billed' ? '#d7f4b2' :
                     item?.status === 'Partially Paid' ? '#cfdcf8' :
                   //  item?.status === 'Billed' ? '#ce7ac' :
                     item?.status === 'Unpaid' || item?.status === 'Not Billed' ? '#f0caee' : '',
    color: item?.status === 'Cancelled' ? '#e51c0e' :
           item?.status === 'Partially Paid' ? '#1650ce' :
           item?.status === 'Not Ordered' ? '#FF6C3C' :
           item?.status === 'Paid' ||item?.status === 'Billed'? '#638D2C' :
          // item?.status === 'Billed' ? '#ce7ac' :
           item?.status === 'Unpaid' || item?.status === 'Not Billed' ? '#e61cdf' : ''
}}>{item?.status}</span>
                                                    <h6>{item?.total}</h6>
                                                </div>
                                                <div className='commonFlex gridFooter'>
                                                    <div>
                                                        <span className='viewIcon orange'><Link to={`/billdetails?id=${item.id}`}><i className="fa-light fa-pen"></i></Link></span>
                                                        {/* <span className='viewIcon'><i className="fa-regular fa-trash-can"></i></span> */}
                                                    </div>
                                                    <div className='invgridicn'>
                                                        {/* <Link to=""><i className="far fa-envelope"></i></Link>&nbsp; */}
                                                        <div className='tdFlex'>
                                                            {/* <span className='viewIcon'><Link to={`/customerdetails`}><i className="fa-light fa-eye"></i></Link></span> */}
                                                            {/* <span className='viewIcon orange'><Link to={`/`}><i className="fa-light fa-pen"></i></Link></span>
                                                            <span className='viewIcon'><i className="fa-regular fa-trash-can"></i></span>&nbsp;&nbsp; */}
                                                                   {!(item?.status === 'Cancelled' || item?.status === 'Paid' || item?.status === 'Partially Paid' || item?.status==='Billed' || item?.status==='Not Billed') &&
                                                            <Dropdown className='dropRow'>
                                                            <Dropdown.Toggle id="dropdown-basic">
                                                                {
                                                                    item?.status != "Cancelled" ?
                                                                        <i className="fa-light fa-ellipsis-vertical"></i> : ""}
                                                            </Dropdown.Toggle>


                                                            <Dropdown.Menu>
                                                                {item?.status =='Paid'||item?.status =='Cancelled'? " ": 
                                                                <Dropdown.Item
                                                                 onClick={(e) => {setAmount(item?.supplierCurrency_dueAmount);setcurrency(item?.user_currency);setSelectedCurrency(item?.currency);fetchCurrencyData();handleShowRecord(e,item?.id);}} >
                                                                    <i className="fa-regular fa-file"></i> &nbsp; Record a payment</Dropdown.Item>}
                                                               
                                                                {item.status=='Unpaid'?
                                                                <Dropdown.Item onClick={() => onCancel(item?.id)} ><i className="fa-regular fa-circle-xmark"></i> &nbsp; Cancel</Dropdown.Item>
                                                                :null}
                                                                
                                                                <Dropdown.Item onClick={() => onResend(item?.id)}><i className="fa-light fa-share"></i> &nbsp; Resend</Dropdown.Item>
                                                                {/* <Dropdown.Item ><i className="fa-light fa-share"></i> &nbsp; Generate Order</Dropdown.Item> */}
                                                            </Dropdown.Menu>
                                                        </Dropdown>
                                                          }
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* <div className='cardBlock actn'>
                                                <div>
                                                    <span className='viewIcon'><Link to={`/customerdetails`}><i className="fa-light fa-eye"></i></Link></span>
                                                    <span className='viewIcon orange'><Link to={`/editcustomer`}><i className="fa-light fa-pen"></i></Link></span>
                                                    <span className='viewIcon'><i className="fa-regular fa-trash-can"></i></span>
                                                </div>
                                            </div> */}
                                            </Card.Body>
                                        </Card>
                                        :  
                                        usertype==2&&item.created_by==userid?
                                        <Card>
                                        <Card.Body>
                                            <div className='commonFlex'>
                                                <h6>{item?.bill_id}</h6>
                                                <span><img src={cals} alt='' className='mb-2' /> {item?.bill_date}</span>
                                            </div>
                                            <div className='commonFlex'>
                                                <span className='customr'>{item?.type === "Company" ? item?.company_name : item?.fname + " " + item?.lname}</span>
                                                <span>&nbsp;</span>
                                            </div>
                                            <div className='commonFlex'>
                                            <span className='pending' style={{
backgroundColor: item?.status === 'Cancelled' ? '#f2b8be' :
                 item?.status === 'Not Ordered' ? '#f8d5c9' :
                 item?.status === 'Paid'||item?.status === 'Billed' ? '#d7f4b2' :
                 item?.status === 'Partially Paid' ? '#cfdcf8' :
               //  item?.status === 'Billed' ? '#ce7ac' :
                 item?.status === 'Unpaid' || item?.status === 'Not Billed' ? '#f0caee' : '',
color: item?.status === 'Cancelled' ? '#e51c0e' :
       item?.status === 'Partially Paid' ? '#1650ce' :
       item?.status === 'Not Ordered' ? '#FF6C3C' :
       item?.status === 'Paid' ||item?.status === 'Billed'? '#638D2C' :
      // item?.status === 'Billed' ? '#ce7ac' :
       item?.status === 'Unpaid' || item?.status === 'Not Billed' ? '#e61cdf' : ''
}}>{item?.status}</span>
                                                <h6>{item?.total}</h6>
                                            </div>
                                            <div className='commonFlex gridFooter'>
                                                <div>
                                                    <span className='viewIcon orange'><Link to={`/billdetails?id=${item.id}`}><i className="fa-light fa-pen"></i></Link></span>
                                                    {/* <span className='viewIcon'><i className="fa-regular fa-trash-can"></i></span> */}
                                                </div>
                                                <div className='invgridicn'>
                                                    {/* <Link to=""><i className="far fa-envelope"></i></Link>&nbsp; */}
                                                    <div className='tdFlex'>
                                                        {/* <span className='viewIcon'><Link to={`/customerdetails`}><i className="fa-light fa-eye"></i></Link></span> */}
                                                        {/* <span className='viewIcon orange'><Link to={`/`}><i className="fa-light fa-pen"></i></Link></span>
                                                        <span className='viewIcon'><i className="fa-regular fa-trash-can"></i></span>&nbsp;&nbsp; */}
                                                               {!(item?.status === 'Cancelled' || item?.status === 'Paid' || item?.status === 'Partially Paid' || item?.status==='Billed' || item?.status==='Not Billed') &&
                                                        <Dropdown className='dropRow'>
                                                        <Dropdown.Toggle id="dropdown-basic">
                                                            {
                                                                item?.status != "Cancelled" ?
                                                                    <i className="fa-light fa-ellipsis-vertical"></i> : ""}
                                                        </Dropdown.Toggle>


                                                        <Dropdown.Menu>
                                                            {item?.status =='Paid'||item?.status =='Cancelled'? " ": 
                                                            <Dropdown.Item
                                                             onClick={(e) => {setAmount(item?.supplierCurrency_dueAmount);setcurrency(item?.user_currency);setSelectedCurrency(item?.currency);fetchCurrencyData();handleShowRecord(e,item?.id);}} >
                                                                <i className="fa-regular fa-file"></i> &nbsp; Record a payment</Dropdown.Item>}
                                                           
                                                            {item.status=='Unpaid'?
                                                            <Dropdown.Item onClick={() => onCancel(item?.id)} ><i className="fa-regular fa-circle-xmark"></i> &nbsp; Cancel</Dropdown.Item>
                                                            :null}
                                                            
                                                            <Dropdown.Item onClick={() => onResend(item?.id)}><i className="fa-light fa-share"></i> &nbsp; Resend</Dropdown.Item>
                                                            {/* <Dropdown.Item ><i className="fa-light fa-share"></i> &nbsp; Generate Order</Dropdown.Item> */}
                                                        </Dropdown.Menu>
                                                    </Dropdown>
                                                      }
                                                    </div>
                                                </div>
                                            </div>
                                            {/* <div className='cardBlock actn'>
                                            <div>
                                                <span className='viewIcon'><Link to={`/customerdetails`}><i className="fa-light fa-eye"></i></Link></span>
                                                <span className='viewIcon orange'><Link to={`/editcustomer`}><i className="fa-light fa-pen"></i></Link></span>
                                                <span className='viewIcon'><i className="fa-regular fa-trash-can"></i></span>
                                            </div>
                                        </div> */}
                                        </Card.Body>
                                    </Card>
                                    :null
                                    ))}

                                </div>
        }

                        </div>
                    </div>
                </div>
            </Sidebar>



            <Modal size="lg" show={showrecord} onHide={handleCloseRecord} className="customerModal sloModal">
                
                    <Modal.Header closeButton>
                        <Modal.Title><h5>Record A Manual Payment</h5></Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                    <div className='commonWhite addCustomerForm p-3 mb-3'>
                        <Form>
                            <Form.Group className="row mb-2" controlId="exampleForm.ControlInput1">
                                <Col md="6">
                                    <Form.Select aria-label="Default select example" onChange={onManulalPaymentMethod} >
                                        {/* <option selected value='Credit Cards'>Credit Cards</option>
                                        <option value="Debit Cards">Debit Cards</option> */}
                                        <option value="NetBanking">NetBanking</option>
                                        <option value="Mobile Payments">Mobile Payments</option>
                                        <option value="Cash" selected>Cash</option>
                                        <option value="Cheque">Cheque</option>
                                        <option value="Online Payment">Online Payment/Credit Cards/Debit Cards</option>
                                    </Form.Select>
                                </Col>
                                <Col md="2" style={{display:'flex',width: '17%'}}>
                                    <p style={{ fontSize: 14 }}>{`Main Amount : ${getSymbolFromCurrency(selectedCurrency)}`}</p>
                                </Col >
                                <Col md="3" className='mb-2'>
                                    <Form.Control type="number" placeholder="Amount" onChange={onAmoutnt} value={amoutnt} />
                                </Col>
                            </Form.Group>

                            <Form.Group className="row" style={{display:"flex",alignItems:"center"}}>
                                <Col md="2"  >
                                    <p style={{ fontSize: 14 }}>Exchange Rate :</p>

                                </Col>

                                <Col md="2">
                                    <input
                                        id="c474_exchange_rate"
                                        // style={{ marginBottom: '15px', marginRight: '40px' }}
                                        name="exchange_rate"
                                        className="input-medium"
                                        type="text"
                                        tabIndex="7"
                                        value={selectedCurrencyValue}
                                        onChange={handleCurrencyChange}
                                    />

                                </Col>

                                <Col md="3" style={{ marginLeft: '75px' }}>
                                    <p style={{ fontSize: 14 }}>Converted Amount :</p>
                                </Col>
                                <Col md="2">
                                    <input
                                        id="c474_exchange_rate"
                                        style={{ marginBottom: '15px' }}
                                        name="exchange_rate"
                                        readOnly
                                        className="input-medium"
                                        type="text"
                                        tabIndex="7"
                                        value={`${getSymbolFromCurrency(currencyData)} ${(selectedCurrencyValue * amoutnt).toFixed(2)}`} // Assuming you want a fixed two decimal places
                                    />
                                </Col>
                                <Col md="1" style={{ marginLeft: '95px', marginTop: '10px' }}>
                                    {/* <i className="fas fa-sync-alt" style={{ cursor: 'pointer' }} onClick={refreshExchangeRate}></i> */}
                                </Col>
      <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px',alignSelf:'center' }}>
                        <p>{getSymbolFromCurrency(selectedCurrency)} 1 {selectedCurrency} = {getSymbolFromCurrency(currencyData)} {selectedCurrencyValue} {currencyData}</p>
                        <i className="fas fa-sync-alt" style={{ marginLeft: '10px', cursor: 'pointer' }} onClick={refreshExchangeRate}></i>
                    </div>
    </Form.Group>
                            <Form.Group className="row" controlId="exampleForm.ControlInput1">
                                    <Col md="6" className='datepickr mb-2'>
                                        <DatePicker
                                            selected={selectedDate}
                                            onChange={handleDateChange}
                                            dateFormat="dd/MM/yyyy" // You can customize the date format
                                            // isClearable
                                            placeholderText="Date"
                                        />
                                    </Col>
                                    <Col md="6" className='mb-3'>
  <Form.Select aria-label="Default select example" onChange={(e) => {e.target.value=== "add"? accountpop() : handleAccountChange(e) }}>
    <option selected hidden>Payment Account</option>
   
      <option value="add"><i className="fa-solid fa-plus"></i>+ Add an Account</option>
     { Account.map((item, i) =>
        <option key={i} selected={select && Account.length===i+1} value={item.account_id}>{item.account_name}</option>
    )}
    
  </Form.Select>
</Col>

{manulalPaymentMethod == 'Cheque' && (
                                            <>
                                                <h6 style={{ marginTop: '20px', textAlign: 'left' }}>Add Cheque Details</h6>
                                                <Col md="4" className='mt-2'>
                                                    <Form.Control type="text" placeholder="Enter Cheque Number" onChange={onChequeNumberChange} value={chequeNumber} />
                                                </Col>
                                                <Col md="4" className='mt-2'>
                                                    <Form.Control type="text" placeholder="Enter Bank Name" onChange={onBankName} value={bankName} />
                                                </Col>
                                                <Col md="4" className='datepickr mt-2'>
                                                    <DatePicker
                                                        selected={selectedchequeDate}
                                                        onChange={handleChequeChange}
                                                        dateFormat="dd/MM/yyyy" // You can customize the date format

                                                        // isClearable
                                                        placeholderText="Cheque Deposit Date" />
                                                </Col>
                                                <Col md="6" className='mt-2'>
                                                    <Form.Control type="text" placeholder="Enter Account Number" onChange={onAccountNo} value={accountno} />
                                                </Col>
                                                <Col md="6" className='datepickr mt-2'>
                                                    <DatePicker
                                                        selected={selectedchequecreditDate}
                                                        onChange={handleChequeCreditChange}
                                                        dateFormat="dd/MM/yyyy" // You can customize the date format

                                                        // isClearable
                                                        placeholderText="Cheque Credit Date" />
                                                </Col>
                                                {/* <Col md="4" className='mt-2'>
                                                    <Form.Control type="text" placeholder="Enter Cheque Id" onChange={onChequeId} value={chequeid} />
                                                </Col> */}
                                                <Col md="6" className='mt-2'>
                                                    <Form.Control type="text" placeholder="Enter Account Holder Name" onChange={onAccountHolderName} value={accountholdername} />
                                                </Col>
                                                <Col md="6" className='mt-2'>
                                                    <Form.Select aria-label="Default select example" onChange={onChequeMethod} value={checkMethod}>
                                                        <option value="">Select cheque status</option>
                                                        <option value="Deposited">Deposited</option>
                                                        <option value="Bounced">Bounced</option>
                                                        <option value="Cleared">Cleared</option>
                                                    </Form.Select>
                                                </Col>
                                            </>
                                        )}
                            </Form.Group>

                            <Form.Group className="row billTextarea" controlId="exampleForm.ControlInput1">
                                <Col md="12" className='mb-2'>
                                <Form.Control as="textarea" placeholder="Notes" rows={3} onChange={onManualPaymentNotes} />
                                </Col>
                            </Form.Group>
                            
                            <p style={{color:'red'}}>{msg}</p>
                        </Form>
                    </div>
                    </Modal.Body>
                    <Modal.Footer>
                    <button className='commonButton addBtn' onClick={handleCloseRecord}>Cancel</button>
                    <button className='commonButton expBtn'onClick={_SUBMIT_RECORD_PAYMENT} >Save</button>                    
                    </Modal.Footer>
            </Modal>

            <Modal size="lg" show={addaccount} onHide={addaccountClose} centered className='customerModal sloModal'>
        <Modal.Header closeButton>
          <Modal.Title>
            <h5>Add an Account</h5>
          </Modal.Title>


        </Modal.Header>
        <Modal.Body>
          <div className='commonWhite addCustomerForm mb-3'>
            <Form>
              <Form.Group className="row" controlId="exampleForm.ControlInput1">
                <Col md="12" className='mb-3'>
                  <CustomDropdown placeholder="Account Type" value="Inventory" />
                </Col>
              </Form.Group>
              <Form.Group className="row" controlId="exampleForm.ControlInput1">
                <Col md="6" className='mb-3'>
                  <Form.Control type="text" placeholder="Account name" onChange={onAccountName} />
                </Col>
                <Col md="6" className='mb-3'>
                  <Form.Control type="text" placeholder="Account ID" onChange={onAccountId} />
                </Col>
              </Form.Group>
              <Form.Group className="row" controlId="exampleForm.ControlInput1">
                <Col md="12" className='mb-0'>
                  <Form.Control as="textarea" placeholder="Description" rows={3} onChange={onAccountDescription} />
                </Col>
              </Form.Group>
            </Form>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button className='commonButton addBtn' onClick={addaccountClose}>Cancel</button>
          <button className='commonButton expBtn' onClick={onSave2} >Save</button>
        </Modal.Footer>
      </Modal>
   {/* ==================================Pdf Modal */}
   <Modal size="lg" show={showPdf} backdrop="static" onHide={handleClosePdf} className="salesExport">
        <div className='salesExpCol fullHgt'>
          <Modal.Header>
            {/* <Modal.Title>Modal heading</Modal.Title> */}
          </Modal.Header>
          <div className='modIframe'><iframe src={dataPdf?.pdf} /></div>
          <div className='expRgt'>
            {/* <button className='commonButton expBtn btnshadow'><i class="fa-regular fa-envelope"></i> Email</button>
                        <button className='commonButton wtBtn btnshadow'><i class="fa-light fa-print"></i> Print</button>
                        <button className='commonButton wtBtn btnshadow'><i class="fa-light fa-down-to-bracket"></i> Download</button> */}

          
              <div>
                {/* <button className='commonButton expBtn btnshadow' onClick={toggleHandlerb}><i class="fa-regular fa-envelope"></i> Email</button> */}
                <button className='commonButton wtBtn btnshadow' onClick={onButtonClick}><i class="fa-light fa-print"></i> Print</button>
                <button className='commonButton wtBtn btnshadow' onClick={onButtonClick}><i class="fa-light fa-down-to-bracket"></i> Download</button><br /><br /><br /><br />
                <button className='commonButton expBtn btnshadow' onClick={vclose}><i class="fa-solid fa-xmark"></i> Close</button>

              </div> 
           


          </div>
        </div>
      </Modal>

        </div>
    )
}


export default Bill