import { React, useState,useEffect } from 'react'
import Dbheader from '../../layout/Dbheader'
import Sidebar from '../../layout/Sidebar'
import { Link } from 'react-router-dom'
import Form from 'react-bootstrap/Form'
import Headtxt from '../Headtxt'
import Nav from 'react-bootstrap/Nav';
import { Col } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import { PhoneInput } from 'react-international-phone';
import { City, Country, State } from "country-state-city";
import { getAddSuppliers, getImportSupplier } from "../../../../Service/Suppliers/SuppliersService";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';
import Papa from 'papaparse';
import { CSVLink } from "react-csv";
import sampleSupplierCsv from './SampleSupplierCsv'
import { getProductServiceList } from "../../../../Service/Customer/CustomerService";
import CurrencySelect from 'react-select-currency';
import Swal from 'sweetalert2'
import instance from '../../../../WebApi/WebApi'
const Addsuppliers = () => {
    const navigate = useNavigate();
    const defaultCountry = 'KE';
    const defaultState = '30'; // ISO code for Nairobi County
    const defaultCity = 'Nairobi'; // Default city in Nairobi County

    let countryData = Country.getAllCountries();
    let initialStates = State.getStatesOfCountry(defaultCountry);
    let initialCities = City.getCitiesOfState(defaultCountry, defaultState);
    const [deleteId, setDeleteId] = useState([]);
    const [productServiceList, setAllProductServiceList] = useState([]);
    const [productServiceList2, setAllProductServiceList2] = useState([]);
    const [country, setCountry] = useState(defaultCountry);
    const [state, setState] = useState("");
    const [city, setCity] = useState("");
    const [countryCode, setCountryCode] = useState("");
    const [companyType, setCompanyType] = useState("Company");
    const [companyName, setCompanyName] = useState("");
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [webUrl, setWebUrl] = useState('');
    const [vatNumber, setVatNumber] = useState('');
    const [billAddress, setBillAddress] = useState('');
    const [zipCode, setZipCode] = useState("");
    const [stateData, setStateData] = useState(initialStates);
    const [cityData, setCityData] = useState(initialCities);
    const [jsondata, setJsonData] = useState([]);
    const [productService2, setProductService2] = useState(false);
    const [supplierId, setSupplierId] = useState();
    const [supplier2, setSupplier2] = useState(false);
    const supplierShow2 = () => setSupplier2(true);
    const productServiceShow2 = () => setProductService2(true);
    const supplier2Close = () => setSupplier2(false);
    const productServiceClose = () => setProductService2(false);
    const [show, setShow] = useState(false);
    const [show2, setShow2] = useState(false);
    const [discountQuantity, setDiscountQuantity] = useState(0);
    const handleShow = () => setShow(true);
    const handleClose = () => setShow(false);
  
    const [selectedCurrency, setSelectedCurrency] = useState('KES');
    const [allProductServiceData, setAllProductServiceData] = useState([]);
    const [allServiceData, setAllServiceData] = useState([]);
    const handleChange = (value, country) => {
        setPhone(value);
        console.log('numericPhone',value, country.country
        .dialCode)
        setCountryCode(country.country.dialCode)
        // if (areAllNumbersZero(value, country.country
        //     .dialCode)) {
        //  toast.error('phone number cannot be started from zero!');
        // }
      };
    
     
      const areAllNumbersZero = (phone, dialCode) => {
        // Remove non-numeric characters
        const numericPhone = phone.replace(/\D/g, '');
        console.log('numericPhone',numericPhone)
        // Remove leading zeros
        const withoutLeadingZeros = numericPhone.replace(/^0+/, '');
        // Remove country code if present
        const withoutCountryCode = dialCode ? withoutLeadingZeros.replace(dialCode, '') : withoutLeadingZeros;
        // Check if all digits are zero
        return /^0+$/.test(withoutCountryCode);
      }

      const handleCurrencyChange = (selectedOption) => {
        const currency = selectedOption.target.value;
        setSelectedCurrency(currency);
        //  const url = `${Base_URL}`;
        // console.log('Selected currency:', currency);
        // console.log('Selected currency:', selectedCurrency);
      
          // You can send this URL to the API endpoint
        };
    const [file, setFile] = useState(null);

    const handleDrop = (event) => {
        event.preventDefault();

        const droppedFile = event.dataTransfer.files[0];
        handleFile(droppedFile);
    };
 
    const handleFile = (selectedFile) => {
        console.log(selectedFile, "selectedFile")
        if (selectedFile) {
            // Check if the selected file is a CSV file
            if (selectedFile.type === 'text/csv' || selectedFile.name.endsWith('.csv')) {
                // File is valid, set it in the state

                setFile(selectedFile);
                Papa.parse(selectedFile, {
                    header: true, // Assuming the first row contains headers
                    complete: (result) => {
                        console.log(result.data, "dadadadada")
                        setJsonData(result.data);
                    },
                    error: (error) => {
                        console.error('CSV parsing error:', error.message);
                    },
                });
            } else {
                // File is not valid, show an error or do something else
                toast.error('Please select a valid CSV file');
                // Optionally, you can clear the file input

            }
        }



        // You can perform additional actions with the file, such as uploading it to a server.
        // For simplicity, we're just logging the file information here.
        console.log('File selected:', selectedFile);
    };
    const handleDragOver = (event) => {
        event.preventDefault();
    };
    useEffect(() => {
   
        getProductServiceDataList();
    
      }, []);
    async function getProductServiceDataList() {
        getProductServiceList("").then(function (result) {
          const response = result?.data;
          // console.log(response, "rrrr")
          setAllProductServiceList2(response?.data);
          setAllProductServiceList(response?.data);
        });
      }

      const onSearchByProSer = (e) => {
        if (e.target.value != "") {
    
          getProductServiceList(e.target.value).then(function (result) {
            const response = result?.data;
            // console.log(response, "rrrr")
    
            setAllProductServiceList(response?.data);
    
          }
          )
        } else {
          setAllProductServiceList(productServiceList2)
        }
      }
      const onCheck = (data) => {
        if (data.type === 'service') {
          if (allServiceData.includes(data)) {
            const filterData = allServiceData.filter(d => d !== data);
            setAllServiceData(filterData, () => {
              console.log(allServiceData, "allServiceData");
            });
          } else {
            const updatedData = [...allServiceData, data];
            console.log(updatedData, "updatedData")
            setAllServiceData(updatedData);
          }
        } else {
          if (allProductServiceData.includes(data)) {
            const filterData = allProductServiceData.filter(d => d !== data);
            setAllProductServiceData(filterData, () => {
              console.log(allProductServiceData, "allProductServiceData");
            });
          } else {
            const updatedData = [...allProductServiceData, data];
            console.log(updatedData, "updatedData")
            setAllProductServiceData(updatedData);
          }
        }
      };
      const [qtyIndex,setQtyIndex]=useState([]);
      const onCheckDelete = (id,indexValue) => {
        // console.log(indexValue,"indexValue")
        setDeleteId((prevDeleteId) => {
          if (prevDeleteId.includes(id)) {
            let tempQtyIndex = qtyIndex.filter(item=>item!==indexValue)
            setQtyIndex(tempQtyIndex)
            // Remove the id from the array
            return prevDeleteId.filter((d) => d !== id);
          } else {
            setQtyIndex([...qtyIndex,indexValue]);
            // Add the id to the array
            return [...prevDeleteId, id];
          }
        }, () => {
          console.log("hihihihihihihi", deleteId);
        });
      };
      console.log(allProductServiceData, "allProductServiceDataoutside");
    const [supply, setSupply] = useState(false)
    const toggleCompany = (e) => {
        setCompanyType("Company")
        e.preventDefault();
        setSupply(false)
    }
    const toggleIndividual = (e) => {
        setCompanyType("Individual")

        e.preventDefault();
        setSupply(true)
    }


    const onCompanyName = (e) => {
        setCompanyName(e.target.value)
    }

    const onFirstName = (e) => {
        setFirstName(e.target.value)
    }

    const onLastName = (e) => {
        setLastName(e.target.value)
    }

    const onEmail = (e) => {
        setEmail(e.target.value)
    }
    const onWebUrl = (e) => {
        setWebUrl(e.target.value)
    }
    const onVatNumber = (e) => {
        console.log(e.target.value, "nnnnnnnnnnoooooooooo")
        setVatNumber(e.target.value)
    }
    const onBillingAddress = (e) => {
        setBillAddress(e.target.value)
    }
    // const onZipCode = (e) => {
    //     setZipCode(e.target.value)
    // }
    const onZipCode = (e) => {
        const input = e.target.value;
        // Regular expression to allow only alphanumeric characters
        const regex = /^[a-zA-Z0-9]*$/;
        if (regex.test(input) || input === '') {
          // If input passes validation or is empty, update state
          setZipCode(input);
        }
        // You can add an else statement to show an error message or handle invalid input
      };
      useEffect(() => {
        setStateData(State.getStatesOfCountry(defaultCountry));
        setCityData(City.getCitiesOfState(defaultCountry, defaultState));
    }, [defaultCountry, defaultState]);

    const onCountryChange = (e) => {
        const selectedCountry = e.target.value;
        setCountry(selectedCountry);
        const states = State.getStatesOfCountry(selectedCountry);
        setStateData(states);
        if (states.length > 0) {
            const newState = states[0].isoCode;
            setState(newState);
            setCityData(City.getCitiesOfState(selectedCountry, newState));
        } else {
            setState('');
            setCityData([]);
        }
        setCity('');
    };

    const onStateChange = (e) => {
        const selectedState = e.target.value;
        setState(selectedState);
        const cities = City.getCitiesOfState(country, selectedState);
        setCityData(cities);
        setCity(cities.length > 0 ? cities[0].name : '');
    };

    const onCityChange = (e) => {
        setCity(e.target.value);
    };
      const onSave = (e) => {
        e.preventDefault();
        // Validation checks
        if (companyType === "Company" && companyName === "") {
            toast.error("Please type company name");
        } else if (companyType === "Individual" && firstName === "") {
            toast.error("Please type first name");
        } else if (companyType === "Individual" && lastName === "") {
            toast.error("Please type last name");
        } else if (email === "") {
            toast.error("Please type valid email");
        } else if (phone === "" || phone.length < 7) {
            toast.error("Please type phone number");
        }
        //  else if (companyType === "Company" && vatNumber <= 0) {
        //     toast.error("Please type a valid VAT Number(Optional)");
        // } 
        // else if (billAddress === "") {
        //     toast.error("Please type billing address");
        // } 
        else if (country === "") {
            toast.error("Please select country");
        } 
        // else if (state === "") {
        //     toast.error("Please select state");
        // } 
        // else if (city === "") {
        //     toast.error("Please select city");
        // } 
        else if (selectedCurrency === "" ||selectedCurrency === null ||selectedCurrency === undefined) {
            toast.error("Please select  currency");
        } 
        // else if (zipCode <= 0) {
        //     toast.error("Please type a valid zipcode");
        // } 
        else if (areAllNumbersZero(phone, countryCode)) {
            toast.error('Invalid phone number');
        }
        //  else if (products_details.length <= 0) {
        //     toast.error('Please add any product');
        // } 
        else {
            console.log('phone==', country);
            getAddSuppliers(
                companyType, 
                companyName, 
                firstName, 
                lastName, 
                email, 
                phone, 
                webUrl, 
                vatNumber, 
                billAddress, 
                countryData.find(c => c.isoCode === country)?.name, 
                stateData.find(c => c.isoCode === state)?.name, 
                city,
                selectedCurrency, 
                zipCode,
                country,
                (r) => {
                    console.log(r, "response==");
                    if (r.success === true) {
                      Swal.fire({
                        title: r.message,
                      //  text: 'The product has been added successfully!',
                        icon: 'success',
                        confirmButtonText: 'OK',
                        customClass: {
                            confirmButton: 'my-confirm-button'
                        }
                    }).then(() => {
                        navigate("/supplierlisting");
                    })
                  }else {
                        console.log("nononononono");
                        toast.error(r.message);
                    }
                }
            );
        }
    };

    const onExcelImport = () => {
        getImportSupplier(jsondata,
            (r) => {
                console.log(r, "response")
                if (r.success === true) {
                Swal.fire({
                  title: r.message,
                //  text: 'The product has been added successfully!',
                  icon: 'success',
                  confirmButtonText: 'OK',
                  customClass: {
                      confirmButton: 'my-confirm-button'
                  }
              }).then(() => {
                  navigate("/supplierlisting");
              })
            }
                else {
                    console.log("nononononono")
                    toast.error(r.message)

                }
            })


    }

    return (
        <div className="customerListing pdt">
            <Dbheader />
            <Sidebar>
                <div className='customerMain'>
                    <div className='custBtm addSupply'>
                        <div className='dbTopsec pb-2'>
                            <Headtxt link="/supplierlisting" title="New Supplier" />
                            <button className='commonButton expBtn' onClick={handleShow}><i class="fa-light fa-download"></i> Import Excel</button>
                        </div>
                        <div className='addsuppBtn'>
                            <button className={`${!supply ? "commonButton active" : "commonButton "} `} onClick={toggleCompany}>Company</button>
                            <button className={`${supply ? "commonButton active" : "commonButton "} `} onClick={toggleIndividual}>Individual</button>
                        </div>
                        <div className='commonWhite addCustomerForm p-3 mb-3'>

                            <Form>
                                {!supply ?
                                    <Form.Group className="row" controlId="exampleForm.ControlInput1">
                                        <Col md="12" className='mb-3'>
                                            <Form.Control type="text" placeholder="Company Name" onChange={onCompanyName} />
                                        </Col>
                                    </Form.Group> : <Form.Group className="row" controlId="exampleForm.ControlInput1">
                                        <Col md="6" className='mb-3'>
                                            <Form.Control type="text" placeholder="First Name" onChange={onFirstName} />
                                        </Col>
                                        <Col md="6" className='mb-3'>
                                            <Form.Control type="text" placeholder="Last Name" onChange={onLastName} />
                                        </Col>
                                    </Form.Group>}

                                <Form.Group className="row" controlId="exampleForm.ControlInput1">
                                    <Col md="6" className='mb-3'>
                                        <Form.Control type="email" placeholder="Email Address" onChange={onEmail} />
                                    </Col>
                                    <Col md="6" className='mb-3'>
                                        <PhoneInput
                                            defaultCountry="ke"
                                            value={phone}
                                            className='phInp'
                                            onChange={handleChange}
                                            forceDialCode
                                        />

                                    </Col>
                                </Form.Group>
                                <Form.Group className="row" controlId="exampleForm.ControlInput1">
                                    <Col md="6" className='mb-3'>
                                        <Form.Control type="text" placeholder="Website URL (Optional)" onChange={onWebUrl} />
                                    </Col>
                                    <Col md="6" className='mb-3'>
                                        <Form.Control type="text" placeholder="VAT Number(Optional)" onChange={onVatNumber} />
                                    </Col>
                                </Form.Group>

                                <Form.Group className="row" controlId="exampleForm.ControlInput1">
                                    <Col md="6" className='mb-3'>
                                        <Form.Control as="textarea" placeholder="Billing Address(Optional)" rows={3} onChange={onBillingAddress} />
                                    </Col>
                                    <Col md="6" className='mb-3'>
                                    <Form.Select aria-label="Select country" onChange={onCountryChange} className="mb-3" value={country}>
                <option value="" hidden>Select country</option>
                {countryData.map((con) => (
                    <option key={con.isoCode} value={con.isoCode}>
                        {con.name}
                    </option>
                ))}
            </Form.Select>
            <Form.Select aria-label="Select state" onChange={onStateChange} className="mb-3" value={state}>
                <option value="" hidden>Select state</option>
                {stateData.map((st) => (
                    <option key={st.isoCode} value={st.isoCode}>
                        {st.name}
                    </option>
                ))}
            </Form.Select>
            <Form.Select aria-label="Select city" onChange={onCityChange} className="mb-3" value={city}>
                <option value="" hidden>Select city</option>
                {cityData.map((ct) => (
                    <option key={ct.name} value={ct.name}>
                        {ct.name}
                    </option>
                ))}
            </Form.Select>
                                        
                              
        <Col md="6" className='mb-3'>
        <CurrencySelect
      value={selectedCurrency}
      onChange={handleCurrencyChange}
      placeholder="Select a currency"      
      className="slctCurrency"
      style={{width:'200%'}}
    />
    {/* </div> */}
    </Col>
                                        <Form.Control type="text" placeholder="Zip Code(Optional)" value={zipCode} onChange={onZipCode} />

                                    </Col>
                                </Form.Group>



                                {/* <Form.Group className="row" controlId="exampleForm.ControlInput1">
                                        <Col md="6" className='mb-3'>
                                            <Form.Control as="textarea" placeholder="Notes" rows={3} />
                                        </Col>
                                        <Col md="6" className='mb-3'>
                                            <div className='subTotal'>
                                                <div className='saflex'>
                                                    <span>Sub Total</span>
                                                    <span>20:00</span>
                                                </div>
                                                <div className='saflex'>
                                                    <span>Discount Rate</span>
                                                    <span>20:00</span>
                                                </div>
                                                <div className='saflex'>
                                                    <span>Discount Amount</span>
                                                    <span>20:00</span>
                                                </div>
                                                <div className='saflex'>
                                                    <span>VAT</span>
                                                    <span>20:00</span>
                                                </div>
                                                <div className='saflex totlbg'>
                                                    <span>Total</span>
                                                    <span>20:00</span>
                                                </div>
                                            </div>
                                        </Col>
                                    </Form.Group> */}

                                {/* <label>Attachments</label>
                                    <div className='adsDrag'>
                                        <div
                                            onDrop={handleDrop}
                                            onDragOver={handleDragOver}
                                            className='drgdrop'
                                        >
                                            <i class="fa-light fa-cloud-arrow-up"></i>
                                            <div style={{ display: "flex", alignItems: "center" }}>
                                                <p>Drag &amp; drop files or </p>
                                                <input type="file" onChange={(e) => handleFile(e.target.files[0])} style={{ display: 'none' }} />
                                                <button onClick={() => document.querySelector('input[type="file"]').click()}>Browse</button>
                                            </div>

                                            {file && <p>Selected File: {file.name}</p>}
                                        </div>
                                    </div> */}
                     
                                {/* <div className='addsBtn'>
                                 
                                   
                            <button className='commonButton expBtn' onClick={(e)=>handleShow2(e)}><i class="fa-regular fa-plus"></i> Add Product or Service</button>&nbsp;&nbsp;
                      
                            <button type='button' className='commonButton addBtn' onClick={handelDelete}>Delete</button>
                                </div> */}

                                <div className='addsBtn' style={{marginTop:'10px'}}>
                                    <button type='submit' className='commonButton expBtn' onClick={onSave}>Save</button>&nbsp;&nbsp;
                                    {/* <button className='commonButton addBtn'>Cancel</button> */}
                     
                      
                                </div>

                            </Form>


                        </div>
                    </div>


                </div>
            </Sidebar >


            <Modal show={show} onHide={handleClose} centered className='customerModal'>
                <Modal.Header closeButton>
                    <Modal.Title><h5>Import Suppliers</h5></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='modalContent'>
                        <div
                            onDrop={handleDrop}
                            onDragOver={handleDragOver}
                            className='drgdrop'
                        >
                            <i class="fa-light fa-cloud-arrow-up"></i>
                            <div style={{ display: "flex" }}>
                                <p>Drag &amp; drop files or </p>
                                <input type="file" onChange={(e) => handleFile(e.target.files[0])} style={{ display: 'none' }} />
                                <button onClick={() => document.querySelector('input[type="file"]').click()}>Browse</button>
                            </div>

                            {file && <p>Selected File: {file.name}</p>}
                        </div>
                    </div>
                    <div>
                        <p><button className='commonButton modalsubmit' onClick={onExcelImport}>Submit</button></p>
                        <CSVLink filename="sample_supplier.csv" data={sampleSupplierCsv}>
                            <p><button className='commonButton downloadformat'>Download format </button></p>
                        </CSVLink>
                        <span className='commonButton downloadformat' style={{ fontSize: "13px" }}>(Please follow Demo data format mentioned in csv file)</span>

                    </div>
                </Modal.Body>
                {/* <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleClose}>
            Save Changes
          </Button>
        </Modal.Footer> */}
            </Modal>
        </div >
    )
}

export default Addsuppliers