import { toast } from "react-toastify";
import webApi, { baseURL } from "../../WebApi/WebApi";

export const getProductList = async (data) => {

    return await webApi.get(`product-list?searchText=${data}`, 
    );
}

export const getServiceList = async (data) => {
    return await webApi.get(`service-list?searchText=${data}`, {

    });
}


//by id
export const getProductListById = async (data) => {
    return await webApi.get(`/product-details/${data}`, {

    });
}

//by id
export const getServiceListById = async (data) => {
    return await webApi.get(`/service-details/${data}`, {

    });
}
export const getInventoryAccounts = async (data) => {
    return await webApi.get(`get-inventory-accounts${data}`, {

    });
}
export const getUserAccountList = async (data) => {
    return await webApi.get(`invoice-user-account-list${data}`, {

    });
}
export const getAddProductService = async (type, name,code, description, unit, salePrice,
    purchaseTax, purchasePrice, orderQty,salesTax, supplierId,inventory,onSuccess, onFailure) => {

    try {
        const res = await webApi.post(

            `/product-service-details-store`,
            {
                "type": type,
                "name": name,
                "description": description,
                "unit": unit,
                "sales_price": salePrice,
                "tax": purchaseTax,
                "purchase_price": purchasePrice,
                "quantity": orderQty,
                "track_inventory": inventory,
                "supplierId":supplierId,
                "code":code,
                "sales_tax":salesTax,
            }
        );
        console.log('response',res)
        if (res?.data?.status === true) {
            const r = res.data;
            console.log(r, "rerere")

            return onSuccess(r);

        } else {
            toast.error(res?.data)
            onFailure("Something Wrong! Please Try again later" + res.data);
        }
    } catch (error) {
        console.log("fdgdf")
    }
};

export const getEditProduct = async (name, description, unit, salePrice,
    purchaseTax, purchasePrice, orderQty,salesTax, inventory, productId,onSuccess, onFailure) => {

console.log(name, description, unit, salePrice,
    purchaseTax, purchasePrice, orderQty, inventory, productId,"jhgfdsa")
    try {
        const res = await webApi.post(

            `/product-edit/${productId}`,
            {
                "name": name,
                "description": description,
                "unit": unit,
                "sales_price": salePrice,
                "tax": purchaseTax,
                "purchase_price": purchasePrice,
                "quantity": orderQty,
                "sales_tax":salesTax,
                "track_inventory": inventory
            }
        );
        if (res.status === 200) {


            const r = res.data;
            console.log(r, "rerere")

            return onSuccess(r);

        } else {
            onFailure("Something Wrong! Please Try again later" + res.data);
        }
    } catch (error) {
        console.log("fdgdf")
    }
};

export const getEditService = async (name, description, unit, salePrice,
    purchaseTax,salesTax,serviceId,onSuccess, onFailure) => {

console.log(name, description, unit, salePrice,
    purchaseTax,serviceId,"jhgfdsa")
    try {
        const res = await webApi.post(

            `/service-edit/${serviceId}`,
            {
                "name": name,
                "description": description,
                "unit": unit,
                "price": salePrice,
                "tax": purchaseTax,
               "sales_tax": salesTax,
            }
        );
        if (res.status === 200) {


            const r = res.data;
            console.log(r, "rerere")

            return onSuccess(r);

        } else {
            onFailure("Something Wrong! Please Try again later" + res.data);
        }
    } catch (error) {
        console.log("fdgdf")
    }
};

export const deleteProduct = async (ProductId, onSuccess, onFailure) => {
    console.log(ProductId,"ProductId")
    try {
        const res = await webApi.get(
            `/product-delete/${ProductId}`, 
        );
        if (res.status === 200) {
            const r = res.data;
            return onSuccess(r);
        } else {
            onFailure("Something Wrong! Please Try again later" + res.data);
        }
    } catch (error) {
        console.log("fdgdf")
    }
};
export const exitingProduct = async ( type,name,description,unit,salePrice,purchaseTax,purchasePrice,orderQty,inventory,supplierId,code,onSuccess, onFailure) => {
    try {
        const res = await webApi.post(
            `/exisisting-product-service-qty-add`,
            {
                // "type": type,
                // "quantity": orderQty,
                // "code":code,
                "type": type,
                "name": name,
                "description": description,
                "unit": unit,
                "sales_price": salePrice,
                "tax": purchaseTax,
                "purchase_price": purchasePrice,
                "quantity": orderQty,
                "track_inventory": inventory,
                "supplierId": supplierId,
                "code": code,
              
            }
        );
        if (res.status === 200) {
            const r = res.data;
            return onSuccess(r);
        } else {
            onFailure("Something Wrong! Please Try again later" + res.data);
        }
    } catch (error) {
        console.log("fdgdf")
    }
};
export const exitingProduct2 = async (type,orderQty,code,supplierId, onSuccess, onFailure) => {
    try {
        const res = await webApi.post(
            `/exisisting-product-service-qty-add`,
            {
                "type": type,
                "quantity": orderQty,
                "code":code,
                'supplierId':supplierId,
            }
        );
        if (res.status === 200) {
            const r = res.data;
            return onSuccess(r);
        } else {
            onFailure("Something Wrong! Please Try again later" + res.data);
        }
    } catch (error) {
        console.log("fdgdf")
    }
};

export const deleteService = async (ServiceId, onSuccess, onFailure) => {
    console.log(ServiceId,"ServiceId")
    try {
        const res = await webApi.get(
            `/service-delete/${ServiceId}`, 
        );
        if (res.status === 200) {
            const r = res.data;
            return onSuccess(r);
        } else {
            onFailure("Something Wrong! Please Try again later" + res.data);
        }
    } catch (error) {
        console.log("fdgdf")
    }
};


export const getExcelProduct = async () => {
    return await webApi.get( `/product-list-export`, 
{

    });
}

export const getExcelService = async () => {
    return await webApi.get( `/service-list-export`, 
{

    });
}

export const getImportProductService = async ( selection,jsonData,onSuccess, onFailure) => {


    try {
        const res = await webApi.post(

            `/product-service-details-import`,
            { 
                "type":selection,
                productService:jsonData,
             }
           
        );
        if (res.status === 200) {


            const r = res.data;
            console.log(r, "rerere")

            return onSuccess(r);

        } else {
            onFailure("Something Wrong! Please Try again later" + res.data);
        }
    } catch (error) {
        console.log("fdgdf")
    }
};
