import React, { useState, useEffect } from 'react';
import Sidebar from '../../layout/Sidebar'
import Dbheader from '../../layout/Dbheader'
import { Link } from 'react-router-dom'
import Form from 'react-bootstrap/Form';
// import Gridview from './Gridview';
// import Listview from './Listview';
import Headtxt from '../Headtxt';
import { Button, Modal } from 'react-bootstrap';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { deleteCustomer, getCustomerList, getSearchCustomer } from "../../../../Service/Customer/CustomerService";
import Card from 'react-bootstrap/Card'
import { getSupplierList, deleteSupplier, getSearchSupplier, getExcelSupplier } from "../../../../Service/Suppliers/SuppliersService";
import { CSVLink } from "react-csv";
import Swal from 'sweetalert2';
import income2 from '../../../images/income2.png';
const Supplierlisting = () => {
  const [loading, setLoading] = useState(false);
  const [view, setView] = useState(false)
  const toggleHandler = (e) => {
    e.preventDefault();
    setView(true)
  }
  const toggleHandler1 = (e) => {
    e.preventDefault();
    setView(false)
  }
  const [supplierList, setAllSupplierList] = useState([]);
  const [deleteSupplierId, setDeleteSupplierId] = useState("");
  const [showDelete, setShowDelete] = useState(false);
  const handleCloseDelete = () => setShowDelete(false);
  const [supplierExport, setSupplierExport] = useState([]);
  const [userData, setUserData] = useState(null);
  const [usertype, setUserType] = useState(null);
  useEffect(() => {
    // Retrieve the JSON string from localStorage
    const jsonString = localStorage.getItem('1Simpleclick_User'); // Replace 'yourStorageKey' with the actual key.
    console.log("localdataaa======", localStorage.getItem('1Simpleclick_User'))
    // Parse the JSON string into an object
    const user_type = localStorage.getItem('1Simpleclick_UserType');
    const data = JSON.parse(jsonString);

    // Update the state with the retrieved data
    setUserData(data);
    setUserType(user_type)
  }, []);
  useEffect(() => {
    getSupplierDataList();
    onExcelExport();
  }, [usertype]);
  const checkModule = (module, subModule) => {
    if (userData && userData.assigned_modules) {
      const assignedModules = userData.assigned_modules;

      // Handle the case where assigned_modules is an empty string
      if (assignedModules === "") {
        return true; // Or false, depending on the desired behavior
      }

      try {
        const modules = JSON.parse(assignedModules);

        // if (modules && modules[module]) {
        return modules[module]?.[subModule] || false; // Fallback to false if subModule is not found
        //   }
      } catch (error) {
        console.error("Error parsing assigned_modules:", error);
      }
    }
    return true; // Default to true if no assigned_modules or any errors
  };
  console.log(supplierList, "supplierList")
  

  async function getSupplierDataList() {
    try {
        const result = await getSupplierList(); // Await the promise to handle it asynchronously
        const response = result?.data;
if (usertype ==1) {  
      setAllSupplierList(response?.data); // Set the supplier list if the module is accessible
  }
    else if (usertype == 2 && checkModule('Purchase', 'Supplier')) { // Check user type
            // if (checkModule('Purchase', 'Supplier')) {
            console.log('pretirori=====') 
                setAllSupplierList(response?.data); // Set the supplier list if the module is accessible
            // } 
           
        }
        else {
          console.log('pretirori=====-----') 
          setAllSupplierList([]); // Clear the supplier list otherwise
      }
    } catch (error) {
        console.error('Error fetching supplier list:', error);
    }
}
console.log('condition---', usertype == 2 && checkModule('Purchase', 'Supplier'))
  const [hasMore, setHasMore] = useState(true);
  const [visibleItems, setVisibleItems] = useState(5);
  const fetchMoreItems = (start) => {
    // Simulate fetching more items from an API
    const itemsPerPage = 5;
    const totalItems = 20; // Adjust as needed for your use case
    const newItems = [];

    for (let i = start; i < start + itemsPerPage; i++) {
      if (i < totalItems) {
        newItems.push(`Item ${i + 1}`);
      }
    }

    return newItems;
  };
  useEffect(() => {
    // Initial load of items
    loadMoreItems();
  }, []);

  const loadMoreItems = () => {
    setLoading(true);
    setTimeout(() => {
      // Simulate loading delay
      setVisibleItems((prev) => prev + 5);
      setLoading(false);
    }, 1000); // Adjust the delay as needed
  };

  const onDelete = (data) => {
    setDeleteSupplierId(data);
    console.log(data, "datadatadatadatadatadata")
    setShowDelete(true)
  }


  const onYes = () => {
    deleteSupplier(deleteSupplierId, (res) => {
      console.log(res, "response")
      //     if (res.success === true) {
      //       toast.success(res.message)
      //       setShowDelete(false)
      //       getSupplierDataList();
      // }
      if (res.success === true) {
        Swal.fire({
          title: res.message,
          //  text: 'The product has been added successfully!',
          icon: 'success',
          confirmButtonText: 'OK',
          customClass: {
            confirmButton: 'my-confirm-button'
          }
        }).then(() => {
          setShowDelete(false)
          getSupplierDataList();
        })
      }
      else {
        toast.error(res.message)

      }
    })
  }

  const onNo = () => {
    setShowDelete(false)

  }

  const onSearch = (e) => {
    console.log(e.target.value, "dsdsdsdsd")
    getSearchSupplier(e.target.value).then(function (result) {
      const response = result?.data;
      console.log(response, "rrrr")
      setAllSupplierList(response?.data);
    });
  }

  const convertToCSV = (data) => {
    const header = Object.keys(data[0]).join(',');
    const rows = data.map((row) => Object.values(row).join(','));
    return [header, ...rows].join('\n');
  };




  const onExcelExport = () => {
    getExcelSupplier().then(function (result) {
      const response = result?.data?.data;
      console.log(response, "response")
      const csvData = [
        ["Billing_Address", "City", "Company_Name", "Country", 'Email', "First_Name", "Last_Name", "Phone", "State", "Type", "VAT_Number", "Website_URL", "Zipcode"],
        ...response.map(({
          Billing_Address, City, Company_Name, Country, Email, First_Name, Last_Name, Phone, State, Type, VAT_Number, Website_URL, Zipcode }) => [
            Billing_Address, City, Company_Name, Country, Email, First_Name, Last_Name, `\n${Phone}\n`, State, Type, VAT_Number, Website_URL, Zipcode
          ]),
      ];
      setSupplierExport(
        csvData
      );
    });

  }
  console.log(supplierExport, 'supplierExport')

  // custom-select-drupdown
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState('More');

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleOptionClick = (option) => {
    setSelectedOption(option);
    setIsOpen(false);
  };
 

  return (
    <div className="customerListing pdt">
      <Dbheader />
      <Sidebar>
        <div className='customerMain'>
          <div className='custTop'>
            <Link to='/supplierlisting' className={`${usertype == 2 ? (checkModule('Purchase', 'Supplier') ? 'active' : 'disabled') : 'active'}`}>Supplier</Link>
            {/* <Link to='/productservicelistings'>Product &amp; Service</Link> */}
            <Link to='/quotelisting' className={`${usertype == 2 ? (checkModule('Purchase', 'Quote') ? '' : 'disabled') : ''}`}>Quote</Link>
            <Link to='/purchaseorderlisting' className={`${usertype == 2 ? (checkModule('Purchase', 'PurchaseOrders') ? '' : 'disabled') : ''}`}>Purchase Order</Link>
            <Link to='/bill' className={`${usertype == 2 ? (checkModule('Purchase', 'Bill') ? '' : 'disabled') : ''}`}>Bill</Link>
            <div className="custom-dropdown-container customDropdown ms-4">
              <div className="custom-dropdown-header" onClick={toggleDropdown}>
                <span>{selectedOption}</span>
                <span className={`dropdown-arrow ${isOpen ? 'open' : ''}`}> <i class="fa-regular fa-angle-down"></i></span>
              </div>

              {isOpen && (
                <ul className="custom-dropdown-options">
                  <li
                    className={`option ${selectedOption === 'Option 1' ? 'selected' : ''}`}
                  //  onClick={() => handleOptionClick('Option 1')}
                  >
                  <Link to='/manualexpenses' className={`${usertype == 2 ?(checkModule('Purchase','ManualExpenses') ? '' :'disabled'):''}`}><img src={income2} alt='' style={{width:'20px',height:'20px',color:'#7E89A1'}}/> Manual Expenses</Link>
                  </li>
                  <li
                    className={`option ${selectedOption === 'Option 2' ? 'selected' : ''}`}
                  //  onClick={() => handleOptionClick('Option 2')}
                  >
                    <Link to="/billpayment" className={`${usertype == 2 ? (checkModule('Purchase', 'BillPayments') ? '' : 'disabled') : ''}`}><i class="fa-light fa-calendar-lines-pen"></i> Bill Payment</Link>
                  </li>
                  <li
                    className={`option ${selectedOption === 'Option 3' ? 'selected' : ''}`}
                    onClick={() => handleOptionClick('Option 3')} hidden
                  >
                    <Link to="">Option 3</Link>
                  </li>
                  <li
                    className={`option ${selectedOption === 'Option 4' ? 'selected' : ''}`}
                  // onClick={() => handleOptionClick('Option 4')}
                  >
                    <Link to="/purchaserefundlist" className={`${usertype == 2 ? (checkModule('Purchase', 'Refund') ? '' : 'disabled') : ''}`}><i className="fa-light fa-calendar-lines-pen"></i> Refunds</Link>
                  </li>
                </ul>
              )}

              {/* <p>You selected: {selectedOption}</p> */}
            </div>
          </div>
          <div className='custBtm'>
            <div className='dbTopsec pb-2'>
              <Headtxt link="/dashboard" title="Suppliers" />
            </div>
            <div className='customerSrchTop pb-3'>
              <div className='customSrchfield'><span><i class="fa-solid fa-magnifying-glass"></i></span><input type='search' placeholder='Search for a specific Name' onChange={onSearch} /></div>
              <div className='custButton'>
                {
                  usertype == 1
                    ?

                    <button className="commonButton addBtn" >
                      <Link to="/addsuppliers">
                        <i className="fa-regular fa-circle-plus"></i> Add Suppliers
                      </Link>
                    </button>
                    : usertype == 2 && checkModule('Purchase', 'Supplier') ? (
                      <button className="commonButton addBtn ">
                        <Link to="/addsuppliers">
                          <i className="fa-regular fa-circle-plus"></i> Add Suppliers
                        </Link>
                      </button>)
                      :
                      <button className="commonButton addBtn disabled" disabled>
                        <Link to="/addsuppliers">
                          <i className="fa-regular fa-circle-plus"></i> Add Suppliers
                        </Link>
                      </button>
                }


                {/* <button className='commonButton expBtn' onClick={onExcelExport}><i class="fa-light fa-download"></i> Export Excel</button> */}
                {
                  usertype == 1
                    ?
                <CSVLink filename="supplier_list.csv" data={supplierExport}>
                  <button className='commonButton expBtn'><i class="fa-light fa-upload"></i> Export Excel</button>
                </CSVLink>
                : usertype == 2 && checkModule('Purchase', 'Supplier') ? (
                  <CSVLink filename="supplier_list.csv" data={supplierExport}>
                  <button className='commonButton expBtn'><i class="fa-light fa-upload"></i> Export Excel</button>
                </CSVLink>)
                : <CSVLink filename="supplier_list.csv" data={supplierExport}>
                <button className='commonButton expBtn disabled'><i class="fa-light fa-upload"></i> Export Excel</button>
              </CSVLink>
}
              </div>
            </div>

            <div className='mt-2'>
              <div className='gridList mb-3 text-end'>
                <button size="100%" style={{ backgroundColor: "#DCE5EC" }} onClick={toggleHandler1} className={!view ? "listactive" : ""}><i class="fa-solid fa-list"></i></button>
                <button size="100%" style={{ backgroundColor: "#DCE5EC" }} onClick={toggleHandler} className={view ? "listactive" : ""}><i class="fa-solid fa-grid"></i></button>
              </div>
              {!view ?

                <div className='listViews'>
                  <table class="table">
                    <thead>
                      <tr>
                        <th scope="col">Supplier Type</th>
                        <th scope="col">Supplier Name</th>
                        <th scope="col">Email</th>
                        <th scope="col">Phone</th>
                        <th scope="col">Country</th>
                        <th scope="col">Created/Updated By</th>
                        <th scope="col">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {supplierList?.map((d) => (
                        <tr>
                          <td>{d?.type}</td>
                          {/* <td>{d?.fname} {d?.lname}</td> */}
                          <td>{d?.type === "Company" ? d?.company_name : d?.fname + " " + d?.lname} </td>
                          <td>{d?.email}</td>
                          <td>{d?.phone}</td>
                          <td>{d?.country}</td>
                          <td>{d?.creatorFname} {d?.creatorLname}</td>
                          <td>
                            <span className='viewIcon'><Link to={`/suppliersdetails?id=${d?.id}`}><i class="fa-light fa-eye"></i></Link></span>
                            <span className='viewIcon orange'><Link to={`/editsupplier?id=${d?.id}`}><i class="fa-light fa-pen"></i></Link></span>
                            <span className='viewIcon'><i class="fa-solid fa-trash-can" onClick={onDelete.bind(d?.id, `${d?.id}`)}></i></span>
                          </td>
                        </tr>
                      ))}


                    </tbody>
                  </table>

                  {loading &&
                    <div className='loadItems'>
                      <button className="commonButton addBtn">
                        Loading...
                      </button>
                    </div>
                  }
                  {!loading && (
                    <div className='loadItems'>
                      <button onClick={loadMoreItems} className="commonButton addBtn">
                        No More Supplier
                      </button>
                    </div>
                  )}

                  <Modal size="sm" show={showDelete} onHide={handleCloseDelete} className='delModal'>
                    <Modal.Header >
                      <Modal.Title><h5>Are you sure want to delete ?</h5></Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <button variant="primary" className='commonButton expBtn' onClick={onYes} >
                        Yes
                      </button>
                      &nbsp;
                      &nbsp;
                      &nbsp;
                      &nbsp;
                      <button variant="primary" className='commonButton expBtn' onClick={onNo}>
                        No
                      </button>
                    </Modal.Body>
                  </Modal>
                </div>


                :

                <div className='gridView'>
                  {supplierList?.map((d) => (

                    <Card>
                      <Card.Body>
                        <div className='cardBlock'>
                          <h6>Supplier Type</h6>
                          {d?.type}
                        </div>
                        <div className='cardBlock'>
                          <h6>Supplier Name</h6>
                          {d?.type === "Company" ? d?.company_name : d?.fname + " " + d?.lname}
                        </div>
                        <div className='cardBlock'>
                          <h6>Email</h6>
                          {d?.email}
                        </div>
                        <div className='cardBlock'>
                          <h6>Phone</h6>
                          {d?.phone}
                        </div>
                        <div className='cardBlock'>
                          <h6>Country</h6>
                          {d?.country}
                        </div>
                        <div className='cardBlock actn'>
                          {/* <h6>Action</h6> */}
                          <div>
                            <span className='viewIcon'><Link to={`/suppliersdetails?id=${d?.id}`}><i class="fa-light fa-eye"></i></Link></span>
                            <span className='viewIcon orange'><Link to={`/editsupplier?id=${d?.id}`}><i class="fa-light fa-pen"></i></Link></span>
                            <span className='viewIcon'><i class="fa-solid fa-trash-can" onClick={onDelete.bind(d?.id, `${d?.id}`)}></i></span>

                          </div>
                        </div>
                      </Card.Body>
                    </Card>
                  ))}
                  <Modal size="sm" show={showDelete} onHide={handleCloseDelete} className='delModal'>
                    <Modal.Header >
                      <Modal.Title><h5>Are you sure want to delete ?</h5></Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <button variant="primary" className='commonButton expBtn' onClick={onYes} >
                        Yes
                      </button>
                      &nbsp;
                      &nbsp;
                      &nbsp;
                      &nbsp;
                      <button variant="primary" className='commonButton expBtn' onClick={onNo}>
                        No
                      </button>
                    </Modal.Body>
                  </Modal>
                </div>


              }

            </div>

          </div>
        </div>
      </Sidebar>
    </div>
  )
}

export default Supplierlisting