import { React, useEffect, useMemo, useState } from 'react'
import Sidebar from '../layout/Sidebar'
import Dbheader from '../layout/Dbheader'
import Form from 'react-bootstrap/Form';
import Headtxt from './Headtxt';
import Col from 'react-bootstrap/Col';
import 'react-datepicker/dist/react-datepicker.css';
import Customdropdown from '../Customdropdown';
import usrimg from '../../images/profimg.png';
import { Link, useNavigate } from 'react-router-dom';
import { getUpdateMasterUser, getUserprofiledata } from '../../../Service/User/UserService';
import { PhoneInput } from 'react-international-phone';
import countryList from 'react-select-country-list';
import Select from 'react-select';
import CurrencySelect from 'react-select-currency';
import Swal from 'sweetalert2';
import { toast } from 'react-toastify';
import { FloatingLabel } from 'react-bootstrap';
import { type } from '@testing-library/user-event/dist/type';
const Myprofile = ({setIsSpinner}) => {
    const navigate = useNavigate();
    const options = useMemo(() => countryList().getData(), [])
    const [image, setImage] = useState({ preview: "", raw: "" });
    const [getImage, setGetImage] = useState(null);
    const [getImageresult, setGetImageresult] = useState("")
    const [profiledata, setProfiledata] = useState();
    const [selectedCurrency, setSelectedCurrency] = useState('KES');
    const [inputValues, setInputValues] = useState([]);
    const [count, setCount] = useState(["A"])
  const [flag, setFlag] = useState(false)
  const [popup, setPopup] = useState(true)
  const [usertype, setUserType] = useState(null);
  
  useEffect(() => {
    // Retrieve the JSON string from localStorage
    const jsonString = localStorage.getItem('1Simpleclick_User'); // Replace 'yourStorageKey' with the actual key.
console.log("localdataaa======",localStorage.getItem('1Simpleclick_UserType'))
    // Parse the JSON string into an object
    const user_type = localStorage.getItem('1Simpleclick_UserType');
    setUserType(user_type)
  }, []);
    const handleCurrencyChange = (selectedOption) => {
        const currency = selectedOption.target.value;
        setSelectedCurrency(currency);
      }
    const onFile = (event) => {

        if (event["target"].files.length > 0) {
          const file = event["target"].files[0];
        //   setFieldValue("browse", file.name)
    
          // setUserData({...userData, profileImage : file});
          setImage({
            preview: URL.createObjectURL(event.target.files[0]),
            raw: event.target.files[0]
          });
    
          setGetImage(file)
          setGetImageresult(file.name)
    
          //     const reader = new FileReader();
          //     reader.readAsDataURL(file);
          //     reader.onload = (event) => {
          //         setGetImageresult(reader.result);
          //   };
        }
    
      }

      const handleAppendDiv = (e) => {
        e.preventDefault();
      
        if (count.length > 0) {
          const newCount = [...count, String.fromCharCode(count[count.length - 1].charCodeAt() + 1)];
          setCount(newCount);
        } else {
          // Handle the case where count is empty
          setCount(['A']); // Initialize with 'A' if count is empty
        }
      };
      
      
    
      // Function to handle div removal at a specific index
      const handleRemoveDiv = (index) => {
        const updatedCount = [...count];
        updatedCount.splice(index, 1);
        setCount(updatedCount);
      
        const updatedValues = [...inputValues];
        updatedValues.splice(index, 1);
        setInputValues(updatedValues);
      };
    
      console.log(inputValues, "ipipipipip")
    
    
      const onTax = (event, index, field) => {
        const updatedValues = [...inputValues];
        if (!updatedValues[index]) {
          updatedValues[index] = {};
        }
        updatedValues[index][field] = event.target.value;
        setInputValues(updatedValues);
      };
      
      useEffect(() => {
        GetMyProfileData();
    }, [usertype]);
    
    const GetMyProfileData = () => {
        getUserprofiledata().then(function (result) {
            const response = result?.data?.data
            console.log(response,"=======response======")
            setProfiledata(response);
            setImage(usertype==1?{preview:response?.logo}:{preview:response?.profile_image})
            setInputValues(response?.tax?.map(item => ({ tax_label: item.tax_label || '', tax_rate: item.tax_rate || 0 })));
            setCount(response?.tax?.map((_, index) => index));
        });
    }
    console.log(image,"=======image======")
    const onPhone = (e) => {
        setProfiledata({ ...profiledata, phone: e.target.value })
    }
    const onCountry = (selectedOption) => {
        console.log('Selected country value:', selectedOption);
        setProfiledata({ ...profiledata, country: selectedOption.label });
    };
    const onAddress = (e) => {
        setProfiledata({ ...profiledata, address: e.target.value });
    }; 
    const onCompanyCountry = (e) => {
        setProfiledata({ ...profiledata, company_country: e.target.value });
    };
    const onCompanyState = (e) => {
        setProfiledata({ ...profiledata, company_state: e.target.value });
    };
    const onCompanyCity = (e) => {
        setProfiledata({ ...profiledata, company_city: e.target.value });
    };
    const onZipcode = (e) => {
        setProfiledata({ ...profiledata, zipcode: e.target.value });
    };
    const onIndustry= (e) => {
        setProfiledata({ ...profiledata, industry_type: e.target.value });
    };
    const onWebsiteUrl= (e) => {
        setProfiledata({ ...profiledata, website_url: e.target.value });
    };
    const _SUBMIT_User_Edit = async (e) => {
        e.preventDefault();
        let formData = new FormData();
        formData.append('type', 'profile')
        formData.append('phone', profiledata?.phone)
        formData.append(usertype==1?'logo':'profile_image',getImage)
        formData.append('country', profiledata?.country)
        formData.append('currency',selectedCurrency)
        //  let data = {
        //       phone:profiledata?.phone,
        //       logo:getImageresult,
        //       type:'profile',
        //       country:profiledata?.country,
        //       currency:selectedCurrency
           
        //     }
             console.log('selectedDate==', formData)
             setIsSpinner(true)
            await getUpdateMasterUser(formData, (res) => {
                console.log(res.message, "????????????????")
                if (res.success === true) {
                  setIsSpinner(false)
                  Swal.fire({
                      title: res.message,
                    //  text: 'The product has been added successfully!',
                      icon: 'success',
                      confirmButtonText: 'OK',
                      customClass: {
                          confirmButton: 'my-confirm-button'
                      }
                  }).then(() => {
                    GetMyProfileData();
                  });
                
              }
              else{
                setIsSpinner(false)
                  toast.error(res.message)  
              }
           })
    }
    const _SUBMIT_User_Edit2 = async (e) => {
        e.preventDefault();
        let formData = new FormData();
        formData.append('type', 'companyProfile')
        formData.append('address',profiledata?.address)
        formData.append('country',profiledata?.company_country)
        formData.append('state',profiledata?.company_state)
        formData.append('city',profiledata?.company_city)
        formData.append('zipcode',profiledata?.zipcode)
        formData.append('industry_type',profiledata?.industry_type)
        formData.append('website_url',profiledata?.website_url)
        //  let data = {
        //     address:profiledata?.address,
        //       type:'companyProfile',
        //       company_country:profiledata?.company_country,
        //       company_state:profiledata?.company_state,
        //       company_city:profiledata?.company_city,
        //       zipcode:profiledata?.zipcode,
        //       industry_type:profiledata?.industry_type,
        //       website_url:profiledata?.website_url
           
        //     }
             console.log('selectedDate==', formData)
             setIsSpinner(true)
            await getUpdateMasterUser(formData, (res) => {
                console.log(res.message, "????????????????")
                if (res.success === true) {
                  setIsSpinner(false)
                  Swal.fire({
                      title: res.message,
                    //  text: 'The product has been added successfully!',
                      icon: 'success',
                      confirmButtonText: 'OK',
                      customClass: {
                          confirmButton: 'my-confirm-button'
                      }
                  }).then(() => {
                    GetMyProfileData();
                  });
                
              }
              else{
                setIsSpinner(false)
                  toast.error(res.message)  
              }
           })
    }
    const _SUBMIT_User_Tax_Edit = async (e) => {
        e.preventDefault();
        let formData = new FormData();

    formData.append('tax', JSON.stringify(inputValues))
    formData.append('type', 'taxData')
        //  let data = {
        //       tax:JSON.stringify(inputValues),
        //     type:'taxData',
        //     }
             console.log('selectedDate==', formData)
             setIsSpinner(true)
            await getUpdateMasterUser(formData, (res) => {
                console.log(res.message, "????????????????")
                if (res.success === true) {
                  setIsSpinner(false)
                  Swal.fire({
                      title: res.message,
                    //  text: 'The product has been added successfully!',
                      icon: 'success',
                      confirmButtonText: 'OK',
                      customClass: {
                          confirmButton: 'my-confirm-button'
                      }
                  }).then(() => {
                    GetMyProfileData();
                  });
                
              }
              else{
                setIsSpinner(false)
                  toast.error(res.message)  
              }
           })
    }
    return (
        <div className="producservlisting pdt">
            <Dbheader />
            <Sidebar>
                <div className='customerMain'>
                    {/* <div className='custTop'>
                    <Link to='/balancesheet' >Balance Sheet</Link>
                    <Link to='/profitloss' >Income (Profit & Loss)</Link>
                    <Link to='' className='active'>Cashflow Statement</Link>
                    
                </div> */}



                    <div className='productServList'>
                        <div className='dbTopsec pb-4 pt-2 noBackbtn'>
                            <Headtxt link="" title="My Profile" />

                            <div className='custButton'>
                                {/* <button className='commonButton addBtn'><Link to="/addproductservice"><i class="fa-regular fa-circle-plus"></i> Add Product</Link></button>&nbsp;&nbsp; */}
                                <Link className='commonButton expBtn' to="/changepassworduser"><i class="fa-light fa-lock"></i> Change Password</Link>
                            </div>
                        </div>


                        <div className='commonWhite addCustomerForm mb-3 p-3'>
                            {/* <h6>Date Range</h6> */}
                            <Form>
                                <div className='profileTop'>
                                    <div className='profileUpload'>
                                    <h6>{usertype==2?'Profile Photo':'Logo'}</h6>
                                        <div className='profileUpblock'>
                                            <label htmlFor="upload-button" className='uploadBtn'>
                                                {image.preview ? (
                                                    <img src={image.preview} alt="dummy" width="100" height="100" />
                                                ) : (
                                                    <>
                                                        <span className="fa-stack fa-2x">
                                                            <i class="fa-thin fa-image"></i>
                                                        </span>
                                                    </>
                                                )}
                                                <span className="profEdit m-0"><i class="fa-light fa-pen-line"></i></span>
                                            </label>

                                            <input
                                                type="file"
                                                accept="image/png, image/jpeg"
                                                id="upload-button"
                                                style={{ display: "none" }}
                                                onChange={onFile}
                                            />
                                        </div>
                                        <div className='brwseInst'>
                                            <h6 style={{marginTop:20}}>&nbsp; {profiledata?.company}</h6>
                                        </div>
                                    </div>
                                    {usertype==1 ?
                                    <Link to="/mysubscription" className='commonButton expBtn'>My Subscription</Link>
:null}

                                </div>
                                {/* <Form.Group className="row" controlId="exampleForm.ControlInput1">
                                    <Col md="12" className='mb-3'>
                                        <Customdropdown options={options} placeholdr="Consulting for" />
                                    </Col>
                                </Form.Group> */}
                                <h5>Personal Information</h5>
                                <Form.Group className="row" controlId="exampleForm.ControlInput1">
                                    <Col lg="6" className='mb-3'>
                                        <Form.Control type="text" placeholder="FirstName" value={profiledata?.fname} readOnly/>
                                    </Col>
                                    <Col lg="6" className='mb-3'>
                                        <Form.Control type="text" placeholder="LastName" value={profiledata?.lname} readOnly/>
                                    </Col>
                                </Form.Group>
                                
                                <Form.Group className="row" controlId="exampleForm.ControlInput1">
                                {usertype==1 ?
                                    <Col lg="6" className='mb-3'>
                                        <Form.Control type="text" placeholder="Company" value={profiledata?.company}/>
                                    </Col>
:null}
                                    <Col lg="6" className='mb-3'>
                                        <Form.Control type="mail" placeholder="Email" value={profiledata?.email} readOnly/>
                                    </Col>
                                </Form.Group>
                                <Form.Group className="row" controlId="exampleForm.ControlInput1">
                                    <Col lg="6" className='mb-3'>
                                        {/* <Form.Control type="text" placeholder="PhoneNo" /> */}
                                      
                      <PhoneInput
                        defaultCountry="ke"
                       value={profiledata?.phone}
                     onChange={(value) => onPhone({ target: { name: 'phone', value } })}
                        //onBlur={formik.handleBlur}
                        className='form-control'
                      />
         
                                    </Col>
                                    {usertype==1?
                                    <Col lg="6" className="mb-3">
    <Select
        options={options} // Ensure options is an array of objects with { value, label } format
        value={
            profiledata?.country
                ? { value: profiledata?.country, label: profiledata?.country }
                : { value: "", label: "Select Country" }
        }
        defaultValue={{ value: "KE", label: "KE" }} // Ensure defaultValue matches the { value, label } format
        onChange={onCountry} // Pass the updated function here
        
    />
</Col>
:null}
                               </Form.Group>
                               {usertype==1?
                               <Form.Group className="row" controlId="exampleForm.ControlInput1">
                                 
                                    <Col lg="6" className='mb-3'>
                                    <CurrencySelect
      value={selectedCurrency}
     onChange={handleCurrencyChange}
      placeholder="Select a currency"      
      className="slctCurrency"
      //readOnly
    />
                                    </Col>
                                </Form.Group>
                                :null}
                                <Col md="12" className='datepickr'>
                                        <button className='commonButton expBtn' onClick={(e)=>_SUBMIT_User_Edit(e)}>Save</button>&nbsp;&nbsp;
                                        {/* <button className='commonButton addBtn'>Cancel</button> */}
                                    </Col>
                                    {/* <div className='custButton'> */}
                                {/* <button className='commonButton addBtn'><Link to="/addproductservice"><i class="fa-regular fa-circle-plus"></i> Add Product</Link></button>&nbsp;&nbsp; */}
                                {/* <div className='brwseInst'>
                                <Link className='commonButton expBtn' to="/changepassworduser">Save</Link>
                                </div> */}
                            {/* </div> */}
                            {usertype==1?
                                <><h5 style={{ marginTop: 20 }}>Professional Information</h5><Form.Group className="row" controlId="exampleForm.ControlInput1">
                                        <Col md="12" className='mb-3'>
                                            <Form.Control as="textarea" placeholder="Address" rows={3} style={{ height: "40px" }} value={profiledata?.address} onChange={(e) => onAddress(e)} />
                                        </Col>
                                    </Form.Group><Form.Group className="row" controlId="exampleForm.ControlInput1">
                                            <Col lg="6" className='mb-3'>
                                                <Form.Control type="text" placeholder="Country" value={profiledata?.country} onChange={(e) => onCompanyCountry(e)} />
                                            </Col>
                                            <Col lg="6" className='mb-3'>
                                                <Form.Control type="text" placeholder="State" value={profiledata?.company_state} onChange={(e) => onCompanyState(e)} />
                                            </Col>

                                        </Form.Group><Form.Group className="row" controlId="exampleForm.ControlInput1">
                                            <Col lg="6" className='mb-3'>
                                                <Form.Control type="text" placeholder="City" value={profiledata?.company_city} onChange={(e) => onCompanyCity(e)} />
                                            </Col>
                                            <Col lg="6" className='mb-3'>
                                                <Form.Control type="text" placeholder="Zipcode" value={profiledata?.zipcode} onChange={(e) => onZipcode(e)} />
                                            </Col>

                                        </Form.Group><Form.Group className="row" controlId="exampleForm.ControlInput1">
                                            <Col lg="6" className='mb-3'>
                                                {/* <Form.Control type="text" placeholder="Financial" /> */}
                                                <Form.Select aria-label="Default select example" id="industry" name="industry" value={profiledata?.industry_type} onChange={(e) => onIndustry(e)}>
                                                    <option hidden selected>Select Business Industry</option>
                                                    <option value="Technology">Technology</option>
                                                    <option value="Finance">Finance</option>
                                                    <option value="Healthcare">Healthcare</option>
                                                    <option value="Retail">Retail</option>
                                                    <option value="Hospitality and Tourism">Hospitality and Tourism</option>
                                                    <option value="Manufacturing">Manufacturing</option>
                                                    <option value="Real Estate">Real Estate</option>
                                                    <option value="Entertainment and Media">Entertainment and Media</option>
                                                </Form.Select>
                                            </Col>
                                            <Col lg="6" className='mb-3'>
                                                <Form.Control type="text" placeholder="Website Url" value={profiledata?.website_url} onChange={(e) => onWebsiteUrl(e)} />
                                            </Col>
                                        </Form.Group><Col md="12" className='datepickr'>
                                            <button className='commonButton expBtn' onClick={(e) => _SUBMIT_User_Edit2(e)}>Save</button>&nbsp;&nbsp;
                                            {/* <button className='commonButton addBtn'>Cancel</button> */}
                                        </Col>
                                <h5 style={{marginTop:20}}>VAT Settings</h5>
                                <h6>Tax</h6>
                                {count?.length !== 0 &&

count?.map((data, index) => (
  <div className='row' key={data}>
    <div className='col-md-5'>
      <FloatingLabel
        controlId={`floatingInputTaxLabel${index}`}
        label="Tax Label"
        className="mb-3"
        
        
      >
        <Form.Control
          name="tax_label"
          type="text"
          placeholder="Tax Label"
          value={inputValues[index]?.tax_label || ''}
          onChange={(event) => onTax(event, index, 'tax_label')}
        />
        <span className='needfield'>*</span>
      </FloatingLabel>
    </div>
    <div className='col-md-5'>
      <FloatingLabel
        controlId={`floatingInputTaxRate${index}`}
        label="Tax Rate"
        className="mb-3"
      >
        <Form.Control
          name="tax_rate"
          type="number"
          placeholder="Tax Rate"
          value={inputValues[index]?.tax_rate || ''}
          onChange={(event) => onTax(event, index, 'tax_rate')}
        />
        <span className='needfield'>%</span>
      </FloatingLabel>
    </div>

    {count.length > 1 && (
      <div className='col-md-2'>
        <button
          onClick={() => handleRemoveDiv(index)}
          className='delIcon'
        >
          <i className="fa-regular fa-trash"></i>
        </button>
      </div>
    )}
  </div>
))}


<button type='submit' onClick={(e) => handleAppendDiv(e)} className='appendbtn'><i class="fa-regular fa-circle-plus"></i> Add a tax</button>



                                {/* <Form.Group className="row" controlId="exampleForm.ControlInput1">
                                    <Col lg="6" className='mb-3'>
                                        <Form.Control type="text" placeholder="Tax Label" value={profiledata?.tax_label}/>
                                    </Col>
                                    <Col lg="6" className='mb-3'>
                                        <Form.Control type="text" placeholder="tax_rate" value={profiledata?.tax_rate}/>
                                    </Col>
                                </Form.Group>
                                <Link><i class="fa-regular fa-circle-plus"></i> Add a tax</Link> */}
                                {/* <h6 className='mt-3'>Purchase Tax</h6>
                                <Form.Group className="row" controlId="exampleForm.ControlInput1">
                                    <Col lg="6" className='mb-3'>
                                        <Form.Control type="text" placeholder="Tax Label" />
                                    </Col>
                                    <Col lg="6" className='mb-3'>
                                        <Form.Control type="text" placeholder="12.00" />
                                    </Col>
                                </Form.Group>
                                <Link><i class="fa-regular fa-circle-plus"></i> Add a tax</Link>*/}

                                <Form.Group className="row mt-3" controlId="exampleForm.ControlInput1">
                                    <Col md="12" className='datepickr'>
                                        <button className='commonButton expBtn' onClick={(e)=>_SUBMIT_User_Tax_Edit(e)}>Save</button>&nbsp;&nbsp;
                                        {/* <button className='commonButton addBtn'>Cancel</button> */}
                                    </Col>

                                </Form.Group> 
                                </>
                                    :null}
                            </Form>
                        </div>




                    </div>
                </div>
            </Sidebar>





        </div>
    )
}


export default Myprofile