import react, { useState } from 'react';
import '../src/assets/css/style.css';
import './App.css';
import Accordion from 'react-bootstrap/Accordion';
import 'bootstrap/dist/css/bootstrap.min.css';
import Footer from '../src/assets/pages/layout/Footer';
// import Logo from '../public/logo.png';
import { useEffect } from 'react';
// import Index from './assets/pages/home/index';
// import Index from './assets/pages/router/Index';
import Index from './assets/pages/router/index';
import { Form, } from 'react-router-dom';
import { HashRouter } from 'react-router-dom';
import Login from './assets/pages/authentication/Login';
import Logo from '../src/assets/images/logo.png';
import Signup from './assets/pages/authentication/Signup';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// import Changepassword from './assets/pages/authentication/Changepassword';

import { BrowserRouter as Router, Route } from 'react-router-dom';
import Dashboard from './assets/pages/dbstructure/Dashboard';
import SpinnerComponent from './assets/components/Spinner'
import { ReactSession } from 'react-client-session';
function App() {
  //  useEffect(() => {
  //   ReactSession.setStoreType("localStorage");
  //   const sessionToken = ReactSession.get("username");
  //   console.log('sessionToken',sessionToken)
  // }, []);
  const getSessionData = (key) => {
    return ReactSession.get(key);
  };

  // Function to set session data
  const setSessionData = (key, value) => {
    ReactSession.set(key, value);
  };

  // Function to clear all sessions
  const clearAllSessions = () => {
    ReactSession.remove("username"); // Remove specific session key
    localStorage.clear(); // Clear all localStorage items
  };
  useEffect(() => {
    ReactSession.setStoreType("localStorage");
    const sessionToken = ReactSession.get("username");
    console.log('sessionToken', sessionToken);

    const checkSessionTimeout = () => {
      const lastActivity = getSessionData('lastActivity');
      const now = new Date().getTime();
      const sessionTimeout = 1000* 60 * 1000; // 1 hour in milliseconds
      console.log('lastActivity',now - lastActivity)
      if (lastActivity && now - lastActivity > sessionTimeout) {
        clearAllSessions();
        toast.error("Session timed out due to inactivity.");
     // window.location.href = 'https://nodeserver.mydevfactory.com/projects/debarati/arghya/1simpleClick/1simpleClickWebportal/#/login'; // Redirect to login page
   window.location.href = 'https://1simpleclick.com/#/login';
       console.log('sessionToken', sessionToken);

      } else {
        setSessionData('lastActivity', now);
      }
    };
    const interval = setInterval(checkSessionTimeout(), 1000 * 3600); // Check every minute

    const handleUserActivity = () => setSessionData('lastActivity', new Date().getTime());

    window.addEventListener('click', handleUserActivity);
    window.addEventListener('keypress', handleUserActivity);

    return () => {
      clearInterval(interval);
      window.removeEventListener('click', handleUserActivity);
      window.removeEventListener('keypress', handleUserActivity);
    };
  });
  const [visible, setVisible] = useState(false);
  const [isSpinner, setIsSpinner] = useState(false);
  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop;
    if (scrolled > 300) {
      setVisible(true)
    }
    else if (scrolled <= 300) {
      setVisible(false)
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
      /* you can also use 'auto' behaviour 
         in place of 'smooth' */
    });
  };

  window.addEventListener('scroll', toggleVisible);



  return (
    <>
      <div className={isSpinner ? "md:ml-64 overlay" : "md:ml-64"}>
        <HashRouter>
          <ToastContainer />

          <button className='scrollTop' onClick={scrollToTop} style={{ display: visible ? 'inline' : 'none' }}>
            <i class="fa-thin fa-arrow-up"></i>
          </button>


          <Index setIsSpinner={setIsSpinner} />
          {/* <Dashboard/> */}
          {/* <Changepassword logo={Logo} /> */}
          <Footer />
        </HashRouter>
        <SpinnerComponent isLoading={isSpinner} />
      </div>
    </>
  );
}

export default App;
