import webApi, { baseURL } from "../../WebApi/WebApi";

//FOR LOGIN 

export const getLogin = async (email, password, onSuccess, onFailure) => {
    console.log(email, password, "login")

    try {
        const res = await webApi.post(

            `/login`,
            {
                email: email,
                password: password,

            }
        );
        if (res.status === 200) {


            const r = res.data;
            console.log(r, "rerere")

            return onSuccess(r);

        } else {
            onFailure("Something Wrong! Please Try again later" + res.data);
        }
    } catch (error) {
        console.log("fdgdf")
    }
};
export const getChangePassword = async (password) => {
    console.log(password, "password change");

    try {
        const res = await webApi.post(`/subuser-password-update`, { password });

        if (res.status === 200) {
            const r = res.data;
            console.log(r, "rerere");
            return r; // Return the response data to the calling function.
        } else {
            throw new Error("Something went wrong! Please try again later.");
        }
    } catch (error) {
        console.error("Error in password update:", error);
        throw error; // Ensure errors are propagated to the caller.
    }
};
export const getChangePasswordUser = async (oldpassword,password) => {
    console.log(oldpassword,password, "password change");

    try {
        const res = await webApi.post(`/updatePassword`, 
            {old_password:oldpassword,password:password });

        if (res.status === 200) {
            const r = res.data;
            console.log(r, "rerere");
            return r; // Return the response data to the calling function.
        } else {
            throw new Error("Something went wrong! Please try again later.");
        }
    } catch (error) {
        console.error("Error in password update:", error);
        throw error; // Ensure errors are propagated to the caller.
    }
};
