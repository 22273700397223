import { React, useState, useEffect, useCallback } from 'react'
import Sidebar from '../../layout/Sidebar'
import Dbheader from '../../layout/Dbheader'
import Form from 'react-bootstrap/Form';
import Headtxt from '../Headtxt';
import Col from 'react-bootstrap/Col';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Modal from 'react-bootstrap/Modal';
import Pagination from 'react-bootstrap/Pagination';
import { PhoneInput } from 'react-international-phone';
import { City, Country, State } from "country-state-city";
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import { getSearchSupplier, getAddSuppliers, getImportCustomer, getSupplierList } from "../../../../Service/Suppliers/SuppliersService";
import { ToastContainer, toast } from 'react-toastify';
import { useLocation, useNavigate } from 'react-router-dom';
import { addInvoice, addSalesOrder, getAddCustomer, getAddSalesquotation, getCustomerList, getInvoiceList, getProductServiceList, getProductServiceListBYSupplierId, getSearchCustomer, getSendInvoice, getSendSalesOrder, getSendSalesQuote } from "../../../../Service/Customer/CustomerService";
import { Link } from 'react-router-dom'
import { getEditSupplier, getSuppliereListById } from "../../../../Service/Suppliers/SuppliersService";
import { exitingProduct, getAddProductService, getImportProductService, getInventoryAccounts, getUserAccountList, getUserccountList } from "../../../../Service/ProductService/ProductService";
import { addManualExpenses, getAccounttId, getAddquotation, getBillList, getexpensesaccount, getManualPaymentData, getSendQuote } from "../../../../Service/Quotation/QuotationService";
import moment from 'moment';
import { CSVLink } from "react-csv";
import axios from 'axios';
import getSymbolFromCurrency from 'currency-symbol-map'
import Swal from 'sweetalert2';
import { getAddInventoryaccount } from '../../../../Service/Account/AccountService';
import instance from '../../../../WebApi/WebApi';
import { Row } from 'react-bootstrap';
const ManualExpensesEdit = ({ setIsSpinner }) => {
  const navigate = useNavigate();
      const location = useLocation();
      const searchParams = new URLSearchParams(location.search);
      const id = searchParams.get('id');
  const [InvoiceId,setInvoiceId] = useState("");
  const [productName,setProductName] = useState("");
  const [productdescription,setProductDescription] = useState("");
  const [otherdescription,setOtherDescription] = useState("");
  const [productreceipt,setProductReceipt] = useState("");
  const [productunitprice,setProductUnitPrice] = useState("");
  const [producttotalprice,setProductTotalPrice] = useState("");
  const [producttax,setProductTax] = useState("");
  const [productdiscount,setProductDiscount] = useState("");
  const [Account, setAccount] = useState([]);
  const [select, setSelect] = useState(false);
  const [selectedaccountId, setSelectedAccountId] = useState('');
  const [ExpnesesAccount, setExpnesesAccount] = useState([]);
  const [categoryIdList, setCategoryIdList] = useState([]);
  const [selectedexpensesaccountId, setSelectedexpensesAccountId] = useState('');
  const [selectedcategoryId, setSelectedCategoryId] = useState('');
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const [finalStartDate, setFinalStartDate] = useState("");
  const [finalEndDate, setFinalEndDate] = useState("");
  const [selectedInventory, setSelectedInventory] = useState("");
  const [selectedExpense, setSelectedExpense] = useState("");
  const toDate = moment(endDate);
  const formattedToDate = toDate.format('YYYY-MM-DD');
  const [error, setError] = useState(null);
  const [inventoryOptions, setInventoryOptions] = useState([]);
  const [costOfGoodsSoldList, setCostOfGoodsSoldList] = useState([]);
  const [incomeAccList, setIncomeAccList] = useState([]);
  const [supplier1, setSupplier1] = useState(false);
  const [dataPdf, setDataPdf] = useState("");
 const [manulalPaymentMethod, setManulaPaymentMethod] = useState("");
  const [show, setShow] = useState(false);
  const [show2, setShow2] = useState(false);
  const handleClose2 = () => setShow2(false);
  const [showPdf, setShowPdf] = useState(false);
  const handleShow = () => setShow(true);
  const handleClosePdf = () => setShowPdf(false);
  const vclose = () => {
    setShowPdf(false);
    navigate('/manualexpenses')
  }
  const cancel = () => {
    navigate('/manualexpenses')
  }
  const onManulalPaymentMethod = (e) => {
    // e.preventDefault();
    setManulaPaymentMethod(e.target.value)
}
  const [views, setViews] = useState(false)
  const [emailQuote, setEmailQuote] = useState("")
  const [addressQuote, setAddressQuote] = useState("")
  const [selectedType, setSelectedType] = useState("")
  const [checks, setChecks] = useState("false");
  const [addaccount, setAddaccount] = useState(false);
  const [addexpensesaccount, setAddexpenseseaccount] = useState(false);
  const [addexpenseaccount, setAddExpenseaccount] = useState(false);
  const accountpop = () => setAddaccount(true);
  const addaccountClose = () => setAddaccount(false);
  const accountpop2 = () => setAddExpenseaccount(true);
 // const addexpensesaccountClose = () => setAddExpenseaccount(false);
  const addexpenaccountClose = () => setAddExpenseaccount(false);
  const [accountId, setAccountId] = useState("");
  const [accountName, setAccountName] = useState("");
  const [accountDate, setAccountDate] = useState("");
  const [accountdescription, setAccountDescription] = useState("");
  const [bill, setBill] = useState([]);
  const [invoice, setInvoice] = useState([]);
   const [userid, setUserId] = useState(null);
  //email send details 
     async function getBill() {
          getBillList("").then(function (result) {
              const response = result?.data;
         console.log(response, "rrrr")
              setBill(response?.data);
             
          });
      }
     async function getInvoiceDataList() {
             getInvoiceList("").then(function (result) {
                 const response = result?.data;
                 console.log(response, "rrrr")
                 setInvoice(response?.data);
             });
         } 
      useEffect(() => {
          getBill();
          getInvoiceDataList();
          getManualExpensesData()
      }, []);
      const getManualExpensesData=useCallback( async()=> {
        // const data={id:id}
        getManualPaymentData(id).then(function (result) {
            const response = result?.data;
            console.log(response, "rrrr")
           // setAccount(response?.data?.accounts);
         setProductName(response?.data?.product_name);
         setSelectedCategoryId(response?.data?.category_id);
          setProductDescription(response?.data?.product_description);
          setInvoiceId(response.data.invoice_id || response.data.bill_id);
          setSelectedType(response.data.invoice_id ? "invoice_id" : "bill_id");
         setProductTotalPrice(response?.data?.total_price);
         setProductReceipt(response?.data?.receipt_id);
         setManulaPaymentMethod(response?.data?.payment_method);
         setSelectedexpensesAccountId(response?.data?.expense_account);
         setSelectedAccountId(response?.data?.cash_account);
         setOtherDescription(response?.data?.other_description);
         setStartDate(new Date(response?.data?.purchase_date));
         setFinalStartDate(response?.data?.purchase_date);
        // setSelect(true)
        });
    },[]);
  const onAccountName = (e) => {
    setAccountName(e.target.value)
  }
  const onAccountId = (e) => {
    setAccountId(e.target.value)
  }
  const onAccountDescription = (e) => {
    setAccountDescription(e.target.value)
  }
  const handleAccountChange = (e) => {
    const selectedId = e.target.value;
    setSelectedAccountId(selectedId);

};
const handleExpenses = (e) => {
  const selectedId = e.target.value;
  setSelectedexpensesAccountId(selectedId);

};
const handleCategoryId = (e) => {
  const selectedId = e.target.value;
  setSelectedCategoryId(selectedId);
 // getExpensesAccount();
};
  const CustomDropdown = ({ placeholder, value, }) => {
    console.log('options==', value)
    return (
      <select value={value} className="form-control">

        <option key='1234' value={value}>{value}</option>



      </select>
    );
  };
   useEffect(() => {
     
        setUserId(localStorage.getItem("1Simpleclick_UserID"));
      }, []);
      console.log(userid,'userId===')
  const getAccount=useCallback( async()=> {
      getAccounttId("").then(function (result) {
          const response = result?.data;
          console.log(response, "rrrr")
          setAccount(response?.data?.accounts);
       
        
      });
  },[]);
  const getExpensesAccount=useCallback( async()=> {
    getexpensesaccount("").then(function (result) {
        const response = result?.data;
        console.log(response, "rrrr")
       setExpnesesAccount(response?.data?.expenseAccountList);
       setCategoryIdList(response?.data?.categoryIdList);
      
    });
},[])
  useEffect(()=>{
          getAccount();
          getExpensesAccount();
  },[])
  const onEmailQuote = (e) => {
    setEmailQuote(e.target.value)
  }

  const onAddressQuote = (e) => {
    setAddressQuote(e.target.value)
  }


  const onSendPdf = () => {
    setIsSpinner(true)
    getSendInvoice(dataPdf?.data?.details?.id, dataPdf?.data?.customerEmail, `Customer order - ${dataPdf?.data?.details?.invoice_id}`,
      addressQuote, checks,
      (r) => {
        console.log(r, "response")
        // if (r.success === true) {
        //   toast.success(r.message)
        //   navigate('/quotelisting')


        // } 
        if (r.success === true) {
          setIsSpinner(false)
          Swal.fire({
            title: r.message,
            //  text: 'The product has been added successfully!',
            icon: 'success',
            confirmButtonText: 'OK',
            customClass: {
              confirmButton: 'my-confirm-button'
            }
          }).then(() => {
            navigate('/invoice')
          })
        }
        else {
          console.log("nononononono")
          toast.error(r.message)
          setIsSpinner(false)
        }
      })
  }

  const checkMe = (e) => {
    setChecks('true');
    // console.log(e.target.checked, "Yesssssssssss");
    console.log(e.target.checked ? "Yesssssssssss" : "Noooooo");
  }


  const toggleHandlerb = (e) => {
    e.preventDefault();
    setViews(true)
  }
  const toggleHandlerc = (e) => {
    e.preventDefault();
    setViews(false)
  }
 
  const onButtonClick = () => {
    const pdfUrl = dataPdf?.data?.pdf;
    const link = document.createElement("a");
    link.href = pdfUrl;
    link.target = '_black';
    // link.download = "document.pdf"; // specify the filename
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  


  const onInvoiceId = (e) => {
    const selectedValue = JSON.parse(e.target.value);
    setInvoiceId(selectedValue.id);
    setSelectedType(selectedValue.type); // Store the type separately
    console.log(selectedValue);
  };
console.log(InvoiceId,'oiuyy')
  const onProductName = (e) => {
    setProductName(e.target.value);
  }
  const onProductDescription = (e) => {
    setProductDescription(e.target.value);
  }
  const onProductQuantity = (e) => {
    setProductReceipt(e.target.value);
  }
  const onProductUnitPrice = (e) => {
    setProductUnitPrice(e.target.value);
  }
  const onProductTotalPrice = (e) => {
    setProductTotalPrice(e.target.value);
  }
  const onProductTax = (e) => {
    setProductTax(e.target.value);
  }
  const onProductDiscount = (e) => {
    setProductDiscount(e.target.value);
  }
  const onOtherDiscount = (e) => {
    setOtherDescription(e.target.value);
  }
  const onDone = () => {
if(finalStartDate=='')
{ toast.error('Please select date')}
else if(productName=='')
  { toast.error('Please enter name')}
else if(producttotalprice=='')
  { toast.error('Please enter amount')}
else if(manulalPaymentMethod=='')
  {toast.error('Please select payment method')}
else if(selectedcategoryId=='')
  {toast.error('Please select expenses category')}
else if(selectedexpensesaccountId=='')
  {toast.error('Please select expenses account')}
else if(selectedaccountId=='')
  {toast.error('Please select cash account')}
else
 { const Data={user_id:userid,
  id:id,
    ...(InvoiceId?.bill_id ? { bill_id: InvoiceId?.bill_id } : { invoice_id: InvoiceId?.invoice_id }), // Dynamically set the key
    product_name:productName,
    product_description:productdescription,
   receipt_id:productreceipt,
   // product_unit_price:productunitprice,
    total_price:producttotalprice,
   // tax:producttax,
   // discount:productdiscount,
    purchase_date:finalStartDate,
    expense_account:selectedexpensesaccountId,
    cacategory_id:selectedcategoryId,
    cash_account:selectedaccountId,
    payment_method:manulalPaymentMethod,
    other_description:otherdescription
  }
  setIsSpinner(true)
      addManualExpenses(Data, (r) => {
        setIsSpinner(false)
        if (r.success === true) {
          Swal.fire({
            title: r.message,
            //  text: 'The product has been added successfully!',
            icon: 'success',
            confirmButtonText: 'OK',
            customClass: {
              confirmButton: 'my-confirm-button'
            }
          }).then(() => {
            navigate("/manualexpenses");
        })
        }

        else {
          toast.error(r.message);

        }
      }).catch((error) => {
        toast.error("An error occurred. Please try again.");
        setIsSpinner(false)
      });}
    
  };


  


  const onFromDate = (e) => {
    setStartDate(e)
    console.log(e, "eeeeeeeeeeeeeee")
    const fromDate = moment(e).format('MM/DD/YYYY');
    console.log(fromDate, "fromDate");
    setFinalStartDate(fromDate)
  }

  const onToDate = (e) => {
    setEndDate(e)
    console.log(e, "eeeeeeeeeeeeeee")
    const toDate = moment(e).format('MM/DD/YYYY');
    console.log(toDate, "toDate")
    setFinalEndDate(toDate)
  }


  const onSave2 = (id) => {

    setSelectedExpense(accountName)
    setSelectedInventory(accountName)
    getAddInventoryaccount(id, accountName, accountId, accountdescription,
      (r) => {
        console.log(r, "response")
        // if (r.success === true) {
        //   toast.success(r.message)
        //   // setAddaccount(false)
        //   // getUserAccounts();
        //   // setSelectedSubtype('')
        //   addaccountClose()
        //   getInventoryDataList();
        // }
        if (r.success === true) {
          Swal.fire({
            title: r.message,
            //  text: 'The product has been added successfully!',
            icon: 'success',
            confirmButtonText: 'OK',
            customClass: {
              confirmButton: 'my-confirm-button'
            }
          }).then(() => {
            addaccountClose();
            getAccount();
            getExpensesAccount();
            addexpenaccountClose()
          })
        }
        else {
          console.log("nononononono")
          toast.error(r.message)
          // setSelectedSubtype('')
        }
      })

  }
  return (
    <div className="producservlisting pdt">
      <Dbheader />
      <Sidebar>
        <div className='customerMain'>
          <div className='productServList'>
            <div className='dbTopsec pb-4 pt-2'>
              <Headtxt link="/manualexpenses" title="Edit Manual Expense" />

            </div>

            <div className='commonWhite addCustomerForm p-3 mb-3'>
              <Form>
                <Form.Group className="row mb-2" controlId="exampleForm.ControlInput1">
  <Col md="4" className='mb-2'>
    {/* <Form.Control
      type="text"
      placeholder="Invoice Id"
    //  readOnly
      onChange={onInvoiceId}
      value={InvoiceId}
    /> */}
    
    <Form.Select aria-label="Default select example" onChange={onInvoiceId} value={JSON.stringify({ id: InvoiceId, type: selectedType })}>
  <option hidden>Invoice/Bill Id</option>
  { [...bill, ...invoice].map((item, i) => (
      <option key={i} value={JSON.stringify({ 
          id: item.bill_id || item.invoice_id, 
          type: item.bill_id ? "bill_id" : "invoice_id" 
      })}>
          {item.bill_id ? `Bill ID: ${item.bill_id}` : `Invoice ID: ${item.invoice_id}`}
      </option>
  ))}
</Form.Select>



                                 
  </Col>
  <Col md="4" className='mb-2'>
    <Form.Control
      type="text"
      placeholder="Receipt Id "
    //  readOnly
      onChange={onProductQuantity}
      value={productreceipt}
    />
  </Col>
  <Col md="4" className='datepickr mb-2'>
                    <DatePicker
                      selectsStart
                      selected={startDate}
                      onChange={onFromDate}
                      placeholderText="Date"
                    />
                 
  </Col>
</Form.Group>
               
<Form.Group className="row mb-2" controlId="exampleForm.ControlInput1">
<Col md="4" className='mb-2'>
    <Form.Control
      type="text"
      placeholder="Name"
    //  readOnly
      onChange={onProductName}
      value={productName}
    />
  </Col>
  <Col md="4" className='mb-2'>
    <Form.Control
      type="number"
      placeholder="Amount"
    //  readOnly
      onChange={onProductTotalPrice}
      value={producttotalprice}
    />
  </Col>
  
<Col md="4" className='mb-3'>
  <Form.Select aria-label="Default select example" onChange={onManulalPaymentMethod}
                                    value={manulalPaymentMethod}>
    <option selected hidden>Payment Method</option>
                                    <option value="Credit Cards">Credit Cards</option>
                                    <option value="Debit Cards">Debit Cards</option>
                                    <option value="NetBanking">NetBanking</option>
                                    <option value="Mobile Payments">Mobile Payments</option>
                                    <option value="Cash">Cash</option>
                                    <option value="Cheque">Cheque</option>
                                    <option value="Online Payment">Online Payment</option>
    
  </Form.Select>
</Col>
</Form.Group>
<Form.Group className="row mb-2" controlId="exampleForm.ControlInput1">
<Col md="4" className='mb-3'>
  <Form.Select aria-label="Default select example" 
  value={selectedcategoryId}
  onChange={(e) => {e.target.value=== "add"? accountpop() : handleCategoryId(e) }}>
    <option selected hidden>Category List</option>
     {categoryIdList.map((item, i) =>
        <option key={i} selected={select && categoryIdList.length===i+1} value={item.id}>{item.subtype_name}</option>
    )}
    
  </Form.Select>
</Col>
<Col md="4" className='mb-3'>
  <Form.Select aria-label="Default select example" 
  value={selectedexpensesaccountId}
  onChange={(e) => {e.target.value=== "add"? accountpop2() : handleExpenses(e) }}>
    <option selected hidden>Expenses Account</option>
   
      <option value="add"><i className="fa-solid fa-plus"></i>+ Add an Account</option>
     {ExpnesesAccount.filter(item => item.subtype_id == selectedcategoryId).map((item, i) =>
        <option key={i} selected={select && ExpnesesAccount.length===i+1} value={item.id}>{item.account_name}</option>
    )}
    
  </Form.Select>
</Col>
<Col md="4" className='mb-3'>
  <Form.Select aria-label="Default select example"
  value={selectedaccountId}
  onChange={(e) => {e.target.value=== "add"? accountpop() : handleAccountChange(e) }}>
    <option selected hidden>Cash Account</option>
   
      <option value="add"><i className="fa-solid fa-plus"></i>+ Add an Account</option>
     { Account.map((item, i) =>
        <option key={i} selected={select && Account.length===i+1} value={item.account_id}>{item.account_name}</option>
    )}
    
  </Form.Select>
</Col>


  {/* <Col md="4" className='mb-2'>
    <Form.Control
      type="number"
      placeholder="Unit Price"
    //  readOnly
      onChange={onProductUnitPrice}
      value={productunitprice}
    />
  </Col> */}
 
</Form.Group>
<Form.Group className="row mb-2" controlId="exampleForm.ControlInput1">



<Col md="6" className='mb-2'>
    <Form.Control
      as="textarea"
      placeholder="Expenses Description"
    //  readOnly
      onChange={onProductDescription}
      value={productdescription}
      style={{ height: '60px' }}
    />
  </Col>
  <Col md="6" className='mb-2'>
    <Form.Control
      as="textarea"
      placeholder="Others Description"
    //  readOnly
      onChange={onOtherDiscount}
      value={otherdescription}
      style={{ height: '60px' }}
    />
  </Col>
</Form.Group>
              </Form>
            </div>
            <button className='commonButton expBtn' onClick={onDone}>Done</button>&nbsp;&nbsp;
            <button className='commonButton addBtn' onClick={cancel} >Cancel</button>


          </div>
        </div>
      </Sidebar>


      <Modal size="lg" show={showPdf} backdrop="static" onHide={handleClosePdf} className="salesExport">
        <div className='salesExpCol fullHgt'>
          <Modal.Header>
            {/* <Modal.Title>Modal heading</Modal.Title> */}
          </Modal.Header>
          <div className='modIframe'><iframe src={dataPdf?.data?.pdf} /></div>
          <div className='expRgt'>
            {/* <button className='commonButton expBtn btnshadow'><i class="fa-regular fa-envelope"></i> Email</button>
                        <button className='commonButton wtBtn btnshadow'><i class="fa-light fa-print"></i> Print</button>
                        <button className='commonButton wtBtn btnshadow'><i class="fa-light fa-down-to-bracket"></i> Download</button> */}

            {!views ?
              <div>
                <button className='commonButton expBtn btnshadow' onClick={toggleHandlerb}><i class="fa-regular fa-envelope"></i> Email</button>
                <button className='commonButton wtBtn btnshadow' onClick={onButtonClick}><i class="fa-light fa-print"></i> Print</button>
                <button className='commonButton wtBtn btnshadow' onClick={onButtonClick}><i class="fa-light fa-down-to-bracket"></i> Download</button><br /><br /><br /><br />
                <button className='commonButton expBtn btnshadow' onClick={vclose}><i class="fa-solid fa-xmark"></i> Close</button>

              </div> :
              <Form>
                <button className='commonButton backArrow' onClick={toggleHandlerc}><i class="fa-solid fa-arrow-left"></i> Back</button>
                <Form.Group className="mb-3 salesRform" controlId="exampleForm.ControlInput1">
                  <Col md="12" className='mb-2'>
                    <label>To <span className='redColor'>*</span></label>
                    <Form.Control type="text" placeholder="Johndoe@gmail.com" value={dataPdf?.data?.customerEmail} />
                  </Col>
                  <Col md="12" className='mb-2'>
                    <label>Subject :</label>
                    <Form.Control type="text" placeholder="ABC Company" value={`Customer Invoice - ${dataPdf?.data?.details?.invoice_id}`} />
                  </Col>
                  <Col md="12" className='mb-3'>
                    <label>Message :</label>
                    <Form.Control as="textarea" placeholder="Enter your message" rows={3} className='mb-3' onChange={onAddressQuote} />
                  </Col>
                  <div className='salesCheck mb-3'><Form.Check type="checkbox" onClick={checkMe} /> Send me a copy</div>
                  <button type="button" className='commonButton expBtn btnshadow' onClick={onSendPdf}>Send</button>

                </Form.Group>
              </Form>
            }


          </div>
        </div>
      </Modal>
      {/* ===============================Inventory Account Modal=========================== */}
      <Modal size="lg" show={addaccount} onHide={addaccountClose} centered className='customerModal sloModal'>
        <Modal.Header closeButton>
          <Modal.Title>
            <h5>Add an Account</h5>
          </Modal.Title>


        </Modal.Header>
        <Modal.Body>
          <div className='commonWhite addCustomerForm mb-3'>
            <Form>
              <Form.Group className="row" controlId="exampleForm.ControlInput1">
                <Col md="12" className='mb-3'>
                  <CustomDropdown placeholder="Account Type" value="Cash" />
                </Col>
              </Form.Group>
              <Form.Group className="row" controlId="exampleForm.ControlInput1">
                <Col md="6" className='mb-3'>
                  <Form.Control type="text" placeholder="Account name" onChange={onAccountName} />
                </Col>
                <Col md="6" className='mb-3'>
                  <Form.Control type="text" placeholder="Account ID" onChange={onAccountId} />
                </Col>
              </Form.Group>
              <Form.Group className="row" controlId="exampleForm.ControlInput1">
                <Col md="12" className='mb-0'>
                  <Form.Control as="textarea" placeholder="Description" rows={3} onChange={onAccountDescription} />
                </Col>
              </Form.Group>
            </Form>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button className='commonButton addBtn' onClick={addaccountClose}>Cancel</button>
          <button className='commonButton expBtn' onClick={() => onSave2('1')} >Save</button>
        </Modal.Footer>
      </Modal>
      
      {/* ===============================Expense Account Modal=========================== */}
      <Modal size="lg" show={addexpenseaccount} onHide={addexpenaccountClose} centered className='customerModal sloModal'>
        <Modal.Header closeButton>
          <Modal.Title>
            <h5>Add an Account</h5>
          </Modal.Title>


        </Modal.Header>
        <Modal.Body>
          <div className='commonWhite addCustomerForm mb-3'>
            <Form>
              <Form.Group className="row" controlId="exampleForm.ControlInput1">
                <Col md="12" className='mb-3'>
                  <CustomDropdown placeholder="Account Type" value="Expenses" />
                </Col>
              </Form.Group>
              <Form.Group className="row" controlId="exampleForm.ControlInput1">
                <Col md="6" className='mb-3'>
                  <Form.Control type="text" placeholder="Account name" onChange={onAccountName} />
                </Col>
                <Col md="6" className='mb-3'>
                  <Form.Control type="text" placeholder="Account ID" onChange={onAccountId} />
                </Col>
              </Form.Group>
              <Form.Group className="row" controlId="exampleForm.ControlInput1">
                <Col md="12" className='mb-0'>
                  <Form.Control as="textarea" placeholder="Description" rows={3} onChange={onAccountDescription} />
                </Col>
              </Form.Group>
            </Form>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button className='commonButton addBtn' onClick={addexpenaccountClose}>Cancel</button>
          <button className='commonButton expBtn' onClick={() => onSave2(selectedcategoryId?selectedcategoryId:'25')} >Save</button>
        </Modal.Footer>
      </Modal>
    
    </div>
  )
}


export default ManualExpensesEdit